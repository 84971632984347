import { getClassById } from '@/Models/Selectors';
import store from '@/Models/store';
import { updateClass } from '@/Services/Class';
import { ListOfAllLanguages, RTLlanguages } from '@/Services/Constants';
import { ClearOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
    _class: Class;
};


const ClassSetting = ({ _class }: Props) => {
    // const thisClass = getClassById(store.getState())(_class.id);
    const { confirm } = Modal;
    const [language, setLanguage] = useState<string>(_class.settings?.language || "");
    const onFinish = (values: any) => {
        console.log('Success:', values);
        setSubject(values.subject);
        message.loading('Updating', 0);
        if (values.name && values.description) {
            const updatedClass = { ..._class, name: values.name, description: values.description, settings: { ..._class.settings, language } };
            updateClass(updatedClass).then(() => {
                console.log("updatedClass is ", updatedClass);
                message.destroy();
                message.success('Class updated');
            }).catch(() => {
                message.destroy();
                message.error('Something went wrong. Please try again in some time.');
            })
        }

    };

    const [subject, setSubject] = useState("")
    const [batch, setBatch] = useState("")
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ name: _class.name, description: _class.description, language: _class.settings.language })
        // setSubject(_class.name);
        // setBatch(_class.description);
    })

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onArchive = (archive: boolean) => {
        const msg = + archive ? "Are you sure you want to Archive this class?" : "Are you sure you want to Unarchive this class?"

        confirm({
            title: msg,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                const updatedClass: Class = { ..._class, is_archived: archive };
                updateClass(updatedClass).then(() => {
                    console.log("updatedClass is ", updatedClass);
                    message.destroy();
                    message.success('Class updated');
                }).catch(() => {
                    message.destroy();
                    message.error('Something went wrong. Please try again in some time.');
                })

            },
            onCancel() {
            },
        });


    }

    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                // initialValues={{ remember: true }}
                initialValues={_class}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            // autoComplete="off"

            >
                <Form.Item
                    initialValue={subject}
                    label="Subject"
                    name="name"
                    rules={[{ required: true, message: 'Please input the subject!' }]}
                >
                    <Input placeholder='Enter name of the course you are going to conduct' />
                </Form.Item>

                <Form.Item
                    initialValue={batch}
                    label="Batch Name"
                    name="description"
                    rules={[{ required: true, message: 'Please input the batch name!' }]}
                >
                    <Input placeholder='Enter name of the batch/class (example Class B - 2021)' />
                </Form.Item>

                <Form.Item
                    label="Language"
                    name="language"
                    rules={[{ required: false, message: 'Please enter your phone number!' }]}
                >
                    <Select
                        showSearch
                        style={{ textAlign: "left" }}
                        placeholder="Select Language"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        defaultValue={language}
                        onChange={(value: string) => setLanguage(value)}
                        options={Object.keys(ListOfAllLanguages).map((name, index) => {
                            const nativeName = Object.values(ListOfAllLanguages)[index].nativeName;
                            const englishName = Object.values(ListOfAllLanguages)[index].name
                            const finalName = nativeName === englishName ? englishName : `${englishName} (${nativeName})`
                            return {
                                value: Object.keys(ListOfAllLanguages)[index], label: finalName
                            }
                        })} />
                    {language && RTLlanguages.includes(language) ?
                        <div className='text-xs text-gray-900 text-right'>All text would be right to left</div>
                        : null}
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button
                        type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form.Item>
            </Form>
            <div>
                {_class.is_archived ?
                    <>
                        <div className='text-blue-600'>This class is marked as archived? Unarchive it to show it in the dashboard and conduct sessions.
                            {/* Note, you can still access all the data and information of the class later. */}
                        </div>
                        <div>
                            <Button onClick={(e) => onArchive(false)} className='mt-2' type="default">
                                Unarchive
                            </Button>
                        </div>
                    </>
                    : <>
                        <div className='text-orange-600'>This class is not active anymore? Archive it to hide from the dashboard.
                            {/* Note, you can still access all the data and information of the class later. */}
                        </div>
                        <div>
                            <Button onClick={(e) => onArchive(true)} className='mt-2' type="default">
                                <ClearOutlined /> Archive
                            </Button>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default ClassSetting