import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Composition } from "atomic-layout";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Input,
  Layout,
  Modal,
  notification,
  PageHeader,
  Result,
  Space,
  Typography,
} from "antd";
import QuestionBuilder from "@/Components/QuestionBuilder";
import Question from "@/Components/Question";
import {
  askQuestion,
  getNextQuestionLabel,
  endCurrentSession,
  fetchLatestSessionStats,
} from "@/Services/Session";
import QuestionsProgress from "../../Session/AdminSession/Started/QuestionsProgress";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  RocketOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { SessionStatsModal } from "../../Session/AdminSession/Started/SessionStatsModal";
import { questionMode } from "@/Components/QuestionBuilder/QuestionBuilder";
import Image from "@/Components/Common/Image";
import { size } from "lodash";
import QuestionTitlePreview from "@/Components/Common/QuestionTitlePreview";
import prepareImage from "@/Assets/prepare.png";
import textIcon from "@/Assets/text-icon.png";
import mcqIcon from "@/Assets/mcq-icon.png";

import { Content } from "antd/lib/layout/layout";
import { Prompt } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import bannerImage from "@/Assets/prepare-questions.png";
import {
  startPreparedSessionForClass,
  startSessionForClass,
  updatePrepareSession,
} from "@/Services/Class";

import "./Prepare.css";
import QuestionCardPreview from "@/Components/Question/QuestionPreview";
import Analytics from "@/Services/Analytics";
import {
  isRTL,
  sampleQuestions,
  sampleSessionName,
} from "@/Services/Constants";
import { queryParamToObject } from "@/Components/Common/Utils";
import { getQuestionForClient } from "@/Services/Question";
import { v4 as uuidv4 } from "uuid";
import QuestionGridManager from "@/Components/Question/QuestionGridManager";
import axios from "axios";
const { Text, Link } = Typography;

interface Props {
  currentClass: Class;
  session: SessionState;
}

const letterIndex = ["A", "B", "C", "D"];

const startedSessionAreas = `
              header
              questionsArea
  `;

const { confirm } = Modal;

export const AdminEditPrepare: React.FC<Props> = ({
  session,
  currentClass,
}) => {
  const [questions, setQuestions] = useState<question[]>(
    session?.draft_questions || []
  );
  const [sessionName, setSessionName] = useState(session?.name || "");
  const [sessionNameError, setSessionNameError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isStarting, setIsStarting] = useState(false);


  const [sessionId, setSessionId] = useState('');
  const [classId, setClassId] = useState('');
  const [statusMessage, setStatusMessage] = useState('');


  const history = useHistory();
  const { search } = useLocation();
  const params = queryParamToObject(search);
  const isSampleClass: boolean =
    params["sample"] && params["sample"] == "true" ? true : false;
  //console.log('Is sample class = ', isSampleClass);

  const questionsArea = useRef<HTMLElement>();

  const onSave = useCallback(() => {
    console.log("save pressed");
    if (!sessionName) {
      setSessionNameError(true);
      return;
    }
    if (!questions) {
      alert("Please add questions in the session draft");
    }
    console.log("Session name and quesiton length are", sessionName, questions);
  }, [setSessionNameError, sessionName]);

  const updateSessionName = useCallback(
    (v:any) => {
      Analytics.track("Update Prepare Session Name", session);
      setSessionNameError(false);
      setSessionName(v);
    },
    [sessionName]
  );

  const addSampleQuestions = () => {
    Analytics.track(`Sample Questions Added`);
    const sampleQs: any[] =
      sampleQuestions.map((q: any, i: number) => {
        return getQuestionForClient({ ...q, id: uuidv4() }, i);
      }) || [];
    setQuestions(sampleQs);
    setSessionName(sampleSessionName);
    updateSessionOnServer(sampleSessionName, sampleQs);
  };
  useEffect(() => {
    console.log(questions);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Analytics.track("Prepare Session", session);

    if (isSampleClass) {
      addSampleQuestions();
    }
  }, []);

  useEffect(() => {
    setQuestions(session.draft_questions);
  }, [session]);

  useEffect(() => {
    if (sessionName != session.name) {
      setSessionName(session.name);
    }
  }, [session]);

  const updateSessionOnServer = (name: string, draftQuestions: question[]) => {
    setIsSaving(true);
    let updatedSession = { ...session };
    updatedSession.name = name;
    updatedSession.draft_questions = draftQuestions;
    return updatePrepareSession(updatedSession, currentClass).then(() => {
      setIsSaving(false);
    });
  };

  // const extraSpace = useMemo(() => (showQuestionBuilder ? 1 : 0), [
  //     showQuestionBuilder,
  // ]);

  const onStart = () => {
    const eventName =
      session.draft_questions.length > 0
        ? "Prepared Session Start"
        : "Empty Session Start";
    Analytics.track(eventName, { ...session, ...questions });

    if (session) {
      setIsStarting(true);
      startPreparedSessionForClass(currentClass, session.name, session).then(
        (data) => {
          setIsStarting(false);
          history.push("/session/" + data.sessionId);
        }
      );
    }
  };

  const handleCreateResources = async (sessionId:string,classId:string,className:string) => {

    //   setSessionId(session.sessionId);
    // setClassId(session.classId);
  
    // console.log("SESSION ID",sessionId);
    // console.log("CLASS ID ",classId);
    // console.log("CLASS NAME ",classId);
  
      if (!sessionId || !classId||!className) {
        setStatusMessage('sessionId and classId are required.');
        return;
      }
  
      try {
        const response = await axios.post('https://us-central1-vidya-prod-f2207.cloudfunctions.net/createResources/api/create_resources', {
          sessionId,
          classId,
          className
        });
  
        if (response.data.success) {
          setStatusMessage('Topics and subscriptions created successfully.');
        } else {
          setStatusMessage('Failed to create resources.');
        }
      } catch (error) {
        console.error('Error creating resources:', error);
        setStatusMessage('Error creating resources. Please try again.');
      }
    };
  

  const handleQuestionListUpdate = (
    updatedQuestions: question[]
  ): Promise<void> => {
    return updateSessionOnServer(sessionName, updatedQuestions);
  };

  const headerAreasDesktop = `
    blank title buttons
    blank sessionName buttons
    `;
  return (
    <Layout dir={isRTL(currentClass.settings?.language) ? "rtl" : "ltr"}>
      <Layout
        className="m-auto sm:w-full w-full"
        style={{
          // padding: '0 24px 24px',
          // maxWidth: '1080px',
          minHeight: "100vh",
          height: "fit-content",
        }}
      >
        {/* <Breadcrumb
                                    className="text-left"
                                    style={{ margin: '16px 0' }}>
                                    <Breadcrumb.Item>All Classes </Breadcrumb.Item>
                                    {classIdInURL === 'add' ? <Breadcrumb.Item>Add New Class</Breadcrumb.Item> : null}
                                    {selectedClass ? <Breadcrumb.Item>{selectedClass.name}</Breadcrumb.Item> : null}
                                </Breadcrumb> */}
        <Content
          className="site-layout-background"
          style={{
            padding: 0,
            // margin: 12,
            minHeight: 280,
          }}
        >
          <Layout
            className="site-layout-background lyt"
            style={{
              padding: "0 0",
            }}
          >
            <Content
              style={{
                // padding: '18px 24px',
                minHeight: 280,
              }}
            >
              <div className="text-left">
                <PageHeader
                  style={{ padding: 0 }}
                  className="site-page-header"
                  backIcon={null}
                  // onBack={() => history.goBack()}
                  // title={"Prepare Class - " + currentClass.description}
                  extra={[
                    // <Button
                    //     type="primary"
                    //     onClick={onSave}
                    //     className=""
                    // >
                    //     Save
                    // </Button>
                    session.state == "STARTED" ? null : isSaving ? (
                      <span className="text-gray-500">
                        {" "}
                        <SyncOutlined spin />{" "}
                        <span className="text-gray-500">saving... </span>{" "}
                      </span>
                    ) : (
                      <span className="text-gray-500">
                        {" "}
                        <CheckCircleOutlined />{" "}
                        <span className="text-gray-500">
                          saved |{" "}
                          <span>
                            {questions.length}{" "}
                            {questions.length == 1 ? "Question" : "Questions"}
                          </span>{" "}
                        </span>{" "}
                      </span>
                    ),
                    <Button
                      loading={isStarting}
                      type={questions.length > 0 ? "primary" : "default"}
                      icon={<PlayCircleOutlined />}
                      onClick={() => {
                        if (session.state == "STARTED") {
                          history.push(`/session/${session.sessionId}`);
                        } else {
                          onStart();
                          handleCreateResources(session.sessionId,session.classId,sessionName);
                        }
                      }}
                      // onClick={() => history.push('/new-session/' + currentClass.id)}
                    >
                      {session.state === "STARTED" ? "Join" : "Start"}
                    </Button>,
                  ]}
                  subTitle={
                    <Space direction="horizontal" className="ml-8">
                      <Text strong>Session Name: </Text>
                      <Space direction="vertical">
                        <div
                          className={
                            sessionNameError
                              ? "border-red-500 border-b ml-4 mt-2"
                              : "text-black border-b mt-2 ml-4"
                          }
                        >
                          <input
                            minLength={80}
                            disabled={session.state == "STARTED"}
                            onChange={(e) => updateSessionName(e.target.value)}
                            onBlur={() =>
                              updateSessionOnServer(sessionName, questions)
                            }
                            className="text text-left w-full focus:outline-none"
                            style={{
                              borderBottomStyle: "dashed",
                              borderWidth: "1px",
                              border: "black",
                            }}
                            value={sessionName}
                            onKeyUp={({ key }) => {
                              if (key === "Enter") {
                                // Do work
                                updateSessionOnServer(sessionName, questions);
                              }
                            }}
                            placeholder="ex. Algebra"
                          ></input>
                        </div>
                        <Text
                          className={
                            sessionNameError
                              ? "float-left ml-6 text-xs"
                              : "hidden"
                          }
                          type="danger"
                        >
                          Please enter the session name
                        </Text>
                      </Space>
                      {session.state === "STARTED" ? (
                        <span>
                          <span className="text-xs text-gray-600">[LIVE]</span>
                          <span className="animate-ping absolute inline-flex ml-1 h-1 w-1 rounded-full bg-blue-400 "></span>
                        </span>
                      ) : null}
                    </Space>
                  }
                  // backIcon={<ArrowLeftOutlined />}
                />
                {session.state == "STARTED" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      background: "rgb(255 255 255 / 69%)",
                      height: "100vh",
                      zIndex: 10,
                    }}
                    className="bdb"
                  ></div>
                ) : null}
                <div className="mt-4">
                  <div className="text-gray-700 ml-8 text-left text-xs">
                    Draft questions in advance and use them in live session
                  </div>
                  <div
                    style={{
                      // height: "calc(100vh - 104px)",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                    className="w-full mt-6"
                  >
                    {
                      <div className="w-full">
                        {/* <Space
                                                    className="w-full"
                                                    direction="horizontal" size={"large"} wrap> */}

                        <QuestionGridManager
                          questionList={questions}
                          EmptyPageAdditionalAction={
                            <div>
                              <div className="mt-4"> or </div>
                              <Button
                                type="link"
                                onClick={() => history.push("/library")}
                              >
                                Load From Library
                              </Button>
                            </div>
                          }
                          onQuestionListUpdate={handleQuestionListUpdate}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </Content>
      </Layout>

      <Composition
        areas={``}
        alignItems="center"
        justifyItems="center"
        width="100%"
      >
        {({ Header, QuestionsArea }) => (
          <>
            <Header
              width="100%"
              // className="overflow-scroll pl-3 pr-3 w-full lg:w-6/12"
            >
              <Composition
                templateCols="1fr 3fr 1fr)"
                // templateColsMd="3, 1fr)"
                alignItems="center"
                areas={headerAreasDesktop}
                style={{
                  margin: "1em 0",
                }}
              >
                {({ SessionName, Blank, Buttons, Title }) => (
                  <>
                    {/* <Blank><span /> </Blank> */}

                    <Title>
                      <div className="text-3xl text-left ml-6">
                        {"Prepare Session - " + currentClass.name}
                      </div>
                      <div className="ml-6 text-gray-700 text-left text-xs">
                        Prepare class by adding all the questions in advance and
                        use them in your next session
                      </div>
                    </Title>
                    <Buttons
                      justifyItems="center"
                      justifyItemsSm="flex-end"
                      alignItems="center"
                      paddingTop="1em"
                    >
                      <Button
                        type="primary"
                        onClick={onSave}
                        className="ml-auto float-right mr-6"
                      >
                        Save
                      </Button>
                    </Buttons>
                    <SessionName>
                      <div
                        className={
                          sessionNameError
                            ? "border-red-500  border-b ml-6"
                            : "border-b ml-6"
                        }
                      >
                        <input
                          onChange={(e) => updateSessionName(e.target.value)}
                          className="text-xl mt-12 text-left w-full focus:outline-none"
                          style={{
                            borderBottomStyle: "dashed",
                            borderWidth: "1px",
                            border: "black",
                          }}
                          placeholder="Enter the session name"
                        ></input>
                      </div>
                      <Text
                        className={
                          sessionNameError ? "float-left ml-6" : "hidden"
                        }
                        type="danger"
                      >
                        Please enter the session name
                      </Text>
                    </SessionName>
                  </>
                )}
              </Composition>
            </Header>
            <QuestionsArea
              style={{
                height: "calc(100vh - 104px)",
              }}
              className="overflow-scroll pl-6 pr-6 w-full lg:w-6/12"
              ref={questionsArea}
            >
              {questions ? (
                <div>
                 
                </div>
              ) : null}
              {/* <div
                                style={{
                                    height: "90px",
                                    borderStyle: "dashed",
                                    borderRadius: "1em",
                                    cursor: "pointer",
                                    border: "lightgray",
                                    borderWidth: "1px",
                                    lineHeight: "90px"
                                }}
                            >
                                <button
                                    className="ant-btn ant-btn-dashed"
                                    onClick={onAddNextQuestion}
                                >Add Question</button>

                            </div> */}
            </QuestionsArea>
          </>
        )}
      </Composition>
    </Layout>
  );
};

export default AdminEditPrepare;
