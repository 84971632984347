import { currentUser } from "@/Models/Selectors";
import { logout } from "@/Services/Auth";
import { isEmbed, isRunningAsZoomApp } from "@/Services/Constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Layout, Modal } from "antd";
import { useSelector } from "react-redux";
import AppLogo from "../AppHeader/AppLogo";

const AppFooter = () => {
  const { confirm } = Modal;

  const user = useSelector(currentUser);
  const onLogoutClicked = () => {
    confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        logout();
      },
      onCancel() {},
    });
  };

  return (
    <Layout>
      <footer className="border-t border-gray-200 mt-6">
        {user && (isRunningAsZoomApp || isEmbed) ? (
          //embed and zoom app footer
          <div className="block justify-center">
            <span className="text-gray-600">
              <div className="float-left ml-4 mb-4">
                Powered by
                <AppLogo mode="light" redirectHome={true} />
              </div>
              <div className="float-right mr-4 text-justify grid">
                {user.full_name}{" "}
                <Button type="link" onClick={() => onLogoutClicked()}>
                  (logout)
                </Button>
              </div>
            </span>
          </div>
        ) : (
          // regular footer
          <div className="block justify-center">
            <div className="text-gray-600">
              <AppLogo mode="light" redirectHome={false} />{" "}
              <div>
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  target="_blank"
                  className=" text-gray-600"
                  href="https://vidya.us"
                >
                  Vidya.us{" "}
                </a>
                . All Rights Reserved |{" "}
                <a
                  className="text-gray-600 underline"
                  href="mailto:happy@vidya.us"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        )}
      </footer>
    </Layout>
  );
};

export default AppFooter;
