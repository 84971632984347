import Actions from "../Actions";

const defaultState: ClassReduxState = {
  allClasses: {},
  currentClass: undefined,
  fetching: false,
};

const classReducer = (
  state = defaultState,
  action: reduxAction
): ClassReduxState => {
  switch (action.type) {
    case Actions.CLASSES.ADD: {
      const { Class } = action.payload;
      const { id } = Class;

      return {
        ...state,
        allClasses: {
          ...state.allClasses,
          [id]: Class,
        },
      };
    }

    case Actions.CLASSES.ADD_BULK: {
      const { Classes } = action.payload;

      Classes.forEach((cls: Class) => {
        const { id } = cls;
        console.log("ADDING CLASS", cls, id);

        state.allClasses = {
          ...state.allClasses,
          [id]: cls,
        };
      });

      return state;
    }

    case Actions.CLASSES.UPDATE: {
      const { Class }: { Class: Class } = action.payload;
      const { id } = Class;
      // const oldClass = state.allClasses[id];

      return {
        ...state,
        allClasses: {
          ...state.allClasses,
          [id]: Class,
        },
      };
    }

    case Actions.CLASSES.DELETE: {
      const { Class }: { Class: Class } = action.payload;
      const { id } = Class;

      delete state.allClasses[id];
      return {
        ...state,
      };
    }

    case Actions.CLASSES.SET_CURRENT_CLASS: {
      const { id }: { id: string } = action.payload;
      return {
        ...state,
        currentClass: id,
      };
    }

    case Actions.CLASSES.SET_FETCHING: {
      return {
        ...state,
        fetching: !!action.payload,
      };
    }
  }
  return state;
};

export default classReducer;
