import { Progress } from "antd";
import { useEffect, useState } from "react";

const CountDownTimer = ({ seconds, onComplete, className }: {
    seconds: number;
    onComplete: () => void;
    className?: string;
}) => {
    // initialize timeLeft with the seconds prop
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) {
            onComplete();
            return;
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <span className={className}>{timeLeft}</span>
    );
};

export default CountDownTimer;

export const CountUpTimer = ({ startTime, keepTicking, className }: {
    startTime: Date;
    keepTicking: boolean;
    className?: string;

}) => {
    // initialize timeLeft with the seconds prop
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [displayTime, setDisplayTime] = useState("");

    function fancyTimeFormat(duration: number) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }


    useEffect(() => {
        // exit early when we reach 0
        // if (!timeLeft) {
        //     onComplete();
        //     return;
        // }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            if (keepTicking)
                setTimeElapsed(timeElapsed + 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeElapsed]);

    return (
        <Progress type="circle"
            strokeColor="#3498db"
            percent={(timeElapsed % 60) * 100 / 60}
            format={percent => <span className="text-white">{fancyTimeFormat(timeElapsed)}</span>}
            className="progrs"
            width={33}
        />
        // <span className={className}> {fancyTimeFormat(timeElapsed)}</span>
    );
};