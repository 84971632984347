import { CheckCircleTwoTone, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Card, Input, Tag } from "antd"
import { Composition } from "atomic-layout"
import QuestionTitlePreview from "../Common/QuestionTitlePreview"
import Image from "@/Components/Common/Image";

type Props = {
    cardTitle: string;
    question: question;
    onCardClick?: (quesiton: question) => void;
    showDelete: boolean;
    showEdit: boolean;
    onEditClicked: (question: question) => void;
    onDeleteClicked: (question: question) => void;
    tags?: string[];
    className?: string;
    disableImagePreview?: boolean;
    borderless?: boolean;
    hoverable?: boolean;
    fixedHeight?: boolean | number;
};

const QuestionCardPreview = ({ className, tags, fixedHeight, cardTitle, question, onCardClick, showDelete, showEdit, disableImagePreview, onEditClicked, onDeleteClicked, borderless, hoverable }: Props) => {
    const letterIndex = ["A", "B", "C", "D"];
    const defaultFixedHeight = typeof fixedHeight === 'number' ? fixedHeight : "214px";

    return <>
        <div
            className={className}
            style={
                {
                    // overflow: "auto"
                    padding: "16px"
                }}
            onClick={() => onCardClick ? onCardClick(question) : null}
        >
            <Card
                bodyStyle={fixedHeight ? { overflow: "scroll", height: defaultFixedHeight } : {}}
                bordered={!!!borderless}
                hoverable={hoverable != undefined ? hoverable : onCardClick ? true : false}
                title={cardTitle}
                headStyle={{ textAlign: "left" }}
                actions={
                    showEdit
                        ? [
                            <a href="#" onClick={() => onEditClicked(question)}>
                                <EditOutlined /> Edit
                            </a>,
                        ]
                        : tags && tags.length > 0
                            ? tags.map((t) => <Tag>{t}</Tag>)
                            : []
                }
                extra={
                    showDelete ? (
                        [
                            <DeleteOutlined
                                id="deleteIcon"
                                style={{ color: "lightcoral" }}
                                className="hover-red"
                                key="delete"
                                onClick={() => onDeleteClicked(question)}
                            />,
                        ]
                    ) : (
                        []
                    )
                }
            >

                <div
                    style={{
                        height: "container",
                        // overflow: "scroll"
                    }}
                    onClick={() => onCardClick ? onCardClick(question) : null}
                >
                    <QuestionTitlePreview body={question.description} className="text-left mb-6" />
                    {question.images.length > 0 && question.images[0].url ?
                        <Image disablePreview={disableImagePreview} imageId={question.images[0].url} /> : null}
                    <div className="text-left">
                        {question.optionsType === "text" ? (
                            <Input
                                disabled
                                value={question.correctValue} />
                        ) : (

                            <Composition
                                justifyItems="self-start">
                                {question.values?.map((option, i) => (
                                    <div
                                        style={{
                                            width: "100%",
                                        }}
                                    >

                                        <span className="text-left font-bold">
                                            {letterIndex[i]}
                                        </span>
                                        <span
                                            className="outline-none ml-4 w-8/12 bg-transparent text-left">
                                            {option.slice(2)}
                                        </span>
                                        {question.correctValueIndex === i ?
                                            <span className="ml-3">
                                                <CheckCircleTwoTone twoToneColor="#00de4e" />
                                            </span> : null}
                                    </div>
                                ))}
                            </Composition>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    </>
}

export default QuestionCardPreview;