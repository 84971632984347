import { omit } from "lodash";
import Actions from "../Actions";
import { allSessions } from "../Selectors";

export type allSessionsType = {
  [key: string]: SessionState;
};
export type sessionsState = {
  allSessions: {
    [key: string]: SessionState;
  };
  currentSessionId: string;
  socketStatus: "INIT" | "CONNECTING" | "CONNECTED" | "DISCONNECTED" | "FAILED";
};

const defaultsessionState: sessionsState = {
  allSessions: {},
  currentSessionId: "",
  socketStatus: "INIT",
};

const getCurrentSession = (sessionState: sessionsState) => {
  const { allSessions, currentSessionId } = sessionState;
  if (!allSessions[currentSessionId]) {
    return undefined;
  }

  return allSessions[currentSessionId];
};

const sessionReducer = (
  state = defaultsessionState,
  action: reduxAction
): sessionsState => {
  switch (action.type) {
    case Actions.SESSIONS.ADD: {
      const session: SessionState = action.payload;
      state.allSessions = {
        ...state.allSessions,
        [session.sessionId]: session,
      };
      return state;
    }

    case Actions.SESSIONS.ADD_BULK: {
      const sessions: SessionState[] = action.payload;

      console.log(sessions, "HEREE");
      console.log("session", sessions);      
      sessions.forEach((session, key) => {
        console.log("session", session, key);
        state.allSessions = {
          ...state.allSessions,
          [session.sessionId]: session,
        };
      });

      return state;
    }
    case Actions.SESSIONS.DELETE: {
      const session: SessionState = action.payload;
      state.allSessions = omit(state.allSessions, [`${session.sessionId}`]);
      return state;
    }

    case Actions.SESSIONS.UPDATE: {
      const { sessionId, changes, updatedSession, createOrUpdate } =
        action.payload;

      const session = state.allSessions[sessionId];
      Object.assign(state.allSessions, allSessions);

      if (!session) {
        if (createOrUpdate) {
          state.allSessions = {
            ...state.allSessions,
            [sessionId]: updatedSession,
          };
          return state;
        }
        return state;
      }
      const batchSession = updatedSession || { ...session, ...changes };
      // deleting old draft session and adding new session details
      let updatedSessionId = sessionId;
      if (batchSession.sessionId !== sessionId) {
        updatedSessionId = updatedSession.sessionId;
        delete state.allSessions[sessionId];
      }
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [updatedSessionId]: { ...batchSession },
        },
      };
    }

    case Actions.SESSIONS.CURRENT_SESSION.SET: {
      const sessionId: string = action.payload;
      return {
        ...state,
        currentSessionId: sessionId,
      };
    }
    case Actions.SESSIONS.CURRENT_SESSION.UPDATE_NAME: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      const name: string = action.payload;
      if (!currentSession) {
        return state;
      }
      const { sessionId } = currentSession;
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            name,
          },
        },
      };
    }
    case Actions.SESSIONS.CURRENT_SESSION.UPDATE_DESCRIPTION: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      const description: string = action.payload;
      if (!currentSession) {
        return state;
      }
      const { sessionId } = currentSession;

      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            description,
          },
        },
      };
    }

    case Actions.SESSIONS.CURRENT_SESSION.START_SESSION: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      if (!currentSession) {
        return state;
      }
      const { sessionId, state: sessionState } = currentSession;
      if (sessionState !== "CREATED") {
        return state;
      }

      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            state: "STARTED",
          },
        },
      };
    }

    case Actions.SESSIONS.CURRENT_SESSION.END_SESSION: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      if (!currentSession) {
        return state;
      }
      const { sessionId } = currentSession;
      state.allSessions[sessionId] = { ...currentSession, state: "ENDED" };
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            state: "ENDED",
          },
        },
      };
    }

    case Actions.SESSIONS.CURRENT_SESSION.ADD_QUESTION: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      if (!currentSession) {
        return state;
      }

      const question = action.payload;
      const { sessionId } = currentSession;
      // current question will be the last question in the list.
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            currentQuestion: question,
            questions: [...currentSession.questions, question],
          },
        },
      };
    }

    case Actions.SESSIONS.CURRENT_SESSION.UPDATE_QUESTION: {
      const currentSession: SessionState | undefined = getCurrentSession(state);
      if (!currentSession) {
        return state;
      }

      const { changes, index } = action.payload;
      const { sessionId } = currentSession;

      const questions = currentSession.questions.map((q, i) =>
        i === index ? { ...q, ...changes } : q
      );
      // const oldQuestion = currentSession.questions[index];
      // const updatedQuestion = { ...oldQuestion, ...changes };
      // let _currentQuestion = currentQuestion;
      // if (
      //   _currentQuestion?.id === updatedQuestion.id ||
      //   (!_currentQuestion && updatedQuestion.state === "RECEIVING_RESPONSE")
      // ) {
      //   _currentQuestion = updatedQuestion;
      // }

      // // _currentQuestion is updated here.
      // if (_currentQuestion && _currentQuestion.state === "RECEIVED_RESPONSE") {
      //   _currentQuestion = undefined;
      // }
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [sessionId]: {
            ...currentSession,
            currentQuestion: questions[questions.length - 1],
            questions,
          },
        },
      };
    }

    case Actions.SESSIONS.UPDATE_QUESTION: {
      const { session_id, question_id, changes } = action.payload;

      const session = state.allSessions[session_id];
      if (!session) {
        return state;
      }

      const questions = session.questions.map((q) =>
        q.id === question_id ? { ...q, ...changes } : q
      );

      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [session_id]: {
            ...session,
            currentQuestion: questions[questions.length - 1],
            questions,
          },
        },
      };
    }

    case Actions.SESSIONS.ADD_QUESTION: {
      const { session_id, question } = action.payload;

      const session = state.allSessions[session_id];
      if (!session) {
        return state;
      }
      // const { sessionId } = session;
      // current question will be the last question in the list.
      return {
        ...state,
        allSessions: {
          ...state.allSessions,
          [session_id]: {
            ...session,
            currentQuestion: question,
            questions: [...session.questions, question],
            
          },
        },
      };
    }

    case Actions.SESSIONS.SOCKET.CONNECTED: {
      return {
        ...state,
        socketStatus: "CONNECTED",
      };
    }
    case Actions.SESSIONS.SOCKET.START_CONNECTION: {
      return {
        ...state,
        socketStatus: "CONNECTING",
      };
    }
    case Actions.SESSIONS.SOCKET.DISCONNECTED: {
      return {
        ...state,
        socketStatus: "DISCONNECTED",
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;
