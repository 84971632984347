import React, { useEffect, useRef, useState } from "react";
import { Button, Layout, Menu, PageHeader, Result, Tabs } from "antd";

import { useSelector } from "react-redux";
import { isFetchingClasses, myClasses } from "@/Models/Selectors";
import {
  Link,
  Redirect,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";
import {
  AppstoreOutlined,
  AreaChartOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  LineChartOutlined,
  PlusOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import "./ClassDetails.css";
import ClassSession from "./ClassSessions";
import ClassStudents from "./ClassStudents";
import ClassSetting from "./ClassSetting";
import NewClass from "../newClass";
import Loader from "@/Components/Loader";
import * as Constants from "@/Services/Constants";
import Analytics from "@/Services/Analytics";
import { isRTL } from "@/Services/Constants";
import PrepareSession from "@/Components/Session/AdminSession/Prepare";

interface RouteParams {
  classId: string;
  subTab: string;
}
const { TabPane } = Tabs;

interface ClassDetailComponent extends RouteComponentProps<RouteParams> {}

const ClassDetail: React.FC<ClassDetailComponent> = (props) => {
  const { SubMenu } = Menu;
  const { Content, Sider } = Layout;

  useEffect(() => {}, []);

  // const allClasses = useSelector(classesForNewSession);
  const allClasses = useSelector(myClasses)();
  const fetchingClasses = useSelector(isFetchingClasses);
  const hasFetchedData = useRef(false);

  const [preselectedTab, setPreselectedTab] = useState(1);

  const history = useHistory();
  const classIdInURL = props.match.params.classId;
  const subTab = props.match.params.subTab;
  const selectedClass: Class = allClasses.filter(
    (c: Class) => c.id === classIdInURL
  )[0];

  const tabValues = ["prepare", "students", "sessions", "reports", "configure"];
  useEffect(() => {
    Analytics.track(`Home Page - ${subTab}`, { subTab });
  }, [0]);

  const updateTabBasedonUrl = () => {
    //const classIdInURL = props.match.params.classId;
    const subTab = props.match.params.subTab;
    const selectedTab =
      subTab && tabValues.indexOf(subTab) > 0 ? tabValues.indexOf(subTab) : 0;
    setPreselectedTab(selectedTab);
  };

  useEffect(() => {
    updateTabBasedonUrl();
  }, [subTab]);

  useEffect(() => {
    if (!fetchingClasses) {
      const selectedTab =
        subTab && tabValues.indexOf(subTab) > 0 ? tabValues.indexOf(subTab) : 0;
      setPreselectedTab(selectedTab);
      if (!hasFetchedData.current || !selectedClass) {
        // setAllClasses(allClasses);
        hasFetchedData.current = true;
      }
    }
  }, [fetchingClasses, preselectedTab, classIdInURL, selectedClass]);

  useEffect(() => {
    // if (!hasFetchedData.current)
    // setAllClasses(allClasses);
  }, [allClasses]);

  if (!classIdInURL && allClasses.length > 0)
    return <Redirect to={"/class/" + allClasses[0].id} />;

  if (!fetchingClasses && classIdInURL !== "add" && !selectedClass) {
    // fetchAllClasses();
  }

  const tabChanged = (key: any) => {
    setPreselectedTab(key);
    history.push({ pathname: "/class/" + classIdInURL + "/" + tabValues[key] });
    console.log("tab changed", key);
  };

  return (
    <>
      <Layout style={{ minWidth: "450px" }}>
        {fetchingClasses ||
        (classIdInURL !== "add" && !selectedClass && allClasses.length > 0) ? (
          <Loader />
        ) : (
          <>
            {allClasses.length === 0 ? (
              classIdInURL === "add" ? (
                <div style={{ height: "calc(100vh - 64px)" }}>
                  <NewClass showBanner={true} />
                </div>
              ) : (
                <Result
                  status="404"
                  title="No Classes"
                  subTitle="You have not created any class yet."
                  extra={<Link to={"/class/add"}>Create Class</Link>}
                />
              )
            ) : (
              <>
                <Layout
                  className="m-auto 
                                 
                                sm:w-full w-full"
                  style={{
                    // padding: '0 24px 24px',
                    maxWidth: "1500px",
                    minHeight: "100vh",
                    height: "fit-content",
                  }}
                >
                  {/* <Breadcrumb
                                    className="text-left"
                                    style={{ margin: '16px 0' }}>
                                    <Breadcrumb.Item>All Classes </Breadcrumb.Item>
                                    {classIdInURL === 'add' ? <Breadcrumb.Item>Add New Class</Breadcrumb.Item> : null}
                                    {selectedClass ? <Breadcrumb.Item>{selectedClass.name}</Breadcrumb.Item> : null}
                                </Breadcrumb> */}
                  <Content
                    className="site-layout-background lg:ml-12 lg:mr-12 lg:mt-6 m-0 md:m-4 "
                    style={{
                      padding: 0,
                      // margin: 12,
                    }}
                  >
                    <Layout
                      className="site-layout-background vidya-page-layout lyt"
                      style={{
                        padding: "0 0",
                      }}
                    >
                      <Sider
                        style={{
                          overflow: "auto",
                          // height: '100%',
                          // position: 'fixed',
                          left: 0,
                        }}
                        width={200}
                        collapsedWidth={40}
                        collapsible={true}
                        breakpoint="lg"
                        className="site-layout-background"
                      >
                        <Menu
                          theme="light"
                          mode="inline"
                          defaultSelectedKeys={[classIdInURL]}
                          defaultOpenKeys={["sub1"]}
                          style={{
                            height: "100%",
                            borderRight: 0,
                            paddingTop: 10,
                            background: "#fafafa",
                          }}
                        >
                          <Menu.Item
                            style={{ marginLeft: "3%", width: "93%" }}
                            className="pl-4 mt-2 border border-dashed"
                            icon={<PlusOutlined />}
                            // itemIcon={<PlusOutlined />}
                            key="add-class"
                          >
                            <Link to={"/class/add"}>Add Class</Link>
                          </Menu.Item>
                          <SubMenu
                            key="sub1"
                            icon={<AppstoreOutlined />}
                            title="All Classes"
                          >
                            {allClasses.map((c) => {
                              return (
                                <Menu.Item
                                  className="pl-4"
                                  style={{ paddingLeft: 30 }}
                                  // itemIcon={<div className='ml-2'>{c.description}</div>}
                                  key={c.id}
                                >
                                  <Link
                                    to={"/class/" + c.id + "/prepare"}
                                    style={{
                                      color: c.is_archived
                                        ? "darkgray"
                                        : "black",
                                    }}
                                  >
                                    {c.description}
                                  </Link>
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        </Menu>
                      </Sider>
                      <Content style={{ padding: "18px 18px", minHeight: 280 }}>
                        {!classIdInURL ? (
                          "No Classes"
                        ) : classIdInURL === "add" ? (
                          <NewClass showBanner={true} />
                        ) : (
                          <div
                            dir={
                              selectedClass.settings &&
                              isRTL(selectedClass.settings.language)
                                ? "rtl"
                                : "ltr"
                            }
                            className="text-left"
                          >
                            <PageHeader
                              style={{ padding: 0 }}
                              className="site-page-header"
                              onBack={() => null}
                              title={selectedClass.name}
                              subTitle={selectedClass.description}
                              backIcon={false}
                            />

                            <div className="mt-4">
                              <Tabs
                                // tabBarExtraContent={
                                //     <Button type="default">
                                //         <ClearOutlined /> {selectedClass.is_archived ? "Unarchive" : "Archive"}
                                //     </Button>
                                // }
                                activeKey={preselectedTab.toString()}
                                defaultActiveKey={preselectedTab.toString()}
                                onChange={tabChanged}
                              >
                                <TabPane
                                  tab={
                                    <>
                                      <FormOutlined />
                                      Prepare
                                    </>
                                  }
                                  key="0"
                                >
                                  {
                                    <PrepareSession
                                      classId={selectedClass.id}
                                    />
                                  }
                                </TabPane>
                                <TabPane
                                  tab={
                                    <>
                                      <TeamOutlined />
                                      Students{" "}
                                    </>
                                  }
                                  key="1"
                                >
                                  {<ClassStudents _class={selectedClass} />}
                                </TabPane>
                                <TabPane
                                  tab={
                                    <>
                                      <FundProjectionScreenOutlined />
                                      Past Sessions
                                    </>
                                  }
                                  key="2"
                                >
                                  {<ClassSession _class={selectedClass} />}
                                </TabPane>

                                {/* {Constants.enable_setting_class_students ? */}

                                {/* : null} */}

                                <TabPane
                                  tab={
                                    <>
                                      <LineChartOutlined />
                                      Reports
                                    </>
                                  }
                                  key="3"
                                >
                                  {selectedClass.students_email.length <= 1 ? (
                                    <>
                                      <Result
                                        icon={<AreaChartOutlined />}
                                        // status="warning"
                                        title="Not enough data to generate any reports."
                                        extra={
                                          <Button
                                            onClick={() =>
                                              history.push(
                                                "/analyse/sample-class/"
                                              )
                                            }
                                            type="primary"
                                            key="console"
                                          >
                                            View Sample Report
                                          </Button>
                                        }
                                      />
                                    </>
                                  ) : (
                                    <Redirect
                                      to={`/analyse/class/${selectedClass.id}/sessions`}
                                    />
                                  )}
                                </TabPane>
                                {Constants.enable_setting_class_configure ? (
                                  <TabPane
                                    disabled={
                                      !Constants.enable_setting_class_configure
                                    }
                                    tab={
                                      <>
                                        <SettingOutlined
                                        // style={{ fontSize: '16px' }}
                                        />
                                        Configure{" "}
                                      </>
                                    }
                                    key="4"
                                  >
                                    <ClassSetting _class={selectedClass} />
                                  </TabPane>
                                ) : null}
                              </Tabs>
                            </div>
                          </div>
                        )}
                      </Content>
                    </Layout>
                  </Content>
                </Layout>
              </>
            )}
          </>
        )}
      </Layout>
    </>
  );
};

export default ClassDetail;
