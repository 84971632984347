import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Composition } from "atomic-layout";
import { Button, Modal, notification, Result } from "antd";
import store from "@/Models/store";
import QuestionBuilder from "@/Components/QuestionBuilder";
import Question from "@/Components/Question";
import {
  askQuestion,
  getNextQuestionLabel,
  endCurrentSession,
  fetchLatestSessionStats,
  getEmptySessionObj,
} from "@/Services/Session";
import QuestionsProgress from "../../Session/AdminSession/Started/QuestionsProgress";
import { BarChartOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { SessionStatsModal } from "../../Session/AdminSession/Started/SessionStatsModal";
import {
  draftSessionsForClass,
  getAllStartedSessions,
  getClassById,
  getPrepareSessionForClass,
  getSessionById,
} from "@/Models/Selectors";
import { Link, RouteComponentProps } from "react-router-dom";
import Loader from "@/Components/Loader";
import AdminEditPrepare from "./NewPrepare";
import { CurrentSession } from "@bugfender/sdk/lib/current-session";
import { sessionsState } from "@/Models/Reducers/session";
import { useSelector } from "react-redux";
import { createEmptyPrepareSessionForClass } from "@/Services/Class";
import {
  Operator,
  subscribeUpdatesForCollectionNew,
} from "@/Services/Firebase";
import { DocumentChange, DocumentData } from "firebase/firestore";

const startedSessionAreas = `
              header
              questionsArea
  `;

interface RouteParams {
  classId: string;
  sessionId: string;
}

interface PrepareSessionComponent extends RouteComponentProps<RouteParams> {}

const { confirm } = Modal;

const getEndConfirmation = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    confirm({
      title: "Are you sure you want to end the session?",
      icon: <ExclamationCircleOutlined />,
      content: "You will not be able to resume the session if ended.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
    });
  });
};

export const PrepareSession = ({ classId }: { classId: string }) => {
  // const { classId } = props;

  // if (!sessionId) {
  //     // new preparation here
  //     console.log("new prepared session")
  //     // const session = {} as SessionState;
  //     const session = useSelector(getPrepareSessionForClass)(classId);
  // const thisClass = getClassById(store.getState())(classId);
  //     console.log("class and session ids are", classId);
  //     if (!thisClass || !session) return <Loader />
  //     return <AdminEditPrepare session={session} currentClass={thisClass} />
  //     // return <div>NEW PREPARED SESSION</div>
  // }

  // if (sessionId) {
  // edit preparation
  const thisClass = getClassById(store.getState())(classId);
  const prepareSession = useSelector(getPrepareSessionForClass)(classId);
  const activeSessions = useSelector(getAllStartedSessions).filter(
    (s) => s.classId == classId
  );

  useEffect(() => {
    const subscribe = subscribeUpdatesForCollectionNew(
      "classes",
      [
        {
          field: "primaryteacher",
          operator: Operator.Equal,
          value: "hemanthmalla@gmail.com",
        },
      ],
      (data: DocumentData) => {
        console.log(data);
      }
    );

    console.log("subs");

    return () => {
      subscribe.forEach((s) => {
        s();
        console.log("unsubs");
      });
    };
  });

  if (!prepareSession && !activeSessions[0]) {
    createEmptyPrepareSessionForClass(thisClass, thisClass.name).then(
      (data) => {
        if (prepareSession)
          return (
            <AdminEditPrepare
              session={prepareSession}
              currentClass={thisClass}
            />
          );
      }
    );

    return <Loader />;
  }
  // todo - cover case when there is an active class
  if (prepareSession && prepareSession.state !== "DRAFT")
    return (
      <Result
        status="404"
        title="This session is not in the 'Prepare mode' anymore"
        extra={
          <Link to="/" className="btn ant-btn-primary">
            Go Home
          </Link>
        }
      />
    );
  // return <div>EDIT PREPARED SESSION</div>
  // }

  if (!prepareSession && activeSessions[0]) {
    return (
      <AdminEditPrepare session={activeSessions[0]} currentClass={thisClass} />
    );
  }
  if (prepareSession)
    return (
      <AdminEditPrepare session={prepareSession} currentClass={thisClass} />
    );

  return <Loader />;
};
export default PrepareSession;
