import React, { useEffect, useState } from "react";
import { Composition } from "atomic-layout";
import {
  ArrowLeftOutlined,
  CheckCircleFilled, DownloadOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { goHome } from "@/Services/Router";
import { SessionStatsModal, SessionStats } from "./Started/SessionStatsModal";
import { Button, message } from "antd";
import { getClassById } from "@/Models/Selectors";
import { useSelector } from "react-redux";
import { downloadSessionStats } from "@/Components/Class/ClassDetails/ClassSessions";
import ReportView from "@/Components/Question/ReportView";
import { sampleSessionName } from "@/Services/Constants";

const enededSessionsArea = `
            header
            baseReport
            scoreReport
            buttons
            questions
`;

const AdminSessionEnded = ({ session }: { session: SessionState }) => {
  const { name } = session;
  const iconEdgeSize = "70px";
  const _class = useSelector(getClassById)(session.classId)
  const duration = new Date().getTime() - new Date(session.start_time).getTime();
  const durationMinutes = Math.floor((duration / ((1000 * 60)) % 60))
  const durationHours = Math.floor(duration / (1000 * 60 * 60))
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const canGoBack = window.location.pathname.includes("class");
  const history = useHistory();

  // goHome();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Composition
      areas={enededSessionsArea}
      alignItems="center"
      justifyItems="center"
      paddingTop="40px"
      paddingBottom='100px'
      gap="50px"
    >
      {({ Header, BaseReport, ScoreReport, Buttons, Questions }) => (
        <>
          <Header>
            <div className="inline-flex">
              {canGoBack ?
                <Button
                  onClick={() => history.goBack()}
                  type="link" className="mt-2">
                  <ArrowLeftOutlined />
                </Button> : null}
              <div className="text-3xl">{name}</div>
            </div>
          </Header>
          <BaseReport>
            <Composition templateRows="100px auto auto">
              <CheckCircleFilled
                style={{
                  fontSize: iconEdgeSize,
                  color: "#52C41A",
                }}
              />
              <div className="text-2xl">
                Session Complete with {session.joinees.length} {session.joinees.length > 1 ? "students" : "student"} !
              </div>
              <div className="text-gray-600 text-xs">Duration: {durationHours ? durationHours : 0} {durationHours > 1 ? "hrs" : "hr"} {durationMinutes && durationMinutes !== Infinity ? durationMinutes : "0"} {durationMinutes > 1 ? "mins" : "min"} </div>
            </Composition>
            <Button
              // className="hover:opacity-100 ant-btn ant-btn-default"
              onClick={() => {
                if (_class.students_email.length == 0) {
                  message.warning('No report generated because of 0 students');
                } else if (session.questions.length == 0) {
                  message.warning('No report generated because of 0 questions');
                } else {
                  downloadSessionStats(session, _class)

                }
              }
              }
              type="default"
              className="mt-6"
            >Download Report <DownloadOutlined /></Button>
          </BaseReport>

          <ScoreReport>
            <Composition
              templateRows='auto auto'
              maxWidth={"50em"}
              gap='5px'
            >
              <SessionStats open={true} onClose={function (): void {
                throw new Error("Function not implemented.");
              }} session={session}
              />
              {/* <Progress type='circle' percent={81} format={ percent => percent ? percent/10 : 0} strokeColor='#27AE60'/>
                <span className='font-bold'>Class Score</span> */}
            </Composition>
          </ScoreReport>

          <Buttons>
            <Composition
              templateCols='auto auto'
              gap='20px'
            >

              <Link
                className="ant-btn ant-btn-default"
                to="/">Home</Link>
              {session.name == sampleSessionName ?
                <Link
                  className="ant-btn ant-btn-primary"
                  to="/analyse/sample-class/">View Sample Report</Link>
                :
                <Link
                  className="ant-btn ant-btn-primary"
                  to={`/analyse/class/${_class.id}/sessions`}>Class Report</Link>
              }

              {/* <Button type='primary'>Detailed Report</Button> */}
            </Composition>
          </Buttons>
          <Questions>
            <>
              <Button
                type="text"
                onClick={() => setShowQuestions(!showQuestions)}
              >
                <span className="text-white underline pb-8">
                  {showQuestions ? "Hide Questions" : "Preview Questions"}
                </span>
              </Button>
              {showQuestions ?
                <div className="mt-8">
                  {session && session.questions ? session.questions.map(q => <ReportView
                    question={q}
                    session={session}
                    showNewQuestionButton={false}
                    onAskNextQuestion={() => null}
                  />) : null}
                </div> : null}
            </>
          </Questions>

        </>
      )}
    </Composition>
  );
};

export default AdminSessionEnded;
