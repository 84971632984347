import Actions from "@/Models/Actions";
import store from "@/Models/store";
import { sendSocketMessage } from "./Socket";
import { currentSessionId as currentSessionIdSelector } from "@/Models/Selectors";
import { v4 as uuid } from "uuid";
import { addDocument, getDocumentMultipleCondition } from "./Firebase";
import { getAuth } from "firebase/auth";
import { getSignupEmail } from "./Auth";

type serverQuestion = {
  kind: "MCQ" | "NUMERIC" | "TEXT" | "IMAGE";
  text: string;
  images: { url: string }[];
  options: serverAnswer[];
  correct_answer: serverAnswer | undefined;
  id: string;
  state: "DRAFT" | "ASKED" | "ENDED";
  eval: {
    is_correct: boolean;
    user_answer: serverAnswer;
  };
  stats: {
    correct: number;
    create_time: number;
    total: number;
    wrong: number;
    dont_know: number;
  };
};

type serverAnswer = {
  text: string;
  numeric: number;
  type: "NUMERIC" | "TEXT";
  image: Image;
  count?: number;
};

const questionStateMapServer = {
  DRAFT: "DRAFT",
  RECEIVING_RESPONSE: "ASKED",
  RECEIVED_RESPONSE: "ENDED",
};

const questionStateMapClient = {
  DRAFT: "DRAFT",
  ASKED: "RECEIVING_RESPONSE",
  ENDED: "RECEIVED_RESPONSE",
};

export const getQuestionForServer = (ques: question) => {
  const options =
    ques.optionsType === "multiple"
      ? ques.values?.map((v) => {
          return { text: v };
        })
      : [{ text: ques.correctValue }];

  const correct_answer =
    ques.optionsType === "multiple"
      ? options?.[ques.correctValueIndex]
      : { text: ques.correctValue };

  const state = questionStateMapServer[ques.state];
  const draft_id = ques.draft_id || "";

  return {
    id: ques?.id,
    kind: ques.optionsType === "multiple" ? "MCQ" : "TEXT",
    text: ques.description,
    images: ques.images,
    options,
    correct_answer,
    state,
    draft_id,
  };
};

export const getQuestionForClient = (q: serverQuestion, index: number) => {
  const { kind, text, options, correct_answer } = q;
  const correctOption = options.find((o) => o.text === correct_answer?.text);
  const state = questionStateMapClient[q.state];
  // q.state === "DRAFT" ? "DRAFT" : "ENDED" ? "RECEIVED_RESPONSE" : "RECEIVING_RESPONSE";
  const optionsType = kind === "MCQ" ? "multiple" : "text";
  return {
    id: q.id || uuid(),
    description: text,
    images: q.images || [],
    label: `Question ${index + 1}`,
    optionsType,
    evaluation: q?.eval?.user_answer ? q.eval : undefined,
    value: correct_answer?.text,
    values: options.map((o) => o.text),
    correctValue: correct_answer?.text,
    correctValueIndex: correctOption ? options.indexOf(correctOption) : 0,
    liveStats: getQuestionStats(q),
    asked_at: new Date(),
    state,
  };
};

const labelIndex = ["A", "B", "C", "D"];

const getQuestionStats = function (question: serverQuestion): questionStats {
  const {
    stats: { correct = 0, wrong = 0, total = 0, dont_know = 0 } = {},
    options = [],
    correct_answer,
  } = question;
  const clientOptions = options.map((o, index) => {
    return {
      count: (o.count || -1) > 0 ? o?.count || 0 : 0,
      label: labelIndex[index],
      value: o?.text?.slice(2) || "",
    };
  });
  const totalCount =
    total < correct + wrong + dont_know ? correct + wrong + dont_know : total;

  return {
    totalCount: totalCount,
    noResponse: totalCount - correct - wrong - dont_know,
    dontKnow: dont_know,
    options: clientOptions,
    correct,
    wrong,
    correctValue: correct_answer?.text || "",
  };
};

export const submitAnswer = (
  ans: string | number,
  questionId: string,
  isDontKnow: boolean,
  session?: SessionState
) => {
  const loggedInUserEmail = getSignupEmail();

  // console.log('sessionid->',session?.sessionId);
  return addDocument("session_answers", {
    question_id: questionId,
    answer: ans,
    dont_know: isDontKnow,
    session_id: session?.sessionId,
    class_id: session?.classId,
    user_id: loggedInUserEmail,
     }).then((session_answers) => {
    if (session_answers && session?.sessionId) {
      const evaluation = ans
        ? {
            user_answer: {
              text: ans,
            },
          }
        : {
            user_answer: {},
            answered_dont_know: true,
          };

      store.dispatch({
        type: Actions.SESSIONS.UPDATE_QUESTION,
        payload: {
          changes: { evaluation },
          session_id: session.sessionId,
          question_id: questionId,
        },
      });
    }
  });
  /* sendSocketMessage({
    type: "POST_ANSWER",
    payload: {
      questionId,
      answer: ans,
      dontKnow: isDontKnow,
    },
  }); */
};

export const stopQuestion = (
  session_id: string,
  class_id: string,
  question: question
) => {
  const loggedInUserEmail = getSignupEmail();

  store.dispatch({
    type: "UPDATE_QUESTION",
    payload: {
      session_id: session_id,
      class_id: class_id,
      question: question,
    },
  });
};

type result = {
  type: "CORRECT" | "INCORRECT" | "DONT_KNOW" | "NOT_RESPONDED";
  correctAnswer: string | undefined;
  questionType: "multiple" | "text";
};
export const getResultFromQuestion = (ques: question): result | undefined => {
  const {
    optionsType,
    state,
    evaluation,
    correctValue,
    correctValueIndex,
    values,
  } = ques;

  if (state === "RECEIVING_RESPONSE") {
    return undefined;
  }

  switch (optionsType) {
    case "text": {
      if (!evaluation) {
        return {
          type: "NOT_RESPONDED",
          correctAnswer: correctValue,
          questionType: "text",
        };
      }

      const { answered_dont_know, user_answer } = evaluation;
      const is_correct =
        correctValue?.trim().toLowerCase() ===
        user_answer.text?.trim().toLowerCase();

      return {
        type: answered_dont_know
          ? "DONT_KNOW"
          : is_correct
          ? "CORRECT"
          : "INCORRECT",
        correctAnswer: correctValue,
        questionType: "text",
      };
    }
    case "multiple": {
      if (!evaluation) {
        return {
          type: "NOT_RESPONDED",
          correctAnswer: values?.[correctValueIndex],
          questionType: "multiple",
        };
      }

      const { user_answer, answered_dont_know } = evaluation;
      const is_correct = values?.[correctValueIndex] === user_answer.text;
      return {
        type: answered_dont_know
          ? "DONT_KNOW"
          : is_correct
          ? "CORRECT"
          : "INCORRECT",
        correctAnswer: values?.[correctValueIndex],
        questionType: "multiple",
      };
    }
    default:
  }
  return undefined;
};

export const getUserResponses = (question: question, session_id?: string) => {
  const { id } = question;
  return new Promise((resolve, reject) => {
    sendSocketMessage(
      {
        type: "FETCH_USER_ANSWERS",
        payload: {
          question_id: id,
        },
      },
      (response) => {
        const { data, http_code } = response;

        if (http_code !== 200) {
          //commenting as not using reject in calling source and
          // reject is causing "Uncaught (in promise) error"
          // reject();
          return;
        }

        const userResponses: {
          [key: string]: string[];
        } = {};

        Object.keys(data).forEach((key) => {
          const value = data[key];

          let responseKey: string = value.answer.text || "no-answer";
          let userAnswer: string = value.answer.text || "";
          if (value.dont_know) {
            responseKey = "dontKnow";
          }
          if (userResponses[responseKey]) {
            // @ts-ignore
            userResponses[responseKey].push({
              value: userAnswer,
              userEmail: key,
            });
          } else {
            userResponses[responseKey] = [
              // @ts-ignore
              {
                value: userAnswer,
                userEmail: key,
              },
            ];
          }

          if (responseKey !== "dontKnow") {
            const isCorrect = value.is_correct;

            const responseObj = {
              value: value.answer.text,
              userEmail: key,
            };
            if (isCorrect) {
              // @ts-ignore
              userResponses["correct"]?.push(responseObj) ||
                // @ts-ignore
                (userResponses["correct"] = [responseObj]);
            } else {
              // @ts-ignore
              userResponses["wrong"]?.push(responseObj) ||
                // @ts-ignore
                (userResponses["wrong"] = [responseObj]);
            }
          }
        });

        const changes = {
          userResponses,
        };

        const currentSessionId = currentSessionIdSelector(store.getState());

        store.dispatch({
          type: Actions.SESSIONS.UPDATE_QUESTION,
          payload: {
            changes,
            question_id: id,
            session_id: session_id ? session_id : currentSessionId,
          },
        });
        resolve("response fetched");
        console.log("build user response from response", userResponses);
      }
    );
  });
};
