import axios from "axios";
import * as Sentry from "@sentry/react";
import { Bugfender } from "@bugfender/sdk";
import { batch, useSelector } from "react-redux";
import store from "@/Models/store";
import { subscribe } from "@/Models/Subscriber";
import { authStatus, currentUser } from "@/Models/Selectors";
import {
  onAuthStart,
  logout,
  onLoginSuccess,
  getAccessToken,
  setAccessToken,
  getSignupEmail,
} from "@/Services/Auth";
import Actions from "@/Models/Actions";
import { requestPermission } from "./Notifications";
import { upperCase } from "lodash";
import {
  defaultLanguage,
  GUESTDOMAIN,
  GuestEmailPlaceholder,
  isRunningAsZoomApp,
  ListOfAllLanguages,
  sampleStudents,
} from "./Constants";
import { userInfo } from "os";
import moment from "moment";
import Analytics from "@/Services/Analytics";
import mixpanel from "mixpanel-browser";
import {
  FirebaseCollectionNames,
  Operator,
  addDocument,
  getDocument,
  getDocuments,
  getFirebaseAuth,
  getSingleDocument,
  queryDocuments,
  updateDocument,
} from "./Firebase";
import { convertUndefinedToEmptyString } from "@/Components/Common/Utils";
import { UserCredential, createUserWithEmailAndPassword } from "firebase/auth";

subscribe(authStatus, async () => {
  const state = authStatus(store.getState());
  const user = currentUser(store.getState());
  console.log("sign_in response 1", user, state);

  if (state === "authenticated" && !user) {
    console.log("fetching the user again");
    fetchCurrentUser(); // now fetching user before marking as authenticated
    requestPermission();
  }
});

const convertToInstituteObject = (obj: any): Institute => {
  const { _id, img_url, name, location, subscription, payment } = obj;
  let institute: Institute = {
    id: _id,
    img_url,
    name,
    location,
    subscription: {
      status: obj.subscription.status == "true",
    },
    payment: {
      status: obj.payment.status == "true",
    },
    feature_flags: {
      whitelabel: obj.feature_flags.whitelabel == "true",
    },
  };
  return institute;
};

export const handleUserLanguage = (_user: User) => {
  if (_user.settings)
    if (!_user.settings?.preferredLanguage) {
      return (_user.settings.preferredLanguage = "ae");
    }
  return _user;
};

export const getUserDetail = (email: string) => {
  return getSingleDocument("user", "email", email);
};

export const signin = (
  raw_email: string,
  token: string,
  auth_type: "custom" | "gauth"
) => {
  store.dispatch({
    type: Actions.AUTH.authenticating,
    payload: null,
  });

  const email = raw_email.toLowerCase();

  // Analytics.setPeople({ $email: email, email, auth_type, signin_date: new Date() });
  Analytics.track("Sign in", { email, auth_type, signin_date: new Date() });
  console.log("Start User");
  return getUserDetail(email)
    .then(async (response) => {
      console.log("sign_in response 2", response);
      //await new Promise((resolve) => setTimeout(resolve, 3000));
      const data = response;

      if (true) {
        const authActiveAction = {
          type: Actions.AUTH.active,
          payload: null,
        };

        const user = response;
        const {
          email,
          username,
          first_name,
          last_name,
          role,
          settings,
          institutes,
        } = user;
        const img_url = user.img_url || "";
        /* const final_institutes = institutes.map((ins: any) =>
          convertToInstituteObject(ins)
        ); */
        const final_institutes: Institute[] = [];
        const userRole = ["STUDENT", "TEACHER"].includes(role)
          ? role
          : "TEACHER";
        let profile_image = "";
        //@ts-expect-error
        if (img_url || window.googleUser) {
          profile_image = img_url; // || window.googleUser.getBasicProfile().getImageUrl();
        }

        const _user: User = {
          email,
          username,
          first_name,
          last_name,
          full_name: first_name + " " + last_name,
          settings,
          institutes: final_institutes,
          primary_institute:
            final_institutes && final_institutes.length > 0
              ? final_institutes[0]
              : undefined,
          img_url: profile_image,
          role: userRole,
        };
        Sentry.setUser({ email });
        Bugfender.setDeviceKey("user_email", email);

        // const img_url = img_url || window.googleUser.getBasicProfile().getImageUrl();
        const thisUser = settings?.trial_end
          ? {
              ..._user,
              full_name: _user.first_name + " " + _user.last_name,
              img_url: img_url,
              settings: {
                ...settings,
                in_trial: moment(settings.trial_end).isAfter(moment()),
                preferredLanguage: defaultLanguage,
              },
            }
          : {
              ..._user,
              full_name: _user.first_name + " " + _user.last_name,
              img_url: img_url,
              settings: {
                ...settings,
                preferredLanguage: defaultLanguage,
              },
            };

        setSignupEmail(email);
        onLoginSuccess(token);
        setUserRole(thisUser.role);

        const addUserAction = {
          type: Actions.USERS.ADD,
          payload: thisUser,
        };

        const setCurrentUserAction = {
          type: Actions.USERS.CURRENT_USER.SET,
          payload: thisUser,
        };

        if (
          (!thisUser.settings || thisUser.settings?.type == "unknown") &&
          thisUser.role === "TEACHER"
        ) {
          console.log("HEREERERE");
          store.dispatch({
            type: Actions.AUTH.onboarding,
            payload: token,
          });
        }

        batch(() => {
          store.dispatch(authActiveAction);
          store.dispatch(addUserAction);
          store.dispatch(setCurrentUserAction);
        });
      }

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const detectBrowserLanguage = () => {
  const browserLang = navigator.language.split("-")[0];
  const availableLang = Object.keys(ListOfAllLanguages).includes(browserLang)
    ? browserLang
    : defaultLanguage;
  return availableLang;
};

export const fetchCurrentUser = async () => {
  const token = getAccessToken();
  if (!token) {
    console.log("Fetch current user - no token, signing out");
    store.dispatch({
      type: Actions.AUTH.signedout,
      payload: null,
    });
    return;
  }
  let email = getSignupEmail();

  getSingleDocument("user", "email", email!)
    .then((user) => {
      if (user) {
        const authActiveAction = {
          type: Actions.AUTH.active,
          payload: null,
        };

        const {
          email,
          username,
          first_name,
          last_name,
          role,
          settings,
          institutes = [],
        } = user;

        const img_url = user.img_url || "";
        const final_institutes = institutes.map((ins: any) =>
          convertToInstituteObject(ins)
        );
        const userRole = ["STUDENT", "TEACHER"].includes(role)
          ? role
          : "TEACHER";

        const profile_image = img_url;
        const _user: User = {
          email,
          username,
          first_name,
          last_name,
          full_name: first_name + " " + last_name,
          settings,
          institutes: final_institutes,
          primary_institute:
            final_institutes && final_institutes.length > 0
              ? final_institutes[0]
              : undefined,
          img_url: profile_image,
          role: userRole,
        };

        Analytics.identify(email);
        Analytics.setPeople({
          ..._user,
          ..._user.settings,
          signin_date: new Date(),
          userAgent: window.navigator.userAgent,
        });
        Analytics.track("Get User Details", {
          ..._user,
          ..._user.settings,
          signin_date: new Date(),
        });
        if (_user.role == "TEACHER") {
          // show walkthrough only for teachers.
          Analytics.initializePendo(_user);
        }
        // NOT UPDATING FIREBASE ON SIGNIN
        // createOrUpdateUserInFirebase(user);
        Sentry.setUser({ email });
        Bugfender.setDeviceKey("user_email", email);

        // const img_url = img_url || window.googleUser.getBasicProfile().getImageUrl();
        const thisUser = settings?.trial_end
          ? {
              ..._user,
              full_name: _user.first_name + " " + _user.last_name,
              img_url: img_url,
              settings: {
                ...settings,
                in_trial: moment(settings.trial_end).isAfter(moment()),
                preferredLanguage:
                  settings.preferredLanguage || defaultLanguage,
              },
            }
          : {
              ..._user,
              full_name: _user.first_name + " " + _user.last_name,
              img_url: img_url,
              settings: {
                ...settings,
                preferredLanguage: defaultLanguage,
              },
            };

        const addUserAction = {
          type: Actions.USERS.ADD,
          payload: thisUser,
        };

        const setCurrentUserAction = {
          type: Actions.USERS.CURRENT_USER.SET,
          payload: thisUser,
        };

        if (
          (!thisUser.settings || !thisUser.settings?.type) &&
          thisUser.role == "TEACHER"
        ) {
          store.dispatch({
            type: Actions.AUTH.onboarding,
            payload: token,
          });
        } else {
          const token = getAccessToken();
          if (token) onLoginSuccess(token);
        }

        batch(() => {
          store.dispatch(authActiveAction);
          store.dispatch(addUserAction);
          store.dispatch(setCurrentUserAction);
        });
      }
      if (user === false) {
        logout();
      }
    })
    .catch((error) => {
      console.log("user not logged in");
      logout();
      return;
    });
};

export const getFirebaseUser = async (email: string) => {
  try {
    const conditions: { field: string; operator: Operator; value: any }[] = [
      { field: "email", operator: Operator.Equal, value: email },
    ];
    const rawuser = await queryDocuments(
      FirebaseCollectionNames.USER,
      conditions
    );
    store.dispatch({
      type: Actions.USERS.ADD_BULK_USERS,
      payload: rawuser,
    });
    // only updates QuestionLibrary and not questionBanks.
    // subscribeUpdatesForCollection(QUESTION_LIBRARY_COLLECTION, conditions);

    return rawuser[0];
  } catch (error) {
    console.error("Error getting question libraries:", error);
    throw error;
  }
};

const getUserMembershipType = (_user: User) => {
  // const _user: User = useSelector(currentUser);
  return _user.settings?.type;
};

// const isTrialActive = (_user: User) => {
//   return _user.settings.
// }

export const signUp = async function ({
  firstName,
  lastName,
  username,
  email,
  img_url,
  role,
  auth_type,
  token,
  settings,
  password,
}: {
  firstName: any;
  lastName: any;
  username: any;
  email: any;
  img_url: any;
  role: any;
  auth_type: any;
  token: any;
  settings: any;
  password: any;
}) {
  // if (value) checks for null, undefined,  NaN, empty string (""), 0, false
  if (!firstName) {
    return Promise.reject({ message: "FirstName is required" });
  }
  if (!lastName) {
    return Promise.reject({ message: "Last name is required" });
  }
  if (!username) {
    return Promise.reject({ message: "username is also required" });
  }
  const userEmail = email.toLowerCase();

  const signupObj = {
    first_name: firstName,
    last_name: lastName,
    username,
    email: userEmail,
    img_url,
    role,
    auth_type,
    token: token,
    password,
  };
  Analytics.identify(userEmail);
  Analytics.setPeople({
    ...signupObj,
    $email: userEmail,
    Name: firstName + " " + lastName,
    ...settings,
    signin_date: new Date(),
    userAgent: window.navigator.userAgent,
  });
  mixpanel.people.set_once({ phone: settings.phone });
  Analytics.track("Sign up", {
    ...signupObj,
    ...settings,
    signin_date: new Date(),
  });

  // if(email.length === 0)
  try {
    return createUserWithEmailAndPassword(
      getFirebaseAuth(),
      email,
      password
    ).then(async (result: UserCredential) => {
      const { user } = result;
      const accessToken = await user.getIdToken();

      setAccessToken(accessToken);
      createOrUpdateUserInFirebase({
        ...signupObj,
        settings: { ...settings, preferredLanguage: "english" },
      });

      store.dispatch({
        type: Actions.AUTH.onboarding,
        payload: token,
      });
      //fetchCurrentUser();
    });
  } catch (e) {
    console.log(e);
  }
  /* return axios
    .post("sign_up", {
      ...signupObj,
      settings,
    })
    .then((response) => {
      const { data } = response;

      if (data.code === 0) {
        console.log("Signed up, sign in again..");
        const token = data.token;
        setAccessToken(token);
        createOrUpdateUserInFirebase({
          ...signupObj,
          settings: { ...settings, preferredLanguage: "english" },
        });
        store.dispatch({
          type: Actions.AUTH.onboarding,
          payload: token,
        });
        fetchCurrentUser();
      }
    })
    .catch((error) => {
      const { response } = error;
      throw response;
    }); */
};

export const isGuestAccount = (email: string) =>
  typeof email === "undefined" || email.split("@")[1] === GUESTDOMAIN;
export const getDisplayEmail = (email: string) =>
  typeof email === "undefined" || email.split("@")[1] === GUESTDOMAIN
    ? GuestEmailPlaceholder
    : email;

export const requestOTP = async (email: string) => {
  // store.dispatch({
  //   type: Actions.AUTH.init,
  //   payload: null,
  // });

  return axios
    .post("send_otp", {
      email: email.toLowerCase(),
    })
    .then((response) => {
      const { data } = response;
      if (data.code === 0) {
        // OTP Sent;
        if (!data.is_user_exists) {
          // if user does not exist, save email and move to sign up process
          setSignupEmail(email);
          store.dispatch({
            type: Actions.AUTH.noCurrentUser,
            payload: null,
          });
        }
      }
      return data;
    });
};

export const setupRegisterEmail = (email: string) => {
  setSignupEmail(email);
  store.dispatch({
    type: Actions.AUTH.noCurrentUser,
    payload: null,
  });
};

export const submitOTP = (
  raw_email: string,
  otp: string,
  callback: () => void
) => {
  // setTimeout(() => {

  const email = raw_email.toLowerCase();
  setSignupEmail(email);
  store.dispatch({
    type: Actions.AUTH.noCurrentUser,
    payload: null,
  });
  callback();
  // }, 3000);
};

export const setSignupEmail = (email: string) => {
  localStorage.setItem("signup_email", email);
};

export const setUserRole = (role: string) => {
  localStorage.setItem("user_role", role);
};

export const getUserRole = () => {
  return localStorage.getItem("user_role");
};

export const isStudent = () => {
  return getUserRole() === "STUDENT";
};

export const updateUser = function (_user: User) {
  // if (value) checks for null, undefined,  NaN, empty string (""), 0, false
  if (!_user.first_name) {
    return Promise.reject({ message: "FirstName is required" });
  }
  if (!_user.last_name) {
    return Promise.reject({ message: "Last name is required" });
  }
  if (!_user.username) {
    return Promise.reject({ message: "username is also required" });
  }
  Analytics.setPeople({
    ..._user,
    Email: _user.email,
    Name: _user.full_name,
    ..._user.settings,
  });
  // if(email.length === 0)
  return createOrUpdateUserInFirebase({
    ..._user,
    settings: {
      ..._user.settings,
      organization_name: "",
      pimary_institute: "",
    },
  }).then((res) => {
    fetchCurrentUser();
  });
};

export const fetchUserForClassSample = () => {
  const usersToAdd = sampleStudents;
  store.dispatch({
    type: Actions.USERS.ADD_BULK_USERS,
    payload: usersToAdd,
  });
};

export const fetchAllUserForClasses = async (classes: Class[]) => {
  let users: string[] = [];
  let allUsers: Map<string, User> = new Map<string, User>();

  classes.forEach((obj: Class, index: number, classes: Class[]) => {
    obj?.students?.forEach((user_email) => {
      users.push(user_email);
    });
  });
  if (users.length > 0) {
    await getDocuments("user", {
      field: "email",
      operator: Operator.In,
      value: users,
    }).then((users) => {
      if (users && users.length === 0) {
      } else {
        users &&
          users.forEach((user: any) => {
            allUsers.set(user.email, getUserObject(user));
          });
      }
    });
  }
  const class_users: User[] = [];

  classes.forEach((obj: Class, index: number, classes: Class[]) => {
    /* const students = Array.from(allUsers.values()).filter((u: any) => {
      return u.email != obj.primary_teacher;
    }); */

    const students = obj.students ?? [];

    const updatedClass: Class = {
      ...obj,
      students_count: students.length,
      students_users: students,
      students_email: students.map((u: any) => u.email),
    };

    store.dispatch({
      type: Actions.CLASSES.UPDATE,
      payload: {
        Class: updatedClass,
      },
    });

    classes.forEach((obj: Class, index: number, classes: Class[]) => {
      obj?.students?.forEach((user_email) => {
        if (allUsers.has(user_email)) {
          const user = allUsers.get(user_email);
          if (!class_users.includes(user!))
            class_users.push(allUsers.get(user_email)!);
        }
      });
    });
  });

  store.dispatch({
    type: Actions.USERS.ADD_BULK_USERS,
    payload: class_users,
  });
  return;
};

export const fetchUserForClass = async (thisClass: Class) => {
  const { data } = await axios.post("api/v1/list_user", {
    kind: "LIST_USER",
    class_id: thisClass.id,
    payload: {},
  });

  const { Code, Data } = data;
  if (Code !== 0) {
    console.error("list_classes failed", data);
    throw Data;
  }

  const users = Data;
  const students = users.filter(
    (u: any) => u.email != thisClass.primary_teacher
  );

  const updatedClass: Class = {
    ...thisClass,
    students_count: students.length,
    students_users: students,
    students_email: students.map((u: any) => u.email),
  };
  // if (students.length > thisClass.students_email.length) {
  store.dispatch({
    type: Actions.CLASSES.UPDATE,
    payload: {
      Class: updatedClass,
    },
  });
  // }
  store.dispatch({
    type: Actions.USERS.ADD_BULK_USERS,
    payload: users,
  });
  // allUsers.forEach((obj: User) => {
  //   store.dispatch({
  //     type: Actions.USERS.ADD,
  //     payload: obj
  //   })
  // })
  // console.log("all users", allUsers);
  return;
};

export const fetchAllUsers = async (classes: Class[]) => {
  const classesCompleted: any[] = [];
  fetchUserForClassSample();
  await fetchAllUserForClasses(classes);
  /* await classes.forEach((obj: Class, index: number, classes: Class[]) =>
    fetchAllUserForClasses(obj)
      .then(() => {
        classesCompleted.push(index);
      })
      .finally(() => {
        if (classesCompleted.length === classes.length) {
          console.log("all users fetched");
        }
      })
  ); */
};

export const getUserObject = (user: any): User => {
  const _user: User = {
    first_name: user["first_name"],
    last_name: user["last_name"],
    role: user["role"],
    username: user["username"],
    email: user["email"],
    img_url: user?.img_url,
    full_name: `${user["first_name"]} ${user["last_name"]}`,
    institutes: [
      {
        id: "",
        name: "",
        location: "",
        payment: { status: false },
        img_url: "",
        subscription: { status: false },
        feature_flags: { whitelabel: false },
      },
    ],
    settings: {
      type: user?.settings?.type,
      start_date: user?.settings?.start_date,
      end_date: user?.settings?.end_date,
      cost: user?.settings?.cost,
      currency: user?.settings?.currency,
      duration: user?.settings?.duration,
      in_trial: user?.settings?.in_trial,
      trial_begin: user?.settings?.trial_begin,
      trial_end: user?.settings?.trial_end,
      subscription_status: user?.settings?.subscription_status,
      coupon: user?.settings?.coupon,
      preferredLanguage: user?.settings?.preferredLanguage,
    },
  };

  return _user;
};

export const createOrUpdateUserInFirebase = async (user: any) => {
  try {
    // Sanitize the user object
    const updatedData = convertUndefinedToEmptyString(user);
    const { email, ...userData } = updatedData;

    // Check if the user exists
    const existingUser = await queryDocuments("user", [
      { field: "email", operator: "==", value: email },
    ]);

    if (existingUser.length > 0) {
      // User exists, update the document
      const existingUserId = existingUser[0].id;
      await updateDocument("user", existingUserId, updatedData);
    } else {
      // User does not exist, create a new document
      await addDocument("user", updatedData);
    }
  } catch (error) {
    console.error("Error creating/updating user:", error);
    throw error;
  }
};
