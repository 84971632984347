import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Composition } from "atomic-layout";
import { Button, Input, Modal, notification, Select, Space } from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import { classesForNewSession, currentSession, getClassById, getPrepareSessionForClass, getSessionById } from "@/Models/Selectors";
import { startPreparedSessionForClass, startSessionForClass } from "@/Services/Class";
import InviteModal, { InviteCode } from "@/Components/Class/inviteModal";
import { RouteComponentProps, useHistory } from "react-router-dom";
import store from "@/Models/store";
import PrepareSession from "./Prepare";

const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
  classId: string
}


interface NewSessionComponent extends RouteComponentProps<RouteParams> {
}

const AdminNewSession: React.FC<NewSessionComponent> = (props) => {
  const allClasses = useSelector(classesForNewSession);
  const history = useHistory();
  const [currentClassId, setCurrentClassId] = useState<string>(
    allClasses.length === 1 ? allClasses[0]?.id : ""
  );
  const classIdInURL = props.match.params.classId;
  const currentSessionObj = useSelector(currentSession);
  const currentClass = useSelector(getClassById)(classIdInURL);

  useEffect(() => {
    if (classIdInURL) {
      setCurrentClassId(classIdInURL);
    }
    return () => {

    }
  }, [classIdInURL])

  const onCurrentClassChange = useCallback((c: Class) => {
    const newUrl = '/new-session/' + c.id;
    history.push(newUrl);
    if (c.state !== "IN_SESSION")
      setCurrentClassId(c.id);
  }, [history]);

  // const currentClass = useMemo(
  //   () => allClasses.find((c) => c.id === currentClassId),
  //   [currentClassId, allClasses]
  // );

  const onStart = useCallback(
    (label:any) => {
      if (!currentClass) return Promise.reject();
      const preparedSession = getPrepareSessionForClass(store.getState())(currentClass.id)
      // const preparedSessionId = currentClass.hasPreparedSession && currentClass.sessions.length > 0 ? currentClass.sessions[0] : '';
      if (preparedSession) {
        return startPreparedSessionForClass(currentClass, label, preparedSession)
      }
      return startSessionForClass(currentClass, label, '');
    },
    [currentClass]
  );

  const onJoin = useCallback(() => {
    if (!currentClass) return Promise.reject();
    if (!currentClass.sessionId) return Promise.reject();

    return Promise.resolve(setCurrentSession(currentClass.sessionId));
  }, [currentClass]);

  if (!allClasses.length) {
    return null;
  }

  if (currentSessionObj) {
    console.log('current Session is ', currentSessionObj.sessionId);
    if (currentClassId && currentSessionObj.classId === currentClassId) {
      const newUrl = '/session/' + currentSessionObj.sessionId;
      history.push(newUrl);
    }
  }

  return (
    <>
      <Composition
        areas={createdSessionAreas}
        alignItems="center"
        justifyItems="center"
        gap="0px"
        paddingBottom="150px"
      >
        {({
          HeroImage,
          Header: HeaderGrid,
          Invitation,
          SessionControls: SessionGrid
        }) => (
          <>
            <HeroImage>
              <img
                // style={{ width: "25em" }}
                src={wallImage} alt='Students sitting together' />
            </HeroImage>
            <HeaderGrid>
              <Header
                allClasses={allClasses}
                currentClassId={currentClassId}
                onCurrentClassChange={onCurrentClassChange}
              />
            </HeaderGrid>


            {currentClass && currentClass.state !== "IN_SESSION" ?

              <>
                <Space direction="vertical"></Space>
                <SessionGrid>
                  <SessionControls
                    onStart={onStart}
                    onJoin={onJoin}
                    _class={currentClass}
                    isSessionOngoing={!!currentClass?.sessionId}
                  />
                </SessionGrid>
                <Invitation>
                  <InvitationControls currentClass={currentClass} />
                </Invitation>
              </>
              : null}
          </>
        )}
      </Composition>
    </>
  );
};

const HeaderTablet = `
    start
    classOption
`;
const HeaderMobile = `
    mobile
    classOption
`;

type headerProp = {
  allClasses: Class[];
  currentClassId: string;
  onCurrentClassChange: (c: Class) => void;
};
const Header = ({
  allClasses = [],
  currentClassId,
  onCurrentClassChange,
}: headerProp) => {
  const onClassSelect = useCallback(
    (class_id: string) => {
      const _class = allClasses.find((_c) => _c.id === class_id && _c.state !== "IN_SESSION");
      if (_class) onCurrentClassChange(_class);
    },
    [allClasses, onCurrentClassChange]
  );
  const defaultClass = allClasses.find((_c) => _c.id === currentClassId && _c.state !== "IN_SESSION");
  const defaultValue = defaultClass ? defaultClass.id : undefined;
  return (
    <Composition
      areas={HeaderMobile}
      areasMd={HeaderTablet}
      alignItems="center"
      gap="20px">

      {(Areas) => (
        <>
          <Areas.Start>
            <h1 className="font-normal text-3xl">Starting new session...</h1>
          </Areas.Start>
          <Areas.Mobile>
            <h1 className="font-normal text-3xl">Starting new session...</h1>
          </Areas.Mobile>

          <Areas.ClassOption>
            <Select
              placeholder='Select Class'
              value={defaultValue}
              style={{ width: "75%" }}
              onSelect={(value: any) => onClassSelect(value)}
            >
              {allClasses.map((c) => {
                let className;
                try {
                  className = c.description + " - " + c.name;
                }
                catch {
                  return;
                }
                className = c.state === "IN_SESSION" ? className.concat(" (ongoing session)") : className;
                return (
                  <Option
                    disabled={c.state === "IN_SESSION"}
                    value={c.id}
                    key={c.id}>
                    {className}
                  </Option>
                )
              }
              )}
            </Select>
          </Areas.ClassOption>
        </>
      )}
    </Composition>
  );
};

const areasControls = `
    description description description
    cancel input start
`;

const areasControlsMd = `
    description description
    input input
    cancel start
`;


type SessionControlsProps = {
  onStart: (label: string) => Promise<any>;
  onJoin: () => void;
  _class: Class;
  isSessionOngoing: boolean;
};
const SessionControls = ({
  onStart,
  onJoin,
  _class,
  isSessionOngoing,
}: SessionControlsProps) => {
  const preparedSessionId = _class.hasPreparedSession ? _class.sessions[0] : null;
  const preparedSession = getPrepareSessionForClass(store.getState())(_class.id);

  const existingSessionName = preparedSession ? preparedSession.name : ""
  const [label, setLabel] = useState<string>(existingSessionName);
  const history = useHistory();
  const onCancel = () => {
    history.push('/');
  }

  const [starting, setStarting] = useState<boolean>(false);
  const onStartClick = useCallback(() => {
    setStarting(true);
    onStart(label).then(() => {
      setStarting(false);
    }, () => {
      notification.error({
        message: "Error",
        description: "Something went wrong while starting session."
      })
    });
  }, [onStart, label]);


  return (
    <Composition
      areas={areasControlsMd}
      areasMd={areasControls}
      paddingTop="3em"
      gapCol="50px"
      gapRow="15px"
    >
      {({
        Input: InputGrid,
        Cancel: CancelGrid,
        Start: StartGrid,
        Description,
      }) => (
        <>
          <Description>
            <span>
              {preparedSession ?
                'Session prepared with ' + preparedSession.draft_questions.length + (preparedSession.draft_questions.length === 1 ? ' question' : ' questions')
                :
                'I am going to teach'}
            </span>
          </Description>
          <InputGrid
            className="w-64"
          >
            <Input
              className="m-2 border-2"
              type="text"
              value={label}
              onChange={(e) => setLabel(e.currentTarget.value)}
              placeholder="Topic (example: Trigonometry)"
            />
          </InputGrid>
          <CancelGrid>
            <Button type="ghost" onClick={onCancel}>
              Cancel
            </Button>
          </CancelGrid>
          <StartGrid>
            {isSessionOngoing && preparedSession?.state === "STARTED" ? (
              <Button type="primary" onClick={onJoin}>
                Join
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={onStartClick}
                loading={starting}
              >
                Start
              </Button>
            )}
          </StartGrid>
        </>
      )}
    </Composition>
  );
};

const invitationMobile = `
    description
    button
`;

const invitationMd = `
    description button
`;

type InvitationControlsProps = {
  currentClass: Class;
};
const InvitationControls = ({
  currentClass,
}: InvitationControlsProps) => {

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteModalClass, setInviteModalClass] = useState<Class | undefined>();

  const onInviteModalClose = useCallback(() => {
    setShowInviteModal(false);
    setInviteModalClass(undefined);
  }, []);

  const onClassInvite = (_class: Class | undefined) => {
    setShowInviteModal(true);
    setInviteModalClass(_class);
  };


  return (
    <Composition
      areas={invitationMobile}
      areasMd={invitationMd}
      paddingTop="2em"
      // gapCol="50px"
      gapRow="15px"
      justifyContent="center"

    >
      {(Areas) => (
        <>
          <Areas.Description>
            <InviteCode
              description="New students would need this invite code "
              size="medium"
              _class={currentClass} />
            {/* <div className="font-bold">
              First time students need to be invited to the class first.
            </div> */}
          </Areas.Description>
          {/* <Areas.Button>
            <Button
              type="ghost"
              size="middle"
              className=""
              onClick={(e) => onClassInvite(currentClass)}
            >
              Invite
            </Button>
          </Areas.Button> */}
          <Modal
            visible={showInviteModal}
            onOk={onInviteModalClose}
            onCancel={onInviteModalClose}
            width={"550px"}
            closable={true}
            footer={null}
            style={{
              padding: "10px",
            }}
            title={`Invite Students - ${inviteModalClass?.name}`}
          >
            {showInviteModal && inviteModalClass ? (
              <InviteModal _class={inviteModalClass} />
            ) : null}
          </Modal>
        </>
      )
      }
    </Composition >

  );
};



export default AdminNewSession;
