// firebaseService.ts

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  WhereFilterOp,
  DocumentData,
  Query,
  QueryDocumentSnapshot,
  onSnapshot,
  getDoc,
  setDoc,
  DocumentChange,
  QueryConstraint,
} from "firebase/firestore";
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { convertUndefinedToEmptyString } from "@/Components/Common/Utils";
import { currentUser } from "@/Models/Selectors";
import store from "@/Models/store";
import { firebaseDevConfig, firebaseProdConfig } from "./Constants";
import { Auth, Unsubscribe, getAuth } from "firebase/auth";
import { getSignupEmail, logout } from "./Auth";
import { getUserRole, isStudent } from "./User";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
  process.env.REACT_APP_ENV == "prod" ? firebaseProdConfig : firebaseDevConfig;

export const FirebaseCollectionNames = {
  QUESTION_LIBRARY: "question_bank",
  QUESTION: "questions",
  USER: "user",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const getRef = (collectionName: string, documentId: string) =>
  doc(db, collectionName, documentId);

export const getFirebaseAuth = (): Auth => {
  return getAuth(app);
};

export const isUserLoggedIn = () => {
  return getFirebaseAuth().currentUser !== null;
};

export const addDocument = async (
  collectionName: string,
  document: any
): Promise<DocumentData> => {
  try {
    let updatedDoc = document;
    if (updatedDoc.hasOwnProperty("id")) {
      delete updatedDoc.id;
    }
    const docRef = await addDoc(collection(db, collectionName), updatedDoc);
    return {
      id: docRef.id,
      ...updatedDoc,
    };
  } catch (error) {
    console.error(`Error adding document to ${collectionName}:`, error);
    throw error;
  }
};

// Export the 'deleteDocument' function
export const deleteDocument = async (
  collectionName: string,
  documentId: string
): Promise<void> => {
  try {
    await deleteDoc(doc(db, collectionName, documentId));
    console.log("Document deleted successfully");
  } catch (error) {
    console.error(`Error deleting document with ID ${documentId}:`, error);
    throw error;
  }
};

export const updateDocument = async (
  collectionName: string,
  documentId: string,
  updates: Partial<any>
): Promise<any> => {
  try {
    const thisUser = currentUser(store.getState());
    // Get a reference to the document you want to update
    const documentRef = doc(db, collectionName, documentId);
    // Update the document
    await updateDoc(documentRef, updates);

    // Fetch the updated document
    const updatedDocSnapshot = await getDoc(documentRef);
    const updatedDocData = updatedDocSnapshot.data();

    return updatedDocData;

    console.log("Document updated successfully");
  } catch (error) {
    console.error(`Error updating document in ${collectionName}:`, error);
    throw error;
  }
};

export const getDocument = async (
  collectionName: string,
  docId: string
): Promise<any> => {
  try {
    const documentRef = doc(collection(db, collectionName), docId);

    const snapshot = await getDoc(documentRef);
    if (snapshot.exists()) {
      // Document found, access its data
      const data = snapshot.data();
      return {
        id: snapshot.id,
        ...data,
      };
      console.log(data);
    } else {
      // Document does not exist
      return Promise.reject("Not found");
      console.log("No such document!");
    }
  } catch (error) {
    console.error(`Error getting documents from ${collectionName}:`, error);
    throw error;
  }
};

export const getDocuments = async (
  collection: string,
  conditions: { field: string; operator: WhereFilterOp; value: any }
) => {
  const data = await queryDocuments(collection, [conditions]);

  if (data) {
    return data ?? false;
  }

  return false;
};

export const getSingleDocument = async (
  collection: string,
  field: string,
  value: string
) => {
  const data = await queryDocuments(collection, [
    {
      field: field,
      operator: "==",
      value: value,
    },
  ]);

  if (data) {
    return data[0] ?? false;
  }

  return false;
};

export const getDocumentMultipleCondition = async (
  collection: string,
  whereConditions: whereCondition[]
) => {
  const queryConstraints: any[] = [];

  whereConditions.forEach((c) => {
    queryConstraints.push(where(c.field, c.operator, c.value));
  });

  const data = await queryDocumentsMultipleWhere(collection, queryConstraints);

  if (data) {
    return data ?? false;
  }

  return false;
};

export const queryDocumentsMultipleWhere = async (
  collectionName: string,
  conditions: any[]
): Promise<any[]> => {
  try {
    let firestoreQuery = query(collection(db, collectionName));

    // Apply conditions if provided
    if (conditions) {
      const q = query(firestoreQuery, ...conditions);
      const querySnapshots = await getDocs(q);

      // Convert the map values back to an array
      const uniqueDocuments = Array.from(querySnapshots.docs.values());

      return uniqueDocuments.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    }

    const snapshot = await getDocs(firestoreQuery);

    if (snapshot.empty) {
      return [];
    }

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error(`Error getting documents from ${collectionName}:`, error);
    throw error;
  }
};

export const queryDocuments = async (
  collectionName: string,
  conditions?: { field: string; operator: WhereFilterOp; value: any }[]
): Promise<any[]> => {
  try {
    let firestoreQuery = query(collection(db, collectionName));

    // Apply conditions if provided
    if (conditions) {
      const conditionQueries = conditions.map((condition) =>
        query(
          firestoreQuery,
          where(condition.field, condition.operator, condition.value)
        )
      );

      // Get the documents for each condition query
      const querySnapshots = await Promise.all(
        conditionQueries.map((conditionQuery) => getDocs(conditionQuery))
      );

      // Merge the document arrays into a single array
      const mergedDocuments = querySnapshots.reduce<
        QueryDocumentSnapshot<any>[]
      >(
        (merged, snapshot) => [...merged, ...snapshot.docs],
        [] as QueryDocumentSnapshot<any>[]
      );

      // Remove duplicates by converting the merged documents to a map with keys as document IDs
      const uniqueDocumentsMap = mergedDocuments.reduce<Map<string, any>>(
        (map, doc) => {
          map.set(doc.id, doc);
          return map;
        },
        new Map<string, any>()
      );

      // Convert the map values back to an array
      const uniqueDocuments = Array.from(uniqueDocumentsMap.values());

      return uniqueDocuments.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    }

    const snapshot = await getDocs(firestoreQuery);

    if (snapshot.empty) {
      return [];
    }

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error(`Error getting documents from ${collectionName}:`, error);
    throw error;
  }
};

export const subscribeUpdatesForCollection = (
  collectionName: string,
  conditions: { field: QuestionLibraryKeys; operator: Operator; value: any }[]
) => {
  let unsubscribers: { number: any };
  conditions.forEach((condition, index) => {
    const q = query(
      collection(db, collectionName),
      where(condition.field, condition.operator, condition.value)
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log("Document updated:", doc.id, doc.data());
        // Perform any actions or update UI based on the updated document data
      });
    });
    // unsubscribers[index] = unsubscribe
  });

  // To stop listening to updates, call the unsubscribe function
  // This can be done when you no longer need the real-time updates or when the component unmounts
  // unsubscribe();
};

export const subscribeUpdatesForCollectionNew = (
  collectionName: string,
  conditions: { field: string; operator: Operator; value: any }[],
  callback?: Function
) => {
  let unsubscribers: Unsubscribe[] = [];
  conditions.forEach((condition, index) => {
    const q = query(
      collection(db, collectionName),
      where(condition.field, condition.operator, condition.value)
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot
        .docChanges()
        .forEach((changes: DocumentChange<DocumentData>) => {
          if (typeof callback === "function") {
            const data = changes.doc.data();
            callback(data);
          }
        });
    });
    unsubscribers[index] = unsubscribe;
  });

  // To stop listening to updates, call the unsubscribe function
  // This can be done when you no longer need the real-time updates or when the component unmounts
  return unsubscribers;
};

export const createOrUpdateData = async (
  collectionName: string,
  field: string,
  fieldValue: string,
  data: any
) => {
  try {
    const updatedData = convertUndefinedToEmptyString(data);
    const collectionRef = collection(db, collectionName);
    const loggedInUserEmail = getAuth().currentUser?.email;
    const q = query(collectionRef, where(field, "==", fieldValue));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // No matching document found, create a new document
      await setDoc(doc(collectionRef), updatedData);

      console.log("New document created successfully.");

      return {
        ...updatedData,
        id: doc(collectionRef).id,
      };
    } else {
      // Matching document found, update the first document in the query snapshot
      const docRef = querySnapshot.docs[0].ref;
      await updateDoc(docRef, updatedData);
      const updatedDocSnapshot = await getDoc(docRef);
      console.log("Document updated successfully.");
      const updatedDocData = updatedDocSnapshot.data();

      return {
        id: updatedDocSnapshot.id,
        ...updatedDocData,
      };
    }
  } catch (error) {
    console.error("Error creating or updating document:", error);
  }
};

export interface whereCondition {
  field: string;
  operator: WhereFilterOp;
  value: string | number | string[] | number[];
}

export const createOrUpdateDataMultiple = async (
  collectionName: string,
  whereConditions: whereCondition[],
  data: any
) => {
  try {
    const queryConstraints: QueryConstraint[] = [];
    const updatedData = convertUndefinedToEmptyString(data);
    const collectionRef = collection(db, collectionName);

    whereConditions.forEach((c) => {
      queryConstraints.push(where(c.field, c.operator, c.value));
    });

    const q = query(collectionRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // No matching document found, create a new document
      await setDoc(doc(collectionRef), updatedData);

      console.log("New document created successfully.");

      return {
        ...updatedData,
        id: doc(collectionRef).id,
      };
    } else {
      // Matching document found, update the first document in the query snapshot
      const docRef = querySnapshot.docs[0].ref;
      await updateDoc(docRef, updatedData);
      const updatedDocSnapshot = await getDoc(docRef);
      console.log("Document updated successfully.");
      const updatedDocData = updatedDocSnapshot.data();

      return {
        id: updatedDocSnapshot.id,
        ...updatedDocData,
      };
    }
  } catch (error) {
    console.error("Error creating or updating document:", error);
  }
};

export const updateDataMultiple = async (
  collectionName: string,
  whereConditions: whereCondition[],
  data: any
) => {
  try {
    const queryConstraints: QueryConstraint[] = [];
    const updatedData = convertUndefinedToEmptyString(data);
    const collectionRef = collection(db, collectionName);

    whereConditions.forEach((c) => {
      queryConstraints.push(where(c.field, c.operator, c.value));
    });

    const q = query(collectionRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Matching document found, update the first document in the query snapshot

      querySnapshot.docs.forEach(async (doc) => {
        await updateDoc(doc.ref, updatedData);
        const updatedDocSnapshot = await getDoc(doc.ref);
        console.log("Document updated successfully.");
        const updatedDocData = updatedDocSnapshot.data();
      });

      return {
        id: true,
      };

      /* return {
        id: updatedDocSnapshot.id,
        ...updatedDocData,
      }; */
    }
  } catch (error) {
    console.error("Error creating or updating document:", error);
  }
};

export const isUserRegistered = async (email: string) => {
  const response = await queryDocuments("users", [
    {
      field: "email",
      operator: "==",
      value: email,
    },
  ]);

  return response.length > 0;
};

export const getCollectionRow = (
  collection: string,
  field: string,
  value: string
) => {
  const loggedInUserEmail = getAuth().currentUser?.email;

  if (loggedInUserEmail) {
    return queryDocuments(collection, [
      {
        field: "admins",
        operator: "==",
        value: loggedInUserEmail,
      },
      {
        field: field,
        operator: "==",
        value: value,
      },
    ]);
  } else {
    logout();
  }
};

export const getCollectionRows = (collection: string) => {
  const loggedInUserEmail = getSignupEmail();
  const _user = currentUser(store.getState());
  console.log("USER EMAIL", loggedInUserEmail, getUserRole());
  if (loggedInUserEmail) {
    return queryDocuments(collection, [
      {
        field: isStudent() ? "students" : "admins",
        operator: Operator.ArrayContains,
        value: loggedInUserEmail,
      },
    ]);
  } else {
    logout();
  }
};

// Enum for the operators
export enum Operator {
  Equal = "==",
  NotEqual = "!=",
  GreaterThan = ">",
  GreaterThanOrEqual = ">=",
  LessThan = "<",
  LessThanOrEqual = "<=",
  ArrayContains = "array-contains",
  In = "in",
  NotIn = "not-in",
  ArrayContainsAny = "array-contains-any",
}

// Enum for the fields
export enum Field {
  CreatedBy = "createdBy",
  SharedWithUser = "shared_with_user",
  Age = "age",
  // Add more fields as needed
}
