import store from './store';
import type { applicationState } from './store';

export type selector<T> = (state: applicationState) => T;
export type callback<T> = (currentValue: T) => void;
type subscription<T> = {
    selector: selector<T>,
    callback: callback<T>,
    currentValue: T
}

const subscriptions: subscription<any>[] = [];

export const subscribe = <T extends unknown>(selector: selector<T>, callback: callback<T>) => {
    const currentValue = selector(store.getState());

    subscriptions.push({
        selector,
        callback,
        currentValue
    } as subscription<T>)
}

store.subscribe(() => {
    subscriptions.forEach((subscription) => {
        const { selector, callback, currentValue } = subscription;

        if (selector(store.getState()) !== currentValue) {
            subscription.currentValue = selector(store.getState());
            callback(currentValue);

        }
    })
})