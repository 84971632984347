import Actions from "../Actions";

const defaultState: questionLibraryReduxState = {
    allLibraries: {}
}

const questionLibraryReducer = (state = defaultState, action: reduxAction): questionLibraryReduxState => {

    switch (action.type) {
        case Actions.QuestionLibrary.BULK_ADD: {
            const { payload } = action;

            const payloadObject: { [id: string]: any } = payload.reduce((obj: any, payload: any) => {
                obj[payload.id] = payload;
                return obj;
            }, {});
            return {
                ...state,
                allLibraries: {
                    ...state.allLibraries,
                    ...payloadObject
                }
            }
        }

        case Actions.QuestionLibrary.ADD: {
            const { payload } = action;
            const id = payload.id;
            return {
                ...state,
                allLibraries: {
                    ...state.allLibraries,
                    [id]: payload
                }
            }
        }

        case Actions.QuestionLibrary.UPDATE: {
            const { payload } = action;
            const id = payload.id;
            // const oldClass = state.allClasses[id];
            return {
                ...state,
                allLibraries: {
                    ...state.allLibraries,
                    [id]: payload
                }
            }
        }

        case Actions.QuestionLibrary.DELETE: {
            const { payload } = action;
            const id = payload;

            delete state.allLibraries[id];
            return {
                ...state
            };
        }
    }
    return state;
}

export default questionLibraryReducer;