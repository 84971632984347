/* globals zoomSdk */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Bugfender } from '@bugfender/sdk';
import store from './Models/store';
import '@/Services/Axios';
import '@/Services/User';
import '@/Services/Socket';
import '@/Services/Class';
// import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";
import axios from 'axios';
import { GOOGLE_CLIENT_ID, isRunningAsTeamsApp, isRunningAsZoomApp, ZOOM_APP_OAUTH_STATE_SECRET, ZOOM_CLIENT_ID } from './Services/Constants';
import { initZoomSetup } from './Services/ZoomServices';
// import * as ZOOMSDK from './SDKs/';

// import { zoomSdk } from '@/SDKs/'
import mixpanel from 'mixpanel-browser';

Sentry.init({
  dsn: "https://fb97cd8974554cd8a0470f9796b66b6d@o552130.ingest.sentry.io/5677264",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Bugfender.init({
  appKey: 'TMGD4c3fZ0pBCWlSWIbhNVotCdTUgH9Q',
});
const mixpanelId = process.env.REACT_APP_MIXPANEL_ID || 'e6fe85d15378b904aa30b3b7b3470680';
mixpanel.init(mixpanelId,
  {
    debug: true,
    ip: true,
    ignore_dnt: true
  });

if (isRunningAsTeamsApp) {
  microsoftTeams.initialize()
}

// //@ts-expect-error
// window.zoomSdk = zoomSdk;

ReactDOM.render(
      <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//Add auth script in this manner, so that it runs after react code is executed.
(function addGAuthScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript'
  script.id = 'google-auth-script';

  script.src = "https://apis.google.com/js/platform.js?onload=initAuth";
  // script.src = "https://accounts.google.com/gsi/client?onload=initAuth";
  script.defer = true;
  script.async = true;
  const newAgent = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36'
  console.log("agent is", navigator.userAgent)

  // script.onload = function () {
  //   // @ts-expect-error
  //   google.accounts.id.initialize({
  //     client_id: GOOGLE_CLIENT_ID,
  //     ux_mode: 'redirect',
  //     login_uri: window.location.origin,
  //     callback: () => {
  //       console.log('google auth responded')
  //     }
  //   });
  //   // @ts-expect-error
  //   google.accounts.id.prompt();
  // };
  document.body.appendChild(script);

})();


(function addZappScript() {

  const script = document.createElement('script');
  script.id = 'zapp-script';
  script.async = true;
  script.src = "/zoom-apps-js-sdk-0.16.12.min.js"
  script.onload = () => {
    console.log("configuring window zoomsdk object")
    //@ts-expect-error
    window.zoomSdk = zoomSdk;
    if (isRunningAsZoomApp) initZoomSetup();
  };
  document.body.appendChild(script);
})();

