



import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Composition } from "atomic-layout";
import { Breadcrumb, Button, Divider, Input, Layout, Menu, Modal, notification, PageHeader, Result, Select, Space, Tabs, DatePicker, Card, Statistic, Row, Col, Progress, Table, Tooltip } from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import { classesForNewSession, cumilativeStatsForStudents, currentSession, getSessionById, getUserById, isFetchingClasses, myClasses } from "@/Models/Selectors";
import { fetchAllClasses, fetchClassSessions, startSessionForClass } from "@/Services/Class";
import InviteModal from "@/Components/Class/inviteModal";
import { Link, Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { AppstoreOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, CalendarOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, DownloadOutlined, DownOutlined, FundProjectionScreenOutlined, LaptopOutlined, LikeOutlined, NotificationOutlined, PlusOutlined, QuestionCircleOutlined, SettingOutlined, StarOutlined, TeamOutlined, UngroupOutlined, UserOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Engagement from "@/Icons/engagement.svg"

import "./Stats.css"
import store from "@/Models/store";
import ClassStudentsStats from "./ClassStudentsStats";
import ClassSessionStats from "./ClassSessionStats";
import Loader from "@/Components/Loader";

import correct_icon from '@/Assets/correct.png';
import incorrect_icon from '@/Assets/refresh.png';
import absent_icon from '@/Assets/question_mark.png';
import engagement_icon from '@/Assets/exclamation.png';



import moment from 'moment';
// import { DualAxes, Line, TinyArea } from '@ant-design/charts';
import { LabelList, Bar, ComposedChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as ChartToolTip, ResponsiveContainer, Legend } from 'recharts';

import UserAvatar from "../Common/Image/UserAvatar";
import StatsTiles from "./StatsTiles";
import { DefaultTileCardTextColor } from "@/Services/Constants";
import UserLabel from "../Common/Image/UserLabel";
import { convertJsonToCSV, downloadCSVFile } from "../Common/CSVConverter";
import { start } from "repl";
import Analytics from "@/Services/Analytics";
import { getDisplayEmail } from "@/Services/User";

const { RangePicker } = DatePicker;


const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
    classId: string,
    studentId: string,
}
const { TabPane } = Tabs;


interface StatsComponent extends RouteComponentProps<RouteParams> {
}

const StudentDetailStats: React.FC<StatsComponent> = (props) => {

    const { SubMenu } = Menu;
    const { Header, Content, Sider } = Layout;
    const [sortedInfo, setSortedInfo] = useState<{ "columnKey": string, "order": string }>();

    // const allClasses = useSelector(classesForNewSession);
    const listOfClasses = useSelector(myClasses)();
    const fetchingClasses = isFetchingClasses(store.getState());
    const hasFetchedData = useRef(false);
    const [allClasses, setAllClasses] = useState<Class[]>(listOfClasses);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(90, 'days').toDate())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateDiff, setDateDiff] = useState(moment(endDate).diff(moment(startDate), 'days'))
    const previousStartDate = moment(startDate).subtract(dateDiff, 'days').toDate()
    const [isFetchingData, setIsFetchingData] = useState(false);

    const history = useHistory();


    const [currentClassId, setCurrentClassId] = useState<string>(
        allClasses.length === 1 ? allClasses[0]?.id : ""
    );
    const classIdInURL = props.match.params.classId;
    const studentIdInURL = props.match.params.studentId;
    const currentStudentId = useRef(studentIdInURL);
    const _class: Class = allClasses.filter((c: Class) => c.id == classIdInURL)[0];
    const selectedStudent = useSelector(getUserById)(studentIdInURL)
    const currentSessionObj = useSelector(currentSession);
    const studentsStatsCurrent = useSelector(cumilativeStatsForStudents)(classIdInURL || '', startDate, endDate)
        .filter((stat: studentStats) => stat.email === studentIdInURL);
    const studentsStatsPast = useSelector(cumilativeStatsForStudents)(classIdInURL || '', previousStartDate, startDate);

    const tabValues = [
        'sessions',
        'students',
        'configure'
    ]


    const studentMenu = _class.students_email?.map((email: string) => {
        const student = getUserById(store.getState())(email)
        return {
            path: 'student/' + student.email,
            breadcrumbName: student.full_name,
        }
    });
    function itemRenderer(route: any, params: any, routes: any, paths: any) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span>{route.breadcrumbName}</span>
        ) : (
            <Link to={'/' + paths.join('/')}>{route.breadcrumbName}</Link>
        );
    }
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        Analytics.track('View Student Stats', { ...selectedStudent, ...studentsStatsCurrent });
    }, [])


    useEffect(() => {
        if (_class) {
            hasFetchedData.current = false;
            setIsFetchingData(true);
            fetchClassSessions(_class).then(() => {
                hasFetchedData.current = true;
                setIsFetchingData(false);
            }).finally(() => {
            });
        }
    }, [_class]);

    useEffect(() => {
        if ((!hasFetchedData.current || currentStudentId.current != studentIdInURL)
            && !isFetchingData
            && _class) {
            fetchClassSessions(_class).then(() => {
                // setSessions(sessions)
                hasFetchedData.current = true;
                currentStudentId.current = studentIdInURL
            });
        }
        onTop()
    }, [studentIdInURL]);


    if (!classIdInURL && allClasses.length > 0) return <Redirect to={"analyse/class/" + allClasses[0].id} />

    if (!fetchingClasses && classIdInURL !== 'add' && !_class) {
        // fetchAllClasses();
    }

    function onDateChange(dates: any, dateStrings: any) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        setStartDate(dates[0].toDate());
        setEndDate(dates[1].toDate());
        setDateDiff(moment(dates[1]).diff(moment(dates[0]), 'days'))
    }

    const engagement_data = studentsStatsCurrent.map((stat: studentStats) => parseInt((100 * (stat.responses || 0) / (stat.totalQuestions || 1)).toFixed(0)))
    const studentDataSource = studentsStatsCurrent
        .filter((stat: studentStats) => stat.startDate >= startDate && stat.startDate < endDate && stat.email == studentIdInURL)
        .map((stat: studentStats) => {
            const statTime = stat.startDate.getDate() + '-' + (stat.startDate.getMonth() + 1) + '-' + stat.startDate.getFullYear()
            const session = getSessionById(store.getState())(stat.sessionId)
            const link = "/class/" + _class.id + "/session/" + session.sessionId + '/review'
            return {
                display_time: statTime,
                date: stat.startDate,
                key: stat.sessionId + stat.email,
                engagement: parseInt((100 * stat.responses / (stat.totalQuestions || 1)).toFixed(0)),
                correctness: parseInt((100 * stat.correct / (stat.totalQuestions || 1)).toFixed(0)),
                incorrectness: parseInt((100 * stat.incorrect / (stat.totalQuestions || 1)).toFixed(0)),
                dontknow: parseInt((100 * stat.dontKnow / (stat.totalQuestions || 1)).toFixed(0)),
                sessionName: <Link to={link}> {session.name} </Link>,
                title: session.name + ' (' + moment(stat.startDate).format('dddd, MMMM Do') + ')'
            }
        })
    const correctness_data = studentDataSource.map((stat) => stat.correctness)
    const attendance_data = studentsStatsCurrent.map((stat: studentStats) => stat.absent ? 0 : 100)

    let arrTime = []

    const dayMillisec = 24 * 60 * 60 * 1000
    for (let q = studentsStatsCurrent.filter((stat: studentStats) => !stat.absent)[0]?.startDate; q <= endDate; q = new Date(q.getTime() + dayMillisec)) {
        arrTime.push(q);
    }
    let session_data: number[] = []
    arrTime.map((date: Date) => {
        const sessionExist = studentsStatsCurrent.filter((stat: studentStats) => moment(stat.startDate).isSame(date, 'days'))
        session_data.push(sessionExist.length > 0 ? 1 : 0)
    })

    const graphConfig = {
        data: studentDataSource,
        xField: 'display_time',
        yField: 'correctness',
        tooltip: {
            title: 'title',
        },
    };

    const allStudentsColumns: Array<any> = [
        {
            "title": <UserOutlined />,
            "dataIndex": "sessionName",
            "key": "sessionName",
            width: 100,
            sortOrder: sortedInfo ? (sortedInfo.columnKey === 'name' || sortedInfo.columnKey === "attendance") && sortedInfo.order : null,
            showSorterTooltip: { title: "Name" }
            // render: (text: any, record: any, index: any) => text ? <Link to={'/analyse/class/' + classIdInURL}><UserLabel email={record.key} /></Link> : record.key

        },
        {
            "title": <CalendarOutlined />,
            "dataIndex": "date",
            "key": "date",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => Date.parse(a.date) - Date.parse(b.date),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'date' && sortedInfo.order : null,
            showSorterTooltip: { title: "Date" },
            render: (text: any, record: any, index: any) => moment(text).format('MMMM D, YYYY')

        },
        {
            "title": <StarOutlined />,
            "dataIndex": "engagement",
            "key": "engagement",
            sorter: (a: any, b: any) => parseInt(a.engagement) - parseInt(b.engagement),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'engagement' && sortedInfo.order : null,
            showSorterTooltip: { title: "Engagement" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0

        },
        {
            "title": <CheckCircleOutlined />,
            "dataIndex": "correctness",
            "key": "correctness",
            sorter: (a: any, b: any) => parseInt(a.correctness) - parseInt(b.correctness),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'correctness' && sortedInfo.order : null,
            showSorterTooltip: { title: "Correct Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        },
        {
            "title": <CloseCircleOutlined />,
            "dataIndex": "incorrectness",
            "key": "incorrectness",
            sorter: (a: any, b: any) => parseInt(a.incorrectness) - parseInt(b.incorrectness),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'incorrectness' && sortedInfo.order : null,
            showSorterTooltip: { title: "Incorrect Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        },
        {
            "title": <QuestionCircleOutlined />,
            "dataIndex": "dontKnow",
            "key": "dontKnow",
            sorter: (a: any, b: any) => parseInt(a.dontKnow) - parseInt(b.dontKnow),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'dontKnow' && sortedInfo.order : null,
            showSorterTooltip: { title: "Not Sures" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        }
    ];
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo(sorter)
    };

    const routes = [
        {
            path: '/analyse',
            breadcrumbName: 'Analyse',
        },
        {
            path: '/class/' + classIdInURL + '/sessions',
            breadcrumbName: _class.description + ' (' + _class.name + ')',
        },
        {
            path: '/analyse/class/' + classIdInURL + '/STUDENT/' + studentIdInURL,
            breadcrumbName: selectedStudent.full_name
        }
    ];

    const columnsLayout = {
        xs: 24,
        sm: 12,
        md: 6,
        lg: 6
    }

    const downloadClassStats = () => {
        Analytics.track('Download Detailed Student Stats', { ...selectedStudent, ...studentsStatsCurrent });
        console.log("download stats for ", selectedStudent.full_name);
        const csvArray: {}[] = [];
        studentDataSource.forEach((stat, index) => {
            var csvObj = {
                '#': index + 1,
                Session_Name: stat.sessionName,
                Date: moment(stat.date,).format('LL'),
                Class: _class.name,
                Student_Name: selectedStudent.full_name,
                Student_Email: getDisplayEmail(selectedStudent.email),
                Attendance: stat.engagement == 0 ? 'Absent' : 'Present',
                Engagement_Percentage: stat.engagement + "%",
                Correct_Percentage: stat.correctness + "%",
                Incorrect_Percentage: stat.incorrectness + "%",
                DontKnow_Percentage: stat.dontknow + "%"
            }
            csvArray.push(csvObj);
        })
        const csvStats = convertJsonToCSV(csvArray);
        console.log(csvStats);
        downloadCSVFile(csvStats, "Vidya Class Stats - " + selectedStudent.full_name + ' (from ' + _class.description + "-" + _class.name + ") - " + moment(startDate).format('DD.MM.YY') + ' - ' + moment(endDate).format('DD.MM.YY') + '.csv')
    };


    const getStudentStats = (stats: studentStats[]) => {

        const studentStats = stats
            .filter((stat: studentStats) => stat.email === studentIdInURL && stat.classId === classIdInURL)

        let totalCorrect = 0;
        let totalResponses = 0;
        let totalIncorrect = 0;
        let totalDontKnows = 0;
        let totalPresent = 0;
        let totalQuestions = 0;

        studentStats.map((stat: studentStats) => {
            totalCorrect = totalCorrect + 100 * ((stat.correct || 0) / stat.totalQuestions)
            totalResponses = totalResponses + 100 * ((stat.responses || 0) / stat.totalQuestions)
            totalIncorrect = totalIncorrect + 100 * ((stat.incorrect || 0) / stat.totalQuestions)
            totalDontKnows = totalDontKnows + 100 * ((stat.dontKnow || 0) / stat.totalQuestions)
            totalPresent = totalPresent + (stat.absent ? 0 : 1)
            totalQuestions = totalQuestions + (stat.totalQuestions || 0)
        })
        return {
            EngagementPercentage: parseInt((totalResponses / studentStats.length).toFixed(0)),
            CorrectPercentage: parseInt((totalCorrect / studentStats.length).toFixed(0)),
            IncorrectPercentage: parseInt((totalIncorrect / studentStats.length).toFixed(0)),
            AttendancePercentage: parseInt((100 * totalPresent / studentStats.length).toFixed(0)),
            TotalSessions: studentStats.length
        }
    }

    const currentStats = getStudentStats(studentsStatsCurrent);
    const pastStats = getStudentStats(studentsStatsPast);

    return (
        <>
            <Layout
            // style={{ height: "100vh" }}
            >
                {fetchingClasses || (classIdInURL !== 'add' && !_class) ? <Loader /> : <>
                    {allClasses.length === 0 ?
                        <Result
                            status="404"
                            title="No Classes"
                            subTitle="You have not created any class yet."
                            extra={<Button type="primary">Create Class</Button>}
                        /> :
                        <>

                            <Layout
                                className="m-auto p-2 sm:p-3 sm:w-full w-full"
                                style={
                                    {
                                        // padding: '0 24px 24px',
                                        maxWidth: '1500px',
                                        minHeight: '100vh',
                                        height: 'fit-content'
                                    }}>
                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 0,
                                        margin: 12,
                                        minHeight: 280,
                                    }}
                                >
                                    <Layout className="site-layout-background lyt"
                                        style={{
                                            padding: '0 0'
                                        }}>
                                        <Sider
                                            style={{
                                                overflow: 'auto',
                                                left: 0,

                                            }}
                                            width={200}
                                            collapsedWidth={40}
                                            collapsible={true}
                                            breakpoint="lg"
                                            className="site-layout-background"
                                        >
                                            <Menu
                                                theme="light"
                                                mode="inline"
                                                defaultSelectedKeys={[studentIdInURL]}
                                                defaultOpenKeys={['sub1']}
                                                style={{
                                                    height: '100%', borderRight: 0, paddingTop: 10,
                                                    background: '#fafafa'
                                                }}
                                            >
                                                {_class.students_email?.map((email: string) => {
                                                    return <Menu.Item
                                                        key={email}>
                                                        <Link to={"/analyse/class/" + classIdInURL + "/students/" + email} >
                                                            <UserLabel email={email} />
                                                        </Link>
                                                    </Menu.Item>
                                                })}
                                            </Menu>
                                        </Sider>
                                        <Content className="vidya-page-layout" style={{ padding: '18px 24px' }}>
                                            {isFetchingData ?
                                                <Loader /> :
                                                <div className="text-left">
                                                    <PageHeader
                                                        style={{ padding: 0 }}
                                                        className="site-page-header"
                                                        onBack={() => null}
                                                        title={<span>  <UserAvatar email={studentIdInURL}></UserAvatar> {selectedStudent?.full_name} </span>}
                                                        subTitle={<span>  {getDisplayEmail(selectedStudent.email)}</span>}
                                                        backIcon={<Link className="text-black" to={'/analyse/class/' + classIdInURL + '/sessions'}> <ArrowLeftOutlined /> </Link>}
                                                        breadcrumb={{ routes: routes, itemRender: itemRenderer }}
                                                    />
                                                    <Composition
                                                        areas={
                                                            `date
                                                            tiles
                                                            graph
                                                            
                                                            studentList
                                                            `}
                                                        // alignItems="start"
                                                        // justifyItems="start"
                                                        gapRow="2em"
                                                        width="100%"
                                                        style={{ display: 'block' }}
                                                    >
                                                        {({ Date, Tiles, Graph, StudentBreakdown, SessionList, StudentList }) => (
                                                            <>
                                                                <div className="inline-block mb-4 w-full">
                                                                    <Date>
                                                                        <div className='float-right mb-4'>
                                                                            <RangePicker
                                                                                style={{ width: 350 }}
                                                                                ranges={{
                                                                                    Yesterday: [moment().subtract(1, "days"), moment()],
                                                                                    'Last 7 days': [moment().subtract(7, "days"), moment()],
                                                                                    'Last 30 days': [moment().subtract(30, "days"), moment()],
                                                                                    'This month': [moment().startOf('month'), moment()],
                                                                                    'Last 3 months': [moment().subtract(1, "quarter"), moment()],

                                                                                }}
                                                                                defaultValue={[moment(startDate), moment(endDate)]}
                                                                                onChange={onDateChange}
                                                                                format='MMMM Do, yy'
                                                                            />
                                                                            <Tooltip title="Download Report">
                                                                                <Button
                                                                                    className="ml-4"
                                                                                    onClick={() => downloadClassStats()} type="text"><DownloadOutlined /></Button>
                                                                            </Tooltip>

                                                                        </div>

                                                                    </Date>
                                                                    <Divider />
                                                                </div>


                                                                <Tiles>
                                                                    <div className="site-statistic-demo-card">
                                                                        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                                                                            <Col
                                                                                {...columnsLayout}
                                                                                className="gutter-row"
                                                                            // span={16}
                                                                            >
                                                                                <StatsTiles
                                                                                    value={currentStats.EngagementPercentage}
                                                                                    icon={<StarOutlined />}
                                                                                    title="Avg. Engagement"
                                                                                    compareNumber={pastStats.EngagementPercentage}
                                                                                    type="percent"
                                                                                    graphData={engagement_data}
                                                                                    tooltip="This compares Total Questions with the Total Responses. Note, absent session would still count as 0 engagement"
                                                                                />
                                                                            </Col>

                                                                            <Col
                                                                                {...columnsLayout}
                                                                                className="gutter-row"
                                                                            >
                                                                                <StatsTiles
                                                                                    value={currentStats.CorrectPercentage}
                                                                                    icon={<CheckCircleOutlined />}
                                                                                    title="Avg. Correctness"
                                                                                    compareNumber={pastStats.CorrectPercentage}
                                                                                    type="percent"
                                                                                    graphData={correctness_data}
                                                                                    tooltip="This compares Total Questions with the Total Correct Responses. Note, absent session would still count as 0 correct responsenses"
                                                                                />

                                                                            </Col>
                                                                            <Col
                                                                                {...columnsLayout}
                                                                                className="gutter-row"
                                                                            >
                                                                                <StatsTiles
                                                                                    value={currentStats.AttendancePercentage}
                                                                                    icon={<UserOutlined />}
                                                                                    title="Avg. Attendance"
                                                                                    compareNumber={pastStats.AttendancePercentage}
                                                                                    type="percent"
                                                                                    graphData={attendance_data}
                                                                                    tooltip="This is calculated by comparing total sessions attended by the total sessions conducted throughout the selected period"
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                {...columnsLayout}

                                                                                className="gutter-row"
                                                                            >
                                                                                <StatsTiles
                                                                                    value={currentStats.TotalSessions}
                                                                                    icon={<FundProjectionScreenOutlined />}
                                                                                    title="Total Sessions"
                                                                                    compareNumber={pastStats.TotalSessions}
                                                                                    type="number"
                                                                                    graphData={session_data}
                                                                                    color={DefaultTileCardTextColor}
                                                                                    tooltip="This is the total sessions conducted during the selected period"

                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                </Tiles>

                                                                <Graph>
                                                                    {/* <Line className="mt-12" {...graphConfig} /> */}

                                                                    <div style={{ width: '100%', height: 300, marginTop: 50 }}>

                                                                        <ResponsiveContainer width="100%" height="100%">
                                                                            <LineChart
                                                                                width={500}
                                                                                height={300}
                                                                                data={studentDataSource}
                                                                                margin={{
                                                                                    top: 5,
                                                                                    right: 30,
                                                                                    left: 20,
                                                                                    bottom: 5,
                                                                                }}
                                                                            >
                                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                                <XAxis dataKey="display_time" />
                                                                                <YAxis />
                                                                                <ChartToolTip
                                                                                    label={"title"}

                                                                                    content={({ active, payload, label }: any) => {
                                                                                        if (active && payload && payload.length) {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{ background: '#f0f0f082', paddingLeft: 10, paddingRight: 10 }}
                                                                                                    className="custom-tooltip">
                                                                                                    <p className="label bold-heading">{`${payload[0].payload.title}`}</p>
                                                                                                    <p className="intro text-green-600">{`Correct : ${payload[0].payload.correctness}%`}</p>
                                                                                                    <p className="intro text-blue-600">{`Engagement : ${payload[0].payload.engagement}%`}</p>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    }}
                                                                                // formatter={(value: any, name: any, props: any) => value + "%"}
                                                                                />

                                                                                <Legend verticalAlign="top"
                                                                                    formatter={(value, entry, index) => (<span style={{ color: entry?.color }}>{"Correct"}</span>)
                                                                                    }
                                                                                    values="Correct" />
                                                                                <Line type="monotone" dataKey="correctness" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                                                            </LineChart>

                                                                        </ResponsiveContainer>
                                                                        <span className="bold-heading block" style={{ textAlign: "center" }}>Correctness and Engagement Breakdown</span>

                                                                    </div>

                                                                </Graph>

                                                                <StudentList>
                                                                    <div className='text-xl mt-12 mb-4'> All Sessions</div>
                                                                    <Table
                                                                        className="virtual-table"
                                                                        //   scroll={{ y: 300, x: 100 }}
                                                                        size="small"
                                                                        pagination={{ showSizeChanger: true, pageSize: 100, hideOnSinglePage: true }}
                                                                        dataSource={studentDataSource}
                                                                        columns={allStudentsColumns}
                                                                        onChange={handleChange}
                                                                        rowClassName={(record, index) => {
                                                                            const className = "text-gray-500";
                                                                            // @ts-ignore
                                                                            if (record.engagement === 0) return className
                                                                            return "";
                                                                        }}
                                                                    />
                                                                </StudentList>
                                                            </>
                                                        )}

                                                    </Composition>
                                                </div>
                                            }
                                        </Content>
                                    </Layout>
                                </Content>
                            </Layout>
                        </>
                    }
                </>
                }
            </Layout>
        </>
    );
};


export default StudentDetailStats;
