import { compose, createStore } from "redux";
import reducers from "./Reducers";
import "@redux-devtools/extension";

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(reducers, composeEnhancers());
const { dispatch, subscribe, getState } = store;

(window as any).getCurrentState = getState;

const state = getState();
export type applicationState = typeof state;
export { dispatch, subscribe };
export default store;
