import { useEffect, useState } from "react";
import EditQuestionLibrary from "./EditQuestionLibrary";
import QuestionLibrary from "./QuestionLibrary";
import { useHistory, useParams } from "react-router-dom";
import { Layout, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { fetchQuestionLibrary } from "@/Services/QuestionLibrary";


const QuestionLibraryHome = () => {
    const [selectedLibrary, setSelectedLibrary] = useState<QuestionLibrary>();
    const history = useHistory();
    const libraryId = useParams<{ libraryId: string }>().libraryId;

    const onQuestionLibrarySelect = (library: QuestionLibrary) => {
        console.log('library selected');
        setSelectedLibrary(library);
        history.push(`/library/edit/${library.id}`)
    }

    useEffect(() => {
        if ((libraryId && !selectedLibrary) || (libraryId && libraryId != selectedLibrary?.id)) {
            fetchQuestionLibrary(libraryId)
                .then((library: QuestionLibrary) => {
                    setSelectedLibrary(library)
                }).catch(e => {
                    message.error({ content: "Something went wrong" });
                })
        } else if (!libraryId) {
            setSelectedLibrary(undefined);
        }
    }, [libraryId])

    useEffect(() => {
        console.log(selectedLibrary);
    }, [selectedLibrary])

    return (
        <Layout
            style={{ minWidth: "450px" }}
        >
            <Layout
                className="m-auto sm:w-full w-full"
                style={
                    {
                        // padding: '0 24px 24px',
                        maxWidth: '1500px',
                        minHeight: '100vh',
                        height: 'fit-content'
                    }}>
                <Content
                    className="site-layout-background lg:ml-12 lg:mr-12 lg:mt-6 m-0 md:m-4 "
                    style={{
                        padding: 0,
                        // margin: 12,
                    }}
                >
                    <div>
                        {selectedLibrary
                            ?
                            <EditQuestionLibrary
                                viewOnly={false}
                                onBackPress={() => setSelectedLibrary(undefined)}
                                libraryParam={selectedLibrary}
                                changeLibrary={(library: QuestionLibrary) => onQuestionLibrarySelect(library)}
                            // onQuestionSelect={onQuestionSelect}
                            />
                            :

                            <QuestionLibrary
                                viewOnly={false}
                                onLibrarySelect={(library: QuestionLibrary) => onQuestionLibrarySelect(library)}
                            />
                        }
                    </div>
                </Content>
            </Layout>
        </Layout>
    )

}

export default QuestionLibraryHome;