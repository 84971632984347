import { ReactElement } from "react";

export type OptionState = 'NORMAL' | 'SELECTED' | 'CORRECT' | 'INCORRECT' | 'NOT-SELECTED' | 'DONTKNOW' | 'DONTKNOWRESULT'
type OptionsLayout = '2x2' | '1x4' | 'extra'

type Props = {
    optionLabel?: string | ReactElement;
    index: number,
    body?: string;
    className?: string,
    labelColor: string,
    state?: OptionState,
    layout: OptionsLayout,
    enabled: boolean
    onSelect: (index: number) => void
};

const StateClassMap = {
    'NORMAL': 'option-box-normal',
    'SELECTED': 'option-box-selected',
    'CORRECT': 'option-box-correct',
    'INCORRECT': 'option-box-incorrect',
    'NOT-SELECTED': '.option-box-unselected',
    'DONTKNOW': 'option-box-dontknow',
    'DONTKNOWRESULT': 'option-box-dontknow'
}

const OptionsCard = ({ optionLabel, body, className, labelColor, state, onSelect, index, layout, enabled }: Props) => {
    // if (!body) return <div className={className} />
    // let evaluatedClass = "";
    let evaluatedClass = state ? StateClassMap[state] : "";
    // evaluatedClass = state == "SELECTED" ? evaluatedClass + " option-box-selected" : evaluatedClass;
    evaluatedClass = !enabled ? evaluatedClass + " disable-hover" : evaluatedClass + " option-box";
    evaluatedClass = layout == "1x4" ? evaluatedClass + " option-box-rectangle" : layout == "extra" ? evaluatedClass + " option-box-semi-rectangle" : evaluatedClass + " option-box-square";
    evaluatedClass = className ? evaluatedClass + " " + className : evaluatedClass;
    evaluatedClass = !optionLabel ? evaluatedClass + " options-box-nolabel" : evaluatedClass
    return (layout === "2x2" ?
        <div
            onClick={() => onSelect(index)}
            className={evaluatedClass}
        // style={{ width: 80 }}
        >
            {optionLabel ?
                <span className={"font-bold float-left text-gray-700 rounded-full flex items-center justify-center "}
                    style={{
                        height: "50px",
                        width: "50px",
                        fontSize: "24px",
                        paddingTop: 4,
                        color: "white",
                        fontWeight: "bold",
                        marginRight: 10,
                        background: labelColor
                    }}>
                    {optionLabel}
                </span> : null}


            <span className={"options-body"}>{body}</span>
        </div> :
        <div
            onClick={() => onSelect(index)}
            className={evaluatedClass}>
            {optionLabel ?
                <span className={"font-bold float-left text-gray-700 rounded-full flex items-center justify-center "}
                    style={{
                        height: "50px",
                        width: "50px",
                        fontSize: "24px",
                        paddingTop: 4,
                        color: "white",
                        fontWeight: "bold",
                        marginRight: 10,
                        background: labelColor
                    }}>
                    {optionLabel}
                </span> : null}


            <span className={optionLabel ? "options-body" : "options-body-nolabel"}>{body}</span>
        </div>




    )
}
export default OptionsCard;
