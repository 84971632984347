
import { addQuestionLibrary, createQuestionAndAddToLibrary, fetchAllLibraries, fetchLibraryQuestions, fetchQuestionLibrary, updateQuestion, updateQuestionLibrary } from "@/Services/QuestionLibrary";
import { Input, Modal, Space, Comment, Row, Col, Button, Divider, Avatar, message } from "antd";
import UserLabel from "../Common/Image/UserLabel";
import { currentUser, getUserById } from "@/Models/Selectors";
import UserAvatar from "../Common/Image/UserAvatar";
import store from "@/Models/store";
import { CloseCircleTwoTone, SafetyOutlined, SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BusinessLogo from "../AppHeader/BusinessLogo";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import { isValidEmail } from "../Common/Utils";
import { isEqual } from "lodash";

const key = 'messageKey'
const LibraryShareModal = ({ questionLibrary, visible, onShareComplete }: {
    questionLibrary: QuestionLibrary;
    visible: boolean;
    onShareComplete: (library: QuestionLibrary) => void;
}) => {
    const onShareModalClose = () => {
    }
    const _user: User = useSelector(currentUser);
    const [thisQuestionLibrary, setQuestionLibrary] = useState(questionLibrary);
    const [newUserEmail, setNewUserEmail] = useState("");
    const sharingDraft = questionLibrary?.shared_with_user.includes(thisQuestionLibrary.created_by) ? questionLibrary?.shared_with_user : [...questionLibrary?.shared_with_user, thisQuestionLibrary.created_by]
    const [sharedUsers, setSharedUsers] = useState<string[]>(sharingDraft || []);
    const [onSaving, setOnSaving] = useState<boolean>(false);
    const { confirm } = Modal;

    function sharedListAreEqual(sharedUsers1: string[], sharedUsers2: string[], author: string): boolean {
        const filteredSharedUsers1 = sharedUsers1.filter((user) => user !== author);
        const filteredSharedUsers2 = sharedUsers2.filter((user) => user !== author);
        const equal = isEqual(filteredSharedUsers1, filteredSharedUsers2);
        return equal;
    }




    useEffect(() => {
        if (questionLibrary && questionLibrary.shared_with_user != sharedUsers) {
            const sharingDraft = questionLibrary?.shared_with_user.includes(questionLibrary.created_by) ? questionLibrary?.shared_with_user : [...questionLibrary?.shared_with_user, questionLibrary.created_by]
            setSharedUsers(sharingDraft);
        }
    }, [questionLibrary])

    const removeSharedUser = (email: string) => {
        if (!sharedUsers.includes(email)) {
            message.warn("User already removed. Please refresh!", 2);
            return;
        }
        if (thisQuestionLibrary?.created_by == email) {
            message.warn("Cannot remove the author!", 2);
            return;
        }

        if (email == _user.email) {
            confirm({
                type: "warning",
                title: "Are you sure you want to remove yourself? You would not be able to access this library",
                okText: "Remove me",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    const newSharing: string[] = sharedUsers.filter(usr => usr != email);
                    setSharedUsers(newSharing);
                },
                onCancel() {
                },
            });
            return;
        }
        if (thisQuestionLibrary?.shared_with_user) {
            const existingUsers = thisQuestionLibrary?.shared_with_user;
            const newSharing: string[] = sharedUsers.filter(usr => usr != email);
            // const updatedLibrary = { ...thisQuestionLibrary, newSharing }
            // updateLibrary(updatedLibrary)
            setSharedUsers(newSharing);
        }
    }

    const updateLibrary = (library: QuestionLibrary) => {
        message.loading({ content: "loading", duration: 2, key })
        return updateQuestionLibrary(library).then(() => {
            message.success({ content: "Saved!", duration: 2, key });
            setQuestionLibrary(library);
        })
    }

    const addShareUser = (email: string) => {
        if (sharedUsers.includes(email)) {
            message.warn({ content: "User already has the access", duration: 2, key });
            return;
        }
        if (!isValidEmail(email)) {
            message.error({ content: "Invalid Email", duration: 2, key });
            return;
        }
        if (thisQuestionLibrary) {
            const existingUsers = sharedUsers;
            const newSharing: string[] = [...existingUsers, email];
            // const updatedLibrary = { ...thisQuestionLibrary, newSharing }
            // updateLibrary(updatedLibrary)
            setSharedUsers(newSharing);
            setNewUserEmail("");
        }

        // if (thisQuestionLibrary?.shared_with_user) {
        //     const newSharing = [...thisQuestionLibrary?.shared_with_user, email];
        //     const updatedLibrary = { ...thisQuestionLibrary, newSharing }
        //     message.loading("loading", 2)
        //     updateLibrary(updatedLibrary).then(() => setNewUserEmail(""))
        // }
    }

    const grantAccessToInstitute = (id: string) => {
        const updatedLibrary: QuestionLibrary = { ...thisQuestionLibrary, shared_with_institute: [id] }
        setQuestionLibrary(updatedLibrary)
        // updateLibrary(updatedLibrary);
    }

    const removeInstituteAccess = () => {
        setQuestionLibrary({ ...thisQuestionLibrary, shared_with_institute: [] });
    }

    const onSave = () => {
        setOnSaving(true);
        const updatedLibrary: QuestionLibrary = { ...thisQuestionLibrary, shared_with_user: sharedUsers }
        updateLibrary(updatedLibrary).then(() => {
            setNewUserEmail("")
            setOnSaving(false);
            onShareComplete(updatedLibrary);
        })
    }

    return (
        <Modal
            destroyOnClose={true}
            visible={visible}
            onOk={() => onSave()}
            onCancel={() => onShareComplete(questionLibrary)}
            width={"560px"}
            closable={true}
            okText="Save"
            okButtonProps={{ icon: <SaveOutlined />, loading: onSaving, disabled: sharedListAreEqual(sharedUsers, thisQuestionLibrary.shared_with_user, thisQuestionLibrary.created_by) && sharedListAreEqual(questionLibrary.shared_with_institute, thisQuestionLibrary.shared_with_institute, thisQuestionLibrary.created_by) }}
            // footer={null}
            style={{
                padding: "10px",
                // top: 10
            }}
            title={`Share '${thisQuestionLibrary?.name}'`}
        > <Space style={{ width: "100%" }} direction="vertical" >
                <Input
                    addonAfter={<Button type={isValidEmail(newUserEmail) ? "link" : "text"} onClick={() => addShareUser(newUserEmail)}>Add</Button>}

                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.currentTarget.value)}
                    onPressEnter={(e) => addShareUser(e.currentTarget.value)}
                    width={"100%"} placeholder="Add people by email" size="large">

                </Input>
                <div>
                    <div className="font-bold mt-4">Share with access</div>
                    {sharedUsers
                        .sort((a, b) => {
                            if (a === thisQuestionLibrary?.created_by) {
                                return -1; // Place userEmail at the beginning
                            } else if (b === thisQuestionLibrary?.created_by) {
                                return 1; // Place userEmail at the beginning
                            } else {
                                return a.localeCompare(b); // Sort other emails alphabetically
                            }
                        })
                        .map((email: string) => {
                            const thisUser = getUserById(store.getState())(email);
                            return (<div className="row">
                                <Row justify="space-between" align="middle">
                                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                        <Comment
                                            className="profile-tooltip-comment"
                                            author={<span style={{ fontSize: "14px" }}>{thisUser?.full_name || email}</span>}
                                            avatar={
                                                <UserAvatar email={email} size="default" />
                                            }
                                            content={<span className="text-gray-500" style={{ fontSize: "12px" }}>{thisUser?.email ? email : ""}</span>}
                                        />
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <span className="float-right">{thisQuestionLibrary.created_by == email ?
                                            <span className="text-gray-700 text-xs mr-8">Owner </span>
                                            :
                                            <span className="text-gray-700 text-xs">Editor <Button onClick={() => removeSharedUser(email)} icon={<CloseCircleTwoTone twoToneColor="#EB5757" />} type="text" /></span>} </span>
                                    </Col>
                                </Row>

                            </div>
                            )
                        }
                        )}
                </div>
                <Divider />
                <div className="font-bold ">General Access</div>

                <Row justify="space-between" align="middle">
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                        <Comment
                            className="profile-tooltip-comment"
                            author={<span style={{ fontSize: "14px" }}>Private</span>}
                            avatar={
                                <Avatar icon={<SafetyOutlined />} />
                            }
                            content={<span className="text-gray-500" style={{ fontSize: "12px" }}>{"Only granted users can access"}</span>}
                        />
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <span className="float-right">

                            {/* {thisQuestionLibrary?.shared_with_institute.includes(_user?.institutes[0].id) ?
                                    <span className="text-gray-700 text-xs">
                                        Editor <Button
                                            onClick={() => removeInstituteAccess()}
                                            disabled={thisQuestionLibrary?.created_by != _user.email}
                                            icon={<CloseCircleTwoTone twoToneColor="#EB5757" />} type="text" />
                                    </span> :
                                    <Button size="small"
                                        onClick={() => grantAccessToInstitute(_user?.institutes ? _user?.institutes[0].id : "")}
                                        type="default"><span className="text-xs">grant access </span></Button>} */}
                        </span>
                    </Col>
                </Row>
                {_user.institutes && _user.institutes?.length > 0 ? <>

                    <Row justify="space-between" align="middle">
                        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                            <Comment
                                className="profile-tooltip-comment"
                                author={<span style={{ fontSize: "14px" }}>{_user?.institutes[0].name}</span>}
                                avatar={
                                    <Avatar src={_user?.institutes[0]?.img_url} />
                                }
                                content={<span className="text-gray-500" style={{ fontSize: "12px" }}>{"Anyone in this organization can use and edit"}</span>}
                            />
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <span className="float-right">

                                {thisQuestionLibrary?.shared_with_institute.includes(_user?.institutes[0].id) ?
                                    <span className="text-gray-700 text-xs">
                                        Editor <Button
                                            onClick={() => removeInstituteAccess()}
                                            // disabled={thisQuestionLibrary?.created_by != _user.email}
                                            icon={<CloseCircleTwoTone twoToneColor="#EB5757" />} type="text" />
                                    </span> :
                                    <Button size="small"
                                        onClick={() => grantAccessToInstitute(_user?.institutes ? _user?.institutes[0].id : "")}
                                        type="default"><span className="text-xs">grant access </span></Button>}
                            </span>
                        </Col>
                    </Row>
                </> : null}



            </Space>

        </Modal>
    )
}

export default LibraryShareModal;