import axios, { CancelToken } from "axios";
import { googleStorageApisURL, vidyaDownloadBaseUrl } from "./Constants";

export const shouldAllowFileType = function (file: File) {

  return file.type.indexOf('image/') > -1 || file.type.indexOf('audio/') > -1;
}

export const shouldAllowFileSize = function (file: File) {
  return file.size < 15 * 1000 * 1000;
}

export const getUploadLink = function () {
  return axios.post("get_upload_link", {}).then((response) => {
    const { data } = response;
    const {
      Data: { url },
    } = data;

    return url;
  });
};

const getContentTypeHeader = (file: File) => {
  // console.log(file);
  return file.type;
}

export const uploadFile = async function (
  file: File,
  onProgress: (e: ProgressEvent) => void,
  cancelToken: CancelToken
) {
  const uploadUrl = await getUploadLink();
  console.log("upload url", uploadUrl);
  const u = new URL(uploadUrl);
  const id = u.pathname.split("/")[2];

  await axios({
    url: uploadUrl,
    method: "PUT",
    headers: {
      "content-type": "application/octet-stream",
      // "content-type": getContentTypeHeader(file),
    },
    data: file,
    onUploadProgress: onProgress,
    cancelToken,
  });

  return id;
};

export const getDownloadLink = function (imageId: string) {
  return axios
    .post("get_download_link", {
      payload: {
        images: [imageId],
      },
    })
    .then((response) => {
      const { data } = response;
      const {
        Data: { images },
      } = data;

      const [imgurl] = images;
      // replace storage url with Vidya download URL to bypass any IP whitelisting.
      // download.vidya.us has reverse proxy for downloading any image.
      const vidyaImageUrl = imgurl.replace(googleStorageApisURL, vidyaDownloadBaseUrl);
      return vidyaImageUrl;
    });
};

