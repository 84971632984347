import { getUserById } from "@/Models/Selectors"
import { getFirebaseUser } from "@/Services/User";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux"

const AutoFilUserName = ({ email }: { email: string }) => {
    const user = useSelector(getUserById)(email);
    const [displayUser, setDisplayUser] = useState(user.full_name || email);

    useEffect(() => {
        if (!user) {
            getFirebaseUser(email)
                .then((usr) => {
                    setDisplayUser(usr.full_name)
                })
        }
    }, [user])

    return (<>
        <span>{displayUser}</span>
    </>)
}

export default AutoFilUserName;