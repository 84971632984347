import logoDark from "@/Assets/logo-dark.png";
import logoLight from "@/Assets/logo-light.png";
import { goHome } from "@/Services/Router";
import { Link } from "react-router-dom";

export const ConditionalLink = ({
  children,
  to,
  condition,
}: {
  children: any;
  to: string;
  condition: boolean;
}) => (!!condition && to ? <Link to={to}>{children}</Link> : <>{children}</>);

const AppLogo = ({
  mode = "light",
  redirectHome,
}: {
  mode: "dark" | "light";
  redirectHome: boolean;
}) => (
  <div
    onClick={() => (redirectHome ? goHome() : null)}
    className={redirectHome ? "cursor-pointer" : ""}
  >
    <img
      style={{ marginTop: redirectHome ? 14 : 0 }}
      src={mode === "dark" ? logoDark : logoLight}
      className="float-left h-8 inline-block lg:ml-4 md:ml-4 ml-4 sm:ml-4"
      alt="Vidya logo"
    />
  </div>
);

export default AppLogo;
