import axios from "axios";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import {
  currentSession as currentSessionSelector,
  allSessions as allSessionsSelector,
  getClassById,
  getSessionById,
  getUserById,
  currentUser,
  allSessionStats,
} from "@/Models/Selectors";
import { goHome as routeHome } from "@/Services/Router";
import { sendSocketMessage } from "@/Services/Socket";
import { getQuestionForClient, getUserResponses } from "../Question";
import { showNotification } from "../Notifications";
import { batch, useSelector } from "react-redux";
import { initiateZoomCollaboration } from "../ZoomServices";
import { isRunningAsZoomApp } from "../Constants";
import Analytics from "@/Services/Analytics";
import { Session } from "inspector";
import {
  addDocument,
  createOrUpdateData,
  createOrUpdateDataMultiple,
  getDocumentMultipleCondition,
  updateDataMultiple,
  updateDocument,
  whereCondition,
} from "../Firebase";
import { convertUndefinedToEmptyString } from "@/Components/Common/Utils";
import { getSessionObj } from "../Class";

export const createSession = (session: SessionState) => {
  // make an api to create session for a class i think.
  // const session = getNewAdminSession({});

  store.dispatch({
    type: Actions.SESSIONS.ADD,
    payload: session,
  });
  Analytics.track("Create Session", session);

  const { sessionId } = session;
  setCurrentSession(sessionId);
};

export const joinSessionFirebase = async (session: SessionState) => {
  const payload = {
    ...session,
  };

  const conditions: whereCondition[] = [
    {
      field: "id",
      operator: "==",
      value: session.sessionId,
    },
  ];

  updateDataMultiple("sessions", conditions, payload).then(async (res) => {
    if (res) {
      await updateDocument("classes", session.classId, {
        sessionId: res.id,
      });
      routeHome();
      store.dispatch({
        type: Actions.SESSIONS.CURRENT_SESSION.SET,
        payload: session.sessionId,
      });
    }
  });
};

export const joinSessionOnServer = async (sessionId: string) => {
  const response = await axios.post("join_session", { session_id: sessionId });
  return response;
};

export const setCurrentSessionObject = async (session: SessionState) => {
  joinSessionFirebase(session);
};

export const setCurrentClassObject = async (
  _class: Class,
  session: SessionState | undefined | null
) => {
  if (session?.sessionId) {
    store.dispatch({
      type: Actions.SESSIONS.ADD,
      payload: session,
    });
    store.dispatch({
      type: Actions.CLASSES.UPDATE,
      payload: {
        Class: { ..._class, hasPreparedSession: true },
      },
    });
  }
};

export const setCurrentSession = async (sessionId: string) => {
  await joinSessionOnServer(sessionId);
  // goHome();
  routeHome();
  store.dispatch({
    type: Actions.SESSIONS.CURRENT_SESSION.SET,
    payload: sessionId,
  });
};

export const goHome /** You're drunk */ = () => {
  // setCurrentSession("non-existent");
  store.dispatch({
    type: Actions.SESSIONS.CURRENT_SESSION.SET,
    payload: "non-existent",
  });
  routeHome();
};

export const endCurrentSession = () => {
  const currentSession = currentSessionSelector(store.getState());
  if (!currentSession) return;
  endSession(currentSession);
};

export const endSession = (session: SessionState) => {
  const classId = session.classId;
  if (!classId) return;
  Analytics.track("End Session", session);

  updateDataMultiple(
    "sessions",
    [
      {
        field: "id",
        operator: "==",
        value: session.sessionId,
      },
    ],
    {
      status: "ENDED",
      state: "ENDED",
    }
  ).then((res) => {
    if (res && res.id) {
      receiveEndSession(session.sessionId);
      return;
    } else {
      alert(`Couldn't end the class. Try again.`);
      return;
    }
  });

  /* sendSocketMessage(
    {
      type: "STOP_SESSION",
      payload: { session: session },
    },
    (payload) => {
      const { code } = payload;

      if (code !== 0) {
        alert(`Couldn't end the class. Try again.`);
        return;
      }
      receiveEndSession(session.sessionId);
    }
  ); */
};

const receiveEndSession = (session_id: string) => {
  const session = getSessionById(store.getState())(session_id);
  if (!session) {
    return;
  }
  const classId = session.classId;
  if (!classId) return;

  const _class = getClassById(store.getState())(classId);

  store.dispatch({
    type: Actions.SESSIONS.CURRENT_SESSION.END_SESSION,
    payload: null,
  });
  store.dispatch({
    type: Actions.CLASSES.UPDATE,
    payload: {
      Class: {
        ..._class,
        sessionId: "",
        state: "NO_SESSION",
      },
    },
  });
  // goHome();
};

export const handleEndSessionSocketEvent = (message: any) => {
  const { kind, session_id } = message;

  if (kind !== "STOP_SESSION") {
    return;
  }

  receiveEndSession(session_id);
};

export const updateCurrentSessionName = (name: string) => {
  store.dispatch({
    type: Actions.SESSIONS.CURRENT_SESSION.UPDATE_NAME,
    payload: name,
  });
};

export const startCurrentSession = () => {
  Analytics.track("Start Session");

  store.dispatch({
    type: Actions.SESSIONS.CURRENT_SESSION.START_SESSION,
    payload: null,
  });
  if (isRunningAsZoomApp) {
    // would only start if this is a zoom app and app is in the meeting context.
    initiateZoomCollaboration();
  }
};

export const getNextQuestionLabel = (session: SessionState) => {
  const { questions } = session;
  return `Question ${questions.length + 1}`;
};

/**
 * Functions which will be executed on current session.
 */

export const askQuestion = async (question: question) => {
  const currentSession = currentSessionSelector(store.getState());
  Analytics.track("Ask Question", question);
  question = convertUndefinedToEmptyString(question);

  await updateDataMultiple(
    "session_questions",
    [
      {
        field: "class_id",
        operator: "==",
        value: currentSession ? currentSession?.classId : "",
      },
    ],
    {
      current_question: 0,
    }
  ).then(async (data) => {
    console.log("COMING UNDER 1 1", data);
    await addDocument("session_questions", {
      session_id: currentSession?.sessionId,
      class_id: currentSession?.classId,
      question_id: question.draft_id ?? "",
      question: question,
      current_question: 1,
    });

    store.dispatch({
      type: Actions.SESSIONS.ADD_QUESTION,
      payload: {
        session_id: currentSession?.sessionId,
        question,
      },
    });
  });
  //  sendSocketMessage(
  //   {
  //     type: "ASK_QUESTION",
  //     payload: {
  //       session_id: currentSession?.sessionId,
  //       class_id: currentSession?.classId,
  //       question,
  //     },

       
  //   }

  //   // (payload) => {
  //   //   receiveQuestion(payload.data, currentSession?.sessionId)
  //   // }
  //     ); 
};

export const receiveQuestion = (data: any, session_id: string) => {
  const session = getSessionById(store.getState())(session_id);
  if (!session) {
    return;
  }
  const index = session.questions.length;

  const question = getQuestionForClient(data, index);
  const thisClass = getClassById(store.getState())(session.classId);
  // disabling notification on questions for now.
  // showNotification(session.name, "New question posted on Vidya");

  store.dispatch({
    type: Actions.SESSIONS.ADD_QUESTION,
    payload: {
      session_id,
      question,
    },
  });
};

export const handleAskQuestionSocketEvent = (message: any) => {
  const { kind, payload, session_id } = message;

  if (kind !== "ASK_QUESTION") {
    return;
  }

  receiveQuestion(payload.data, session_id);
 
};

export const receiveUpdateQuestion = function (data: any, session_id: string) {
  const session = getSessionById(store.getState())(session_id);
  if (!session) {
    return;
  }

  const qId = data.id;
  const question = session.questions.find((q) => q.id === qId);

  if (!question) {
    return;
  }

  const index = session.questions.indexOf(question);

  const updatedQuestion = getQuestionForClient(data, index);
  const { liveStats, state, correctValueIndex, correctValue } = updatedQuestion;
  const changes = {
    liveStats,
    state, 
    correctValueIndex,
    correctValue,
  };

  getUserResponses(updatedQuestion as question);

  store.dispatch({
    type: Actions.SESSIONS.UPDATE_QUESTION,
    payload: {
      changes,
      session_id,
      question_id: question.id,
    },
  });
}; 

export const handleUpdateQuestionSocketEvent = (message: any) => {
  const { kind, payload, session_id } = message;

  if (kind !== "UPDATE_QUESTION") {
    return;
  }

  receiveUpdateQuestion(payload.data, session_id);
};

export const receivePostAnswer = (data: any, session_id: string) => {
  const session = getSessionById(store.getState())(session_id);
  if (!session) {
    return;
  }

  const qId = data.id;
  const question = session.questions.find((q) => q.id === qId);

  if (!question) {
    return;
  }

  const evaluation = data.eval;

  store.dispatch({
    type: Actions.SESSIONS.UPDATE_QUESTION,
    payload: {
      changes: { evaluation },
      session_id,
      question_id: question.id,
    },
  });
};

export const handlePostAnswerSocketEvent = (message: any) => {
  const { kind, payload, session_id } = message;

  if (kind !== "POST_ANSWER") {
    return;
  }

  receivePostAnswer(payload.data, session_id);
};

// stop receiving responses for a question
export const stopQuestion = (question: question) => {
  const currentSession = currentSessionSelector(store.getState());
  Analytics.track("Stop Question", question);

  const index = currentSession?.questions.indexOf(question);

  if (index === undefined || index < 0) {
    console.error(`Question doesn't exist to stop receiving responses`);
    return;
  }
  const newQuestion = { ...question, state: "RECEIVED_RESPONSE" };
  /* sendSocketMessage({
    type: "UPDATE_QUESTION",
    payload: {
      session_id: currentSession?.sessionId,
      class_id: currentSession?.classId,
      question: newQuestion,
    },
  }); */
};

// start receiving responses for a question
export const startQuestion = (question: question) => {
  const currentSession = currentSessionSelector(store.getState());

  const index = currentSession?.questions.indexOf(question);

  if (index === undefined || index < 0) {
    console.error(`Question doesn't exist to start receving responses`);
    return;
  }
  const newQuestion = {
    ...question,
    asked_at: new Date(),
    state: "RECEIVING_RESPONSE",
  };
  sendSocketMessage({
    type: "UPDATE_QUESTION",
    payload: {
      session_id: currentSession?.sessionId,
      class_id: currentSession?.classId,
      question: newQuestion,
    },
  });
};

export const getSession = (SessionId: string) => {
  const allSessions = allSessionsSelector(store.getState());
  return allSessions[SessionId];
};

export const isSessionStarted = (session: SessionState) => {
  return session && session.state === "STARTED";
};

export const fetchLatestSessionStats = (session: SessionState) => {
  const totalQuestions = session.questions.length;
  sendSocketMessage(
    {
      type: "GET_SESSION_STATS",
      payload: {
        session: session,
      },
    },
    (response) => {
      console.log(response);

      const { http_code, data } = response;

      if (http_code !== 200) {
        return;
      }
      const sessionClass = getClassById(store.getState())(session.classId);
      const sessionWithStats: SessionState = processSessionStats(
        sessionClass,
        session,
        data
      );

      batch(() => {
        store.dispatch({
          type: Actions.SESSIONS.UPDATE,
          payload: {
            sessionId: session.sessionId,
            changes: {
              stats: sessionWithStats.stats,
              live_stats: sessionWithStats.live_stats,
              summary_stats: sessionWithStats.summary_stats,
            },
          },
        });
        sessionWithStats.live_stats.forEach((live_stat) => {
          store.dispatch({
            type: Actions.STATS.ADD,
            payload: {
              stats: live_stat,
            },
          });
        });
        store.dispatch({
          type: Actions.STATS.SUMMARY.ADD,
          payload: sessionWithStats.summary_stats,
        });
      });
    }
  );
};

export const getEmptySessionObj = (classId: string): SessionState => ({
  sessionId: "",
  classId: classId,
  type: "ADMIN",
  state: "CREATED", // TBC to "draft"
  currentQuestion: {},
  name: "",
  description: "",
  joinees: [],
  questions: [],
  draft_questions: [],
  stats: {} as session_stats,
  created_at: {} as Date,
  updated_at: {} as Date,
  start_time: {} as Date,
  live_stats: [],
});

export const processSessionStats = (
  _class: Class,
  session: SessionState,
  sessionData: any
): SessionState => {
  const totalQuestions = session.questions.length;
  const sessionStats: session_stats = {
    attendance: sessionData.attendees || [],
    dontKnow: [],
    noResponse: [],
    correct: [],
    incorrect: [],
  };

  const liveStats: studentStats[] = [];

  const respondants = Object.keys(sessionData.stats || {});

  respondants.forEach((key) => {
    const stat = sessionData.stats[key] || {};

    if ((stat.dont_know || 0) > 0) {
      sessionStats.dontKnow.push({
        name: key,
        count: stat.dont_know,
      });
    }

    if ((stat.correct || 0) > 0) {
      sessionStats.correct.push({
        name: key,
        count: stat.correct,
      });
    }

    if ((stat.wrong || 0) > 0) {
      sessionStats.incorrect.push({
        name: key,
        count: stat.wrong,
      });
    }

    if ((stat.total_answered || 0) < totalQuestions) {
      sessionStats.noResponse.push({
        name: key,
        count: totalQuestions - stat.total_answered,
      });
    }

    const live_stat: studentStats = {
      correct: stat.correct || 0,
      incorrect: stat.wrong || 0,
      dontKnow: stat.dont_know || 0,
      responses: stat.total_answered || 0,
      absent: stat.total_answered === 0,
      email: key,
      user: getUserById(store.getState())(key),
      sessionId: session.sessionId,
      classId: _class.id,
      totalQuestions: session.questions.length,
      startDate: new Date(session.start_time),
    };
    liveStats.push(live_stat);
  });

  const { questions } = session;
  const joinees = _class.students_email;
  const absentees: studentStats[] = liveStats.filter(
    (stat) => stat.absent === true
  );
  joinees.forEach((joinee: string) => {
    const student = liveStats.filter((stat) => stat.email === joinee);
    if (student.length === 0) {
      absentees.push({
        absent: true,
        correct: 0,
        dontKnow: 0,
        email: joinee,
        incorrect: 0,
        responses: 0,
        user: getUserById(store.getState())(joinee),
        sessionId: session.sessionId,
        classId: _class.id,
        totalQuestions: session.questions.length,
        startDate: new Date(session.start_time),
      });
    }
  });
  absentees.forEach((absent) =>
    liveStats.findIndex((stat) => stat.email == absent.email) == -1
      ? liveStats.push(absent)
      : null
  );
  let total_responses: number = 0;
  let total_correct: number = 0;

  liveStats.forEach((stat) => {
    total_responses = total_responses + stat.responses;
    total_correct = total_correct + stat.correct;
  });
  const presentStudentCount = joinees.length - absentees.length;
  const sessionEngagement =
    Math.floor(
      (total_responses / (questions.length * presentStudentCount)) * 100
    ) || 0;
  const sessionCorrectness =
    Math.floor(
      (total_correct / (questions.length * presentStudentCount)) * 100
    ) || 0;

  const statsSummary: sessionStats = {
    engagement_percent: sessionEngagement,
    absent_students: absentees.length,
    present_students: joinees.length - absentees.length,
    correct_percent: sessionCorrectness,
    total_students: joinees.length,
    sessionId: session.sessionId,
    startDate: new Date(session.start_time),
    teacher_email: _class.primary_teacher,
    attendance_percent: Math.floor(
      (presentStudentCount / joinees.length) * 100
    ),
    total_questions: session.questions.length,
    classId: _class.id,
  };
  const updatedSession: SessionState = {
    ...session,
    live_stats: liveStats,
    stats: sessionStats,
    summary_stats: statsSummary,
  };

  return updatedSession;
};

export const getClassSessions = async (classes: Class[]) => {
  const allClasses: any[number] = [];
  const class_ids: any[] = [];

  classes.forEach((thisClass) => {
    class_ids.push(thisClass.id);
    allClasses[thisClass.id] = thisClass;
  });

  return getDocumentMultipleCondition("sessions", [
    {
      field: "class_id",
      operator: "in",
      value: class_ids,
    },
    {
      field: "status",
      operator: "in",
      value: ["DRAFT", "STARTED"],
    },
  ])
    .then((session_data) => {
      let currenSessionId;

      let sessionStates: any = [];

      if (session_data) {
        session_data.forEach((s: any) => {
          let sessionState = getSessionObj(allClasses[s.class_id], s);

          getSessionCurrentQuestion(sessionState.sessionId).then((question) => {
            console.log("Current question", question.live_stats);
            console.log("Current question", question.liveStats);
            sessionState.currentQuestion = question;
          });

          sessionStates[sessionState.classId] = sessionState;
          //setCurrentSessionObject(sessionState);

          /* if (s.id == sessionId && s.status == "STARTED") {
          currenSessionId = s.id;
        } */
        });

        if (Object.values(sessionStates).length) {
          store.dispatch({
            type: Actions.SESSIONS.ADD_BULK,
            payload: Object.values(sessionStates),
          });
        }

        /* if (currenSessionId) {
        store.dispatch({
          type: Actions.SESSIONS.CURRENT_SESSION.SET,
          payload: currenSessionId,
        });
      } */
      }

      return sessionStates;
    })
    .catch((e: any) => {
      console.log(e, "ERROR");
    });
};

const getSessionCurrentQuestion = async (sessionId: string) => {
  return getDocumentMultipleCondition("session_questions", [
    {
      field: "session_id",
      operator: "==",
      value: sessionId,
    },
    {
      field: "current_question",
      operator: "==",
      value: 1,
    },
  ]).then((session_question: any) => {
    if (session_question[0] && session_question[0].question) {
      return session_question[0].question;
    }

    return {};
  });
};
