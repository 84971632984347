import React from "react";
import { Composition } from "atomic-layout";
import wallImage from "@/Assets/join-success.png";


const InviteSuccessModal = () => {

  return (
    <div>

      <Composition>
        {/* <div>Invite students - {name}</div> */}
        <Composition
          templateCols="auto"
          templateRows="auto auto"
          // templateColsMd="45% 55%"
          // templateRowsMd="auto"
          gapCol="10px"
        >
          <div>
            <img
              className="w-full mx-auto"
              src={wallImage}
              alt="students sitting"
            />
          </div>
          <Composition
            templateRows="30px 1fr 1fr 20px 20px 20px"
            justifyItems="center"
          >
            <div className="font-bold">Congratulations!</div>
            <div className='text-center'>
              You have now accepted the invitation and are now part of the Vidya class.
            </div>
          </Composition>
        </Composition>
      </Composition>

    </div>
  );
};

export default InviteSuccessModal;
