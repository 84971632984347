import React, { useEffect } from "react";
import { getTokenFromUrl, logout, onAuthStart, onLoginSuccess } from "@/Services/Auth";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";

const GauthLogin = () => {

  let location = useLocation();

  useEffect(() => {
    if (location.pathname == '/gauth-login') {
      try {
        const token = getTokenFromUrl(location.hash);
        console.log("token is", token);
        onLoginSuccess(token);
      } catch (e) {
        console.log('some error for automatic loading');
        logout();
      }
    }

    if (location.search.includes('source=zoomauth')) {
      // onAuthStart();
    }
  }, []);

  console.log(window.location.href);

  return (
    <>
      <div className="mb-16"></div>
      <Loader />
    </>
  );
};

export default GauthLogin;
