// Array of handlerObjects. 

const registeredHandlers: ((x: any) => void)[] = [];


export const messageHandler = function (message: any) {
    registeredHandlers.forEach(cb => cb(message))
}

export const registerMessageHandler = function (callback: (x: any) => void) {
    registeredHandlers.push(callback);
}


export const unregisterMessageHandler = function (callback: (x: any) => void): boolean {
    const idx = registeredHandlers.indexOf(callback);
    if (idx < 0) {
        console.warn('Message handler was not registered');
        return false;;
    }

    registeredHandlers.splice(idx, 1);
    return true;
}

export const responseHandler = function (msgid: string) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            if (unregisterMessageHandler(cb)) {
                reject(`Request timed out, for ${msgid}`);
            }
        }, 1500);
        const cb = (msg: any) => {
            const { kind } = msg;
            if (kind !== 'RESPONSE') return;

            const { msg_uuid } = msg;
            if (msg_uuid !== msgid) return;

            const { payload } = msg;
            resolve(payload);
            unregisterMessageHandler(cb);
            clearTimeout(timer);
        }
        registerMessageHandler(cb);
    })
}

