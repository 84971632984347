import Actions from '@/Models/Actions';

type appRoute = {
    currentRoute: 'home' | 'new-class' | 'class' | 'new-session' | 'session',
    currentPath: string
}

const thisPath: string = "";
const defaultState: appRoute = {
    currentRoute: 'home',
    currentPath: thisPath
}

const routeReducer = (state = defaultState, action: reduxAction): appRoute => {
    switch (action.type) {
        case Actions.ROUTE.UPDATE: {
            const route = action.payload;
            return {
                ...state,
                currentRoute: route
            }
        }
        case Actions.ROUTE.UPDATE_PATH: {
            const { currentPath } = action.payload;

            return {
                ...state,
                currentPath: currentPath
            }
        }
        default:
            return state;
    }
}

export default routeReducer;