import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from 'axios';
import { Composition } from "atomic-layout";
import { CheckOutlined, DeleteOutlined, FileImageOutlined, FormOutlined, InboxOutlined, UngroupOutlined } from "@ant-design/icons";
import { Input, Upload, Radio, Button, Checkbox, notification, Progress, Modal } from "antd";
import { shouldAllowFileSize, shouldAllowFileType, uploadFile } from "@/Services/Images";
import { UploadFile } from "antd/lib/upload/interface";
import Image from "@/Components/Common/Image";
import { Typography } from 'antd';
import { optionColorIndex } from "../Question/StudentResponseForm";
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder'
import { ReactMic } from 'react-mic';
import AudioRecorder from "./AudioRecorder";


const { TextArea } = Input;
const { Dragger } = Upload;
const { Text } = Typography;
const builderArea = `
    questionLabel
    questionTextArea
    answerArea
    footerArea
`;
export enum questionMode {
  LIVE = "live",
  PREPARE = "prepare"
}
type answer = {
  optionsType: "multiple" | "text";
  values?: string[];
  value?: string;
  correctValueIndex?: number;
  correctValue?: string;
};

const getDefaultAnswer = (): answer => {
  return {
    optionsType: "multiple",
    values: ["A_", "B_", "C_", "D_"],
    correctValueIndex: 0,
  };
};

const defaultQuestion = {
  optionsType: "text",
  description: "",
  images: [] as { url: string }[],
} as question;

// type question = typeof defaultQuestion;

const QuestionBuilder = ({
  editQuestion,
  mode,
  askButtonLabel,
  numberOfStudents = 0,
  onAsk,
  Label,
  selectQuestionButtonTitle,
  onSelectQuestion,
  isRTL
}: {
  editQuestion: question | undefined;
  askButtonLabel: string;
  numberOfStudents: number;
  mode: questionMode;
  onAsk: (question: question, answer: answer) => void;
  Label: string;
  selectQuestionButtonTitle: ReactNode;
  onSelectQuestion?: () => void;
  isRTL: boolean;
}) => {
  const actualQuestion = editQuestion ? editQuestion : defaultQuestion;
  const [question, setQuestion] = useState(actualQuestion);
  const [answer, setAnswer] = useState(getDefaultAnswer());
  const hasFetchedData = useRef(false);
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage
  } = useAudioRecorder()
  const [recodingData, setRecodingData] = useState<Blob>();


  const [questionImageFile, setQuestionImage] = useState<
    UploadFile | undefined
  >();
  const [questionImageUploading, setQuestionImageUploading] = useState<boolean>(
    false
  );

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const composedQuestion = useRef<question>(actualQuestion);

  const uploadCancelToken = useRef<CancelTokenSource>();

  const updateQuestionText = useCallback(
    (v:any) => {
      composedQuestion.current = { ...question, description: v };
      setQuestion({ ...question, description: v });
      if (editQuestion)
        setAnswer({ ...question, description: v } as answer)
    },
    [question, setQuestion]
  );

  useEffect(() => {
    if (editQuestion && JSON.stringify(editQuestion) !== JSON.stringify(question)) {
      console.log("question builder useeffect trigerred")
      // editQuestion json contains both the answer and question
      setQuestion(editQuestion as question);
      setAnswer(editQuestion as answer);
      composedQuestion.current = editQuestion
      hasFetchedData.current = true;
    }
  }, [editQuestion])

  useEffect(() => {
    return () => {
      setQuestion(defaultQuestion);
      setAnswer(getDefaultAnswer());
    }
  }, []);


  useEffect(() => {
    setQuestion(actualQuestion);
    if (editQuestion)
      setAnswer(actualQuestion as answer)
    else
      setAnswer(getDefaultAnswer() as answer);
  }, [actualQuestion])

  const updateQuestionImage =
    (id: string) => {
      const latestQuestion = composedQuestion.current ? composedQuestion.current : question;
      const updatedQuestion = { ...latestQuestion, images: [{ url: id }] }
      setQuestion(updatedQuestion);
      composedQuestion.current = updatedQuestion;
    }

  const removeQuestionImage = useCallback(() => {
    setQuestion({ ...question, images: [] });
  }, [question])

  const onSubmit = useCallback(() => {
    const { optionsType, correctValue } = answer;
    if (optionsType === "text" && !correctValue) {
      return;
    }
    onAsk(question, answer);
    setQuestion(defaultQuestion);
    setAnswer(getDefaultAnswer());
  }, [question, answer, onAsk]);

  const onUploadError = useCallback((errorType?: 'FILE_TYPE_ERROR' | 'FILE_SIZE_ERROR' | 'RETRY_IMAGE') => {
    let message = '';
    switch (errorType) {
      case 'FILE_TYPE_ERROR':
        message = 'We only allow image files for questions';
        break;
      case 'FILE_SIZE_ERROR':
        message = 'Maximum 15MB files are allowed';
        break;
      case 'RETRY_IMAGE':
        message = 'Automatic image upload failed. Please retry the image upload';
        break;
      default:
        message = 'Some error occured during uploading image.'
    }
    notification.error({
      message
    })
  }, [])

  const uploadAttachment = async (options: any) => {
    const { file } = options;

    console.log(options);
    // return;
    const shouldAllowType = shouldAllowFileType(file as File);
    const shouldAllowSize = shouldAllowFileSize(file as File);
    if (!shouldAllowType) {
      setQuestionImageUploading(false);
      setUploadProgress(0);
      onUploadError('FILE_TYPE_ERROR');
      return;
    }
    if (!shouldAllowSize) {
      setQuestionImageUploading(false);
      setUploadProgress(0);
      onUploadError('FILE_SIZE_ERROR');
      return;
    }
    setQuestionImageUploading(true);
    try {
      uploadCancelToken.current = axios.CancelToken.source()
      const fileId = await uploadFile(
        file as File,
        (event: ProgressEvent) => {
          // onProgress?.(
          //   // { percent: (event.loaded / event.total) * 100 },
          //   event as Upload
          // );
          setUploadProgress(Math.round((event.loaded / event.total) * 100));
        },
        uploadCancelToken.current.token
      );

      uploadCancelToken.current = undefined;
      // onSuccess?.({ succes: true });
      setUploadProgress(0);
      setQuestionImageUploading(false);
      updateQuestionImage(fileId);
      setQuestionImage(file as UploadFile);
    } catch (err) {
      console.log("Error: ", err);
      setQuestionImageUploading(false);
      setUploadProgress(0);
    }
  };

  const getImageFromHtml = (html: string) => {
    try {
      const parser = new DOMParser();
      var htmlDoc = parser.parseFromString(html, 'text/html');
      const images = htmlDoc.getElementsByTagName('img')
      var sources = [];
      for (var i in images) {
        if (images[i].src) sources.push(images[i].src)
      }
      return sources.length > 0 ? sources[0] : null;
    }
    catch (e) {
      return null;
    }

  }

  const processHtmlFile = useCallback((url: string, type: 'image' | 'audio') => {
    try {
      const objType = type == 'image' ? 'image/jpeg' : 'audio/ogg'
      fetch(url, {
        method: "GET",
        headers: {}
      }).then(response => {
        response.arrayBuffer().then((buffer) => {
          if (buffer) {
            var blob = new Blob([buffer])
            blob = blob.slice(0, blob.size, objType)
            uploadAttachment({ file: blob });
            setQuestionImageUploading(true);
            setUploadProgress(5);
          }
        })
      }).catch((e) => {
        onUploadError('RETRY_IMAGE');
      })
    }
    catch (e) {
      console.log("image upload failed while processing html image", e);
    }
  }, [questionImageUploading, uploadProgress, question])


  const pastedQuestion = useCallback((e:any) => {
    // composedQuestion.current = undefined;
    const pastedText = e.clipboardData.getData('Text');
    let htmlImageSrc: any;
    try {
      const files = e.clipboardData.items;
      const html = e.clipboardData.getData('text/html');
      var gdoc = e.clipboardData.getData('application/x-vnd.google-docs-image-clip+wrapped')
      let foundImageObj = false;
      htmlImageSrc = html ? getImageFromHtml(html) : null;
      // process images copied from web. 
      // Gdoc image type to be processed as URL and not as file
      if (files !== undefined && !gdoc) {
        // process non-web images i.e. images copied from desktop. 
        for (var i = 0; i < files.length; i++) {
          // Skip content if not image

          if (files[i].type.indexOf("image") === -1) continue;
          // Retrieve image on clipboard as blob
          var blob = files[i].getAsFile();
          foundImageObj = true;
          uploadAttachment({ file: blob });
          setQuestionImageUploading(true);
          setUploadProgress(5);

        }
      }
      if (htmlImageSrc && !foundImageObj) {
        processHtmlFile(htmlImageSrc, "image");
      }
    }
    catch {

    }
    let splitText = pastedText.trim().split("\n").filter((item: string) => item !== "" && item !== "\r")
    let finalQuestionText: any;
    let options: string[]
    if (splitText && splitText.length > 0 && splitText.length >= 5 && answer.optionsType === "multiple") {
      options = splitText.slice(-4);
      splitText.splice(splitText.length - 4, 4);
      finalQuestionText = splitText.join('\n');
      const rawOptions = options;
      if (options && finalQuestionText) {
        options = options.map((optionValue, index) => {
          const updatedItem = letterIndex[index] + "_" + optionValue
          return updatedItem
        })

        const { confirm } = Modal;
        confirm({
          title: "Magic!",
          icon: <UngroupOutlined />,
          content: (<div className="pt-2">
            <Text className="text-lg pb-1"> Detected Question</Text>
            <div className="pt-2">
              <Text italic> {finalQuestionText} </Text>
            </div>
            <div className="pt-5 pb-1"><Text className="text-lg"> Detected Options</Text></div>
            <div>{rawOptions.map((option) => <li><Text mark italic> {option} </Text></li>)}</div>
            <div className="pt-6"> Do you want to auto-fill the question and options?</div>

          </div>),
          okText: "Yes",
          okType: "primary",
          cancelText: "No",
          onOk() {
            const latestQuestion = composedQuestion.current ? composedQuestion.current : question;
            setQuestion({ ...latestQuestion, description: finalQuestionText });
            setAnswer({ ...answer, values: options });
            e.preventDefault();
            composedQuestion.current = { ...question, description: finalQuestionText }
          },
          onCancel() {
            // reject();
          },
        });
      }
    } else if (pastedText) {
      const latestQuestion = { ...question, textValue: pastedText };
      composedQuestion.current = latestQuestion;

    }
  }, [question, answer, questionImageUploading, uploadProgress])


  const onUploadCancel = useCallback(() => {
    if (uploadCancelToken.current) {
      // @ts-ignore
      uploadCancelToken.current.cancel();
    }
    setQuestionImageUploading(false);
    setRecodingData(undefined);
    setUploadProgress(0);
  }, [questionImageUploading, uploadProgress])

  const pageHeight = mode.valueOf() === questionMode.LIVE ? "96vh" : 0;
  const disableButton = (answer.optionsType === "text" && !answer.correctValue)
    || questionImageUploading
    || (mode.valueOf() === questionMode.PREPARE && (question.images.length == 0 && !question.description))

  const uploadRecording = (data: Blob) => {
    uploadAttachment({ file: data })
    setRecodingData(data);
  }

  return (
    <div dir={isRTL ? "rtl" : "ltr"}>

      <Composition
        areas={builderArea}
        alignItems="center"
        gap={"2rem"}
        gapRow={'1rem'}
        justify="center"
        minWidth="100%"
        // width="80vw"
        widthMd="500px"
        style={mode === questionMode.LIVE ? {
          minHeight: "calc(100vh - 92px)"
          // height: { pageHeight }
        } : {}}    >
        {({ QuestionLabel, QuestionTextArea, AnswerArea, FooterArea }) => (
          <>
            <QuestionLabel>
              <div className="text-xl text-left">{Label}</div>
            </QuestionLabel>
            <QuestionTextArea>
              {selectQuestionButtonTitle ?
                <span>
                  <Button
                    type={!question.description ? "primary" : "default"}
                    onClick={onSelectQuestion}
                    className={'float-left mb-4 text-xs'}
                  >
                    {selectQuestionButtonTitle}
                  </Button> </span>
                : null}
              <TextArea
                className="rounded-box"
                value={question.description}
                autoSize={{ minRows: 4, maxRows: 14 }}
                placeholder="Type or paste your question here..."
                onPaste={pastedQuestion}
                onChange={(e) => updateQuestionText(e.target.value)}
              />

              <div>
                {!recodingData && question.images.length == 0 ?
                  <AudioRecorder
                    mode={mode.valueOf() === questionMode.PREPARE ? "light" : "dark"}
                    enabled={!recodingData}
                    onRecordingComplete={(data) => { uploadRecording(data); }}
                    title={!recodingData ? "Record Question" : ""}
                  /> : null}
              </div>



              {question.images.length == 0 && !questionImageUploading ?

                <Dragger
                  style={{
                    marginTop: "20px",
                    borderRadius: "15px"
                  }}
                  className="rounded-2xl"
                  customRequest={uploadAttachment}
                  fileList={questionImageFile ? [questionImageFile] : []}
                  showUploadList={false}
                  listType={"picture"}
                >
                  <FileImageOutlined />
                  <p>Add an image</p>
                  <p className="text-sm text-gray-500 ">(Diagrams, equations, graphs, etc)</p>
                </Dragger>
                : null}
              {questionImageUploading ? (
                <Composition
                  templateCols={"auto"}
                  justifyItems={"center"}
                  alignItems={"center"}
                  padding={"10px"}
                  margin={"10px 0 10px 0"}
                  className="border-2 border-dashed"
                >
                  <Progress percent={uploadProgress} />
                  <Button danger onClick={onUploadCancel}> Cancel </Button>
                </Composition>
              ) : null}
              {question.images.length > 0 ? (
                <Composition
                  templateCols={"auto 50px"}
                  justifyItems={"center"}
                  alignItems={"center"}
                  padding={"10px"}
                  margin={"10px 0 10px 0"}
                  className="border-2 border-dashed"
                >
                  <Image

                    imageId={question.images[0].url} />
                  <DeleteOutlined
                    onClick={() => {
                      removeQuestionImage();
                      setQuestionImage(undefined);
                      setRecodingData(undefined);
                    }}
                    style={{
                      fontSize: "20px",
                      color: "#ff4d4f",
                    }}
                  />
                </Composition>
              ) : null}
            </QuestionTextArea>
            <AnswerArea>
              <AnswerBuilder answerObject={answer} changeAnswer={setAnswer} />
            </AnswerArea>
            <FooterArea>
              <div className="text-center mt-4">
                <button
                  style={{ minWidth: 130 }}
                  className={mode === questionMode.PREPARE ? "mr-2 ant-btn ant-btn-primary" : disableButton ? "disabled-btn" : "primary-btn"
                  }
                  onClick={onSubmit}
                  // type="primary"
                  disabled={(answer.optionsType === "text" && !answer.correctValue)
                    || questionImageUploading
                    || (mode.valueOf() === questionMode.PREPARE && (question.images.length == 0 && !question.description))}
                >
                  {askButtonLabel ? askButtonLabel : `Ask ${numberOfStudents || ''} students`}
                </button>
              </div>
            </FooterArea>
          </>
          // </div>

        )}
      </Composition>
    </div>
  );
};

export default QuestionBuilder;

const answerArea = `
    typeSelectorArea
    optionsArea
`;

const letterIndex = ["A", "B", "C", "D"];

const AnswerBuilder = ({
  answerObject,
  changeAnswer,
}: {
  answerObject: answer;
  changeAnswer: Dispatch<SetStateAction<answer>>;
}) => {
  const { optionsType, values, correctValue, correctValueIndex } = answerObject;

  const updateAnswerType = useCallback(
    (newType:any) => {
      const updatedDefaultAnswer = (!answerObject.values || answerObject.values.length < 4) && newType === "multiple" ? { ...answerObject, ...getDefaultAnswer() } : answerObject
      changeAnswer({ ...answerObject, values: updatedDefaultAnswer.values, optionsType: newType });
    },
    [changeAnswer, answerObject]
  );

  const updateAnswerValue = useCallback(
    (newValue:any) => {
      changeAnswer({ ...answerObject, correctValue: newValue });
    },
    [changeAnswer, answerObject]
  );

  const updateCorrectAnswerIndex = useCallback(
    (index:any) => {
      changeAnswer({ ...answerObject, correctValueIndex: index });
    },
    [changeAnswer, answerObject]
  );

  const updateMultipleOptionAnswer = useCallback(
    (optionValue:any, index:any) => {
      values?.splice(index, 1, `${letterIndex[index]}_${optionValue}`);
      changeAnswer({
        ...answerObject,
        values,
      });
    },
    [changeAnswer, answerObject, values]
  );

  return (
    <Composition areas={answerArea} gap="2rem">
      {({ TypeSelectorArea, OptionsArea }) => (
        <>
          <TypeSelectorArea>
            <Radio.Group
              defaultValue={optionsType}
              value={optionsType}
              onChange={(e) => updateAnswerType(e.target.value)}
            >
              <Radio.Button value="multiple">Multi-Choice</Radio.Button>
              <Radio.Button value="text">Text/Numeric</Radio.Button>
            </Radio.Group>
          </TypeSelectorArea>
          <OptionsArea width="100%" justify="center">
            {optionsType === "text" ? (
              <Input
                className="question-input-text-small border-b-2 ml-3 outline-none w-8/12 bg-transparent"
                value={correctValue}
                onChange={(e) => updateAnswerValue(e.target.value)}
              />
            ) : (
              // <Checkbox.Group
              //   style={{
              //     width: "100%",
              //   }}
              //   value={
              //     correctValueIndex || correctValueIndex === 0
              //       ? [correctValueIndex]
              //       : undefined
              //   }
              // >
              <Composition
                gapRow={20}
                justifyItems="self-start">
                {values?.map((option, i) => (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {/* <Checkbox
                        value={i}
                        style={{ width: "100%", paddingBottom: "5px" }}
                        className=""
                        onChange={(e) =>
                          updateCorrectAnswerIndex(e.target.value)
                        }
                      > */}
                    <span className={"cursor-pointer flex float-left font-bold hover:border-gray-600 hover:shadow-xl items-center justify-center rounded-full text-gray-700"}

                      onClick={() => updateCorrectAnswerIndex(i)}
                      style={{
                        height: "50px",
                        width: "50px",
                        fontSize: "24px",
                        paddingTop: 4,
                        color: "white",
                        fontWeight: "bold",
                        marginRight: 10,
                        marginTop: 5,
                        background: optionColorIndex[i]

                      }}>
                      {
                        correctValueIndex === i ?
                          <CheckOutlined />
                          :
                          letterIndex[i]
                      }
                    </span>
                    {/* <span>{letterIndex[i]}</span> */}
                    <input
                      // style={correctValueIndex === i ? {
                      //   background: "#219653",
                      //   color: "white"
                      // } : {}}
                      className={"question-input-text-small border-b-2 ml-3 outline-none w-8/12 bg-transparent".concat(correctValueIndex === i ? " option-box-correct" : " option-box-neutral")}
                      value={option.slice(2)}
                      onChange={(e) =>
                        updateMultipleOptionAnswer(e.target.value, i)
                      }
                      type="text"
                    />
                    {/* </Checkbox> */}
                  </div>
                ))}
              </Composition>
              // </Checkbox.Group>
            )}
          </OptionsArea>
        </>
      )}
    </Composition>
  );
};
