import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Composition } from "atomic-layout";
import { Breadcrumb, Button, Divider, Input, Layout, Menu, Modal, notification, PageHeader, Result, Select, Space, Tabs, DatePicker, Card, Statistic, Row, Col, Progress, Table, Tooltip, InputNumber, Dropdown } from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import { classesForNewSession, cumilativeStatsForClass, cumilativeStatsForStudents, currentSession, getClassById, getSessionById, getUserById, isFetchingClasses, myClasses, pastSessionsForClass, pastSessionsForClassFromDate } from "@/Models/Selectors";
import { fetchAllClasses, fetchClassSessions, startSessionForClass } from "@/Services/Class";
import InviteModal from "@/Components/Class/inviteModal";
import { Link, Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { AppstoreOutlined, ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, DownOutlined, FundProjectionScreenOutlined, LaptopOutlined, LikeOutlined, NotificationOutlined, PlusOutlined, QuestionCircleOutlined, SettingOutlined, StarOutlined, TeamOutlined, UngroupOutlined, UserOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Engagement from "@/Icons/engagement.svg"

import "./Stats.css"
import store from "@/Models/store";
import ClassStudentsStats from "./ClassStudentsStats";
import ClassSessionStats from "./ClassSessionStats";
import StudentDetailStats from "./StudentDetailStats";
import Loader from "@/Components/Loader";

import correct_icon from '@/Assets/correct.png';
import incorrect_icon from '@/Assets/refresh.png';
import absent_icon from '@/Assets/question_mark.png';
import engagement_icon from '@/Assets/exclamation.png';



import moment from 'moment';
// import { DualAxes, TinyArea } from '@ant-design/charts';
import { LabelList, Bar, ComposedChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as ChartToolTip, ResponsiveContainer, Legend } from 'recharts';

import StatsTiles from "./StatsTiles";
import { DefaultTileCardTextColor, Subjects } from "@/Services/Constants";
import UserLabel from "../Common/Image/UserLabel";
import { stat } from "fs";
import { convertJsonToCSV, downloadCSVFile } from "../Common/CSVConverter";
import { valueType } from "antd/lib/statistic/utils";
import Analytics from "@/Services/Analytics";
import { getDisplayEmail } from "@/Services/User";

const { RangePicker } = DatePicker;
const DefaultDateDifference = 7 // 7 days period as default stats date range 

const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
    classId: string
}
const { TabPane } = Tabs;

export const getAverageMetric = (stats: sessionStats[], type: 'engagement' | 'correctness') => {
    let totalValue = 0;
    stats
        .map((stat: sessionStats) =>
            totalValue = totalValue + (stat.engagement_percent || 0));

    const average = totalValue / stats.filter((stat: sessionStats) => type == "engagement" ? stat.engagement_percent != 0 : stat.correct_percent).length;

    return parseInt(average.toFixed(0)) || 0
}


interface StatsComponent extends RouteComponentProps<RouteParams> {
}

const ClassStats: React.FC<StatsComponent> = (props) => {

    const { SubMenu } = Menu;
    const { Header, Content, Sider } = Layout;
    const [sortedInfo, setSortedInfo] = useState<{ "columnKey": string, "order": string }>();
    const allClasses = useSelector(myClasses)();

    const fetchingClasses = useSelector(isFetchingClasses);
    const hasFetchedData = useRef(false);
    // const [allClasses, setAllClasses] = useState<Class[]>(listOfClasses);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(90, 'days').toDate())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateDiff, setDateDiff] = useState(moment(endDate).diff(moment(startDate), 'days'))
    const previousStartDate = moment(startDate).subtract(dateDiff, 'days').toDate()
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [percentageFilteredStudents, setPercentageFilteredStudents] = useState(50);

    const history = useHistory();

    const classIdInURL = props.match.params.classId;
    const currentClassId = useRef(classIdInURL);
    // const _class: Class = useSelector(getClassById)(classIdInURL);
    const _class: Class = allClasses.filter((c: Class) => c.id == classIdInURL)[0];
    const classStatsCurrent = useSelector(cumilativeStatsForClass)(classIdInURL || '', startDate, endDate);
    const classStatsPast = useSelector(cumilativeStatsForClass)(classIdInURL || '', previousStartDate, startDate);
    const studentsStatsCurrent = useSelector(cumilativeStatsForStudents)(classIdInURL || '', startDate, endDate);
    const sessions: SessionState[] = pastSessionsForClassFromDate(store.getState())(classIdInURL, startDate, endDate);
    useEffect(() => {
        Analytics.track('View Class Stats', { ..._class, ...classStatsCurrent });
    }, [])



    useEffect(() => {
        if (_class && !hasFetchedData.current && !isFetchingData) {
            hasFetchedData.current = false;
            setIsFetchingData(true);
            fetchClassSessions(_class).then(() => {
                hasFetchedData.current = true;
                setIsFetchingData(false);
            }).finally(() => {
            });
        }
        return () => {
        }

    }, [allClasses]);

    // useEffect(() => {
    //     setAllClasses(listOfClasses);
    //     return () => {
    //     }

    // }, [listOfClasses])

    useEffect(() => {
        if ((!hasFetchedData.current || currentClassId.current != classIdInURL) && !isFetchingData && _class) {
            setIsFetchingData(true);
            fetchClassSessions(_class).then(() => {
                // setSessions(sessions)
                hasFetchedData.current = true;
                setIsFetchingData(false);
                currentClassId.current = _class.id
            });
        }
    }, [sessions]);




    const getAverageAttendance = (stats: sessionStats[]) => {
        let totalAttendance = 0;
        stats
            .map((stat: sessionStats) =>
                totalAttendance = totalAttendance + (stat.attendance_percent || 0));

        const avgAttendance = totalAttendance / stats.filter((stat: sessionStats) => stat.attendance_percent != 0).length;

        return parseInt(avgAttendance.toFixed(0)) || 0
    }

    const currentAverageEngagement = getAverageMetric(classStatsCurrent, "engagement");
    const pastAverageEngagement = getAverageMetric(classStatsPast, "engagement");

    const currentAverageAttendance = getAverageAttendance(classStatsCurrent);
    const pastAverageAttendance = getAverageAttendance(classStatsPast);

    const currentSessionCount = classStatsCurrent.length;
    const pastSessionCount = classStatsPast.length;

    if (!classIdInURL && allClasses.length > 0) return <Redirect to={"analyse/class/" + allClasses[0].id + '/sessions'} />
    if (allClasses.length == 0) return <Redirect to={"/"} />

    function onDateChange(dates: any, dateStrings: any) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        setStartDate(dates[0].toDate());
        setEndDate(dates[1].toDate());
        setDateDiff(moment(dates[1]).diff(moment(dates[0]), 'days'))
    }

    const engagement_data = classStatsCurrent
        .filter((stat: sessionStats) => stat.engagement_percent > 0)
        .map((stat: sessionStats) => stat.engagement_percent);

    const attendance_data = classStatsCurrent
        .filter((stat: sessionStats) => stat.attendance_percent > 0)
        .map((stat: sessionStats) => stat.attendance_percent);

    let arrTime = []
    const dayMillisec = 24 * 60 * 60 * 1000
    for (let q = classStatsCurrent.filter((stat: sessionStats) => stat.engagement_percent > 0)[0]?.startDate; q <= endDate; q = new Date(q.getTime() + dayMillisec)) {
        arrTime.push(q);
    }
    let session_data: number[] = []
    arrTime.map((date: Date) => {
        const sessionExist = classStatsCurrent.filter((stat: sessionStats) => moment(stat.startDate).isSame(date, 'days'))
        session_data.push(sessionExist.length > 0 ? 1 : 0)
    })

    const graphData = classStatsCurrent.map((stat: sessionStats) => {
        const statTime = stat.startDate.getDate() + '-' + (stat.startDate.getMonth() + 1) + '-' + stat.startDate.getFullYear()
        const sessionName = getSessionById(store.getState())(stat.sessionId).name
        return {
            time: statTime,
            engagement: stat.engagement_percent,
            attendance: stat.attendance_percent,
            correctness: stat.correct_percent,
            title: sessionName + ' (' + moment(stat.startDate).format('dddd, MMMM Do') + ')'
        }
    })

    const graphConfig = {
        data: [graphData, graphData],
        xField: 'time',
        yField: ['engagement', 'attendance'],
        geometryOptions: [
            {
                geometry: 'column',
            },
            {
                geometry: 'line',
                lineStyle: {
                    lineWidth: 2,
                },
            },
        ],
        tooltip: {
            title: 'title',
        }

    };

    const columnsLayout = {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 8
    }


    const studentBreakdownDataSource = _class?.students_email?.map((email: string, index: number) => {

        const studentStats = studentsStatsCurrent.filter((stat: studentStats) => stat.email === email)
        let totalCorrect = 0;
        let totalResponses = 0;
        let totalIncorrect = 0;
        let totalDontKnows = 0;
        let totalPresent = 0;
        let totalQuestions = 0;

        studentStats.map((stat: studentStats) => {
            totalCorrect = totalCorrect + 100 * ((stat.correct || 0) / stat.totalQuestions)
            totalResponses = totalResponses + 100 * ((stat.responses || 0) / stat.totalQuestions)
            totalIncorrect = totalIncorrect + 100 * ((stat.incorrect || 0) / stat.totalQuestions)
            totalDontKnows = totalDontKnows + 100 * ((stat.dontKnow || 0) / stat.totalQuestions)
            totalPresent = totalPresent + (stat.absent ? 0 : 1)
            totalQuestions = totalQuestions + (stat.totalQuestions || 0)
        })

        const thisStudent = getUserById(store.getState())(email);

        return {
            key: classIdInURL + email,
            email: email,
            user: thisStudent,
            name: thisStudent.full_name,
            engagement: parseInt((totalResponses / (studentStats.length || 1)).toFixed(0)),
            correct: parseInt((totalCorrect / (studentStats.length || 1)).toFixed(0)),
            incorrect: parseInt((totalIncorrect / (studentStats.length || 1)).toFixed(0)),
            responses: totalResponses,
            dontKnow: parseInt((totalDontKnows / (studentStats.length || 1)).toFixed(0)),
            attendance: parseInt((100 * totalPresent / (studentStats.length || 1)).toFixed(0))
        }
    })


    const leastEngagedStudents = studentBreakdownDataSource?.filter((stat) => stat.engagement > 0
        && stat.engagement < percentageFilteredStudents)
        .sort((a, b) => a.engagement - b.engagement)

    const leastPresentStudents = studentBreakdownDataSource?.sort((a, b) => a.attendance - b.attendance)
        .filter((stat) => stat.attendance < percentageFilteredStudents)

    const mostCorrectStudents = studentBreakdownDataSource?.filter((stat) => stat.engagement > 0
        && stat.correct >= percentageFilteredStudents)
        .sort((a, b) => b.correct - a.correct)

    const leastCorrectStudents = studentBreakdownDataSource?.filter((stat) => stat.engagement > 0
        && stat.correct <= percentageFilteredStudents)
        .sort((a, b) => a.correct - b.correct)

    const attentionColumn = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any, index: any) => text ? <Link to={'students/' + record.email}><UserLabel email={record.email} /></Link> : record.email

        },
        {
            title: 'Engagement',
            dataIndex: 'engagement',
            key: 'engagement',
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        }
    ];

    const attendanceColumn = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any, index: any) => text ? <Link to={'students/' + record.email}><UserLabel email={record.email} /></Link> : record.email

        },
        {
            title: 'Attendance',
            dataIndex: 'attendance',
            key: 'attendance',
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        }
    ];


    const correctColumn = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any, index: any) => text ? <Link to={'students/' + record.email}><UserLabel email={record.email} /></Link> : record.email

        },
        {
            title: 'correct',
            dataIndex: 'correct',
            key: 'correct',
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        }
    ];

    const allStudentsColumns: Array<any> = [
        {
            "title": <UserOutlined />,
            "dataIndex": "name",
            "key": "name",
            defaultSortOrder: 'descend',
            sorter: (a: studentStats, b: studentStats) => {
                if (sortedInfo?.columnKey == "attendance") {
                    if (a.user && b.user)
                        return Number(b.absent) - Number(a.absent)
                }
                if (a.user && b.user)
                    return b.user?.full_name.localeCompare(a.user?.full_name)
            },
            width: 100,
            sortOrder: sortedInfo ? (sortedInfo.columnKey === 'name' || sortedInfo.columnKey === "attendance") && sortedInfo.order : null,
            showSorterTooltip: { title: "Name" },
            render: (text: any, record: any, index: any) => text ? <Link to={'students/' + record.email}><UserLabel email={record.email} /></Link> : record.email

        },
        {
            "title": <StarOutlined />,
            "dataIndex": "engagement",
            "key": "engagement",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => parseInt(a.engagement) - parseInt(b.engagement),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'engagement' && sortedInfo.order : null,
            showSorterTooltip: { title: "Engagement" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0

        },
        {
            "title": <CheckCircleOutlined />,
            "dataIndex": "correct",
            "key": "correct",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => parseInt(a.correct) - parseInt(b.correct),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'correct' && sortedInfo.order : null,
            showSorterTooltip: { title: "Correct Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0

        },
        {
            "title": <CloseCircleOutlined />,
            "dataIndex": "incorrect",
            "key": "incorrect",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => parseInt(a.incorrect) - parseInt(b.incorrect),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'incorrect' && sortedInfo.order : null,
            showSorterTooltip: { title: "Incorrect Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0



        },
        {
            "title": <QuestionCircleOutlined />,
            "dataIndex": "dontKnow",
            "key": "dontKnow",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => parseInt(a.dontKnow) - parseInt(b.dontKnow),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'dontKnow' && sortedInfo.order : null,
            showSorterTooltip: { title: "Not Sures" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0

        }
    ];
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo(sorter)
    };

    if (fetchingClasses) {
        return <Loader />
    }

    const downloadClassStats = () => {
        console.log("download stats for ", _class.name);
    };

    const downloadStudentStats = () => {
        Analytics.track('Download Class Students Stats', { ..._class, ...classStatsCurrent });

        const csvArray: {}[] = [];
        studentBreakdownDataSource.forEach((stat, index) => {
            var csvObj = {
                '#': index + 1,
                Class: _class.name,
                Date_from: moment(startDate).format('LL'),
                Date_until: moment(endDate).format('LL'),
                Name: stat.user.full_name,
                email: getDisplayEmail(stat.user.email),
                total_sessions: sessions.length,
                attendance_percentage: stat.attendance + "%",
                engagement_percentage: stat.engagement + "%",
                correct_percentage: stat.correct + "%",
                incorrect_percentage: stat.incorrect + "%",
                dont_know_percentage: stat.dontKnow + "%",
            }
            csvArray.push(csvObj);
        })
        const csvStats = convertJsonToCSV(csvArray);
        console.log(csvStats);
        downloadCSVFile(csvStats, "Vidya Students Stats - " + _class.name + " - " + moment(startDate).format('DD.MM.YY') + ' - ' + moment(endDate).format('DD.MM.YY') + '.csv')

    }

    const downloadSessionStats = () => {
        const csvArray: {}[] = [];
        Analytics.track('Download Class Session Stats', { ..._class, ...classStatsCurrent });

        classStatsCurrent.forEach((stat, index) => {
            const _session = getSessionById(store.getState())(stat.sessionId)
            var csvObj = {
                '#': index + 1,
                Session_Name: _session.name,
                Teacher: _class.primary_teacher,
                Class: _class.name,
                Date: moment(_session.start_time).format('LL'),
                Total_Students: _class?.students_email?.length,
                Attendance_percentage: stat.attendance_percent + "%",
                Engagement_percentage: stat.engagement_percent + "%",
                Correct_percentage: stat.correct_percent + "%"
            }
            csvArray.push(csvObj);
        })
        const csvStats = convertJsonToCSV(csvArray);
        console.log(csvStats);
        downloadCSVFile(csvStats, "Vidya Session Stats - " + _class.name + " - " + moment(startDate).format('DD.MM.YY') + ' - ' + moment(endDate).format('DD.MM.YY') + '.csv')
    }

    const downloadMenu = (
        <Menu>
            <Menu.Item key="1" onClick={(e) => downloadStudentStats()} icon={<UserOutlined />}>
                Students Stats
            </Menu.Item>
            <Menu.Item key="2" onClick={(e) => downloadSessionStats()} icon={<FundProjectionScreenOutlined />}>
                Sessions Stats
            </Menu.Item>
        </Menu>
    );

    const StudentCount: FC<{ title: string }> = (props: { title: string }) => <div>
        <div
            className="float-right mb-2 mt-2"
            style={{
                justifyItems: "end"
            }}

        >
            <span className="text-gray-500 ml-2">{props.title}</span>
            <InputNumber
                defaultValue={100}
                value={percentageFilteredStudents}
                bordered={false}
                min={0}
                max={100}
                formatter={value => `${value}%`}
                parser={(value: string | undefined) => value ? parseInt(value.replace('%', '')) : 0}
                onChange={((e) => setPercentageFilteredStudents(50))}
                onStep={((value: number, info: { offset: valueType, type: 'up' | 'down' }) => {
                    let num = info.type == "up" ? value + 4 > 100 ? 100 : value + 4 : value
                    num = info.type == "down" ? value - 4 < 0 ? 0 : value - 4 : num
                    setPercentageFilteredStudents(num)
                }
                )

                }
            />
            {/* <span >{percentageFilteredStudents === 1 ? 'student' : 'students'}</span> */}
        </div>
    </div>



    const showLoader = isFetchingData || (sessions.length > 0 && classStatsCurrent.length == 0 && _class.students_email.length != 0)

    return (
        <>
            <Layout>
                {(classIdInURL !== 'add' && !_class) ? <Loader /> : <>
                    {allClasses.length === 0 ?
                        <Result
                            status="404"
                            title="No Classes"
                            subTitle="You have not created any class yet."
                            extra={<Button type="primary">Create Class</Button>}
                        /> :
                        <>

                            <Layout
                                className="m-auto p-2 sm:p-3 sm:w-full w-full"
                                style={
                                    {
                                        maxWidth: '1500px',
                                        minHeight: '100vh',
                                        height: 'fit-content'
                                    }}>

                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 0,
                                        margin: 12,
                                        minHeight: 280,
                                    }}
                                >
                                    <Layout className="site-layout-background lyt"
                                        style={{
                                            padding: '0 0'
                                        }}>
                                        <Sider
                                            style={{
                                                overflow: 'auto',
                                                // height: '100%',
                                                // position: 'fixed',
                                                left: 0,

                                            }}
                                            width={200}
                                            collapsedWidth={40}
                                            collapsible={true}
                                            breakpoint="lg"
                                            className="site-layout-background"
                                        >
                                            <Menu
                                                theme="light"
                                                mode="inline"
                                                defaultSelectedKeys={[classIdInURL]}
                                                defaultOpenKeys={['sub1']}
                                                style={{
                                                    height: '100%', borderRight: 0, paddingTop: 10,
                                                    background: '#fafafa'
                                                }}

                                            >

                                                {allClasses.map((c) => {
                                                    return (
                                                        <Menu.Item
                                                            // className="pl-4"
                                                            // style={{ paddingLeft: 30 }}
                                                            // itemIcon={<div className='ml-2'>{c.description}</div>}
                                                            key={c.id}>
                                                            <Link to={"/analyse/class/" + c.id + "/sessions"}
                                                                style={{
                                                                    color: c.is_archived ? "darkgray" : "black"
                                                                }}

                                                            >
                                                                {c.description}
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                })}
                                                <Divider />
                                                <Menu.Item
                                                    // className="pl-4"
                                                    // style={{ paddingLeft: 30 }}
                                                    // itemIcon={<div className='ml-2'>{c.description}</div>}
                                                    key="001">
                                                    <Link to={"/analyse/sample-class/"} >
                                                        Sample Report
                                                    </Link>
                                                </Menu.Item>
                                            </Menu>
                                        </Sider>
                                        <Content className="vidya-page-layout" style={{ padding: '18px 24px' }}>
                                            {
                                                // isFetchingData || (sessions.length > 0 && !classStatsCurrent.length && _class.students_email.length != 0) ?
                                                // <Loader /> :
                                                !fetchingClasses
                                                    && !showLoader
                                                    && hasFetchedData
                                                    && _class
                                                    && _class.students_email.length == 0 ?
                                                    <Result
                                                        status="404"
                                                        title="No Students yet"
                                                        subTitle="You have not invited any students in this class yet."
                                                    />
                                                    :

                                                    <div className="text-left">
                                                        <PageHeader
                                                            style={{ padding: 0 }}
                                                            className="site-page-header"
                                                            onBack={() => history.length > 1 ? history.goBack() : history.push('/')}
                                                            title={_class.name}
                                                            subTitle={`${_class.description} ${_class.is_archived ? " (archived) " : ""}`}
                                                        // backIcon={true}
                                                        />
                                                        <Composition
                                                            areas={
                                                                !showLoader && (sessions.length == 0 || classStatsCurrent.length == 0) ?
                                                                    `date
                                                                     noData`
                                                                    :
                                                                    `date                                                            
                                                                    tiles
                                                                    graph
                                                                    studentBreakdown
                                                                    studentList
                                                            `}
                                                            // alignItems="start"
                                                            // justifyItems="start"
                                                            gapRow="2em"
                                                            width="100%"
                                                            style={{ display: 'block' }}
                                                        >
                                                            {({ Date, Tiles, Graph, StudentBreakdown, SessionList, StudentList, NoData }) => (
                                                                <>
                                                                    <div className="inline-block mb-8 w-full">

                                                                        <Date>
                                                                            <div className='float-right mb-4'>

                                                                                <RangePicker
                                                                                    style={{ width: 300 }}
                                                                                    ranges={{
                                                                                        Yesterday: [moment().subtract(1, "days"), moment()],
                                                                                        'Last 7 days': [moment().subtract(7, "days"), moment()],
                                                                                        'Last 30 days': [moment().subtract(30, "days"), moment()],
                                                                                        'This month': [moment().startOf('month'), moment()],
                                                                                        'Last 3 months': [moment().subtract(1, "quarter"), moment()],

                                                                                    }}
                                                                                    defaultValue={[moment().subtract(dateDiff, "days"), moment()]}
                                                                                    onChange={onDateChange}
                                                                                    format='MMM Do, yy'
                                                                                    disabled={showLoader}
                                                                                />
                                                                                <Tooltip title="Download Report">
                                                                                    <Dropdown overlay={downloadMenu}>
                                                                                        <Button type="ghost" className="ml-4" icon={<DownloadOutlined />} size='small'>
                                                                                        </Button>
                                                                                    </Dropdown>

                                                                                    {/* <Button
                                                                                        className="ml-4"
                                                                                        onClick={() => downloadClassStats()} type="text"><DownloadOutlined /></Button> */}
                                                                                </Tooltip>

                                                                            </div>

                                                                        </Date>
                                                                        <Divider />

                                                                    </div>
                                                                    <NoData>
                                                                        <Result
                                                                            status="404"
                                                                            title="No Data to Analyse"
                                                                            subTitle={"You have not conducted any sessions from " + moment(startDate).format("dddd, MMMM Do") + " to " + moment(endDate).format("dddd, MMMM Do YYYY")}
                                                                        >
                                                                        </Result>
                                                                    </NoData>

                                                                    <Tiles>
                                                                        <div className="site-statistic-demo-card">
                                                                            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                                                                                <Col
                                                                                    {...columnsLayout}

                                                                                    className="gutter-row"
                                                                                // span={16}
                                                                                >

                                                                                    <StatsTiles
                                                                                        value={currentAverageEngagement}
                                                                                        icon={<StarOutlined />}
                                                                                        title="Avg. Engagement"
                                                                                        compareNumber={pastAverageEngagement}
                                                                                        type="percent"
                                                                                        graphData={engagement_data}
                                                                                        loading={showLoader}
                                                                                        tooltip="This is calculated by comparing Total Questions with the Total Responses throughout the selected period"
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    {...columnsLayout}

                                                                                    className="gutter-row"
                                                                                // span={8}
                                                                                >
                                                                                    <StatsTiles
                                                                                        value={currentAverageAttendance}
                                                                                        icon={<UserOutlined />}
                                                                                        title="Avg. Attendance"
                                                                                        compareNumber={pastAverageAttendance}
                                                                                        type="percent"
                                                                                        graphData={attendance_data}
                                                                                        loading={showLoader}
                                                                                        tooltip="This is calculated by averaging the attendance of each session throughout the selected period"

                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    {...columnsLayout}


                                                                                    className="gutter-row"
                                                                                // span={8}
                                                                                >
                                                                                    <StatsTiles
                                                                                        value={currentSessionCount}
                                                                                        icon={<FundProjectionScreenOutlined />}
                                                                                        title="Total Sessions"
                                                                                        compareNumber={pastSessionCount}
                                                                                        type="number"
                                                                                        graphData={session_data}
                                                                                        color={DefaultTileCardTextColor}
                                                                                        loading={showLoader}
                                                                                        tooltip="This is the total sessions conducted during the selected period"

                                                                                    />
                                                                                </Col>
                                                                            </Row>

                                                                        </div>

                                                                    </Tiles>

                                                                    <Graph>

                                                                        {/* <DualAxes {...graphConfig}
                                                                        // loading={showLoader}
                                                                        /> */}

                                                                        <div style={{ width: '100%', height: 400, marginTop: 50 }}>

                                                                            <ResponsiveContainer width="100%" height="100%">
                                                                                <ComposedChart
                                                                                    width={500}
                                                                                    height={300}
                                                                                    data={graphData}
                                                                                    margin={{
                                                                                        top: 50,
                                                                                        right: 0,
                                                                                        left: 0,
                                                                                        bottom: 5,
                                                                                    }}
                                                                                >
                                                                                    <XAxis dataKey="time" />
                                                                                    <YAxis />

                                                                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}

                                                                                    <ChartToolTip
                                                                                        label={"title"}

                                                                                        content={({ active, payload, label }: any) => {
                                                                                            if (active && payload && payload.length) {
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{ background: '#f0f0f082', paddingLeft: 10, paddingRight: 10 }}
                                                                                                        className="custom-tooltip">
                                                                                                        <p className="label bold-heading">{`${payload[0].payload.title}`}</p>
                                                                                                        <p className="intro text-blue-600">{`Engagement : ${payload[0].payload.engagement}%`}</p>
                                                                                                        <p className="intro text-green-600">{`Correctness : ${payload[0].payload.correctness}%`}</p>

                                                                                                        <p className="intro text-gray-600">{`Attendance : ${payload[0].payload.attendance}%`}</p>

                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        }}
                                                                                    // formatter={(value: any, name: any, props: any) => value + "%"}
                                                                                    />

                                                                                    <Legend verticalAlign="top" />
                                                                                    <Line type="monotone" dataKey="engagement" stroke="#3182ce" activeDot={{ r: 8 }} />
                                                                                    <Bar dataKey="attendance" barSize={20} fill="#38a169" >
                                                                                        {/* <LabelList dataKey="title" position="top" /> */}
                                                                                    </Bar>
                                                                                    {/* <Line type="monotone" dataKey="attendance" stroke="#82ca9d" /> */}
                                                                                </ComposedChart>
                                                                            </ResponsiveContainer>
                                                                            <span className="bold-heading block" style={{ textAlign: "center" }}>Engagement and Attendance Breakdown</span>
                                                                        </div>
                                                                    </Graph>

                                                                    <StudentBreakdown>
                                                                        <div className='text-xl mt-12 mb-4'> Students who are</div>
                                                                        <Tabs defaultActiveKey="1"
                                                                            style={{ display: "contents" }}
                                                                            tabBarExtraContent={<div style={{ display: "none" }}>
                                                                                <div
                                                                                    className="float-right"
                                                                                    style={{
                                                                                        justifyItems: "end"
                                                                                    }}

                                                                                >
                                                                                    <span className="text-gray-500 ml-2">Show max</span>

                                                                                    <InputNumber
                                                                                        className="border-bottom-thin"
                                                                                        bordered={false}
                                                                                        style={{ width: 50, textAlign: 'left' }}
                                                                                        value={percentageFilteredStudents}
                                                                                        min={1}
                                                                                        onChange={((e) => setPercentageFilteredStudents(50))}
                                                                                    />
                                                                                    <span >{percentageFilteredStudents === 1 ? 'student' : 'students'}</span>
                                                                                </div>
                                                                            </div>
                                                                            }

                                                                        >

                                                                            <TabPane
                                                                                disabled={showLoader}
                                                                                tab={
                                                                                    <span><img className="w-10 ml-4 mr-2 align-middle" src={engagement_icon} />Least Engaged</span>
                                                                                }
                                                                                key="1"

                                                                            >
                                                                                <span
                                                                                    className="text-gray-500"
                                                                                >
                                                                                    Students who joined the sessions but responded the least number of questions
                                                                                </span>
                                                                                <StudentCount title="Students below" />
                                                                                <Table
                                                                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                                                                    pagination={{ position: [] }}
                                                                                    dataSource={leastEngagedStudents}
                                                                                    columns={attentionColumn}
                                                                                    loading={showLoader}
                                                                                />

                                                                            </TabPane>
                                                                            <TabPane
                                                                                tab={
                                                                                    <span><img className="w-10 ml-4 mr-2 align-middle" src={absent_icon} />Least Present</span>
                                                                                }
                                                                                key="2"
                                                                            >
                                                                                <span
                                                                                    className="text-gray-500"
                                                                                >
                                                                                    Students who joined the least number of sessions. Note, Zero engagement is treated is absent.
                                                                                </span>
                                                                                <StudentCount title="Students below" />
                                                                                <Table
                                                                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                                                                    pagination={{ position: [] }}
                                                                                    dataSource={leastPresentStudents}
                                                                                    columns={attendanceColumn} />

                                                                            </TabPane>
                                                                            <TabPane
                                                                                tab={
                                                                                    <span><img className="w-10 ml-4 mr-2 align-middle" src={correct_icon} />Most Correct</span>
                                                                                }
                                                                                key="3"
                                                                            >
                                                                                <span
                                                                                    className="text-gray-500"
                                                                                >
                                                                                    Students who gave maximum correct responses.
                                                                                </span>
                                                                                <StudentCount title="Students above" />
                                                                                <Table
                                                                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                                                                    pagination={{ position: [] }}
                                                                                    dataSource={mostCorrectStudents}
                                                                                    columns={correctColumn} />

                                                                            </TabPane>
                                                                            <TabPane
                                                                                tab={
                                                                                    <span><img className="w-10 ml-4 mr-2 align-middle" src={incorrect_icon} />Least Correct</span>
                                                                                }
                                                                                key="4"
                                                                            >
                                                                                <span
                                                                                    className="text-gray-500"
                                                                                >
                                                                                    Students with the least correct responses. These would include both "incorrect" and "don't knows".
                                                                                </span>
                                                                                <StudentCount title="Students below" />
                                                                                <Table
                                                                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                                                                    pagination={{ position: [] }}
                                                                                    dataSource={leastCorrectStudents}
                                                                                    columns={correctColumn} />

                                                                            </TabPane>
                                                                        </Tabs>

                                                                    </StudentBreakdown>

                                                                    <SessionList>
                                                                        this is session list
                                                                    </SessionList>

                                                                    <StudentList>
                                                                        <div className='text-xl mt-12 mb-4'> All Students</div>
                                                                        <Table
                                                                            className="virtual-table"
                                                                            //   scroll={{ y: 300, x: 100 }}
                                                                            size="small"
                                                                            pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
                                                                            dataSource={studentBreakdownDataSource}
                                                                            columns={allStudentsColumns}
                                                                            onChange={handleChange}
                                                                            loading={showLoader}
                                                                            rowClassName={(record, index) => {
                                                                                const className = "text-gray-500";
                                                                                // @ts-ignore
                                                                                if (record.attendance === 0) return className
                                                                                return "";
                                                                            }}
                                                                        />
                                                                    </StudentList>
                                                                </>
                                                            )}
                                                        </Composition>
                                                    </div>
                                            }
                                        </Content>
                                    </Layout>
                                </Content>
                            </Layout>
                        </>
                    }
                </>
                }
            </Layout>
        </>
    );
};


export default ClassStats;
