import React, { useEffect, useState } from "react";
import { Composition } from "atomic-layout";
import wallImage from "@/Assets/adminSessionWall.png";
import { Button, notification } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getInviteCode, getInviteShortCode } from "@/Services/Class";
import { CopyOutlined } from "@ant-design/icons";
import Loader from "../Loader";
import Analytics from "@/Services/Analytics";

type Props = {
  _class: Class;
};

export const InviteCode = ({
  _class,
  description,
  size,
}: {
  _class: Class;
  description?: string;
  size?: "large" | "medium" | "small";
}) => {
  const [code, setCode] = useState<string>(_class.class_invite_code);
  const textSize =
    size && size == "medium"
      ? " text-2xl "
      : size == "small"
      ? " text-xl "
      : " text-3xl ";
  const iconSize =
    size && size == "medium"
      ? " text-xl "
      : size == "small"
      ? " text-lg "
      : " text-2xl ";
  const mt =
    size && size == "medium" ? " mt-2 " : size == "small" ? "" : " mt-4 ";

  useEffect(() => {
    if (!_class.class_invite_code) {
      getInviteShortCode(_class).then((code) => {
        setCode(code);
      });
    }
  }, [_class]);

  useEffect(() => {
    if (code != _class.class_invite_code) {
      setCode(_class.class_invite_code);
    }
  }, [_class]);

  return (
    <div
      className=""
      // style={{ zIndex: 10000000000000 }}
    >
      <div className="font-bold">
        {description
          ? description
          : "The invite code for " +
            _class.description +
            " (" +
            _class.name +
            ")" +
            " is"}{" "}
      </div>
      <CopyToClipboard
        text={code}
        onCopy={() => {
          Analytics.track("Copy Invite Code", { code });
          notification.success({
            message: "Invite code copied",
          });
        }}
      >
        <div
          className={"pl-8 pr-8 ml-auto mr-auto pt-2 pb-2 text-black "
            .concat(textSize)
            .concat(mt)}
          style={{
            height: "fit-content",
            background: "aliceblue",
            borderStyle: "dashed",
            borderWidth: "1px",
            borderColor: "#a1d1fc",
            borderRadius: "12px",
            letterSpacing: "9px",
            fontWeight: "bold",
            width: "fit-content",
          }}
        >
          <span>{code ? code : <Loader />}</span>{" "}
          <span className={iconSize}>{code ? <CopyOutlined /> : null}</span>
        </div>
      </CopyToClipboard>
    </div>
  );
};

const InviteModal = ({ _class }: Props) => {
  const [inviteCode, setInviteCode] = useState<string>("");
  const [shortCode, setShortCode] = useState<string>(_class.class_invite_code);
  const [fetchStatus, setFetchStatus] = useState<string>("fetching");
  const inviteBaseDomain =
    typeof window !== "undefined" ? window.location.host : "app.vidya.us";
  const inviteBaseUrl = inviteBaseDomain + "/join-class/" + _class.id + "/";

  useEffect(() => {
    if (!_class.class_invite_code) {
      setInviteCode("");
      getInviteShortCode(_class).then(
        (code) => {
          const inviteUrl = inviteBaseUrl + code;
          setInviteCode(inviteUrl);
          setShortCode(code);
          setFetchStatus("complete");
        },
        () => setFetchStatus("error")
      );
    } else {
      const inviteUrl = inviteBaseUrl + _class.class_invite_code;
      setInviteCode(inviteUrl);
      setShortCode(_class.class_invite_code);
    }
    Analytics.track("Open Invite Modal", { inviteBaseUrl });
  }, [_class, inviteBaseUrl]);

  return (
    <Composition>
      <Composition
        templateCols="auto"
        templateRows="auto auto"
        // templateColsMd="45% 55%"
        // templateRowsMd="auto"
        gapCol="10px"
      >
        <div className="text-center">
          <img
            className="w-full md:w-8/12 mx-auto ml-auto mr-auto"
            src={wallImage}
            alt="students sitting"
          />
        </div>
        {fetchStatus === "error" ? (
          <div className="">
            Error fetching the class code. Please refresh and try again.
          </div>
        ) : (
          <Composition templateRows="2fr 1fr 1fr 1fr" justifyItems="center">
            <>
              <InviteCode _class={_class} size="large" />
            </>
            {/* <Button
                className="mt-4 text-xl"
                icon={<CopyOutlined />}
                type="ghost"
                size="middle">
                {shortCode ? shortCode : "generating... "}
              </Button> */}
            {/* <div className="border-2 border-black mb-2 p-2 text-xs">
              {shortCode ? shortCode : "generating... "}
            </div> */}
            {/* <div className="font-bold">Your invite link is </div> */}
            {/* <div className="border-2 border-black mb-2 p-2 text-xs">
              {inviteCode ? inviteCode : "generating... "}
            </div> */}
            <div className="text-gray-500 mt-4">
              Share this code with students to join this batch
            </div>
            <div className="inline">
              <CopyToClipboard
                text={inviteCode}
                onCopy={() => {
                  notification.success({
                    message: "Invite link copied",
                  });
                }}
              >
                <Button
                  className="mt-4"
                  icon={<CopyOutlined />}
                  type="ghost"
                  size="middle"
                >
                  Copy Invite Link
                </Button>
              </CopyToClipboard>
              <CopyToClipboard
                text={shortCode}
                onCopy={() => {
                  notification.success({
                    message: "Invite code copied",
                  });
                }}
              >
                <Button
                  className="mt-4 ml-8"
                  icon={<CopyOutlined />}
                  type="primary"
                  size="middle"
                >
                  Copy Code
                </Button>
              </CopyToClipboard>
            </div>
            <div className="text-center mt-4 text-gray-500">
              Note, students would need to create Vidya account first if not
              already created.
            </div>
          </Composition>
        )}
      </Composition>
    </Composition>
  );
};

export default InviteModal;
