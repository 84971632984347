import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Composition } from "atomic-layout";
import { Button, Progress, Tooltip, Comment, Avatar } from "antd";
import { currentSession as currentSessionSelector, getClassById, getUserById } from "@/Models/Selectors";
import { startQuestion, stopQuestion } from "@/Services/Session";
import { getUserResponses } from "@/Services/Question";
import { CheckCircleTwoTone, DownOutlined, RightOutlined } from "@ant-design/icons";
import Image from "@/Components/Common/Image";
import { createSelector } from "reselect";
import Question from "../Common/QuestionTitlePreview";
import store from "@/Models/store";
import UserLabel from "../Common/Image/UserLabel";
import '../../index.css'
import { sessionTheme } from "@/Services/Constants";
import { CountUpTimer } from "../Common/Timer";

const currentSessionJoinees = createSelector(
  currentSessionSelector,
  (session) => session?.joinees || []
);

const reportViewAreaLive = `
    labelArea
    descriptionArea
    dataArea
    actionsArea
`;
const reportViewAreaReview = `
    labelArea
    descriptionArea
    dataArea
`;

const ReportView = ({ 
  session,
  question,
  onAskNextQuestion,
  showNewQuestionButton,
}: {
  session: SessionState;
  question: question;
  onAskNextQuestion: () => void;
  showNewQuestionButton: boolean;
}) => {
  const {
    liveStats,
    label,
    description,
    correctValueIndex,
    state,
    optionsType,
    userResponses,
    values,
    images
  } = question;

   console.log("Question-> ", liveStats);

   //console.log("Question-> ", userResponses);
  const imageId = images.length > 0 ? images[0].url : '';

  const [questionState, setQuestionState] = useState<"RECEIVING_RESPONSE" | "RECEIVED_RESPONSE" | "DRAFT">(state);

  const [renderUserResponsesFor, setRenderUserResponsesFor] = useState<any>();
  const joinedStudents = useSelector(getClassById)(session.classId).students_email
  // useSelector(currentSessionJoinees);

  const toggleUserResponseView = useCallback(
    (value: any) => {
      if (!userResponses) {
        getUserResponses(question);
        // return;
      }

      if (value === renderUserResponsesFor) {
        setRenderUserResponsesFor(undefined);
      } else {
        setRenderUserResponsesFor(value);
      }
    },
    [renderUserResponsesFor, userResponses, question]
  );

  const getUsersForResponse = useCallback(
    (option: string) => {
      if (!userResponses) return [];
      return userResponses[option] || [];
    },
    [userResponses]
  );

  const allRespondedUsers = useMemo(() => {
    if (!userResponses) {
      return [];
    }

    const respondedUsers: String[] = [];

    Object.values(userResponses).forEach((responseArray) => {
      responseArray.forEach((responseObj) => {
        // const thisUser = getUserById(store.getState())(responseObj.userEmail)
        respondedUsers.push(responseObj.userEmail)
      }
      );
    });
    return respondedUsers;
  }, [userResponses]);

  const getNotRespondedUsers = useCallback(() => {
    const notResponders = session.state !== "STARTED" ? [] : joinedStudents
      .filter((user) => allRespondedUsers.indexOf(user) < 0)
      .map((email) => {
        // const thisUser = getUserById(store.getState())(email)
        // return thisUser;
        return { userEmail: email, value: "" };
      });
    return notResponders;
  }, [allRespondedUsers, joinedStudents]);

  

  if (!liveStats) {
    return (
      <Composition>
        <div>
        <div>No data received yet for {label}</div>
          {state === "RECEIVING_RESPONSE" ? (
            <Button type={"ghost"} onClick={() => stopQuestion(question)}>
              Stop
            </Button>
          ) : null}

          {state === "RECEIVED_RESPONSE" ? (
            <Composition templateCols="auto auto" gap="20px">
              <Button type={"ghost"} onClick={() => startQuestion(question)}>
                Start
              </Button>
              <button
                className="primary-btn"
                onClick={onAskNextQuestion}>
                Ask Next Question
              </button>
            </Composition>
          ) : null}
        </div>
      </Composition>
    );
  }

  const {
    noResponse,
    dontKnow,
    options,
    totalCount,
    correct,
    wrong,
    correctValue,
  } = liveStats;




  return (
    <Composition
      areas={session.state === "STARTED" ? reportViewAreaLive : reportViewAreaReview}
      templateRows="min-content 0 0 0"
      gap={"2rem"}
    >
      {({ LabelArea, DescriptionArea, DataArea, ActionsArea }) => (
        <>
          <div
            className="mt-8"
            style={
              // question.state == "RECEIVING_RESPONSE" ?
              {
                minHeight: "60vh"
              }
              // : { }
            }
          >

            <LabelArea
              className="text-left text-xl"
              onClick={() => getUserResponses(question)}
            >
              {label}
            </LabelArea>
            <DescriptionArea>
              <Composition
                templateRows={'auto auto'}
                justifyItems="center"
              >
                <Question body={description} className="pb-5" />
                {imageId ? <Image imageId={imageId} /> : null}
              </Composition>
            </DescriptionArea>
            <DataArea marginTop={"1em"} width={"100%"} justify={"center"}>
              <Composition
                templateCols={"auto minmax(0px, 40px) 1fr 15px"}
                gapRow={"10px"}
                gapCol="2px"
                className="w-full sm:w-11/12 md:w-auto px-2"
              >
                <Composition col="1/3" className="pr-3 text-right">
                  No response
                </Composition>
                <Progress
                  percent={(noResponse * 100) / totalCount}
                  format={() => <span className={sessionTheme == "dark" ? "text-white" : "text-black"}>{noResponse}</span>}
                  strokeColor="#1890ff"
                />
                <UserResponse
                  responseKey={"noResponse"}
                  getUsersForResponse={getNotRespondedUsers}
                  toggleUserResponseView={toggleUserResponseView}
                  renderUserResponsesFor={renderUserResponsesFor}
                  shouldShowArrow={noResponse !== 0 && session.state === "STARTED"}
                  session={session}
                />

                <Composition col="1/3" className="pr-3 text-right">
                  Not Sure
                </Composition>
                {/* <div className='pr-3 text-right'>Don't know</div> */}
                <Progress
                  percent={(dontKnow * 100) / totalCount}
                  format={() => <span className={sessionTheme == "dark" ? "text-white" : "text-black"}>{dontKnow}</span>}
                  strokeColor="#F2C94C"
                />
                <UserResponse
                  responseKey={"dontKnow"}
                  getUsersForResponse={getUsersForResponse}
                  toggleUserResponseView={toggleUserResponseView}
                  renderUserResponsesFor={renderUserResponsesFor}
                  shouldShowArrow={dontKnow !== 0 && session.state === "STARTED"}
                  session={session}
                />
                {optionsType === "multiple" ? (
                  options.map(({ label, count, value }, index) => (
                    <>
                      <div className="pr-3 text-right">
                        {correctValueIndex === index ?
                          <span className="ml-3 mr-3">
                            <CheckCircleTwoTone twoToneColor="#00de4e" />
                          </span> : null}

                        {label}
                      </div>
                      <Tooltip title={value}>
                        <div className="text-left truncate">{value}</div>
                      </Tooltip>
                      <Progress
                        percent={(count * 100) / totalCount}
                        format={() => <span className={sessionTheme == "dark" ? "text-white" : "text-black"}>{count}</span>}
                        strokeColor={
                          correctValueIndex === index ? "#219653" : "#F17979"
                        }
                      />
                      <UserResponse
                        responseKey={values?.[index] || ""}
                        getUsersForResponse={getUsersForResponse}
                        toggleUserResponseView={toggleUserResponseView}
                        renderUserResponsesFor={renderUserResponsesFor}
                        shouldShowArrow={count !== 0 && session.state === "STARTED"}
                        session={session}
                      />
                    </>
                  ))
                ) : (
                  <>
                    <div className="pr-3 text-right">Correct</div>
                    <Tooltip title={correctValue}>
                      <div className="text-left truncate">{correctValue}</div>
                    </Tooltip>
                    <Progress
                      percent={(correct * 100) / totalCount}
                      format={() => correct}
                      strokeColor={"#219653"}
                    />
                    <UserResponse
                      responseKey="correct"
                      getUsersForResponse={getUsersForResponse}
                      toggleUserResponseView={toggleUserResponseView}
                      renderUserResponsesFor={renderUserResponsesFor}
                      shouldShowArrow={correct !== 0 && session.state === "STARTED"}
                      session={session}
                    />
                    <div className="pr-3 text-right">Wrong</div>
                    <div />
                    <Progress
                      percent={(wrong * 100) / totalCount}
                      format={() => wrong}
                      strokeColor={"#F17979"}
                    />
                    <UserResponse
                      responseKey="wrong"
                      getUsersForResponse={getUsersForResponse}
                      toggleUserResponseView={toggleUserResponseView}
                      renderUserResponsesFor={renderUserResponsesFor}
                      shouldShowArrow={wrong !== 0 && session.state === "STARTED"}
                      showResponseValue={true}
                      session={session}
                    />
                  </>
                )}
              </Composition>
            </DataArea>
            <ActionsArea
              style={{
                paddingTop: "5em"
              }}
            >
              <div className="mb-6"> <CountUpTimer className="" keepTicking={state === "RECEIVING_RESPONSE"} startTime={question.asked_at} /> </div>

              {state === "RECEIVING_RESPONSE" ? (
                <>
                  <Button type={"ghost"} onClick={() => stopQuestion(question)}>
                    <span className="text-white">Stop and Reveal Answer</span>
                  </Button>

                </>

              ) : null}

              {state === "RECEIVED_RESPONSE" && showNewQuestionButton ? (
                <Composition templateCols="auto" gap="20px" justifyItems="center">
                  {/* <Button type={"ghost"} onClick={() => startQuestion(question)}>
                  Start
                </Button> */}
                  <button
                    className="primary-btn"
                    // type="primary"
                    onClick={onAskNextQuestion}>
                    Ask Next Question
                  </button>
                </Composition>
              ) : null
              }
            </ActionsArea>
          </ div>
        </>
      )
      }
    </Composition >
  );
};

type userResponseType = {
  toggleUserResponseView: (value: string) => void;
  renderUserResponsesFor: any;
  getUsersForResponse: (
    value: string
  ) => { value: string; userEmail: string }[];
  responseKey: string;
  shouldShowArrow: boolean;
  showResponseValue?: boolean;
  session: SessionState;
};

const UserResponse = ({
  toggleUserResponseView,
  renderUserResponsesFor,
  getUsersForResponse,
  responseKey,
  shouldShowArrow = true,
  showResponseValue = false,
  session
}: userResponseType) => {
  const [showUsers, setShowUsers] = useState(false);

  const onArrowClick = useCallback(() => {
    toggleUserResponseView(responseKey);
    setShowUsers(!showUsers);
  }, [showUsers, toggleUserResponseView, responseKey]);

  const prevShouldShowArrow = useRef(shouldShowArrow);
  useEffect(() => {
    // Changed from false to true i.e count has moved from 0 to greater than 1
    if (shouldShowArrow !== prevShouldShowArrow.current) {
      setShowUsers(true);
    }
    prevShouldShowArrow.current = shouldShowArrow;
  }, [shouldShowArrow]);

  return (
    <>
      <div
        onClick={() => onArrowClick()}
        className={`${shouldShowArrow ? "" : "invisible"} cursor-pointer`}
      >
        {showUsers ? <DownOutlined /> : <RightOutlined />}
      </div>
      {showUsers && getUsersForResponse(responseKey).length > 0 ? (
        <>
          <Composition
            col="3/4"
            justify="start"
            templateRows="auto"
            className="text-left p-2 w-full overflow-scroll"
            style={{
              backgroundColor: "#F5F5F5",
              maxHeight: "10em",
              overflowX: "clip"
            }}
          >
            {getUsersForResponse(responseKey)
              .reverse()
              .map((obj) => {
                const reportListItem = (showResponseValue ? ` | ${obj.value}` : "")
                return (
                  <p className="w-full text-black">
                    <UserLabel email={obj.userEmail} suffix={reportListItem} classId={session.classId} />
                  </p>

                )
              }
              )}
            {/* {getUsersForResponse(responseKey).length === 0 ? (
            <p>No user picked this option</p>
          ) : null} */}
          </Composition>
          <div />
        </>
      ) : null}
    </>
  );
};

export default ReportView;
