import {
  allActiveClassesArray,
  getClassById,
  getPrepareSessionForClass,
} from "@/Models/Selectors";
import { Button, Collapse, Modal, Select, notification } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QuestionCardPreview from "../Question/QuestionPreview";
import store from "@/Models/store";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  createPrepareSessionForClass,
  updatePrepareSession,
} from "@/Services/Class";
import { createEmptyPrepareSessionForClass } from "@/Services/Class";
import { sessionsState } from "@/Models/Reducers/session";
import Analytics from "@/Services/Analytics";
import { uuid4 } from "@sentry/utils";

const { Option } = Select;
const { Panel } = Collapse;

const LaunchModal = ({
  visible,
  questions,
  name,
  onComplete,
}: {
  visible: boolean;
  questions: question[];
  name: string;
  onComplete: () => void;
}) => {
  const [isSavingCopySession, setIsSavingCopySession] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(visible);
  const _classes = useSelector(allActiveClassesArray);
  const [copyToClassId, setCopyToClassId] = useState(_classes[0]?.id || "");
  const [libraryQuestions, setLibraryQuestions] = useState<question[]>([]);

  const history = useHistory();

  const handleOkCopyModal = () => {
    if (copyToClassId) {
      Analytics.track("Copy Library Questions", {
        copyToClassId,
        name,
        questions,
      });
      setIsSavingCopySession(true);
      const _class = getClassById(store.getState())(copyToClassId);

      if (_class.state === "IN_SESSION") {
        const key = `open${Date.now()}`;
        notification.open({
          message: "Cannot copy",
          description: (
            <span>
              There is an ongoing session for this class. Please end the
              existing session to draft next session
            </span>
          ),
          icon: <WarningTwoTone twoToneColor="#eb2f96" />,
          key,
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                history.push("/session/" + _class.sessionId);
                notification.close(key);
              }}
            >
              Open Session
            </Button>
          ),
        });
        setIsSavingCopySession(false);
        return;
      }
      const finalQuestions: question[] = questions.map((q: question) => {
        return {
          ...q,
          question_id: uuid4(),
          draft_id: q.id,
          state: "DRAFT",
          description: q.description,
        };
      });

      let preparedSession: SessionState | null = null;
      let draftQuestions;
      if (_class.hasPreparedSession) {
        preparedSession = getPrepareSessionForClass(store.getState())(
          _class.id
        );
        draftQuestions = [
          ...finalQuestions,
          ...(preparedSession?.draft_questions || []),
        ];
        const updatedSession: any = {
          ...preparedSession,
          // type: "ADMIN",
          // state: "DRAFT",
          // currentQuestion: "",
          // description: "",
          // copy session name if destination class has 0 questions or does not have a prepared session. Persist the destiantion class session name if it already has some questions.
          name: preparedSession
            ? preparedSession.draft_questions.length > 0
              ? preparedSession.name
              : name
            : name,
          sessionId: preparedSession?.sessionId || uuid4(),
          draft_questions: draftQuestions,
          classId: copyToClassId,
        };
        processSessionToCopy(updatedSession, _class);
      } else {
        createEmptyPrepareSessionForClass(_class, name).then((session) => {
          const preparedSession: SessionState | null =
            getPrepareSessionForClass(store.getState())(_class.id);
          draftQuestions = [...finalQuestions];
          const updatedSession: any = {
            ...preparedSession,
            // copy session name if destination class has 0 questions or does not have a prepared session. Persist the destiantion class session name if it already has some questions.
            name,
            sessionId: uuid4(),
            draft_questions: draftQuestions,
            classId: copyToClassId,
          };
          processSessionToCopy(updatedSession, _class);
        });
      }
    }
  };

  const processSessionToCopy = (session: SessionState, _class: Class) => {
    createOrUpdateSession(session, _class)
      .then(() => {
        const key = `open${Date.now()}`;
        // notification.open({
        //     message: session.draft_questions.length == 1 ? session.draft_questions.length + ' Question drafted' : session.draft_questions.length + ' Questions drafted',
        //     description: (session?.sessionId ? 'The existing draft updated for ' : 'A new session has been drafted for ') + _class.description + ' (' + _class.name + ')',
        //     // <> <span>A new session has been drafted for </span>
        //     //     {/* <Link to={"/class/" + _class.id}> */}
        //     //     {_class.description + ' (' + _class.name + ')'}
        //     //     {/* </Link> */}
        //     //     <span>{'with ' + finalQuestions.length + ' questions.'}</span>
        //     // </>,
        //     btn: <Button type="link" size="small"
        //         onClick={() => { history.push("/class/" + _class.id + "/prepare"); notification.close(key) }}>
        //         View Draft
        //     </Button>,
        //     key,
        //     icon: <CheckCircleTwoTone twoToneColor="#52c41a" />
        //     ,
        // });
        onConfirm();
        history.push("/class/" + _class.id + "/prepare");
        setIsSavingCopySession(false);
      })
      .catch((e) => {
        notification.open({
          message: "Unable to copy",
          description:
            "Something went wrong. Please check there is no active sessions for this class",
          icon: <WarningTwoTone twoToneColor="#eb2f96" />,
        });
        setIsSavingCopySession(false);
      });
  };

  const createOrUpdateSession = (
    updatedSession: SessionState,
    _class: Class
  ) => {
    if (_class.hasPreparedSession)
      return updatePrepareSession(updatedSession, _class);
    return createPrepareSessionForClass(updatedSession, _class);
  };

  const handleCancelCopyModal = () => {
    // setCopyFromSession(undefined);
    // setShowCopyModal(false);
    onConfirm();
  };

  const onConfirm = () => {
    setIsSavingCopySession(false);
    // setCopyToClassId()
    onComplete();
  };

  return (
    <>
      <Modal
        title="Use Newton Laws of Motion in"
        visible={visible}
        okButtonProps={{
          loading: isSavingCopySession,
          hidden: questions.length == 0,
        }}
        okText={"Proceed"}
        cancelButtonProps={{ hidden: questions.length > 0 }}
        cancelText="Close"
        onOk={handleOkCopyModal}
        onCancel={onConfirm}
      >
        <div>
          {questions.length == 0 ? (
            <span className="w-full ">There are no questions to use.</span>
          ) : (
            <>
              <span className="w-full ">{`Select Class:`}</span>
              <Select
                className="float-right"
                defaultValue={copyToClassId}
                style={{ width: 370, zIndex: 10000000000000 }}
                onChange={(classId) => setCopyToClassId(classId)}
              >
                {_classes
                  .filter((c) => c.canStartSession)
                  .map((c) => (
                    <>
                      <Option
                        style={{
                          // width: 220,
                          zIndex: 10000000000000,
                        }}
                        key={c.id}
                        disabled={false}
                        value={c.id}
                      >
                        {c.description + " (" + c.name + ")"}
                      </Option>
                    </>
                  ))}
              </Select>

              {/* <Divider /> */}

              <div className="mt-10">
                <Collapse
                  defaultActiveKey={["0"]}
                  ghost
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Panel className="re" header="Preview Questions" key="1">
                    <div style={{ maxHeight: 400, overflow: "scroll" }}>
                      {questions.map((q: question, i: number) => {
                        return (
                          <div className="mt-4 mb-4">
                            <QuestionCardPreview
                              cardTitle={"Question " + (i + 1)}
                              // onCardClick={() => { }}
                              showDelete={false}
                              showEdit={false}
                              onEditClicked={() => {}}
                              onDeleteClicked={() => {}}
                              question={q}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
                <div
                  style={{
                    bottom: "55px",
                    position: "absolute",
                  }}
                  className="text-xs text-gray-500"
                >
                  Note, you can choose later which questions to use during the
                  session
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default LaunchModal;
