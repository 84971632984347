// import { TinyArea } from "@ant-design/charts"
import { ArrowDownOutlined, ArrowUpOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Card, Tooltip, Statistic } from "antd"
import { ReactNode } from "react"

import { AreaChart, Area, Bar, ComposedChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as ChartToolTip, ResponsiveContainer, Legend } from 'recharts';


interface RouteParams {
    value: number,
    compareNumber?: number,
    graphData?: number[],
    type: 'percent' | 'number'
    title: string,
    icon: ReactNode,
    color?: string,
    loading?: boolean,
    tooltip?: string
}

const DefaultCardTextColor = '#3182ce'
const DefaultCardTextColorClass = 'text-blue-600'


const StatsTiles = (props: RouteParams) => {
    const { tooltip, loading, value, title, compareNumber, graphData, type, icon, color } = props

    const graphContents = graphData?.map(num => { return { value: num } })
    const compareValue = compareNumber || 0;
    let comparePercent = compareValue === 0 ? 100 : 100 * (value - compareValue) / compareValue
    // comparePercent = comparePercent == 0 ? 0 : 100
    const defaultColor = !color ? value >= compareValue ? '#38a169' : '#e53e3e' : color
    const defaultColorClass = !color ? value >= compareValue ? 'text-green-600' : 'text-red-600' : 'text-blue-600'

    return <Card
        loading={loading}

    // style={{ height: "175px" }}
    >
        {value || (value === 0 && compareValue > 0) || loading ?
            <>
                {tooltip ?
                    <div className="float-right text-gray-600">
                        <Tooltip title={tooltip}
                            color={"#ffffff"}
                            overlayInnerStyle={{ color: "gray" }}
                            key={Math.random() * 1000}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    :
                    null
                }
                <Statistic
                    title={title}
                    value={value}
                    precision={0}
                    // className="text-red-600"
                    valueStyle={{ color: defaultColor }}
                    prefix={icon}
                    suffix={type === 'percent' ? "%" : ''}
                    loading={loading}

                />

                <div
                    className={defaultColorClass}
                >
                    {compareNumber || compareNumber === 0 ?
                        compareNumber > value ?
                            <ArrowDownOutlined /> :
                            <ArrowUpOutlined />
                        : null}

                    <span
                        className={defaultColorClass}
                    > {Math.abs(comparePercent).toFixed(0) + "%" || '-'}   </span>

                </div>
                {graphData ?
                    // <TinyArea
                    //     height={40}
                    //     autoFit={true}
                    //     data={graphData}
                    //     smooth={true}
                    // />
                    <div style={{ width: '100%', height: 50 }}>
                        <ResponsiveContainer>

                            <AreaChart
                                width={200}
                                height={60}
                                data={graphContents}
                                margin={{
                                    top: 5,
                                    right: 0,
                                    left: 0,
                                    bottom: 5,
                                }}
                            >

                                <defs>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#3182ce" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#3182ce" stopOpacity={0} />
                                    </linearGradient>

                                </defs>
                                {/* <Tooltip /> */}

                                <Area type="monotone" dataKey="value" stroke="#3182ce" fill="url(#colorPv)" />
                                <ChartToolTip
                                    label={""}
                                    formatter={(value: any, name: any, props: any) => [value + (type == "percent" ? "%" : null), null]}
                                    labelFormatter={(label: any, payload: any) => ""}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>

                    : null}
            </>
            :
            <Statistic
                title={title}
                value={'insufficient data'}
                precision={2}
                // className="text-red-600"
                valueStyle={{ color: 'gray' }}
            />
        }


    </Card>
}

export default StatsTiles;