import Actions from "../Actions";

const defaultState: statsSummaryReduxState = {
    allStats: {}
}

const statsSummaryReducer = (state = defaultState, action: reduxAction): statsSummaryReduxState => {

    switch (action.type) {
        case Actions.STATS.SUMMARY.ADD: {
            const stats = action.payload;
            const id = stats.sessionId;
            return {
                ...state,
                allStats: {
                    ...state.allStats,
                    [id]: stats
                }
            }
        }

        case Actions.STATS.SUMMARY.UPDATE: {
            const stats = action.payload;
            const id = stats.sessionId;
            return {
                ...state,
                allStats: {
                    ...state.allStats,
                    [id]: stats
                }
            }
        }

        case Actions.STATS.SUMMARY.DELETE: {
            const stats = action.payload;
            const id = stats.sessionId;

            delete state.allStats[id];
            return {
                ...state
            };
        }
    }
    return state;
}

export default statsSummaryReducer;