import { Button, Card, Col, Divider, Layout, Menu, PageHeader, Result, Row, Select, Space, Tabs, Tag, Typography, message, notification } from "antd";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { addQuestionLibrary, createQuestionAndAddToLibrary, fetchAllLibraries, fetchLibraryQuestions } from "@/Services/QuestionLibrary";
import { sampleQuestions } from "@/Services/Constants";
import { useSelector } from "react-redux";
import { currentUser, getAllQuestionLibraries, getAllSubjects, getAllTags } from "@/Models/Selectors";
import { EditOutlined, PlayCircleOutlined, PlusOutlined, ShareAltOutlined, WarningTwoTone } from "@ant-design/icons";
import UserAvatar from "../Common/Image/UserAvatar";
import moment from "moment";
import { timeAgoDate, toTitleCase } from "../Common/Utils";
import Search from "antd/lib/input/Search";
import UserLabel from "../Common/Image/UserLabel";
import AutoFilUserName from "./AutoFilUserName";
import LibraryShareModal from "./LibraryShareModal";
import LaunchModal from "./LaunchLibraryModal";
import Analytics from "@/Services/Analytics";

const { TabPane } = Tabs;
const { Option } = Select;
const { Paragraph, Text } = Typography;

const defaulyLibraryObject: QuestionLibrary = {
    created_by: "",
    subject: "",
    created_on: new Date(),
    id: "",
    is_public: false,
    name: "Untitled Question Library",
    shared_with_institute: [],
    shared_with_user: [],
    updated_by: "",
    updated_on: new Date(),
    brief: "",
    image: "",
    questions: [],
    tags: []
}

// Define the state type
interface FilterState {
    selectedTags: string[];
    selectedSubjects: string[];
    searchQuery: string;
}

// Define the initial state
const initialFilterState: FilterState = {
    selectedTags: [],
    selectedSubjects: [],
    searchQuery: '',
};



const QuestionLibrary = ({ viewOnly, onLibrarySelect }
    : {
        viewOnly: boolean,
        onLibrarySelect?: (library: QuestionLibrary) => void

    }) => {
    const [loadingLibraries, setLoadingLibraries] = useState(false);
    const [creatingLibrary, setCreatingLibrary] = useState(false);
    const [libraries, setLibraries] = useState<QuestionLibrary[]>([]);
    type tabsTypes = "My Sets" | "Shared with Me";
    const tabs: tabsTypes[] = ["My Sets", "Shared with Me"];
    const [selectedTab, setSelectedTab] = useState<string[]>(["My Sets"]);
    const questionLibraries = useSelector(getAllQuestionLibraries);
    const tags: { value: string, count: number }[] = useSelector(getAllTags);
    const subjects: { value: string, count: number }[] = useSelector(getAllSubjects);
    const _thisUser = useSelector(currentUser);
    const history = useHistory();
    const [shareLibrary, setShareLibrary] = useState<QuestionLibrary>();
    const [launchLibraryQuestions, setLaunchLibraryQuestions] = useState<question[]>();
    const [launchLibrary, setLaunchLibrary] = useState<QuestionLibrary>();

    const [filterState, setFilterState] = useState<FilterState>(initialFilterState);
    const [filteredLibraries, setFilteredLibraries] = useState<QuestionLibrary[]>(questionLibraries);

    // Function to update selected tags
    const updateSelectedTags = (tags: string[]) => {
        Analytics.track("Update Filter Tags", tags)
        setFilterState((prevState) => ({
            ...prevState,
            selectedTags: tags,
        }));
    };

    // Function to update selected subjects
    const updateSelectedSubjects = (subjects: string[]) => {
        Analytics.track("Update Filter Subject", subjects)
        setFilterState((prevState) => ({
            ...prevState,
            selectedSubjects: subjects,
        }));
    };

    // Function to update search query
    const updateSearchQuery = (query: string) => {
        Analytics.track("Update Search Query", {query})
        setFilterState((prevState) => ({
            ...prevState,
            searchQuery: query,
        }));
    };

    useEffect(() => {
        // Filter the libraries based on the current filter state
        Analytics.track("Update Search Filter", filterState)
        const filtered = filterLibraries(questionLibraries, filterState);
        setFilteredLibraries(filtered.length > 0 ? filtered : questionLibraries);
    }, [filterState, questionLibraries]);


    // Function to filter the libraries based on the current filter state
    const filterLibraries = (libraries: QuestionLibrary[], filters: FilterState): QuestionLibrary[] => {
        const { selectedTags, selectedSubjects, searchQuery } = filters;

        return libraries.filter((library) => {
            // Filter by tags
            const hasTags = selectedTags.length === 0 || selectedTags.some((tag) => library.tags?.includes(tag));

            // Filter by subjects
            const hasSubjects = selectedSubjects.length === 0 || selectedSubjects.some((subject) => library.subject.toLowerCase() === subject.toLowerCase());

            // Filter by title/search query
            const hasTitle = searchQuery === '' || library.name.toLowerCase().includes(searchQuery.toLowerCase());

            // Return true if any filter matches
            return hasTags && hasSubjects && hasTitle;
        });
    };


    useEffect(() => {
        const instituteId = _thisUser.institutes[0]?.id || undefined;
        setLoadingLibraries(true);
        fetchAllLibraries(_thisUser.email, instituteId).then((data) => {
            setLoadingLibraries(false);
            console.log('conponent received libraries');
            console.log(data)
        });
    }, [])


    useEffect(() => {
        console.log("question libraries state updated")
        console.log(questionLibraries);
    }, [questionLibraries])

    const handleChange = (tab: string, checked: boolean) => {
        const nextSelectedTags = checked ? [...selectedTab, tab] : selectedTab.filter(t => t !== tab);
        console.log('Changed filter to: ', nextSelectedTags);
        if (nextSelectedTags.length > 0)
            setSelectedTab(nextSelectedTags);
    };

    const libraryClicked = (library: QuestionLibrary) => {
        if (onLibrarySelect) {
            onLibrarySelect(library)
        } else {
            history.push(`/library/edit/${library.id}`)
        }
    }

    const createQuestionLibrary = () => {
        const newLibrary: QuestionLibrary = {
            ...defaulyLibraryObject,
            created_by: _thisUser.email,
            created_on: new Date(),
            updated_by: _thisUser.email,
            updated_on: new Date()
        }
        setCreatingLibrary(true);
        addQuestionLibrary(newLibrary)
            .then((library) => {
                console.log(library);
                setCreatingLibrary(false);
                if (onLibrarySelect) {
                    onLibrarySelect(library);
                }
                // history.push(`/library/edit/${library.id}`)
            }).catch((e) => {
                message.error('Something went wrong. Please try again later');
            });
    }

    const saveQuestion = () => {
        const q = sampleQuestions[0];
        // createQuestionAndAddToLibrary(q, "HfxrHLCZKBeQTMELx43B")
        // addQuestionLibrary(library);
    }

    const shareClicked = (library: QuestionLibrary) => {
        setShareLibrary(library);
    }

    const launchClicked = (library: QuestionLibrary) => {
        if (library.questions && library.questions?.length > 0) {
            message.loading({ content: 'loading', key: "msg", duration: 5 });
            fetchLibraryQuestions(library, library.questions)
                .then((questions: question[]) => {
                    setLaunchLibraryQuestions(questions);
                    setLaunchLibrary(library);
                    message.destroy("msg");
                })
                .catch((error) => message.error({ content: 'Something went wrong', key: "msg", duration: 2 }))
        } else {
            notification.open({
                message: 'Nothing to launch',
                description: 'There are no questions in this library to launch',
                icon: <WarningTwoTone twoToneColor="#eb2f96" />
                ,
            });

        }


    }

    return (
        <>
            {shareLibrary ?
                <LibraryShareModal
                    questionLibrary={shareLibrary}
                    visible={shareLibrary != undefined}
                    onShareComplete={(library: QuestionLibrary) => {
                        setShareLibrary(undefined);
                    }}
                /> : null}

            {launchLibrary && launchLibraryQuestions ?
                <LaunchModal
                    name={launchLibrary.name}
                    questions={launchLibraryQuestions}
                    visible={launchLibrary != undefined}
                    onComplete={() => {
                        setLaunchLibraryQuestions(undefined);
                        setLaunchLibrary(undefined);

                    }}
                /> : null}

            {loadingLibraries ? <Loader /> : <>
                {filteredLibraries.length == 0 ?
                    <Result
                        status="404"
                        title="No Libraries Yet"
                        subTitle="You have not created any library yet."
                        extra={<Button type="primary" icon={<PlusOutlined />} onClick={createQuestionLibrary} loading={creatingLibrary} size={"middle"}> New Library</Button>

                        }
                    /> :
                    <>



                        <Layout className="site-layout-background vidya-page-layout lyt"
                            style={{
                                padding: '0 0'
                            }}>
                            <Content style={{ padding: '18px 18px', minHeight: 280 }}>
                                {
                                    <div
                                        className="text-left">
                                        <PageHeader
                                            style={{ padding: 0 }}
                                            className="site-page-header"
                                            onBack={() => null}
                                            title={<span className="text-3xl">Question Library ({questionLibraries.length})</span>}
                                            // subTitle={'Collections of questions to conduct live quiz'}
                                            backIcon={false}
                                        />
                                        <div className="page-header">
                                            <div className="text-sm text-gray-600">Collections of questions to conduct live quiz</div>
                                            <Divider />
                                            {/* Seatch bar and Add New button */}
                                            <Row justify="space-between" className="flex flex-wrap">
                                                <Col xs={24} sm={12} lg={16} className="mb-4">
                                                    <div className="">
                                                        <Search placeholder="Search by title"
                                                            onChange={(e) => updateSearchQuery(e.target.value)}
                                                            onSearch={(e) => updateSearchQuery(e)}
                                                            style={{ width: "100%", paddingRight: "15px" }} />

                                                    </div>
                                                </Col>
                                                {!viewOnly ?
                                                    <Col xs={24} sm={12} lg={6} className="mb-4">
                                                        <div className="sm:text-right text-center">
                                                            <Button type="primary" icon={<PlusOutlined />} onClick={createQuestionLibrary} loading={creatingLibrary} size={"middle"}> New Library</Button>

                                                        </div>
                                                    </Col>
                                                    : null}
                                            </Row>

                                            {/* Filters and Sorting */}
                                            <Space className="mt-4 justify-start" direction="horizontal" size={30} wrap>
                                                <span className="subjects">
                                                    <span>Subjects: </span>
                                                    <Select
                                                        mode="multiple"
                                                        style={{ minWidth: '175px' }}
                                                        placeholder="filter subjects"
                                                        // defaultValue={''}
                                                        onChange={(e) => updateSelectedSubjects(e)}
                                                        optionLabelProp="subjects"
                                                    >
                                                        {subjects.map((subject) => <Option value={subject.value} label={"subjects"}>
                                                            <div className="demo-option-label-item">
                                                                {`${subject.value} (${subject.count})`}
                                                            </div>
                                                        </Option>)}
                                                    </Select>

                                                </span>
                                                <div className="tags">
                                                    <span className="">
                                                        <span>Tags: </span>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ minWidth: '150px' }}
                                                            placeholder="filter tags"
                                                            // defaultValue={''}
                                                            onChange={(e) => updateSelectedTags(e)}
                                                            optionLabelProp="tags"
                                                        >
                                                            {tags.map((tag) => <Option value={tag.value} label="tags">
                                                                <div className="demo-option-label-item">
                                                                    {`${tag.value} (${tag.count})`}
                                                                </div>
                                                            </Option>)}
                                                        </Select>

                                                    </span>
                                                </div>
                                                {/* <div className="sharing">
                                                                {tabs.map(tab => (
                                                                    <CheckableTag
                                                                        key={tab}
                                                                        checked={selectedTab.indexOf(tab) > -1}
                                                                        onChange={checked => handleChange(tab, checked)}
                                                                    >
                                                                        {tab}
                                                                    </CheckableTag>
                                                                ))}
                                                            </div> */}
                                            </Space>
                                        </div>

                                        {/* <div className="mt-8">

                                                        <Button
                                                            onClick={() => createQuestionLibrary()}
                                                        >Add New
                                                        </Button>
                                                        <Button
                                                            onClick={() => saveQuestion()}
                                                        >Add Question
                                                        </Button>
                                                    </div> */}
                                        <div className="w-full mt-16">

                                            {/* </Space> */}
                                            <div

                                            >
                                                <Space
                                                    // className="justify-center md:justify-around"
                                                    style={{
                                                        width: "100%",
                                                        paddingBottom: "80px"
                                                        // justifyContent: "space-between"
                                                    }}
                                                    wrap align="start" size={"large"} direction="horizontal">

                                                    {filteredLibraries
                                                        .sort((a, b) => {
                                                            try {
                                                                return b.updated_on.getTime() - a.updated_on.getTime()
                                                            }
                                                            catch (e) {
                                                                console.log(e)
                                                                return 0
                                                            }
                                                        })
                                                        .map((q, i) => (

                                                            <>

                                                                <div
                                                                    className="question-card transition-shadow shadow hover:shadow-2xl"
                                                                    style={
                                                                        {
                                                                            minWidth: "400px",
                                                                            // maxWidth: "350px",
                                                                            // height: "300px",
                                                                            // overflow: "clip",

                                                                        }}>
                                                                    {/* <Question> */}
                                                                    <Card
                                                                        size="default"
                                                                        // title={q.subject}
                                                                        // extra={}
                                                                        style={{ background: "#f8fafc" }}
                                                                        actions={!viewOnly ? [
                                                                            <Button onClick={() => history.push(`/library/edit/${q.id}`)} type="text" style={{ width: "100%" }}><EditOutlined /></Button>,
                                                                            <Button onClick={() => shareClicked(q)} type="text" style={{ width: "100%" }}><ShareAltOutlined /></Button>,
                                                                            <Button onClick={() => launchClicked(q)} type="text" style={{ width: "100%" }}><PlayCircleOutlined />Launch</Button>
                                                                        ] : []}
                                                                    >
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() => libraryClicked(q)}
                                                                        >
                                                                            <p className="text-xl mb-0">{toTitleCase(q.name)}</p>
                                                                            <p className="text-gray-600" style={{ minHeight: "1.5em" }}>{q.subject.toLowerCase() || ""}</p>
                                                                            <span className="text-gray-500 text-xs"><Space direction="horizontal"
                                                                                split={<Divider type="vertical" />}
                                                                            >
                                                                                <span>{q.questions?.length} questions</span>
                                                                                <span> {timeAgoDate(q.created_on)}</span>
                                                                                <Text
                                                                                    style={{ width: 100 }}
                                                                                    ellipsis={{ tooltip: q.created_by }}
                                                                                ><AutoFilUserName email={q.created_by} /></Text>
                                                                                {/* <span>{moment.duration(moment().diff(moment(new Date())))}</span> */}
                                                                            </Space>
                                                                            </span>
                                                                            <Space direction="horizontal"
                                                                                className="mt-2"
                                                                                style={{ minHeight: "1.5em" }}

                                                                                wrap>
                                                                                {q.tags && q.tags?.length > 0 ?
                                                                                    q.tags?.filter(tag => tag != "").map(tag => <Tag>{tag}</Tag>) :
                                                                                    !viewOnly ?
                                                                                        <span><Tag style={{
                                                                                            "background": "#fff",
                                                                                            "borderStyle": "dashed"
                                                                                        }}>
                                                                                            <PlusOutlined /> New Tag
                                                                                        </Tag></span>
                                                                                        : null
                                                                                }
                                                                            </Space>
                                                                        </div>
                                                                    </Card>

                                                                    {/* </Question> */}
                                                                </div>
                                                            </>
                                                            //     )}

                                                            // </Composition>
                                                        ))}

                                                </Space>
                                            </div>
                                        </div>

                                    </div>


                                }
                            </Content>
                        </Layout>
                    </>
                }
            </>
            }
        </>
    )
}

export default QuestionLibrary;