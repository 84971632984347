import { convertJsonToCSV } from "@/Components/Common/CSVConverter";
import Loader from "@/Components/Loader";
import { SessionStatsModal } from "@/Components/Session/AdminSession/Started/SessionStatsModal";
import {
  allActiveClassesArray,
  allSummaryStats,
  draftSessionsForClass,
  getClassById,
  getPrepareSessionForClass,
  getUserById,
  liveStatsForSession,
  pastSessionsForClass,
} from "@/Models/Selectors";
import store from "@/Models/store";
import {
  createPrepareSessionForClass,
  fetchClassSessions,
  updatePrepareSession,
} from "@/Services/Class";
import {
  CheckCircleTwoTone,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  PlayCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  message,
  Modal,
  notification,
  Progress,
  Result,
  Table,
  Tooltip,
} from "antd";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Constants from "@/Services/Constants";

import { Select } from "antd";
import { uuid4 } from "@sentry/utils";
import QuestionCardPreview from "@/Components/Question/QuestionPreview";
import Analytics from "@/Services/Analytics";
import { getDisplayEmail } from "@/Services/User";

const { Option } = Select;
const { Panel } = Collapse;

type Props = {
  _class: Class;
};

export const downloadSessionStats = (session: SessionState, _class: Class) => {
  console.log("download stats for ", session.name);
  const csvArray: {}[] = [];
  const live_stats = liveStatsForSession(store.getState())(session.sessionId);
  Analytics.track("Download Individual Session Stats", {
    ...session,
    ...live_stats,
  });

  live_stats.forEach((stat) => {
    var csvObj = {
      Name: stat.user?.full_name,
      Email: getDisplayEmail(stat.email),
      Responses: stat.responses,
      Correct: stat.correct,
      Dont_Know: stat.dontKnow,
      Incorrect: stat.incorrect,
    };
    csvArray.push(csvObj);
  });
  csvArray.push({});
  csvArray.push({
    Name: "Total Questions:",
    Email: session.questions.length,
    Responses: "Host:",
    Correct: getUserById(store.getState())(_class.primary_teacher).full_name,
    Dont_Know: "Date:",
    Incorrect: session.start_time,
  });
  const csvStats = convertJsonToCSV(csvArray);
  console.log(csvStats);
  downloadCSVFile(
    csvStats,
    "Vidya Session Stats - " + session.name + " - " + session.start_time
  );
};

const downloadCSVFile = (csvObj: any, filename: string) => {
  const blob = new Blob([csvObj], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const ClassSession = ({ _class }: Props) => {
  // const { sessions } = _class;
  const sessions: SessionState[] = useSelector(pastSessionsForClass)(_class.id);
  const preparedSessions: SessionState[] = useSelector(draftSessionsForClass)(
    _class.id
  );

  // const [sessions, setSessions] = useState<SessionState[]>(thisClass.sessions);
  const [showSessionStatsModal, setShowSessionStatsModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState<SessionState>();
  const hasFetchedData = useRef(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const currentClassId = useRef(_class.id);
  const _classes = useSelector(allActiveClassesArray);
  const allStats: { [id: string]: sessionStats } =
    useSelector(allSummaryStats)();
  const history = useHistory();
  const [copyFromSession, setCopyFromSession] = useState<SessionState>();
  const [copyToClassId, setCopyToClassId] = useState<string>(_class.id);
  const [showCopyModal, setShowCopyModal] = useState<boolean>(false);
  const [isSavingCopySession, setIsSavingCopySession] =
    useState<boolean>(false);

  const showStatsModal = (show: boolean) => {
    setShowSessionStatsModal(show);
    Analytics.track("View Session Stats Modal", selectedSession?.summary_stats);
  };

  // useEffect(() => {
  //     // if (!hasFetchedData.current) {
  //     hasFetchedData.current = false;
  //     setIsFetchingData(true);
  //     fetchClassSessions(_class).then(() => {
  //         // setSessions(sessions)
  //         hasFetchedData.current = true;
  //         currentClassId.current = _class.id
  //         setIsFetchingData(false);
  //         setCopyToClassId(_class.id);
  //     }).finally(() => {
  //     });
  //     // }
  // }, [_class]);

  useEffect(() => {
    if (
      (!hasFetchedData.current || currentClassId.current !== _class.id) &&
      !isFetchingData
    ) {
      fetchClassSessions(_class).then(() => {
        // setSessions(sessions)
        setIsFetchingData(false);
        hasFetchedData.current = true;
        currentClassId.current = _class.id;
      });
    }
  }, [sessions]);

  if (isFetchingData || currentClassId.current != _class.id) {
    // fetchClassSessions(_class).then((sessions) => {
    //     // setSessions(sessions)
    //     hasFetchedData.current = true;
    //     currentClassId.current = _class.id
    // });
    return <Loader />;
  }

  const headers: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => Date.parse(a.date) - Date.parse(b.date),
      fixed: "left",
    },
    {
      title: "Session Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      // fixed: 'left',
    },

    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      sorter: (a: any, b: any) => {
        const a_attendance = a.attendance_percent;
        const b_attendance = b.attendance_percent;
        return a_attendance - b_attendance;
      },
      // responsive: ['lg']
    },
    {
      title: "Engagement",
      dataIndex: "engagement",
      key: "engagement",
      sorter: (a: any, b: any) => {
        const a_engagement = a.engagement_percent;
        const b_engagement = b.engagement_percent;
        return a_engagement - b_engagement;
      },
    },
    {
      title: "",
      dataIndex: "download",
      key: "download",
    },
  ];

  const dataSource: any[] | undefined = [];

  const copyQuestions = (session: SessionState, classId: string) => {
    console.log("copying questions from " + session.name + " to " + classId);
  };

  sessions.forEach((session, index) => {
    const stats = allStats[session.sessionId];
    const link =
      session.state === "STARTED"
        ? "/session/" + session.sessionId
        : "/class/" + _class.id + "/session/" + session.sessionId + "/ended";
    const item = {
      index: index + 1,
      name: (
        <>
          <Link to={link}> {session.name} </Link>
          {session.state === "STARTED" ? (
            <span>
              <span className="text-xs text-gray-600">[LIVE]</span>
              <span className="animate-ping absolute inline-flex ml-1 h-1 w-1 rounded-full bg-blue-400 "></span>
            </span>
          ) : null}
        </>
      ),
      date: session.start_time.toDateString(),
      // date: (new Date()).toDateString(),
      attendance: (
        <>
          {" "}
          {session.state === "STARTED" ? (
            "-"
          ) : stats && stats.attendance_percent !== 0 ? (
            <div
              key={
                "attendance_" +
                session.sessionId +
                "_" +
                stats.attendance_percent
              }
              onClick={() => {
                if (
                  session.live_stats &&
                  session.questions &&
                  session.stats &&
                  session.joinees
                ) {
                  setSelectedSession(session);
                  if (!showSessionStatsModal) showStatsModal(true);
                }
              }}
              className="cursor-pointer text-center"
            >
              <Tooltip
                title={
                  "Class Attendance" +
                  (stats
                    ? " (" +
                      (stats.total_students - stats?.absent_students) +
                      "/" +
                      stats.total_students +
                      ")"
                    : "")
                }
              >
                <Progress
                  strokeColor="#7f8c8d"
                  percent={stats.attendance_percent}
                  size="small"
                />
              </Tooltip>
            </div>
          ) : !stats ? (
            <span className="text-gray-600 text-xs animate-pulse">
              calculating...
            </span>
          ) : (
            // <span className='text-gray-600 text-xs'>Insufficient data</span>
            <span className="text-gray-600 text-xs">Insufficient data</span>
          )}
        </>
      ),
      // tbd
      engagement: (
        <>
          {" "}
          {session.state === "STARTED" ? (
            "-"
          ) : stats && stats.attendance_percent !== 0 ? (
            <div
              key={
                "engagement_" +
                session.sessionId +
                "_" +
                stats.engagement_percent
              }
              onClick={() => {
                if (
                  session.live_stats &&
                  session.questions &&
                  session.stats &&
                  session.joinees
                ) {
                  setSelectedSession(session);
                  if (!showSessionStatsModal) showStatsModal(true);
                }
              }}
              className="cursor-pointer text-center"
            >
              <Tooltip title="Average Session Engagement">
                <Progress
                  type="circle"
                  strokeColor="#3498db"
                  percent={stats?.engagement_percent}
                  format={(percent) => <span className="">{percent}%</span>}
                  className="progrs"
                  width={33}
                />
              </Tooltip>
            </div>
          ) : !stats ? (
            <span className="text-gray-600 text-xs animate-pulse">
              calculating...
            </span>
          ) : (
            // <span className='text-gray-600 text-xs'>Insufficient data</span>
            <span className="text-gray-600 text-xs">Insufficient data</span>
          )}
        </>
      ),
      download: (
        <>
          <Button
            className="hover:opacity-100 opacity-50"
            onClick={() => downloadSessionStats(session, _class)}
            type="text"
          >
            <DownloadOutlined />
          </Button>
          {/* <Popconfirm
                    title={
                        <>
                            <Select
                                defaultValue={session.classId}
                                style={{ width: 220, zIndex: 10000000000000 }}
                                onChange={(classId) => copyQuestions(session, classId)}
                            >
                                {
                                    _classes.map(c =>
                                        <>
                                            <Option
                                                style={{ width: 220, zIndex: 10000000000000 }}
                                                key={c.id}
                                                value={c.id}>
                                                {c.description + " (" + c.name + ")"}
                                            </Option>
                                        </>)
                                }

                            </Select>
                        </>
                    }
                //   visible={visible}
                //   onConfirm={handleOk}
                //   okButtonProps={{ loading: confirmLoading }}
                //   onCancel={handleCancel}
                > */}
          <Tooltip title="Reuse questions">
            <Button
              className="hover:opacity-100 opacity-50"
              onClick={() => {
                setCopyFromSession(session);
                setShowCopyModal(true);
              }}
              type="text"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          {/* </Popconfirm> */}
        </>
      ),
      engagement_percent: stats?.engagement_percent || 0,
      attendance_percent: stats?.attendance_percent || 0,
    };
    dataSource.push(item);
    // if (!session.summary_stats || session.summary_stats.attendance_percent === 0) {
    //     console.log('insufficient data', session);
    // }
  });

  const preparedColumns = [
    {
      title: "Draft Session",
      dataIndex: "session",
      key: "session",
    },
    {
      title: "Questions",
      dataIndex: "questions",
      key: "questions",
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "updated",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const preparedSessionsDataSource: any[] | undefined = [];

  preparedSessions.map((session: SessionState) => {
    const item = {
      session: session.name,
      questions: session.draft_questions.length,
      updated: session.updated_at.toDateString(),
      action: (
        <>
          <Tooltip title="Start Session">
            <Button type="link" className="mr-2">
              <Link to={"/new-session/" + _class.id}>
                <PlayCircleTwoTone />
              </Link>
            </Button>
          </Tooltip>

          <Tooltip title="Edit Session">
            <Button type="link">
              <Link to={"/prepare-class/" + _class.id}>
                <EditOutlined />
              </Link>
            </Button>
          </Tooltip>
          <Tooltip title="Reuse questions">
            <Button
              type="link"
              className=""
              onClick={() => {
                setCopyFromSession(session);
                setShowCopyModal(true);
              }}
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    };
    preparedSessionsDataSource.push(item);
  });

  const prepareClassClicked = (_class: Class) => {
    const activeSessions = sessions.filter(
      (session: SessionState) => session.state === "STARTED"
    );
    if (activeSessions.length > 0) {
      message.warn(
        "Please end the active session (" +
          activeSessions[0].name +
          " | " +
          activeSessions[0].start_time.toDateString() +
          ") before preparing next session."
      );
      return;
    }

    if (_class.is_archived) {
      message.warn(
        <span>
          Cannot prepare for Archived class. Please go to{" "}
          <a href="configure">Configure </a> to Unarchive
        </span>
      );
      return;
    }
    history.push("/class/" + _class.id + "/prepare");
    // createEmptyPrepareSessionForClass(_class, _class.name).then(() => {
    //     history.push("/prepare-class/" + _class.id)
    // });
  };

  const shouldMergeToDraftedSession = async (
    _class: Class,
    draftQuestions: question[]
  ) => {
    if (_class.hasPreparedSession && copyToClassId) {
      const draftSession = draftSessionsForClass(store.getState())(_class.id);

      if (draftSession) {
        const finalQuestions = [
          ...draftSession[0].draft_questions,
          ...draftQuestions,
        ];
        const updatedSession: SessionState = {
          ...draftSession[0],
          draft_questions: finalQuestions,
        };
        return updatedSession;
      }
      return null;
    }
  };

  const handleOkCopyModal = () => {
    if (copyFromSession && copyToClassId) {
      setIsSavingCopySession(true);
      const _class = getClassById(store.getState())(copyToClassId);

      if (_class.state === "IN_SESSION") {
        const key = `open${Date.now()}`;
        notification.open({
          message: "Cannot copy",
          description: (
            <span>
              There is an ongoing session for this class. Please end the
              existing session to draft next session
            </span>
          ),
          icon: <WarningTwoTone twoToneColor="#eb2f96" />,
          key,
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                history.push("/session/" + _class.sessionId);
                notification.close(key);
              }}
            >
              Open Session
            </Button>
          ),
        });
        setIsSavingCopySession(false);
        return;
      }

      let preparedSession: SessionState | null = null;
      let questions;
      if (_class.hasPreparedSession) {
        preparedSession = getPrepareSessionForClass(store.getState())(
          _class.id
        );
        questions = [
          ...copyFromSession.questions,
          ...copyFromSession.draft_questions,
          ...(preparedSession?.draft_questions || []),
        ];
      } else {
        questions = [
          ...copyFromSession.questions,
          ...copyFromSession.draft_questions,
        ];
      }
      const finalQuestions: question[] = questions.map((q: question) => {
        return {
          ...q,
          id: uuid4(),
          state: "DRAFT",
          description: q.description,
        };
      });
      const updatedSession: SessionState = {
        ...copyFromSession,
        // copy session name if destination class has 0 questions or does not have a prepared session. Persist the destiantion class session name if it already has some questions.
        name: preparedSession
          ? preparedSession.draft_questions.length > 0
            ? preparedSession.name
            : copyFromSession.name
          : copyFromSession.name,
        sessionId: preparedSession?.sessionId || uuid4(),
        draft_questions: finalQuestions,
        classId: copyToClassId,
      };

      if (finalQuestions.length == 0) {
        setCopyFromSession(undefined);
        setShowCopyModal(false);
      }
      Analytics.track("Copy Draft Questions", {
        finalQuestions: finalQuestions.length,
      });
      createOrUpdateSession(updatedSession, _class)
        .then(() => {
          setShowCopyModal(false);
          const key = `open${Date.now()}`;
          notification.open({
            message:
              finalQuestions.length == 1
                ? finalQuestions.length + " Question drafted"
                : finalQuestions.length + " Questions drafted",
            description:
              (preparedSession?.sessionId
                ? "The existing draft updated for "
                : "A new session has been drafted for ") +
              _class.description +
              " (" +
              _class.name +
              ")",
            // <> <span>A new session has been drafted for </span>
            //     {/* <Link to={"/class/" + _class.id}> */}
            //     {_class.description + ' (' + _class.name + ')'}
            //     {/* </Link> */}
            //     <span>{'with ' + finalQuestions.length + ' questions.'}</span>
            // </>,
            btn: (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  history.push("/class/" + _class.id + "/prepare");
                  notification.close(key);
                }}
              >
                View Draft
              </Button>
            ),
            key,
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          setIsSavingCopySession(false);
        })
        .catch((e) => {
          notification.open({
            message: "Unable to copy",
            description:
              "Something went wrong. Please check there is no active sessions for this class",
            icon: <WarningTwoTone twoToneColor="#eb2f96" />,
          });
          setIsSavingCopySession(false);
        });
    }
  };

  const createOrUpdateSession = (
    updatedSession: SessionState,
    _class: Class
  ) => {
    if (_class.hasPreparedSession)
      return updatePrepareSession(updatedSession, _class);
    return createPrepareSessionForClass(updatedSession, _class);
  };

  const handleCancelCopyModal = () => {
    setCopyFromSession(undefined);
    setShowCopyModal(false);
  };

  return (
    <>
      <Modal
        title="Reuse Questions"
        visible={showCopyModal}
        okButtonProps={{ loading: isSavingCopySession }}
        okText={
          copyFromSession?.draft_questions.length == 0 &&
          copyFromSession?.questions.length == 0
            ? "Okay"
            : "Save"
        }
        onOk={handleOkCopyModal}
        onCancel={handleCancelCopyModal}
      >
        <div>
          {copyFromSession?.draft_questions.length == 0 &&
          copyFromSession?.questions.length == 0 ? (
            <span className="w-full ">
              There were no questions in this session.
            </span>
          ) : (
            <>
              <span className="w-full ">
                Copy
                {/* <Link target="_blank" to={"/class/" + copyFromSession?.classId + '/session/' + copyFromSession?.sessionId + '/review'}>  */}{" "}
                {(copyFromSession?.draft_questions?.length || 0) +
                  (copyFromSession?.questions?.length || 0)}{" "}
                questions to :{/* </Link> */}
              </span>
              <Select
                className="float-right"
                defaultValue={copyToClassId}
                style={{ width: 250, zIndex: 10000000000000 }}
                onChange={(classId) => setCopyToClassId(classId)}
              >
                {_classes
                  .filter((c) => c.canStartSession)
                  .map((c) => (
                    <>
                      <Option
                        style={{ width: 220, zIndex: 10000000000000 }}
                        key={c.id}
                        disabled={false}
                        value={c.id}
                      >
                        {c.description + " (" + c.name + ")"}
                      </Option>
                    </>
                  ))}
              </Select>

              {/* <Divider /> */}

              <div className="mt-10">
                <Collapse
                  defaultActiveKey={["0"]}
                  ghost
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Panel className="re" header="Preview Questions" key="1">
                    <div style={{ maxHeight: 400, overflow: "scroll" }}>
                      {
                        /* {() => {
                                let questions;
                                if (!copyFromSession) return null;
                                if (_class.hasPreparedSession) {
                                    const preparedSession = getPrepareSessionForClass(store.getState())(_class.id);
                                    questions = [...copyFromSession.questions, ...copyFromSession.draft_questions, ...preparedSession?.draft_questions || []]
                                } else {

                                    questions = [...copyFromSession.questions, ...copyFromSession.draft_questions]

                                } */

                        copyFromSession
                          ? [
                              ...copyFromSession.questions,
                              ...copyFromSession.draft_questions,
                            ].map((q: question, i: number) => {
                              return (
                                <div className="mt-4 mb-4">
                                  <QuestionCardPreview
                                    cardTitle={"Question " + (i + 1)}
                                    // onCardClick={() => { }}
                                    showDelete={false}
                                    showEdit={false}
                                    onEditClicked={() => {}}
                                    onDeleteClicked={() => {}}
                                    question={q}
                                  />
                                </div>
                              );
                            })
                          : null
                      }
                    </div>
                  </Panel>
                </Collapse>
                <div
                  style={{
                    bottom: "55px",
                    position: "absolute",
                  }}
                  className="text-xs text-gray-500"
                >
                  Note, you can choose later which questions to use during the
                  session
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      {/* {sessions.length > 0 || preparedSessions.length > 0 ? */}

      {sessions.length === 0 && preparedSessions.length === 0 ? (
        <Result
          status="404"
          title="No Sessions"
          subTitle="You have not conducted any session yet."
          extra={
            Constants.enable_prepare_class ? (
              // <Link to={`/class/${_class.id}/prepare`}>Prepare Session </Link>
              <Button
                type="primary"
                className="btn ant-btn ant-btn-primary"
                onClick={() => prepareClassClicked(_class)}
              >
                Prepare Session
              </Button>
            ) : null
          }
        />
      ) : (
        <>
          {selectedSession ? (
            <SessionStatsModal
              open={showSessionStatsModal}
              onClose={() => setShowSessionStatsModal(false)}
              session={selectedSession}
            />
          ) : null}

          <div>
            {Constants.enable_prepare_class && preparedSessions.length == 0 ? (
              <Button
                type="primary"
                className={`${
                  !_class.is_archived ? "ant-btn-primary" : ""
                } float-right mb-4`}
                onClick={() => prepareClassClicked(_class)}
              >
                Prepare Session
              </Button>
            ) : // <div className='mb-4'>
            //     <Table
            //         scroll={{ x: true }}
            //         pagination={{ pageSize: 10, hideOnSinglePage: true }}
            //         dataSource={preparedSessionsDataSource}
            //         showHeader={true}
            //         columns={preparedColumns} />
            // </div>
            null}
          </div>
          {sessions.length > 0 ? (
            <Table
              scroll={{ x: true }}
              pagination={{ pageSize: 10, hideOnSinglePage: true }}
              dataSource={dataSource}
              columns={headers}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ClassSession;
