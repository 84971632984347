import {
  allClasses,
  allSessions,
  getSessionById,
  sessionsForClass,
} from "@/Models/Selectors";
import { setCurrentSession, setCurrentSessionObject } from "@/Services/Session";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Loader from "../Loader";
import AdminSession from "./AdminSession";
import StudentSession from "./StudentSession";
import { currentSession as currentSessionSelector } from "@/Models/Selectors";
import store from "@/Models/store";
import {
  fetchAllClasses,
  fetchClassSessions,
  getSessionObj,
} from "@/Services/Class";
import { useVisibilityChange } from "use-visibility-change";
import { message } from "antd";
import {
  getDocumentMultipleCondition,
  getSingleDocument,
} from "@/Services/Firebase";
import Actions from "@/Models/Actions";

interface RouteParams {
  sessionId: string;
  classId: string;
  action: SessionRouteActions;
}

interface AdminSessionComponent extends RouteComponentProps<RouteParams> {}

const Session: React.FC<AdminSessionComponent> = (props) => {
  const sessionId = props.match.params.sessionId;
  const action = props.match.params.action;
  const classId = props.match.params.classId;
  const currentSession = useSelector(getSessionById)(sessionId);
  const registeredCurrentSession = currentSessionSelector(store.getState());
  const listOfallClasses = useSelector(allClasses);
  const allSession = useSelector(allSessions);
  const [isLoading, setIsLoading] = useState(false);

  console.log("All Sessions", allSession);

  const hidden = () => {
    console.log("page hidden");
  };

  const showUpdateMessage = () => {
    message
      .loading("Refreshing", 2.5)
      .then(() => message.success("Loading finished", 2.5));
  };

  const shown = () => {
    const _thisClass = listOfallClasses[currentSession.classId];
    console.log("page visible");
    const msg = message;
    msg.loading("Refreshing", 2.5);
    fetchAllClasses().then(() => {
      msg.destroy();
      message.success("Refreshed!", 1);
    });
  };

  const result = useVisibilityChange({ onHide: hidden, onShow: shown });

  useEffect(() => {
    document.getElementById("Smallchat")?.classList.add("hidden");
    if (
      currentSession &&
      currentSession.state !== "ENDED" &&
      (registeredCurrentSession === undefined ||
        registeredCurrentSession?.sessionId !== sessionId)
    ) {
      setCurrentSessionObject(currentSession);
      //setCurrentSession(sessionId);
    }
  }, []);

  useEffect(() => {
    if (Object.getOwnPropertyNames(allSession).length == 0) {
      setIsLoading(true);
      fetchAllClasses().then((res) => {
        let class_ids: any[] = [];
        let classes: any[] = [];
        res.forEach((cls: any) => {
          class_ids.push(cls.id);
          classes[cls.id] = cls;
        });

        console.log("Class ids", class_ids);
        getDocumentMultipleCondition("sessions", [
          {
            field: "class_id",
            operator: "in",
            value: class_ids,
          },
        ]).then((session_data) => {
          let currenSessionId;

          if (session_data) {
            session_data.forEach((s: any) => {
              const sessionState = getSessionObj(classes[s.class_id], s);
              setCurrentSessionObject(sessionState);
              store.dispatch({
                type: Actions.SESSIONS.ADD,
                payload: sessionState,
              });

              if (s.id == sessionId && s.status == "STARTED") {
                currenSessionId = s.id;
              }
            });

            if (currenSessionId) {
              store.dispatch({
                type: Actions.SESSIONS.CURRENT_SESSION.SET,
                payload: currenSessionId,
              });
              setIsLoading(false);
            }
          }
        });
      });
    }
  }, [allSession, currentSession]);

  // if (!currentSession) {
  if (Object.keys(listOfallClasses).length === 0 && !currentSession) {
    return <Loader />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (Object.keys(listOfallClasses).length > 0 && !currentSession) {
    if (classId) {
      alert(`Session not found ??? ${classId}`);
      const _thisClass = listOfallClasses[classId];
      if (_thisClass) fetchClassSessions(_thisClass);
      return <Loader />;
    } else {
      alert("Session not found...");
      return <Redirect to="/" />;
    }
  }
  //     alert('Session not active');

  // }
  if (currentSession.state === "ENDED" && !["review", "ended"].includes(action))
    return <Redirect to={`/session/${currentSession.sessionId}/ended`} />;
  // if (currentSession.state === "ENDED" && action !== "review")
  //     return <Redirect to='/' />
  const { type } = currentSession;
  if (currentSession.state === "ENDED" && type === "STUDENT") {
    return <Redirect to="/" />;
  }

  return (
    <div className="session">
      <ul className="moving-background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="relative text-white">
        {type === "ADMIN" ? (
          <AdminSession action={action} session={currentSession} />
        ) : null}
        {type === "STUDENT" ? (
          <StudentSession session={currentSession} />
        ) : null}
      </div>
    </div>
  );
};

export default Session;
