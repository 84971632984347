import React, { useEffect, useRef } from "react";
import Question from "@/Components/Question";
import { Composition } from "atomic-layout";
import { fetchAllClasses } from "@/Services/Class";
import { Link } from "react-router-dom";
import AppLogo from "../AppHeader/AppLogo";
import { Result, Typography } from "antd";
import MovingBackground from "../MovingBackground";
import { useSelector } from "react-redux";
import { currentUser, getClassById } from "@/Models/Selectors";
import BusinessLogo from "../AppHeader/BusinessLogo";
import { isEmbed, isRTL, sessionTheme } from "@/Services/Constants";
import {
  RocketOutlined,
  SmileOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  askQuestionEventHandler,
  handleEndSessionEvent,
  startEndSessionEventHandler,
  unsubscribeHandler,
} from "@/Services/Firebase/Subscriber";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import { DocumentData } from "firebase/firestore";
import { getUserRole } from "@/Services/User";

const { Paragraph, Text } = Typography;

const StudentSession = ({ session }: { session: SessionState }) => {
  const { currentQuestion } = session;
  const hasFetchedData = useRef(false);
  const loggedInUser: User = useSelector(currentUser);
  const _class = useSelector(getClassById)(session.classId);

  useEffect(() => {
    console.log("Student session loaded for the first time");
    if (!hasFetchedData.current) {
      console.log("HERE", currentQuestion, session);
      // add first time load logic here
      if (!currentQuestion) {
        fetchAllClasses();
        hasFetchedData.current = true;
      }
    }
  }, []);

  useEffect(() => {
    let subscribers: any[] = [];

    if (_class.id && getUserRole() == "STUDENT") {
      startEndSessionEventHandler(_class.id, "ENDED").then((sub) => {
        subscribers.push(sub);
      });

      askQuestionEventHandler(session.sessionId, _class.id).then((sub) => {
        subscribers.push(sub);
      });
    }

    return () => {
      unsubscribeHandler(subscribers);
    };
  }, [_class]);

  if (!currentQuestion) {
    return (
      <div className="align-middle flex h-screen justify-center">
        {/* <p className="my-auto">No question yet!</p>
        <p>Your host will soon ask a question!</p> */}
        <div className="my-auto mt-24">
          <Result
            icon={<RocketOutlined />}
            title={
              <span
                className={sessionTheme == "dark" ? "text-white" : "text-black"}
              >
                Get ready!
              </span>
            }
            subTitle={
              <span
                className={sessionTheme == "dark" ? "text-white" : "text-black"}
              >
                Your host will soon ask a question...
              </span>
            }
            extra={null}
          />
        </div>
      </div>
    );
  }
  const { name } = session;
  return (
    <Composition templateRows="auto auto">
      <div
        dir={_class && isRTL(_class.settings.language) ? "rtl" : "ltr"}
        style={{ width: "100%" }}
      >
        {/* <MovingBackground /> */}
        <Composition
          templateCols={isEmbed ? "1fr" : "repeat(2, 1fr)"}
          templateColsMd="repeat(3, 1fr)"
          alignItems="center"
          alignContent="center"
          placeContent="center"
          areas={isEmbed ? `SessionName` : `blank SessionName`}
          areaSm={`barChart SessionName`}
          areaMd={`barChart SessionName`}
          style={{
            margin: "0 0 1em 0",
            backgroundColor: "#2d3748",
            color: "#FFF",
            position: "fixed",
            top: "0px",
            zIndex: 1000,
            minHeight: "68px",
            paddingLeft: "0.75em",
            paddingRight: "0.75em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            width: "100%",
          }}
        >
          {({ SessionName, Blank }) => (
            <>
              <Blank>
                <span>
                  <Link to="/">
                    {loggedInUser &&
                    loggedInUser.primary_institute &&
                    loggedInUser.primary_institute?.feature_flags
                      ?.whitelabel ? (
                      <>
                        <div style={{ marginTop: "-24px" }}>
                          <BusinessLogo
                            imgUrl={loggedInUser.primary_institute.img_url}
                          />
                        </div>
                      </>
                    ) : (
                      <AppLogo mode="dark" redirectHome />
                    )}
                  </Link>
                </span>
              </Blank>
              <SessionName>
                <div
                  className={"text-2xl lg:w-full md:w-1/2 sessionTitle".concat(
                    isEmbed ? "ml-auto mr-auto" : ""
                  )}
                >
                  <Text
                    style={{ color: "#fff" }}
                    ellipsis={{ tooltip: session.name }}
                  >
                    {session.name}
                  </Text>
                </div>
              </SessionName>
            </>
          )}
        </Composition>

        <Question
          session={session}
          question={currentQuestion}
          role="STUDENT"
          showNewQuestionButton={false}
        />
      </div>
    </Composition>
  );
};

export default StudentSession;
