import Actions from "../Actions";

export type usersState = {
  users: {
    [email: string]: User;
  };
  currentUser: User | undefined;
};

const defaultState: usersState = {
  users: {},
  currentUser: undefined,
};

export const userReducer = (state = defaultState, action: reduxAction) => {
  switch (action.type) {
    case Actions.USERS.ADD: {
      let user = action.payload;
      user.full_name = user.first_name + " " + user.last_name;
      const { email } = user;
      return {
        ...state,
        users: {
          ...state.users,
          [email]: user,
        },
      };
    }
    case Actions.USERS.CURRENT_USER.SET:
      // if (state.users.indexOf(user) < 0) {
      //     return state;
      // }
      return {
        ...state,
        currentUser: action.payload,
      };
    case Actions.USERS.ADD_BULK_USERS: {
      let users: {
        [email: string]: User;
      } = {};
      action.payload
        .map((user: User) => ({
          ...user,
          full_name: user.first_name + " " + user.last_name,
        }))
        .forEach((user: User) => {
          if (user.email !== state.currentUser?.email) users[user.email] = user;
        });
      // .map((user: User) => ({ [user.email]: user }))
      // const userMap = new Map(allUsers.map((user: User) => [user.email, user]));

      return {
        ...state,
        users: {
          ...state.users,
          ...users,
        },
      };
    }
    case Actions.USERS.DELETE: {
      // const { User }: { email: User }  = action.payload;
      // const { email } = User;

      // delete state.users[email];
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
