import React from 'react';
import { Composition } from 'atomic-layout';
import { Button, Input } from 'antd';
import { endCurrentSession, startCurrentSession, updateCurrentSessionName } from '@/Services/Session';
import wallImage from '@/Assets/adminSessionWall.png';

const headerDesktop = `
    start student
`;

const HeaderTablet = `
    start
    student
`

const Header = ({ joinees }: { joinees: number }) =>
    <Composition
        areas={HeaderTablet}
        areasMd={headerDesktop}
        alignItems="center"
        gap='20px'
    >
        {Areas => (
            <>
                <Areas.Start><h1 className='font-normal text-3xl'>Starting new session...</h1></Areas.Start>
                <Areas.Student>
                    <div className="">
                        <div className="bg-red-400 grid h-10 w-10 mx-auto p-2 place-content-center rounded-full text-white ">
                            <span>{joinees}</span>
                        </div>
                        <span className='text-gray-500'>students</span>
                    </div>
                </Areas.Student>
            </>
        )}
    </Composition>

const ClassCode = ({ code }: { code: number }) => {
    return <div>
        <div className='text-2xl'>Your class code is</div>
        <div className='underline tracking-widest'>{code}</div>
    </div>
}

const InvitationLink = ({ link }: { link: string }) => {
    return <div>
        <p className='font-light text-gray-500'>Ask your students to visit <a href={link}><strong className='text-black'>{link}</strong></a> and enter the class code</p>
        <Button type='ghost' size='small'>Copy Invitation link</Button>
    </div>
}

const areasControls = `
    description description description
    cancel input start
`

const areasControlsMd = `
    description description
    input input
    cancel start
`
const SessionControls = ({ sessionName }: { sessionName: string }) => {
    return <Composition
        areas={areasControlsMd}
        areasMd={areasControls}
        paddingTop='15px'
        gapCol='50px'
        gapRow='15px'
    >
        {({ Input: InputGrid, Cancel: CancelGrid, Start: StartGrid, Description }) => <>
            <Description>
                <span>I am going to teach</span>
            </Description>
            <InputGrid className='w-64'>
                <Input
                    className="m-2 border-2"
                    type='text'
                    defaultValue={sessionName}
                    onChange={e => updateCurrentSessionName(e.currentTarget.value)}
                />
            </InputGrid>
            <CancelGrid>
                <Button type="ghost" onClick={endCurrentSession}>Cancel</Button>
            </CancelGrid>
            <StartGrid>
                <Button type="primary" onClick={startCurrentSession}>Start</Button>
            </StartGrid>

        </>}
    </Composition>
}


const createdSessionAreas = `
            HeroImage
            Header
            ClassCode
            InvitationLink
            SessionControls
`


const AdminSessionCreated = ({ session }: { session: SessionState }) => {
    const { joinees } = session;
    return <Composition
        areas={createdSessionAreas}
        alignItems='center'
        justifyItems='center'
        gap='0px'
        paddingBottom='150px'
    >
        {({ HeroImage, Header: HeaderGrid, ClassCode: CodeGrid, InvitationLink: LinkGrid, SessionControls: SessionGrid }) => (
            <>
                {/* <HeroImage><img src={wallImage} alt="students sitting together" /></HeroImage> */}
                <HeaderGrid><Header joinees={joinees.length} /></HeaderGrid>
                <CodeGrid><ClassCode code={1234} /></CodeGrid>
                <LinkGrid><InvitationLink link={'https://pausch.in/'} /></LinkGrid>
                <SessionGrid>
                    <SessionControls sessionName={session.name} />
                </SessionGrid>
            </>
        )}
    </Composition>

}

export default AdminSessionCreated;
