import { GoogleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, FormInstance, Input, PageHeader, Select, Tag } from "antd"
import { Composition } from "atomic-layout";

import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import { useEffect, useState } from "react";
import { notionRenderBaseUrl, notionSopPath } from "@/Services/Constants";
import Loader from "../Loader";
import { Link, RouteComponentProps } from "react-router-dom";



const HelpSetting = ({ pageId }: { pageId?: string }) => {

    const [notionBlock, setNotionBlock] = useState();
    const baseUrl = notionRenderBaseUrl
    const [notionUrlPath, setNotionUrlPath] = useState<string>(pageId || notionSopPath);

    const fetchNotionBlock = (page: string) => {
        fetch(baseUrl + page)
            .then(res => res.json())
            .then(response => {
                setNotionBlock(response);
            });

    }

    useEffect(() => {
        fetchNotionBlock(notionUrlPath)
    }, [])

    useEffect(() => {
        if (pageId != notionUrlPath) {
            const page = pageId ? pageId : notionSopPath;
            setNotionUrlPath(page);
            fetchNotionBlock(page);
        }
    })

    return <div>



        <Composition
            justify="start"
            className="lg:w-6/12 md:w-full"
        >
            {pageId ?
                <div className="float-left pb-4 text-left">
                    <Link to="/settings/help" > ← Back</Link>
                </div>
                : null}
            <div style={{ maxWidth: 768 }}>
                {
                    !notionBlock ?
                        <Loader />
                        :
                        <NotionRenderer mapPageUrl={(pageId: string) => '/settings/help/' + pageId} blockMap={notionBlock} />
                }
            </div>
        </Composition>
    </div>
}

export default HelpSetting;