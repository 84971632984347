import Actions from "../Actions";

const defaultState: StatsReduxState = {
    allStats: {}
}

const statsReducer = (state = defaultState, action: reduxAction): StatsReduxState => {

    switch (action.type) {
        case Actions.STATS.ADD: {
            const { stats } = action.payload;
            const id = stats.sessionId + "_" + stats.email;
            return {
                ...state,
                allStats: {
                    ...state.allStats,
                    [id]: stats
                }
            }
        }

        case Actions.STATS.UPDATE: {
            const { stats } = action.payload;
            const id = stats.sessionId + "_" + stats.email;
            // const oldClass = state.allClasses[id];
            return {
                ...state,
                allStats: {
                    ...state.allStats,
                    [id]: stats
                }
            }
        }

        case Actions.STATS.DELETE: {
            const { stats } = action.payload;
            const id = stats.sessionId + "_" + stats.email;

            delete state.allStats[id];
            return {
                ...state
            };
        }
    }
    return state;
}

export default statsReducer;