import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Composition } from "atomic-layout";
import {
  Breadcrumb,
  Button,
  Divider,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  PageHeader,
  Result,
  Select,
  Space,
  Tabs,
  DatePicker,
  Card,
  Statistic,
  Row,
  Col,
  Progress,
  Table,
  Tooltip,
  InputNumber,
  Dropdown,
  Form,
  Tag,
  Avatar,
} from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import {
  authStatus,
  classesForNewSession,
  cumilativeStatsForClass,
  cumilativeStatsForStudents,
  currentSession,
  currentUser,
  getSessionById,
  getUserById,
  isFetchingClasses,
  myClasses,
  pastSessionsForClass,
  pastSessionsForClassFromDate,
} from "@/Models/Selectors";
import {
  fetchAllClasses,
  fetchClassSessions,
  startSessionForClass,
} from "@/Services/Class";
import InviteModal from "@/Components/Class/inviteModal";
import {
  Link,
  Redirect,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CreditCardOutlined,
  DownloadOutlined,
  DownOutlined,
  FundProjectionScreenOutlined,
  GoogleOutlined,
  HeartOutlined,
  LaptopOutlined,
  LikeOutlined,
  LockOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  StarOutlined,
  TeamOutlined,
  UngroupOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Engagement from "@/Icons/engagement.svg";
import * as Sentry from "@sentry/react";

import store from "@/Models/store";
import Loader from "@/Components/Loader";

import correct_icon from "@/Assets/correct.png";
import incorrect_icon from "@/Assets/refresh.png";
import absent_icon from "@/Assets/question_mark.png";
import engagement_icon from "@/Assets/exclamation.png";
import { getCurrentPath as currentPathSelector } from "@/Models/Selectors";

import moment from "moment";
// import { DualAxes, TinyArea } from '@ant-design/charts';
import {
  LabelList,
  Bar,
  ComposedChart,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartToolTip,
  ResponsiveContainer,
  Legend,
} from "recharts";

import * as Constants from "@/Services/Constants";
import UserLabel from "../Common/Image/UserLabel";
import { stat } from "fs";
import { convertJsonToCSV, downloadCSVFile } from "../Common/CSVConverter";
import { valueType } from "antd/lib/statistic/utils";
import { signUp, updateUser } from "@/Services/User";
import { toLower, toUpper, upperFirst } from "lodash";
import TeacherSetting from "./TeacherSetting";
import StudentSetting from "./StudentSettings";
import NotificationsSetting from "./NotificationsSetting";
import BillingSetting from "./BilingSetting";
import HelpSetting from "./HelpSetting";
import { logout } from "@/Services/Auth";
import OrganizationSetting from "./OrganizationSetting";
import Analytics from "@/Services/Analytics";

const { RangePicker } = DatePicker;
const DefaultDateDifference = 7; // 7 days period as default stats date range

const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
  option: string;
  pageId: string;
}
const { TabPane } = Tabs;

interface StatsComponent extends RouteComponentProps<RouteParams> {}

const ClassStats: React.FC<StatsComponent> = (props) => {
  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    img_url: "",
  };

  const history = useHistory();
  const hasFetchedData = useRef(false);
  const _user: User = useSelector(currentUser);
  const [userData, setUserData] = useState<User>(_user);
  const [img_url, setImg_url] = useState("");
  const [form] = Form.useForm();
  const status = useSelector(authStatus);
  const settingPage = props.match.params.option;
  const pageId = props.match.params.pageId;

  Analytics.track("Open Settings", { settingPage });

  const UserSettingsMenuItems = [
    {
      title: "Profile",
      icon: <UserOutlined />,
      emoji: "🙂",
      disabled: false,
      disabledToolTip: "",
      access: ["teacher", "student"],
    },
    // {
    //     title: 'Notifications',
    //     icon: <NotificationOutlined />,
    //     emoji: "📣",
    //     disabled: false,
    //     disabledToolTip: 'This feature is not enabled for your account yet',
    //     access: ['teacher', 'student']
    // },
    {
      title: "Subscription",
      icon: <CreditCardOutlined />,
      emoji: "💳",
      disabled: false,
      disabledToolTip: "You are on extended trial",
      access: ["teacher"],
    },
    {
      title: "Organization",
      icon: <ApartmentOutlined />,
      emoji: "📣",
      disabled: !_user.primary_institute ? true : false,
      disabledToolTip: "This feature is not enabled for your account yet",
      access: ["teacher"],
    },
    {
      title: "Help",
      icon: <QuestionCircleOutlined />,
      emoji: "📣",
      disabled: false,
      disabledToolTip: "Get help on all features",
      access: ["teacher"],
    },
    {
      title: "Integrations",
      icon: <ApiOutlined />,
      emoji: "📣",
      disabled: true,
      disabledToolTip: "Your account does not support Integrations",
      access: ["teacher"],
    },
  ];
  const selectedMenuIndex = settingPage
    ? UserSettingsMenuItems.findIndex(
        (s) => s.title.toLowerCase() === settingPage.toLowerCase()
      )
    : 0;

  if (
    !settingPage ||
    UserSettingsMenuItems.filter(
      (s) => s.title.toUpperCase() == settingPage.toUpperCase()
    ).length === 0
  ) {
    return <Redirect to={"/settings/profile"} />;
  }

  const onFinish = (
    _user: User,
    success: () => void,
    failed: (error: string) => void
  ) => {
    updateUser(_user)
      .then(() => {
        // const { data } = response;
        // if (data.Code === 1) {
        //     alert('Something went wrong. Please refresh and try again');
        //     return;
        // }
        console.log("Onboarding complete");
        const nextUrl = "/";
        success();
        // history.push(nextUrl)
        // setCurrentStep(currentStep < 2 ? currentStep + 1 : currentStep)
        Sentry.captureMessage("User settings updated");
      })
      .catch((error) => {
        console.error(error);
        // Sentry.captureMessage("Sign up, failed");
        // setError(error.message);
        alert(error.message);
        failed(error.message);
      })
      .finally(() => {
        // callback();
      });
    // onSubmit({ ...values, img_url });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Signup Error:", errorInfo);
  };

  const onCancel = () => {
    console.log("cancel pressed");
    history.push("/login");
  };

  return (
    <Layout hasSider>
      {!_user || !_user.role ? (
        <Loader />
      ) : (
        <>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 64,
              bottom: 0,
            }}
            theme="light"
          >
            <Menu
              style={{ marginTop: 20 }}
              theme="light"
              mode="inline"
              defaultSelectedKeys={[toLower(settingPage)]}
            >
              {UserSettingsMenuItems.filter((setting) =>
                setting.access.includes(toLower(_user.role))
              ).map((setting) => {
                return (
                  <Menu.Item
                    disabled={setting.disabled}
                    key={toLower(setting.title)}
                    icon={setting.icon}
                  >
                    {setting.disabled ? (
                      <>
                        <Tooltip
                          placement="right"
                          title={setting.disabledToolTip}
                        >
                          {setting.disabled ? (
                            setting.title
                          ) : (
                            <Link
                              to={"/settings/" + setting.title.toLowerCase()}
                            >
                              {setting.title}
                            </Link>
                          )}
                        </Tooltip>{" "}
                        <LockOutlined />{" "}
                      </>
                    ) : (
                      <Link to={"/settings/" + setting.title.toLowerCase()}>
                        {" "}
                        {setting.title}{" "}
                      </Link>
                    )}
                  </Menu.Item>
                );
              })}

              <Menu.Item key="6" onClick={logout} icon={<LogoutOutlined />}>
                Logout
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout
            className="site-layout"
            style={{
              marginLeft: 200,
              minHeight: "calc(100vh - 123px)",
            }}
          >
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div
                className="site-layout-background"
                style={{
                  padding: 24,
                  textAlign: "center",
                  minHeight: "100%",
                }}
              >
                <PageHeader
                  className="site-page-header"
                  avatar={
                    settingPage == "profile" || settingPage == "organization"
                      ? {
                          style: {
                            border: "1px solid rgb(219, 219, 219)",
                            borderStyle: "solid",
                          },
                          src:
                            settingPage == "organization"
                              ? _user.primary_institute?.img_url
                              : settingPage == "profile"
                              ? _user.img_url
                              : undefined,
                          size: 64,
                        }
                      : undefined
                  }
                  // avatar={"_user.primary_institute?.img_url !== undefined ? _user.primary_institute?.img_url : undefined"}
                  title={
                    settingPage == "organization"
                      ? _user.primary_institute?.name
                      : settingPage == "profile"
                      ? _user.full_name
                      : upperFirst(settingPage)
                  }
                />
                <Divider />
                {settingPage == "profile" ? (
                  _user.role == "STUDENT" ? (
                    <TeacherSetting
                      userData={_user}
                      form={form}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    />
                  ) : (
                    <TeacherSetting
                      userData={_user}
                      form={form}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    />
                  )
                ) : null}
                {settingPage == "notifications" ? (
                  <TeacherSetting
                    userData={_user}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  />
                ) : null}
                {settingPage == "organization" ? (
                  <OrganizationSetting
                    userData={_user}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  />
                ) : null}

                {settingPage == "subscription" ? (
                  <BillingSetting
                    userData={_user}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  />
                ) : null}

                {settingPage == "help" ? <HelpSetting pageId={pageId} /> : null}
              </div>
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default ClassStats;
