import { Composition } from "atomic-layout";
import React, { useCallback, useEffect, useState } from "react";
import adminWallImg from "@/Assets/adminSessionWall.png";
import { Input, Button, Modal, notification } from "antd";
import { fetchAllClasses, joinClass } from "@/Services/Class";
import InviteSuccessModal from "@/Components/Class/inviteSuccessModal";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import { currentUser, getClassById } from "@/Models/Selectors";
import store from "@/Models/store";
import Analytics from "@/Services/Analytics";

interface RouteParams {
  classId: string;
  inviteCode: string;
}

interface JoinClassComponent extends RouteComponentProps<RouteParams> {}

const JoinClass: React.FC<JoinClassComponent> = (props) => {
  const _user: User = useSelector(currentUser);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [code, setCode] = useState("");
  const [inviteClass, setInviteClass] = useState<Class>();
  const [modalTitle, setModalTitle] = useState<String>("Invitation Accepted");
  const history = useHistory();
  const classId = props.match.params.classId;
  const inviteCode = props.match.params.inviteCode;
  const [saving, setSaving] = useState<boolean>(false);

  const shouldJoin = (callback: any) => {
    if (!classId) return callback(true);
    fetchAllClasses().finally(() => {
      const _class = getClassById(store.getState())(classId);
      // teacher shoudl not be able to join their class.
      const amITeacher = _class && _class.primary_teacher === _user.email;
      callback(!amITeacher);
    });
  };

  const onJoin = useCallback(
    (code: string) => {
      shouldJoin((shouldJoin: any) => {
        setSaving(true);
        console.log("Should join - ", shouldJoin);
        if (!shouldJoin) {
          const _class = getClassById(store.getState())(classId);
          if (_class.sessionId && _class.state === "IN_SESSION") {
            Analytics.track("Joning Class", {
              state: "Success",
              classId: classId,
              code: code,
              existing_session: true,
            });
            history.push("/session/" + _class.sessionId);
          }
          Analytics.track("Joning Class", {
            state: "Already Joined",
            classId: classId,
            code: code,
          });
          setShowSuccessModal(true);
          const modalTitle = "Already Accepted";
          setModalTitle(modalTitle);
          setSaving(false);
          return;
        }
        joinClass(code)
          .then((_class) => {
            if (!_class) {
              setShowSuccessModal(true);
              return;
            }
            const classId = _class.id;
            console.log("Successful joining class");
            Analytics.track("Joning Class", {
              state: "Success",
              classId: classId,
              code: code,
            });
            fetchAllClasses().finally(() => {
              const _class = getClassById(store.getState())(classId);
              setSaving(false);
              if (_class) {
                if (_class.sessionId && _class.state === "IN_SESSION") {
                  Analytics.track("Joning Class", {
                    state: "Success",
                    classId: classId,
                    code: code,
                    existing_session: true,
                  });
                  history.push("/session/" + _class.sessionId);
                }
                const modalTitle =
                  "Invitation Accepted " +
                  " - " +
                  _class?.name +
                  " (" +
                  _class?.description +
                  ")";
                setModalTitle(modalTitle);
                setInviteClass(_class);
              }
              setShowSuccessModal(true);
            });
          })
          .catch((e) => {
            const message =
              e.response?.data?.message ||
              "Invalid invitation. Please double check and enter the valid class code/link";
            Analytics.track("Joning Class", {
              state: "Failed",
              classId: classId,
              code: code,
            });
            console.error("Invalid class code/link ", code);
            notification.error({
              key: "join-error",
              message:
                message + ". For any help, reach out to support@vidya.us.",
            });
            setCode("");
            history.push("/join-class");
            setSaving(false);
          });
      });
    },
    [history]
  );
  useEffect(() => {
    Analytics.track("Joning Class", props.match.params);
  }, []);

  useEffect(() => {
    console.log("use effect triggered in joinclass");
    console.log("invite code in URL is ", inviteCode);
    console.log("text code in URL is ", code);
    if (inviteCode && inviteCode !== code) {
      setCode(inviteCode);
      onJoin(inviteCode);
    }
  }, []);

  const onProceed = useCallback(
    (code: string) => {
      let joinCode = code.trim();
      try {
        joinCode = code.split("/")[3] ? code.split("/")[3] : code;
      } catch (e) {
        console.log("failed in parsing class code - ", e);
      } finally {
        onJoin(joinCode);
      }
    },
    [onJoin]
  );

  const onInviteModalClose = useCallback(() => {
    setShowSuccessModal(false);
    history.push("/");
  }, [history]);

  return (
    <>
      <Composition
        templateRows="auto"
        gap="20px"
        justifyItems="center"
        padding="20px"
      >
        <img src={adminWallImg} alt="join class" />
        {inviteCode ? (
          <Loader />
        ) : (
          <>
            <h1 className="text-4xl">
              {saving ? "Joining..." : "Join a class"}
            </h1>
            <div className="inline-block">
              <span>Enter the class code or invitation link</span>
              <Input
                type="text"
                placeholder="4-digit class code or the invitation link"
                className="text-center bold-heading"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>

            <Composition paddingTop="20px">
              <Button
                type="primary"
                loading={saving}
                className="justify-self-center"
                disabled={code.length < 4}
                onClick={() => onProceed(code)}
              >
                Proceed
              </Button>
            </Composition>
          </>
        )}
      </Composition>

      <Modal
        visible={showSuccessModal}
        onOk={onInviteModalClose}
        onCancel={onInviteModalClose}
        width={"550px"}
        closable={true}
        footer={
          <div>
            <Link to="/" className="ant-btn ant-btn-primary" type="primary">
              Go Home
            </Link>
          </div>
        }
        style={{
          padding: "10px",
        }}
        title={modalTitle}
      >
        {showSuccessModal ? <InviteSuccessModal /> : null}
      </Modal>
    </>
  );
};

export default JoinClass;
