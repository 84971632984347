import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { Composition } from "atomic-layout";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Result,
  Row,
  Select,
  Space,
  Steps,
  Switch,
  Tooltip,
} from "antd";
import {
  detectBrowserLanguage,
  fetchCurrentUser,
  requestOTP,
  signUp,
  updateUser,
} from "@/Services/User";
import { Redirect, useHistory } from "react-router-dom";
import { getGoogleToken, isGoogleSignup, logout } from "@/Services/Auth";
import { useSelector } from "react-redux";
import { authStatus, currentUser } from "@/Models/Selectors";
import store from "@/Models/store";
import { getCurrentPath as currentPathSelector } from "@/Models/Selectors";
import teacher from "@/Assets/teacher.png";
import student from "@/Assets/student.png";
import SignUp, { SignupData } from "./SignUp";
import { Footer } from "antd/lib/layout/layout";
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CreditCardOutlined,
  InfoCircleOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import NewClass from "./Class/newClass";
import {
  country_codes,
  defaultLanguage,
  FAQs,
  IPLOOKUPURL,
  isRTL,
  ListOfAllLanguages,
  OTP_RESEND_SECONDS,
  payment_link_personal_monthly,
  Subjects,
} from "@/Services/Constants";
import classImg from "@/Assets/newClass.png";
import CheckableTag from "antd/lib/tag/CheckableTag";
import Loader from "./Loader";
import { createNewClass, detectLanguage } from "@/Services/Class";
import moment from "moment";
import OtpInput from "react-otp-input";
import { isValidEmail } from "./Common/Utils";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import Analytics from "@/Services/Analytics";
import CountDownTimer from "./Common/Timer";

const { Step } = Steps;
const { Panel } = Collapse;

// import "./Signup.css"
const { Option } = Select;

type role = "TEACHER" | "STUDENT";

const signUpArea = `
    headerImage
    firstName
    lastName
    username
    errorMessage
    submit
    footer
`;

const defaultState = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  img_url: "",
};

const coupons = [
  {
    title: "EARLYBIRD",
    discount: 0.5,
  },
  {
    title: "START10",
    discount: 0.1,
  },
];

const preselectedPackage = "Personal";

const pricingPackages = [
  {
    name: "Starter",
    price_permonth: { usd: 0, inr: 0, cad: 0 },
    price_permonth_compare: 2100,
    price_peryear: 0,
    disabled: false,
    beta_mode: false,
    subtitle: "/month",
    tag: "",
    features: [
      "Unlimited Sessions",
      "30 Students",
      "1 Class (i.e. Batch)",
      "1 month of Reporting",
      "1 GB Storage",
      "Class and Students Reports",
      "Standard Support",
      "",
      "",
    ],
    missing_features: ["Account system", "Prepare Class", "None of the above"],
  },
  {
    name: "Personal",
    price_permonth: { usd: 15, inr: 850, cad: 30 },
    price_permonth_compare: 2100,
    price_peryear: 7500,
    disabled: false,
    beta_mode: true,
    price_peryear_compare: 25200,
    subtitle: "/month",
    // subtitle: '(Early Bird Program)',
    tag: "Most Popular",
    features: [
      "7-days free trial",
      "Everything in Starter",
      "1 Teacher",
      "Unlimited Classes",
      "200 Students per Class",
      "2 years of Reporting",
      "10 GB Storage",
      "Export Reports",
      "Premium Support",
    ],
  },
  {
    name: "Enterprise",
    price_permonth: { usd: 30, inr: 1950, cad: 55 },
    price_permonth_compare: 2100,
    price_peryear: 5000,
    disabled: false,
    beta_mode: true,
    price_peryear_compare: 25200,
    subtitle: "/TEACHER/month",
    tag: "",
    features: [
      "7-days free trial",
      "Everything in Personal Plan",
      "Personalised Onboarding",
      "Upto 200 Teachers",
      "2,000 Students per Class",
      "Organization Level Reports",
      "Unlimited Storage",
      "Priority Support",
      // ''
      // ''
    ],
  },
];

const TeacherSignUpForm = ({
  signUpInfo,
  updateInfo,
  step,
  stepUpdated,
}: // Signup, CreateClass
{
  signUpInfo: SignupData;
  updateInfo: (signupdata: SignupData) => void;
  step: number;
  stepUpdated: (s: number) => void;
  // Signup: (user: SignupData) => void,
  // CreateClass: (name: string, subject: string) => void
}) => {
  const history = useHistory();
  const [country, setCountry] = useState("United States of America");
  const [countryCode, setCountryCode] = useState("us");
  const [otpInputElement, setOtpInputElement] = useState();

  const [currency, setCurrency] = useState<"usd" | "inr" | "cad">("usd");
  const currency_symbol =
    currency === "inr" ? "₹" : currency === "cad" ? "C$ " : "$";
  const packages = pricingPackages;
  const hasFetchedData = useRef(false);
  const [selectedTier, setSelectedTier] = useState<number>(1);
  const [signUpData, setSignUpData] = useState(signUpInfo);
  const _user = useSelector(currentUser);
  const [resendOtp, setResendOtp] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(step);
  const [img_url, setImg_url] = useState("");
  const [userType, setUserType] = useState<role>();
  const [showUserPickerDrawer, setShowUserPickerDrawer] = useState<boolean>();
  const [coupon, setCoupon] = useState("");
  const [couponStatus, setCouponStatus] = useState<
    "invalid" | "valid" | "none"
  >("none");
  const [discountValue, setDiscountValue] = useState(0);
  const [tncAccepted, setTncAccepted] = useState<boolean>(false);
  const [form] = Form.useForm();
  const status = useSelector(authStatus);
  const [paymentDuration, setPaymentDuration] = useState<"monthly" | "yearly">(
    "monthly"
  );
  const [subjects, setSubjects] = useState(
    Subjects.map((val) => ({ value: val }))
  );
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [tax, setTax] = useState("0");
  const [costBeforeDiscount, setCostBeforeDiscount] = useState("0");
  const [costAfterDiscount, setCostAfterDiscount] = useState("0");
  const [showFeatureBreakdown, setShowFeatureBreakdown] =
    useState<boolean>(false);
  const [showFeatureBreakdownLoader, setShowFeatureBreakdownLoader] =
    useState<boolean>(true);
  const [step1Loading, setStep1Loading] = useState<boolean>(false);
  const [step2Loading, setStep2Loading] = useState<boolean>(false);
  const [step3Loading, setStep3Loading] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [ipLookupData, setIPLookupData] = useState<any>();
  const [isRightToLeft, setIsRightToLeft] = useState<boolean>(false);
  const [detectedLang, setDetectedLang] = useState<string>();
  const [newClassForm] = Form.useForm();

  const createClass = (name: string, subject: string) => {
    createNewClass(
      subject,
      name,
      (data: any) => {
        console.log(data);
        const { Code, Data } = data;
        if (Code != 0) {
          console.log("error while creating class");
          alert("Error while creating class. Try again!");
          return;
        }
        history.push("/signup/TEACHER/1");
      },
      detectedLang || defaultLanguage
    );
  };

  useEffect(() => {
    if (currentStep > 0 && !_user) {
      // fetch current user if user refreshes screen and is not on step 1
      fetchCurrentUser();
    }
  }, []);

  useEffect(() => {
    if (currentStep != step) {
      stepUpdated(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (step != currentStep) {
      setCurrentStep(step);
    }
  }, [step]);

  useEffect(() => {
    fetch(IPLOOKUPURL)
      .then((res) => res.json())
      .then((response) => {
        setCountry(response.country);
        setIPLookupData(response);
        setCountryCode(response.countryCode);
        // setSignUpData({...signUpData, country, })
        setCurrency(
          response.country === "India"
            ? "inr"
            : response.country === "Canada"
            ? "cad"
            : "usd"
        );
        form.setFieldsValue({
          country: response.country,
          city: response.city,
          phone: { short: response.countryCode },
        });
      })
      .catch((reason: any) => {
        console.log("Request failed:", reason);
      });
  }, []);

  useEffect(() => {
    if (signUpInfo.email && signUpInfo.email !== signUpData.email) {
      setSignUpData(signUpInfo);
      form.setFieldsValue({
        firstName: signUpInfo.firstName,
        lastName: signUpInfo.lastName,
        email: signUpInfo.email,
      });
    }
    const language = detectBrowserLanguage();
    form.setFieldsValue({ language });
  }, [signUpInfo]);

  useEffect(() => {
    let costBefore =
      paymentDuration === "monthly"
        ? formatCurrency(packages[selectedTier].price_permonth[currency])
        : formatCurrency(packages[selectedTier].price_permonth[currency] * 10);
    let costAfter = formatCurrency(
      (paymentDuration === "monthly"
        ? packages[selectedTier].price_permonth[currency] * 1.12
        : packages[selectedTier].price_permonth[currency] * 11.2) -
        totalDiscount
    );
    let calculatedTax = formatCurrency(
      paymentDuration === "monthly"
        ? packages[selectedTier].price_permonth[currency] * 0.12
        : packages[selectedTier].price_permonth[currency] * 10 * 0.12
    );
    if (packages[selectedTier].beta_mode) {
      // costBefore = "0";
      costAfter = "0";
      calculatedTax = "0";
    }
    setCostBeforeDiscount(costBefore);
    setCostAfterDiscount(costAfter);
    setTax(calculatedTax);
  }, [discountValue, selectedTier, paymentDuration, currency]);

  const signingUpViaJoinLink = (): boolean => {
    const currentPath = currentPathSelector(store.getState());
    const isSigningViaJoin = currentPath.includes("join-class");
    return isSigningViaJoin;
  };

  useEffect(() => {
    setShowUserPickerDrawer(true);
  }, []);

  if (status !== "no-current-user") {
    // return <Redirect to="/" />
  }

  const onFinish = (values: any) => {
    setStep1Loading(true);
    const { firstName, lastName, otp, language, password } = values;
    const phoneObj = values.phone;
    const phone = "+" + phoneObj.code.toString() + phoneObj.phone.toString();
    const { email } = signUpData;
    const role = "TEACHER";
    const username = email;
    const country =
      ipLookupData && ipLookupData.country ? ipLookupData.country : "";
    const city = ipLookupData && ipLookupData.city ? ipLookupData.city : "";
    const auth_type = isGoogleSignup() ? "gauth" : "custom";
    const token = isGoogleSignup() ? getGoogleToken() : otp;
    // return setStep1Loading(false);
    let img_url = "";
    try {
      //@ts-expect-error
      img_url = window.googleUser.getBasicProfile().getImageUrl();
    } catch (e) {
      console.log(e);
    }

    setSignUpData({
      ...signUpData,
      email,
      firstName,
      lastName,
      role,
      img_url: img_url,
    });
    Sentry.captureMessage("Sign up clicked");
    signUp({
      firstName,
      lastName,
      username,
      email,
      img_url: img_url,
      role,
      auth_type,
      token,
      settings: {
        phone,
        city,
        country,
        preferredLanguage: language,
      },
      password,
    })
      .then((response) => {
        console.log("singup complete");
        const nextUrl = "/signup/TEACHER/1";
        history.push(nextUrl);
        setCurrentStep(currentStep < 2 ? currentStep + 1 : currentStep);
        Sentry.captureMessage("Signed up");
        Analytics.track("Signup Step 1 Complete", { ...signUpData });
      })
      .catch((response) => {
        const { data } = response;
        notification.error({
          message:
            data.message + ". For any help, reach out to support@vidya.us.",
        });
        Sentry.captureMessage(data.message);
      })
      .finally(() => {
        setStep1Loading(false);
        setResendOtp(true);
      });
    // onSubmit({ ...values, img_url });
  };

  const onFinishStep2 = (values: any) => {
    setStep2Loading(true);
    const { subject, description } = values;
    console.log("Signup Success:", values);
    createNewClass(
      subject,
      description,
      (data: any) => {
        console.log(data);
        const { Code, Data, name } = data;
        setStep2Loading(false);
        if (name == null) {
          console.log("error while creating class");
          alert("Error while creating class. Try again!");
          return;
        }
        // FinishSignup();
        setShowSuccessModal(true);
        setStep3Loading(true);
        // setCurrentStep(2)
        // history.push("/signup/TEACHER/2")
      },
      detectedLang || defaultLanguage
    );

    Analytics.track("Signup Step 2 Complete", { ...values });

    // }
    // //@ts-expect-error
    // const basicProfile = window.googleUser.getBasicProfile();
    // onSubmit({ ...values, img_url });
    // setCurrentStep(currentStep < 2 ? currentStep + 1 : currentStep)
  };

  const FinishSignup = () => {
    const _user = currentUser(store.getState());
    const evalSettings = _user.settings ? _user.settings : {};
    const country =
      ipLookupData && ipLookupData.country ? ipLookupData.country : "";
    const city = ipLookupData && ipLookupData.city ? ipLookupData.city : "";
    const finalUser: User = {
      ..._user,
      settings: {
        ...evalSettings,
        type: ["starter", "personal", "enterprise"][selectedTier],
        subscription_status: "active",
        cost: costBeforeDiscount,
        duration: paymentDuration,
        start_date: moment(new Date()).add(1, "week").toDate(),
        end_date: moment(new Date())
          .add(paymentDuration === "monthly" ? 2 : 13)
          .toDate(),
        in_trial: true,
        trial_begin: new Date(),
        trial_end: moment(new Date()).add(1, "week").toDate(),
        coupon,
        currency,
        city,
        country,
      },
    };
    setShowSuccessModal(true);
    setStep3Loading(true);
    updateUser(finalUser)
      .then(() => {
        // const { data } = response;
        // if (data.Code === 1) {
        //     alert('Something went wrong. Please refresh and try again');
        //     return;
        // }
        console.log("Onboarding complete");
        setStep3Loading(false);
        const nextUrl = "/";
        Analytics.track("Signup Step 3 Complete", { ...finalUser });
        // history.push(nextUrl)
        // setCurrentStep(currentStep < 2 ? currentStep + 1 : currentStep)
        Sentry.captureMessage(
          "Selected Tier is " + selectedTier + " for duration" + paymentDuration
        );
      })
      .catch((error) => {
        console.error(error);
        // Sentry.captureMessage("Sign up, failed");
        // setError(error.message);
        Analytics.track("Signup Step 3 Failed", { ...finalUser });
        setShowSuccessModal(false);
        setStep3Loading(false);
        alert(error.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Signup Error:", errorInfo);
  };

  const onCancel = () => {
    Analytics.track("Signup Cancelled");
    console.log("cancel pressed");
    logout();
    history.push("/login");
  };

  const setUser = (user: "TEACHER" | "STUDENT") => {
    setSignUpData({ ...signUpData, role: user });
    // setUserType(user);
    setShowUserPickerDrawer(false);
  };
  const prefixSelector = () => {
    // const uniqueCountryCodes = country_codes
    //     .map(item => item.dial_code)
    //     .filter((value, index, self) => self.indexOf(value) === index)

    return (
      <Form.Item name="prefix" noStyle>
        <Select
          defaultValue={"+91"}
          style={{ width: 70 }}
          options={country_codes
            .sort(
              (a, b) =>
                parseInt(b.dial_code.split("+")[1]) -
                parseInt(a.dial_code.split("+")[1])
            )
            .map((country) => {
              return {
                label: country.dial_code,
                value: country.dial_code,
              };
            })}
        />
        {/* {country_codes
                .sort((a, b) => parseInt(b.dial_code) - parseInt(a.dial_code))
                .map((country) => {
                    return <Option key={country.dial_code} value={country.dial_code}> {country.dial_code} </Option>
                })}
        </Select> */}
      </Form.Item>
    );
  };
  const onSearch = (searchText: string) => {
    const newSubjects = Subjects.filter((sub) => {
      return sub.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
    setSubjects(newSubjects.map((val) => ({ value: val })));
  };

  // const isSelected = (tier: any) {

  //     return
  // }

  const formatCurrency = (num: number) => {
    return num
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const enterCoupon = (val: string) => {
    setCouponStatus("none");
    setCoupon(val.toUpperCase());
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  useEffect(() => {
    const dis =
      paymentDuration === "monthly"
        ? packages[selectedTier].price_permonth[currency] * discountValue
        : packages[selectedTier].price_permonth[currency] * 10 * discountValue;
    if (dis !== totalDiscount)
      // return;
      setTotalDiscount(dis);
  }, [paymentDuration, totalDiscount, discountValue, selectedTier]);

  const applyCoupon = (coupon: string) => {
    const selectedCopupon = coupons.filter(
      (c) => c.title.toUpperCase() === coupon.toUpperCase()
    );
    if (selectedCopupon.length > 0) {
      const disValue = selectedCopupon[0].discount;
      setDiscountValue(disValue);
      setCouponStatus("valid");
      setCoupon(coupon.toUpperCase());
      const dis =
        paymentDuration === "monthly"
          ? packages[selectedTier].price_permonth[currency] * disValue
          : packages[selectedTier].price_permonth[currency] * 10 * disValue;
      setTotalDiscount(dis);
    } else {
      setDiscountValue(0);
      setTotalDiscount(0);
      setCouponStatus("invalid");
    }
    if (coupon === "") {
      setDiscountValue(0);
      setCouponStatus("none");
      setCoupon("");
    }
  };

  const getPaymentLink = () => {
    return payment_link_personal_monthly;
  };

  const accountPrice = formatCurrency(
    paymentDuration === "monthly"
      ? packages[selectedTier].price_permonth[currency] * 0.12
      : packages[selectedTier].price_permonth[currency] * 10 * 0.12
  );
  const totalPrice = formatCurrency(
    (paymentDuration === "monthly"
      ? packages[selectedTier].price_permonth[currency] * 1.12
      : packages[selectedTier].price_permonth[currency] * 11.2) - totalDiscount
  );

  const resendOtpClicked = () => {
    const email = signUpData.email;
    if (isValidEmail(email)) {
      console.log("email is ", email);
      console.log("generating OTP");
      setResendOtp(false);
      requestOTP(email).then(() => {
        notification.success({
          message: "Code Resent",
          description: (
            <span>
              Verification code resent on your email id <b>{email}</b>. Please
              check your Inbox (including the spam folder)
            </span>
          ),
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      });
    } else {
      notification.error({
        message: "Invalid Email",
        description: (
          <span>
            Please check the email. <b>{email}</b> is not a valid email address.
          </span>
        ),
      });
      //   setError('Invalid Email Address')
    }
  };

  const tailFormItemLayout = {
    // wrapperCol: {
    //     xs: {
    //         span: 24,
    //         offset: 0,
    //     },
    //     sm: {
    //         span: 16,
    //         offset: 6,
    //     },
    // },
    wrapperCol: {
      lg: { span: 16 },
      md: { span: 16, offset: 4 },
      xl: { span: 16 },
    },
  };

  return (
    <>
      <Drawer
        title=""
        placement={"bottom"}
        width={500}
        height={"80%"}
        className="ml-auto mr-auto w-full h-full"
        closable={true}
        onClose={() => setShowFeatureBreakdown(false)}
        visible={showFeatureBreakdown}
      >
        <>
          {showFeatureBreakdownLoader ? <Loader /> : null}
          <iframe
            style={showFeatureBreakdownLoader ? { opacity: 0 } : {}}
            onLoad={() => setShowFeatureBreakdownLoader(false)}
            className="w-full h-full"
            src="https://vidya.us/vidya_pricing.html?preview=true"
          />
        </>
      </Drawer>

      <div className="lg:m-0 md:mb-0 sm:mb-0  mb-40">
        <Steps
          style={{
            height: 64,
          }}
          type="navigation"
          size="small"
          current={currentStep}
          onChange={(current) => {
            setCurrentStep(current);
          }}
          className="site-navigation-steps"
        >
          <Step
            disabled={currentStep != 0}
            title={
              <>
                <UserOutlined className="text-lg" /> Identity
              </>
            }
            status={currentStep == 0 ? "process" : "finish"}
            // description="Identity"
          />
          <Step
            disabled={currentStep != 1}
            title={
              <>
                <SettingOutlined className="text-lg" /> Setup
              </>
            }
            status={
              currentStep == 1 ? "process" : currentStep < 1 ? "wait" : "finish"
            }
            // description="Setup"
          />
          {/* <Step
                        title={<><ShoppingCartOutlined className="text-lg" /> Checkout</>}
                        disabled={currentStep != 2}
                        status={currentStep == 2 ? "process" : currentStep < 2 ? "wait" : "finish"}
                    // description="Checkout"
                    /> */}
        </Steps>
      </div>
      <Divider
        // className="mt-40 mb-4"
        style={{ marginTop: "0px" }}
      />

      {currentStep == 0 ? (
        <div>
          <div className="text-gray-500 text-center mt-2">
            All the basic information for setting up your profile
          </div>
          <ConfigProvider locale={en}>
            <Form
              name="basic"
              labelCol={{
                lg: { offset: 2, span: 8 },
                md: { offset: 0, span: 8 },
              }}
              wrapperCol={{
                lg: { span: 8 },
                md: { span: 12 },
                xl: { span: 6 },
              }}
              initialValues={signUpData}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              style={{
                marginTop: 50,
                paddingBottom: 40,
              }}
            >
              <Form.Item
                label="Registration Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your login email!" },
                ]}
              >
                <Input
                  defaultValue={signUpData.email}
                  onChange={(e) => {
                    setResendOtp(true);
                    setSignUpData({
                      ...signUpData,
                      email: e.target.value.trim(),
                    });
                  }}
                  disabled={signUpData.signup_type != "otp"}
                />
              </Form.Item>
              {signUpData.signup_type === "otp" ? (
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the password!",
                    },
                  ]}
                >
                  <Form.Item name="password" noStyle>
                    <Input
                      defaultValue={signUpData.email}
                      type="password"
                      onChange={(e) => {
                        setResendOtp(true);
                        setSignUpData({
                          ...signUpData,
                          password: e.target.value.trim(),
                        });
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              ) : null}
              <Form.Item
                label="First Name"
                name="firstName"
                initialValue={signUpData.firstName}
                rules={[
                  { required: true, message: "Please enter your First Name!" },
                ]}
              >
                <Input value={signUpData.firstName} />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please enter your Last Name!" },
                ]}
              >
                <Input defaultValue={signUpData.lastName} />
              </Form.Item>

              {/* <Form.Item
                                    label="Phone"
                                    name="phone"
                                    rules={[{ required: true, message: 'Please enter your phone number!' }]}
                                >
                                    <Input
                                        // defaultValue={country_codes.filter(c => c.code == ipLookupData.countryCode)[0].dial_code || ""}
                                        style={{ width: '100%' }} />
                                    {/* <Input defaultValue={signUpData.email} disabled /> */}
              {/* </Form.Item> */}

              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                  // {
                  //     type: 'number',
                  //     message: 'enter only the phone number'
                  // },
                  // {
                  //     min: 6,
                  //     message: 'Please check the phone number'

                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const s = Number(value.phone);
                      if (value && !Number(value.phone)) {
                        return Promise.reject(
                          new Error("Cannot add symbols or characters")
                        );
                      }
                      if (value && value.phone.length >= 6) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please enter a valid phone number!")
                      );
                    },
                  }),
                ]}
                initialValue={{
                  short: countryCode,
                }}
              >
                <CountryPhoneInput allowClear={true} height={10} />
              </Form.Item>

              {/* <Form.Item
                                    label="Language"
                                    name="language"
                                    rules={[{ required: true, message: 'Please enter your phone number!' }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ textAlign: "left" }}
                                        placeholder="Select Language"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={Object.keys(ListOfAllLanguages).map((name, index) => {
                                            const nativeName = Object.values(ListOfAllLanguages)[index].nativeName;
                                            const englishName = Object.values(ListOfAllLanguages)[index].name
                                            const finalName = nativeName === englishName ? englishName : `${englishName} (${nativeName})`
                                            return {
                                                value: Object.keys(ListOfAllLanguages)[index], label: finalName
                                            }
                                        })} />

                                </Form.Item> */}

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please accept TnC to proceed")
                          ),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  <span className="ml-2">
                    I accept the{" "}
                    <a target={"_blank"} href="https://vdy.app/tnc">
                      terms and conditions{" "}
                    </a>
                  </span>{" "}
                </Checkbox>
              </Form.Item>

              {/* <Form.Item
                                label="City"
                                name="city"
                                rules={[{ required: true, message: 'Please enter your phone city!' }]}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                label="Country"
                                name="country"
                                initialValue={country}
                                rules={[{ required: true, message: 'Please enter your country!' }]}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item name="org" className="text-left" label="Member Type">
                                <Radio.Group defaultValue={"organization"} optionType="button" buttonStyle="solid">
                                    <Radio.Button value="organization">Organization</Radio.Button>
                                    <Radio.Button value="personal">Personal</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Organization Name"
                                name="organization_name"
                                rules={[{ required: false, message: 'Please enter name of your organization!' }]}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item> */}

              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button
                  htmlType="button"
                  style={{ margin: "0 8px" }}
                  type="default"
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button loading={step1Loading} type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
      ) : currentStep == 1 ? (
        <div>
          <div className="w-full text-center ml-2 text-gray-800 text-xl mt-4">
            {" "}
            Let's create your first class!{" "}
          </div>
          <div className="text-gray-500 text-center">
            You will be able to add learners to this class for live questioning
          </div>
          {/* < img style={{
                                minWidth: '250px',
                                maxWidth: '40%',
                                marginTop: 25
                            }}
                                src={classImg} alt='new class' /> */}
          <Form
            form={newClassForm}
            style={{
              marginTop: 50,
              paddingBottom: 40,
            }}
            name="basic"
            // labelCol={{ lg: { span: 8 }, md: { span: 12 } }}
            // wrapperCol={{ lg: { span: 6 }, md: { span: 12 } }}

            labelCol={{
              lg: { offset: 8, span: 8 },
              md: { offset: 4, span: 12 },
            }}
            wrapperCol={{
              lg: { offset: 8, span: 8 },
              md: { offset: 4, span: 12 },
            }}
            onFinish={onFinishStep2}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Class Name"
              name="description"
              rules={[
                { required: true, message: "You gotta name your class!" },
              ]}
            >
              <Input
                onChange={(e) =>
                  detectLanguage(e.target.value).then((lang: string) => {
                    const isleft = isRTL(lang);
                    if (isleft) {
                      setIsRightToLeft(isleft);
                      form.setFieldsValue({ language: lang });
                      setDetectedLang(lang);
                      // not saving as form is not updating right now.
                    }
                  })
                }
                placeholder={"Group Name (eg. Class of 2023)"}
              />
            </Form.Item>
            <Form.Item
              label="Topic"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "You gotta have a topic/subject to your questions",
                },
              ]}
            >
              <AutoComplete
                options={subjects}
                defaultActiveFirstOption
                // style={{ width: 200 }}
                // onSelect={onSelect}
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={onSearch}
                onSearch={onSearch}
                placeholder="Subject Name"
              />
              {/* <Input
                                value={subject}
                                placeholder="(example - History of Magic)"
                            /> */}
            </Form.Item>
            {detectedLang ? (
              <>
                <Form.Item
                  label="Language (optional)"
                  name="language"
                  initialValue={detectedLang}
                  dependencies={[detectedLang]}
                  // shouldUpdate
                  rules={[
                    { required: false, message: "Please enter your language!" },
                  ]}
                >
                  {/* <Input
                                                value={detectedLang}
                                                placeholder={'Language'}
                                            /> */}
                  <Select
                    showSearch
                    style={{ textAlign: "left" }}
                    placeholder="Select Language"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    value={detectedLang}
                    defaultValue={detectedLang}
                    onChange={(value: string) => {
                      setDetectedLang(value);
                      setIsRightToLeft(isRTL(value));
                    }}
                    options={Object.keys(ListOfAllLanguages).map(
                      (name, index) => {
                        const nativeName =
                          Object.values(ListOfAllLanguages)[index].nativeName;
                        const englishName =
                          Object.values(ListOfAllLanguages)[index].name;
                        const finalName =
                          nativeName === englishName
                            ? englishName
                            : `${englishName} (${nativeName})`;
                        return {
                          value: Object.keys(ListOfAllLanguages)[index],
                          label: finalName,
                        };
                      }
                    )}
                  />
                  {detectedLang && isRightToLeft ? (
                    <div className="text-xs text-gray-900 text-right">
                      All text for this class would be right to left
                    </div>
                  ) : null}
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              style={{
                paddingTop: 40,
              }}
              wrapperCol={{
                lg: { offset: 8, span: 8 },
                md: { offset: 4, span: 12 },
              }}
              // wrapperCol={{ lg: { offset: 7, span: 16 }, md: { offset: 12, span: 8 } }}
            >
              <Button loading={step2Loading} type="primary" htmlType="submit">
                Get Started!
              </Button>
              {/* <div className="mt-2 text-gray-600"> (free tier available)</div> */}
            </Form.Item>
          </Form>
        </div>
      ) : currentStep == 2 ? (
        <div>
          <div className="text-gray-500 text-center mb-8 mt-4">
            Select the best plan for you. No CC or payment required right now
          </div>
          <div className="site-card-wrapper">
            <Radio.Group
              className="pb-12"
              options={[
                {
                  label: "Monthly",
                  value: "monthly",
                },
                {
                  label: "Yearly (20% Off)",
                  value: "yearly",
                },
              ]}
              onChange={(e: RadioChangeEvent) => {
                setPaymentDuration(e.target.value);
              }}
              value={paymentDuration}
              optionType="button"
              buttonStyle="solid"
            />

            <Row
              style={{ justifyContent: "center" }}
              gutter={24}
              className="mt-10"
            >
              {packages.map((tier, index) => (
                <Col className="ml-2 mt-4">
                  <Card
                    style={{
                      width: 300,
                      background: tier.disabled ? "#f9f9f9" : "#fff",
                    }}
                    className={
                      index === selectedTier ? "shadow-xl ml-2" : "ml-2"
                    }
                    hoverable={!tier.disabled}
                    onClick={() => {
                      if (!tier.disabled) setSelectedTier(index);
                    }}
                    // title={<span className="text-xl">Free</span>}
                    bordered={true}
                  >
                    <div>
                      <div
                        className={
                          index === selectedTier
                            ? "text-blue-500 text-sm"
                            : "font-black text-sm"
                        }
                      >
                        {tier.name}
                      </div>
                      <div
                        className={
                          index === selectedTier
                            ? "font-extrabold text-blue-700 text-4xl mt-2"
                            : "font-extrabold text-4xl mt-2"
                        }
                      >
                        {/* <span className={tier.beta_mode ? "line-through mr-4 text-3xl" : ""}> */}
                        {currency_symbol}
                        {paymentDuration === "monthly"
                          ? formatCurrency(tier.price_permonth[currency])
                          : formatCurrency(
                              tier.price_permonth[currency] * 0.83
                            )}
                        {/* </span> */}
                        {/* {tier.beta_mode ? currency_symbol + "0" : ""} */}
                      </div>
                      <div className="font-thin text-gray-600 text-xs">
                        {tier.subtitle}
                      </div>

                      <div>
                        {index === selectedTier ? (
                          <button className="bg-green-700 font-extrabold hover:bg-green-500 hover:text-white mb-6 mt-6 px-4 py-2 rounded-lg text-white w-48">
                            <CheckCircleOutlined />
                          </button>
                        ) : (
                          <button
                            disabled={tier.disabled}
                            onClick={() => setSelectedTier(index)}
                            className={
                              "font-extrabold mb-6 mt-6 px-4 w-48 py-2 rounded-lg text-blue-700" +
                              (!tier.disabled
                                ? "hover:bg-blue-500 hover:text-white bg-blue-200"
                                : "bg-gray-500")
                            }
                          >
                            {tier.disabled ? "All Slots Full" : "Select"}
                          </button>
                        )}
                      </div>

                      <div
                        style={{
                          textAlign: "initial",
                        }}
                      >
                        {tier.features.map((feature, index) => (
                          <div key={tier.name + index} className="mt-2">
                            {feature ? (
                              <CheckCircleOutlined
                                style={{ color: "limegreen" }}
                              />
                            ) : null}
                            <span className="ml-2">{feature}</span>
                          </div>
                        ))}
                        {tier.tag ? (
                          <div className="bg-green-100 font-extrabold mb-6 mt-6 px-4 py-2 rounded-lg text-center text-green-700 w-full">
                            {tier.tag}
                          </div>
                        ) : (
                          <div className="mb-6 mt-6 px-6 py-6"></div>
                        )}
                        {/* <div>
                                                        <CheckCircleOutlined style={{ color: 'limegreen' }} />
                                                        <span className="ml-2">1 User </span>
                                                    </div> */}
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row
              style={{ justifyContent: "center" }}
              gutter={24}
              className="mt-10"
            >
              <Button
                type="default"
                shape="round"
                onClick={() => setShowFeatureBreakdown(true)}
              >
                {" "}
                <span>See Features Breakdown</span>{" "}
              </Button>
            </Row>

            <div className="lg:ml-16 mt-16 text-left">
              <Card
                style={{
                  maxWidth: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="ml-auto mr-auto"
                title={<span className="text-bold text-lg">Summary</span>}
              >
                {/* <Divider /> */}

                <div className="text-left lg:ml-8">
                  <div className="mb-8">
                    {/* <div className="text-gray-500">Discount</div> */}

                    <Input
                      size="large"
                      onChange={(e) => enterCoupon(e.target.value)}
                      value={coupon}
                      onPressEnter={(e) => applyCoupon(coupon)}
                      className="mb-8"
                      placeholder="Coupon"
                    />
                    {couponStatus == "invalid" ? (
                      <div className="text-red-500 w-full text-center">
                        Invalid Coupon
                      </div>
                    ) : null}
                    {couponStatus == "valid" ? (
                      <div className="text-green-500 w-full text-center">
                        Coupon Applied
                      </div>
                    ) : null}
                    {/* <div className="mt-4">
                                                    {coupons.length > 0 ? <span>Available Coupons:  </span> : null}
                                                    <span className="text-right">
                                                        {coupons.map(code => (
                                                            <CheckableTag
                                                                key={code.title}
                                                                checked={code.title === coupon}
                                                                onChange={checked => applyCoupon(checked ? code.title : '')}
                                                            >
                                                                {code.title}
                                                            </CheckableTag>

                                                        ))}
                                                    </span>
                                                </div> */}
                  </div>

                  <Row>
                    <Col span={16}>
                      <div className="text-gray-500">
                        Account Type:{" "}
                        <b className="text-gray-700">
                          {packages[selectedTier].name}
                        </b>
                      </div>
                    </Col>

                    <Col span={8}>
                      <div className="text-right">
                        <span className="">
                          {currency_symbol} {costBeforeDiscount}{" "}
                          {paymentDuration === "monthly" ? "/month" : "/year"}
                        </span>
                        {/* {packages[selectedTier].beta_mode ? currency_symbol + "0" : null}{paymentDuration === "monthly" ? "/month" : "/year"} */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <div className="text-gray-500">Subscription Type</div>
                    </Col>

                    <Col span={8}>
                      <div className="text-right">
                        {packages[selectedTier].beta_mode
                          ? "7-Day Trial"
                          : paymentDuration}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <div className="text-gray-500">Tax</div>
                    </Col>

                    <Col span={8}>
                      <div className="text-right">
                        {currency_symbol} {tax}{" "}
                      </div>
                    </Col>
                  </Row>
                  {discountValue > 0 ? (
                    <Row>
                      <Col span={16}>
                        <div className="text-gray-500">
                          Discount{" "}
                          {couponStatus === "valid" ? (
                            <span>({discountValue * 100 + "% Off"})</span>
                          ) : null}
                        </div>
                      </Col>

                      <Col span={8}>
                        <div className="text-right text-green-600">
                          {discountValue == 0
                            ? "0"
                            : "- ₹" + formatCurrency(totalDiscount)}
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <Divider />
                  <Row>
                    <Col span={16}>
                      <div className="text-black-500 font-bold text-lg">
                        Total Amount
                      </div>
                    </Col>

                    <Col span={8}>
                      <div className="text-right text-lg font-bold">
                        {currency_symbol} {costAfterDiscount}{" "}
                      </div>
                    </Col>
                  </Row>

                  {/* <div className="mt-8">
                                                    <Checkbox value={tncAccepted} onChange={(e) => setTncAccepted(e.target.checked)}>
                                                        I accept the <a target={"_blank"} href="https://vdy.app/tnc"> terms and conditions </a>
                                                    </Checkbox>
                                                </div> */}

                  <div className="mt-4 text-center text-lg">
                    <button
                      // type="primary"
                      // size="large"
                      // loading={step3Loading}
                      // href={getPaymentLink()}
                      disabled={step3Loading}
                      // onClick={() => { window.open(getPaymentLink(), "_blank"); }}
                      onClick={() => {
                        FinishSignup();
                      }}
                      className={
                        "bg-blue-500 font-extrabold hover:bg-blue-800 text-white mb-6 mt-12 px-4 py-2 rounded-lg w-48"
                      }
                    >
                      {packages[selectedTier].beta_mode
                        ? "Start Free Trial"
                        : "Proceed"}
                    </button>
                  </div>
                </div>
              </Card>
            </div>

            <Row
              wrap
              className="px-8 py-8 bg-indigo-100 mt-12 pl-0 md:pl-4 pt-4 rounded-2xl text-left lg:w-11/12"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              gutter={48}
            >
              {/* <div> */}
              <Col span={8} style={{ display: "flex" }}>
                <LockOutlined className="text-blue" />
                <span className="pl-4 text-gray-600">
                  Secure 128-bit SSL Encrypted payments powered by Stripe.
                </span>
              </Col>
              <Col span={8} style={{ display: "flex" }}>
                <InfoCircleOutlined className="text-blue" />
                <span className="pl-4 text-gray-600">
                  The standard VAT/GST rate may be charged, following the law of
                  your currency.
                </span>
              </Col>
              <Col span={8} style={{ display: "flex" }}>
                <QuestionCircleOutlined className="text-blue" />
                <span className="pl-4 text-gray-600">
                  Need help or have a question? Please feel free to{" "}
                  <a href="mailto:happy@vidya.us">contact us.</a>
                </span>
              </Col>{" "}
              {/* </div> */}
              {/* <Divider /> */}
            </Row>

            <Row className="mt-8 text-left hidden" style={{ display: "none" }}>
              <div className="w-full">
                <div>
                  {" "}
                  <span className="text-bold text-2xl">FAQs</span>{" "}
                </div>
                <Divider />

                <Collapse defaultActiveKey={["1"]} ghost>
                  {FAQs.map((s, i) => {
                    return (
                      <Panel showArrow={true} header={s.q} key={i}>
                        <span>{s.a}</span>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            </Row>
          </div>

          {/* </div> */}
        </div>
      ) : null}
      <Modal
        title={"Welcome!"}
        visible={showSuccessModal}
        onOk={() => {}}
        onCancel={() => {}}
        footer={null}
        closable={false}
      >
        {!showSuccessModal ? (
          <Loader />
        ) : (
          <Result
            status="success"
            title="Free Upgrade!"
            subTitle="Enjoy 7-day access to all premium features for free."
            extra={[
              <Button
                onClick={() => {
                  setStep3Loading(false);
                  // history.push('/')
                  FinishSignup();
                }}
                type="primary"
                key="console"
              >
                Take me home!
              </Button>,
              //   <Button key="buy">Buy Again</Button>,
            ]}
          />
        )}
      </Modal>
    </>
  );
};

export default TeacherSignUpForm;
