import { isGoogleSignup } from "@/Services/Auth";
import { ListOfAllLanguages } from "@/Services/Constants";
import { getDisplayEmail } from "@/Services/User";
import { GoogleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  PageHeader,
  Select,
  Switch,
  Tag,
} from "antd";
import { Composition } from "atomic-layout";
import { useEffect, useState } from "react";
import UserAvatar from "../Common/Image/UserAvatar";

const defaultSetting: UserSettings = {
  type: "starter",
  start_date: new Date(),
  end_date: new Date(),
  cost: 0,
  currency: "usd",
  duration: "monthly",
  in_trial: false,
  trial_begin: new Date(),
  trial_end: new Date(),
  subscription_status: "active",
  coupon: "",
  city: "",
  phone: "",
  country: "",
  organization_name: "",
  preferredLanguage: "en",
};

const TeacherSetting = ({
  userData,
  onFinish,
  onFinishFailed,
  form,
}: {
  userData: User;
  onFinish: (
    values: any,
    success: () => void,
    failed: (error: string) => void
  ) => void;
  onFinishFailed: (errorInfo: any) => void;
  form: FormInstance<any>;
}) => {
  const [saving, setSaving] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(userData?.img_url);

  useEffect(() => {
    form.setFieldsValue({
      ...userData,
      email: getDisplayEmail(userData.email),
      city: userData?.settings?.city,
      country: userData?.settings?.country,
      phone: userData?.settings?.phone,
      organization_name: userData?.settings?.organization_name,
      language: userData?.settings?.preferredLanguage,
    });
    // setSubject(_class.name);
    // setBatch(_class.description);
  });

  const formFilled = (values: any) => {
    setSaving(true);
    message.loading("Updating", 0);
    const {
      firstName,
      lastName,
      email,
      role,
      img_url,
      city,
      phone,
      country,
      organization_name,
      language,
    } = values;
    const settings = userData.settings || defaultSetting;
    if (userData) {
      const _user: User = {
        ...userData,
        first_name: firstName,
        last_name: lastName,
        email: userData.email,
        full_name: firstName + " " + lastName,
        img_url,
        role,
        settings: {
          ...settings,
          city,
          country,
          phone,
          organization_name,
          preferredLanguage: language,
        },
      };

      onFinish(
        _user,
        () => {
          message.destroy();
          message.success("Profile Updated");
          setSaving(false);
        },
        (error) => {
          message.destroy();
          message.error("Update failed. Please try again");
          setSaving(false);
          // setUser
        }
      );
    } else {
      message.error(
        "Something went wrong. Please reach out to support@vidya.us for profile update"
      );
    }
  };

  // useEffect(() => {
  //     if (saving) {
  //         message.loading('Updating', 0);
  //     } else {}
  // }, [saving])

  return (
    <div>
      <Composition justify="start" className="lg:w-6/12 md:w-full">
        <Form
          name="basic"
          labelCol={{ sm: { span: 8 }, md: { span: 8 } }}
          wrapperCol={{ sm: { span: 16 }, md: { span: 16 }, lg: { span: 12 } }}
          initialValues={userData}
          onFinish={formFilled}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            initialValue={userData?.first_name}
            rules={[
              { required: true, message: "Please enter your First Name!" },
            ]}
          >
            <Input value={userData?.first_name} />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            initialValue={userData?.last_name}
            rules={[
              { required: true, message: "Please enter your Last Name!" },
            ]}
          >
            <Input value={userData?.last_name} />
          </Form.Item>
          {/* <Form.Item
                    label={<UserAvatar email={userData?.email} />}
                    name="img_url"
                    initialValue={avatarUrl.length > 0}
                    rules={[{ required: false, message: 'Please enter your First Name!' }]}
                > <div className="float-left" >
                        <Switch checked={avatarUrl.length > 0} onChange={(checked) => setAvatarUrl(checked ? userData.img_url : "")} style={{ marginRight: 40 }} />
                        <span className="text-gray-500">{"Avatar" + avatarUrl.length ? 'true' : 'false' + " visible to others"}</span>
                    </div>
                </Form.Item> */}

          <Form.Item
            label={
              isGoogleSignup() ? (
                <div>
                  <GoogleOutlined /> Login Account
                </div>
              ) : (
                <div> Email</div>
              )
            }
            name="email"
            rules={[
              { required: true, message: "Please enter your login email!" },
            ]}
          >
            <Input defaultValue={getDisplayEmail(userData?.email)} disabled />
          </Form.Item>

          <Form.Item
            label="I am here as a"
            name="role"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select className="text-left" disabled value={userData?.role}>
              <Select.Option value="TEACHER">Teacher</Select.Option>
              <Select.Option value="STUDENT">Student</Select.Option>
              <Select.Option disabled value="MANAGER">
                Manager
              </Select.Option>
            </Select>
          </Form.Item>

          {/* {userData?.settings?.city ? */}
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: false, message: "Please enter your city!" }]}
          >
            <Input defaultValue={userData?.settings?.city} />
          </Form.Item>
          {/* : null} */}

          {/* {userData?.settings?.country ? */}
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: false, message: "Please enter your country!" }]}
          >
            <Input defaultValue={userData?.settings?.country} />
          </Form.Item>
          {/* : null} */}

          {/* {userData?.settings?.phone ? */}
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: false, message: "Please enter your phone!" }]}
          >
            <Input defaultValue={userData?.settings?.phone} />
          </Form.Item>
          <Form.Item
            label="Language"
            name="language"
            rules={[
              {
                required: true,
                message: "Please enter your preferred language!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ textAlign: "left" }}
              placeholder="Select Language"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={Object.keys(ListOfAllLanguages).map((name, index) => {
                const nativeName =
                  Object.values(ListOfAllLanguages)[index].nativeName;
                const englishName =
                  Object.values(ListOfAllLanguages)[index].name;
                const finalName =
                  nativeName === englishName
                    ? englishName
                    : `${englishName} (${nativeName})`;
                return {
                  value: Object.keys(ListOfAllLanguages)[index],
                  label: finalName,
                };
              })}
            />
          </Form.Item>

          {/* : null} */}
          {userData?.role === "TEACHER" && !userData?.primary_institute ? (
            <Form.Item
              label="Organization"
              name="organization_name"
              rules={[
                { required: false, message: "Please enter your organization!" },
              ]}
            >
              <Input defaultValue={userData?.settings?.organization_name} />
            </Form.Item>
          ) : null}

          {userData?.role === "TEACHER" ? (
            <Form.Item
              label="Subscription"
              name="subscription"
              className="text-left"
              // rules={[{ required: true, message: 'Please select an option!' }]}
            >
              {userData.primary_institute ? (
                <Tag className="" color="green">
                  {userData?.settings?.type.toUpperCase()}
                </Tag>
              ) : (
                <>
                  <Tag className="" color="green">
                    {userData?.settings?.type?.toUpperCase()}{" "}
                  </Tag>
                  {userData?.settings?.in_trial ? (
                    <Tag className="" color="geekblue">
                      FREE TRIAL
                    </Tag>
                  ) : null}
                </>
              )}
            </Form.Item>
          ) : null}

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={saving}>
              Update
            </Button>
            {/* <div
                        style={{
                            marginRight: "34px"
                        }}
                        className="mt-2 text-gray-600 text-xs"
                    >
                        Please reach out to support@vidya.us to request any profile updates
                    </div> */}
          </Form.Item>
        </Form>
      </Composition>
    </div>
  );
};

export default TeacherSetting;
