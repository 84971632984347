import React, { useEffect, useLayoutEffect } from "react";
// import queryString from 'query-string';
import AppHeader from "@/Components/AppHeader";
import Dashboard from "./Components/Dashboard";
import Session from "./Components/Session";
import Login from "@/Components/Login";
import PasswordReset from "@/Components/PasswordReset";
import SignUp from "@/Components/SignUp";
import {
  authStatus,
  currentUser,
  getCurrentPath as currentPathSelector,
} from "@/Models/Selectors";
import store from "@/Models/store";
import "./App.css";
import { useSelector } from "react-redux";
import Loader from "@/Components/Loader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import NewClass from "./Components/Class/newClass";
import JoinClass from "./Components/Class/joinClass";
import InviteModal from "./Components/Class/inviteModal";
import { setCurrentPath } from "./Services/Router";
import AdminNewSession from "./Components/Session/AdminSession/NewSession";
import PrepareSession from "./Components/Session/AdminSession/Prepare";
import ClassDetail from "./Components/Class/ClassDetails/ClassDetails";
import { Layout } from "antd";
import Stats from "./Components/Stats/Stats";
import AppFooter from "./Components/Footer/footer";
import UserSettingDetails from "./Components/Settings/UserSettings";
import { fetchCurrentUser } from "./Services/User";
import GauthLogin from "./Components/GAuth-Login";
import {
  isEmbed,
  isRunningAsZoomApp,
  RTLlanguages,
} from "./Services/Constants";
import GuestSignUpForm from "./Components/GuestSignup";
import QuestionLibraryHome from "./Components/QuestionLibrary/QuestionLibraryHome";
// import { User } from "./@types/global";

// import AdminSessionCreated from "./Components/Session/Created";

// eslint-disable-next-line no-restricted-globals
// console.log("Location query params ", queryString.parse(location.search));

interface PrivateRouteProps extends RouteProps {}
// any props that come into the component

const App = function () {
  const status = useSelector(authStatus);
  const match = useRouteMatch();
  const loggedInUser: User = useSelector(currentUser);

  // window.zoomSdk = zoomSdk;

  // // //@ts-expect-error
  // // const configResponse = window.zoomSdk.config({
  // //   size: { width: 480, height: 360 },
  // //   capabilities: [
  // //     //APIs
  // //     "startOrJoinMeeting",
  // //     "shareApp",
  // //     "listCameras",
  // //     "setCamera",
  // //     "setVideoMirrorEffect",
  // //     "getMeetingParticipants",
  // //     "cloudRecording",
  // //     "allowParticipantToRecord",
  // //     "getRunningContext",
  // //     "getMeetingContext",
  // //     "getSupportedJsApis",
  // //     "showNotification",
  // //     "openUrl",
  // //     "setVirtualBackground",
  // //     "canSupportVirtualBackground",
  // //     "listCameras",
  // //     "setCamera",
  // //     "sendAppInvitation",
  // //     "sendAppInvitationToAllParticipants",
  // //     "getUserContext",
  // //     "getRecordingContext",
  // //     "getMeetingContext",
  // //     "getMeetingJoinUrl",
  // //     "getMeetingUUID",
  // //     "expandApp",
  // //     "connect",
  // //     "postMessage",
  // //     "allowParticipantToStartLocalRecording",
  // //     //Events
  // //     "onShareApp",
  // //     "onSendAppInvitation",
  // //     "onCloudRecording",
  // //     "onActiveSpeakerChange",
  // //     "onAppPopout",
  // //     "onCohostChange",
  // //     "onParticipantChange",
  // //     "onReaction",
  // //     "onConnect",
  // //     "onExpandApp",
  // //     "onMessage",
  // //     "onMeeting",
  // //   ],
  // // });

  // //@ts-expect-error
  // console.log(window.zoomSdk)

  const isStudent = loggedInUser && loggedInUser.role === "STUDENT";
  const currentPath = useSelector(currentPathSelector);

  console.log("App - params are ", match.params);
  let location = useLocation();

  console.log(window.location.href);
  let path = currentPathSelector(store.getState());
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useLayoutEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser.settings &&
      loggedInUser.settings.preferredLanguage
    ) {
      if (RTLlanguages.includes(loggedInUser.settings.preferredLanguage)) {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      } else {
        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = "@/SDKs/zoom-apps-js-sdk-0.11.0.min.js";
    // script.async = true;
    // document.body.appendChild(script);
    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  /* const getTokenFromUrl = (url: string) => {
    return url.split('&')[1].split('=')[1]
  } */

  const isSignupOrLogin = (path: string): boolean => {
    const isSignup =
      path.includes("/signup/") ||
      path.includes("login") ||
      path.includes("/guest-signup") ||
      path.includes("/password-reset");
    return isSignup;
  };

  console.log("app rendered with status ", status);
  // if (
  //   status === "authenticating" ||
  //   status === "init" ||
  //   status === "authenticated" ||
  //   status === "active" ||
  //   status === "unreachable"
  // ) {
  //   if (status === "authenticated") {
  //     // const currentPath = currentPathSelector(store.getState());
  //     const redirectPath =
  //       !currentPath || isSignupOrLogin(currentPath) ? "/" : currentPath;
  //     console.log("redirect path - ", redirectPath);

  console.log("STATUS", status);
  if (status === "signed-out") {
    // this is primary redirect
    let redirectPath = "/login";

    if (location.search) {
      // convert string to json object.
      const search = location.search.substring(1);
      const params = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      // if url contains creds, pass them to login page.
      if (params.email && params.pwd) {
        redirectPath = "/login/" + params.email + "/" + params.pwd;
      }
      if (params.name && params.classcode) {
        redirectPath = "/guest-signup/" + params.name + "/" + params.classcode;
      }
    }
    // avoid re-rendering of login page
    if (
      location &&
      location.pathname !== redirectPath &&
      !isSignupOrLogin(location.pathname)
    )
      return <Redirect to={redirectPath} />;
  }
  if (status === "init") {
    var cpath = "/";
    if (path === "") {
      cpath = location.search
        ? location.pathname + location.search
        : location.pathname;
      setCurrentPath(cpath);
    }
  }
  //   if (location.pathname !== "/gauth-login") {
  //     cpath = location.search
  //       ? location.pathname + location.search
  //       : location.pathname;
  //     return <Redirect to={cpath} />;
  //   }
  // }

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...rest }) => {
    const status = useSelector(authStatus);
    switch (status) {
      case "signed-out": {
        console.log("going to /login");
        const redirectpath = isSignupOrLogin(location.pathname)
          ? currentPath
          : currentPath;
        return <Redirect to={{ pathname: redirectpath }} />;
      }
      case "no-current-user": {
        if (currentPath && currentPath.startsWith("/signup"))
          return <Redirect to={currentPath} />;
        console.log("going to /singup");
        return <Redirect to={{ pathname: "/signup" }} />;
      }
      // default:
      case "onboarding": {
        // case "authenticated":
        const currentPath = "/";
        console.log("going to /");
        return <Redirect to={{ pathname: currentPath }} />;
      }
    }
    return <Route path={rest.path} component={rest.component} />;
  };

  return (
    <Router>
      <div>
        <Layout>
          <div className="App h-100vh" style={{ overflow: "none" }}>
            {isStudent && (isEmbed || isRunningAsZoomApp) ? null : (
              <AppHeader />
            )}
            <div
              className="pt-16 background"
              style={{
                minHeight: "calc(100vh - 92px)",
                overflow: "auto",
              }}
            >
              <Switch>
                <Route path="/signup" exact component={SignUp}></Route>
                <Route
                  path="/signup/:type/:step"
                  exact
                  component={SignUp}
                ></Route>
                <Route
                  path="/guest-signup/"
                  exact
                  component={GuestSignUpForm}
                ></Route>
                <Route
                  path="/guest-signup/:classcode"
                  exact
                  component={GuestSignUpForm}
                ></Route>
                <PrivateRoute
                  path="/"
                  exact
                  component={Dashboard}
                ></PrivateRoute>
                <Route
                  path="/guest-signup/:name/:classcode"
                  exact
                  component={Login}
                ></Route>
                <Route path="/login" exact component={Login}></Route>
                <Route
                  path="/password-reset"
                  exact
                  component={PasswordReset}
                ></Route>
                <Route
                  path="/login/:email/:password"
                  exact
                  component={Login}
                ></Route>
                <PrivateRoute
                  path="/loading"
                  exact
                  component={Loader}
                ></PrivateRoute>
                <Route path="/add-class" exact component={NewClass}></Route>
                <Route path="/join-class" exact component={JoinClass}></Route>
                <Route
                  path="/join-class/:classId"
                  exact
                  component={JoinClass}
                ></Route>
                <Route
                  path="/join-class/:classId/:inviteCode"
                  exact
                  component={JoinClass}
                ></Route>
                <Route
                  path="/invite/:classId"
                  exact
                  component={InviteModal}
                ></Route>
                <Route
                  path="/new-session/:classId"
                  exact
                  component={AdminNewSession}
                ></Route>
                <Route
                  path="/new-session/"
                  exact
                  component={AdminNewSession}
                ></Route>
                <Route
                  path="/session/:sessionId"
                  exact
                  component={Session}
                ></Route>
                <Route
                  path="/class/:classId/session/:sessionId/:action"
                  exact
                  component={Session}
                ></Route>
                <Route
                  path="/session/:sessionId/:action"
                  exact
                  component={Session}
                ></Route>
                <Route
                  path="/library"
                  exact
                  component={QuestionLibraryHome}
                ></Route>
                <Route
                  path="/library/new"
                  exact
                  component={QuestionLibraryHome}
                ></Route>
                <Route
                  path="/library/edit/:libraryId"
                  exact
                  component={QuestionLibraryHome}
                ></Route>
                <Route path="/class" exact component={ClassDetail}></Route>
                <Route
                  path="/class/:classId"
                  exact
                  component={ClassDetail}
                ></Route>
                <Route
                  path="/class/:classId/:subTab"
                  exact
                  component={ClassDetail}
                ></Route>
                <Route
                  path="/class/:classId/prepare/session"
                  exact
                  component={PrepareSession}
                ></Route>
                <Route
                  path="/class/:classId/prepare/session/:sessionId"
                  exact
                  component={PrepareSession}
                ></Route>
                <Route path="/analyse/" exact component={Stats}></Route>
                <Route path="/analyse/class" exact component={Stats}></Route>
                <Route
                  path="/analyse/class/:classId"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/sample-class"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/sample-class/sample-session"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/sample-class/sample-student"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/sample-class/sample-student/:studentId"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/class/:classId/sessions"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/class/:classId/sessions/:sessionId"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/class/:classId/students"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/analyse/class/:classId/students/:studentId"
                  exact
                  component={Stats}
                ></Route>
                <Route
                  path="/prepare-class/:classId/"
                  exact
                  component={PrepareSession}
                ></Route>
                <Route
                  path="/prepare-class/:classId/session/:sessionId"
                  exact
                  component={PrepareSession}
                ></Route>
                <Route
                  path="/settings"
                  exact
                  component={UserSettingDetails}
                ></Route>
                <Route
                  path="/settings/:option"
                  exact
                  component={UserSettingDetails}
                ></Route>
                <Route
                  path="/settings/:option/:pageId"
                  exact
                  component={UserSettingDetails}
                ></Route>
                <Route path="/gauth-login" exact component={GauthLogin}></Route>

                <Redirect from="*" to="/" />
              </Switch>
            </div>
            <AppFooter />
          </div>
        </Layout>
      </div>
    </Router>
  );
};

export default App;
