import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Question from "./Question";
import { Button, Divider, Modal, Result, Space, message, notification } from "antd";
import { ReactNode, useCallback, useState } from "react";
import Analytics from "@/Services/Analytics";
import QuestionBuilder from "../QuestionBuilder";
import { questionMode } from "../QuestionBuilder/QuestionBuilder";
import QuestionCardPreview from "./QuestionPreview";
import bannerImage from "@/Assets/prepare-questions.png";


const { confirm } = Modal;

const QuestionGridManager = ({ questionList, onQuestionListUpdate, disableEdit, EmptyPageAdditionalAction, onQuestionAdd, onQuestionEdit, onQuestionDelete, onQuestionSelect, flexibleHeight }:
    {
        questionList: question[];
        EmptyPageAdditionalAction?: ReactNode;
        onQuestionListUpdate: (updatedQuestions: question[]) => Promise<void>;
        onQuestionAdd?: (updatedQuestion: question) => Promise<void>;
        onQuestionEdit?: (updatedQuestion: question) => Promise<void>;
        onQuestionDelete?: (updatedQuestion: question) => Promise<void>;
        onQuestionSelect?: (updatedQuestion: question) => void;
        disableEdit?: boolean;
        flexibleHeight?: boolean;
    }) => {

    // const [questions, setQuestions] = useState<question[]>(questionList || []);

    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [editQuestion, setEditQuestion] = useState<question | undefined>();
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    const [showQuestionBuilder, setShowQuestionBuilder] = useState(false);


    const onQuestionModalClose = useCallback(() => {
        setShowQuestionModal(false);
        setEditQuestion(undefined);
        setShouldBlockNavigation(false)
    }, []);
    const key = 'updatable';

    const onQuestionSave = useCallback(
        (partialQuestion:any, answer:any) => {
            const { textValue, images } = partialQuestion;
            const { type, value, correctValue, values, correctValueIndex } = answer;
            const question: question = { ...partialQuestion, ...answer, state: 'DRAFT' }
            const finalQuestion: question = { ...question, ...answer, images }
            const updatedQuestion = finalQuestion.id ? questionList.find(q => q.id == question.id) : null;
            message.loading({ content: 'Saving...', key });
            if (finalQuestion.id) {
                if (onQuestionEdit) onQuestionEdit(finalQuestion)
                    .then(() => {
                        resetQuestionState(questionsArray);
                    })
                    .catch(() => {
                        message.error({ content: 'Something went wrong', key, duration: 2 });
                    });
            } else {
                if (onQuestionAdd) onQuestionAdd(finalQuestion)
                    .then(() => {
                        resetQuestionState(questionsArray);
                    })
                    .catch(() => {
                        message.error({ content: 'Something went wrong', key, duration: 2 });
                    })
                    ;
            }
            const questionsArray: question[] = finalQuestion.id ? questionList.map(q => {
                if (q.id === finalQuestion.id) return finalQuestion
                return q
            }) : [...questionList, finalQuestion];
            onQuestionListUpdate(questionsArray)
                .then(() => {
                    resetQuestionState(questionsArray);
                })
                .catch(() => {
                    message.error({ content: 'Something went wrong', key, duration: 2 });
                })
            // updateSessionOnServer(sessionName, questionsArray);
            Analytics.track('Save Question', { question });
        },
        [questionList, editQuestion]
    );

    const resetQuestionState = (questionsArray: question[]) => {
        // setQuestions(questionsArray);
        setShowQuestionModal(false);
        message.success({ content: 'Saved!', key, duration: 2 });
        setEditQuestion(undefined)
    }
    const onAddNextQuestion = useCallback(() => {
        Analytics.track(`Manual Question Add - Clicked`);
        setEditQuestion(undefined);
        setShowQuestionBuilder(true);
        setShowQuestionModal(true);
    }, [editQuestion, showQuestionBuilder, showQuestionModal]);

    const deleteQuestion = (q: question): Promise<void> => {
        const questionsArray = questionList.filter((question) => question.id != q.id)
        Analytics.track('Delete Drafted Question');
        return new Promise((resolve, reject) => {
            confirm({
                title: "Are you sure you want to delete this question?",
                icon: <ExclamationCircleOutlined />,
                content: "You will not be able to undo this action",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    message.loading({ content: 'Deleting...', key });
                    if (onQuestionDelete) onQuestionDelete(q)
                        .then(() => {
                            resetQuestionState(questionsArray);
                        })
                        .catch(() => {
                            message.error({ content: 'Something went wrong', key, duration: 2 });
                        })
                    else onQuestionListUpdate(questionsArray)
                    onQuestionListUpdate(questionsArray)
                        .then(() => {
                            resetQuestionState(questionsArray);
                        })
                        .catch(() => {
                            message.error({ content: 'Something went wrong', key, duration: 2 });
                        })
                    resolve();
                },
                onCancel() {
                    setShowQuestionModal(false);
                    reject();
                },
            });
        });
    };



    return <>
        <Modal
            destroyOnClose={true}
            visible={showQuestionModal}
            onOk={onQuestionModalClose}
            onCancel={onQuestionModalClose}
            width={"560px"}
            closable={true}
            footer={null}
            style={{
                padding: "10px",
                top: 10
            }}
            title={editQuestion ? `Edit Question` : 'Add Question'}
        >
            <QuestionBuilder
                isRTL={false}
                selectQuestionButtonTitle={null}
                editQuestion={editQuestion}
                askButtonLabel="Save"
                mode={questionMode.PREPARE}
                numberOfStudents={1}
                onAsk={onQuestionSave}
                Label={``}
            />

        </Modal>
        {questionList && questionList.length > 0 ? <>
            <Button
                hidden={disableEdit}
                icon={<PlusOutlined />}
                type="primary"
                ghost
                style={{
                    display: "block",
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                className="ant-btn ml-auto"
                onClick={onAddNextQuestion}
            >Add Question</Button>
            <Divider />
        </> : null}

        {/* </Space> */}
        <div

        >{!questionList || questionList.length === 0 ?
            <>
                <Result
                    title="Add Questions Here "
                    icon={<img style={{ maxWidth: "15rem" }} src={bannerImage} />}
                    subTitle="Add questions to use in a session or share with your team"
                    extra={<div>
                        <button
                            style={{
                                display: "block",
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                            className="ant-btn ant-btn-primary ml-auto"
                            onClick={onAddNextQuestion}
                        >Add Question
                        </button>
                        {EmptyPageAdditionalAction ? EmptyPageAdditionalAction : null}
                        {/* <div className="mt-4"> or </div> */}
                        {/* <Button type="link" onClick={() => addSampleQuestions()}>Load Sample Questions</Button> */}
                    </div>
                    }
                >
                </Result>
            </>
            :
            <Space
                className="justify-center md:justify-around mt-4"
                style={{
                    width: "100%",
                    paddingBottom: "80px"
                    // justifyContent: "space-between"
                }}
                wrap align="start" size={"large"} direction="horizontal">

                {questionList.map((q, i) => (
                    // <Composition
                    //     alignItems="start"
                    //     areas={
                    //         `Question`
                    //     }
                    //     style={{
                    //         margin: "1em 0"
                    //     }}
                    // >
                    //     {({ Number, Question, Edit }) => (
                    <>

                        <div
                            className="question-card transition-shadow shadow hover:shadow-2xl"
                            style={
                                {
                                    maxHeight: flexibleHeight ? "fit-content" : "350px",
                                    // minWidth: "350px",
                                    // maxWidth: "350px",
                                    // height: "300px",
                                    // overflow: "clip",

                                }}>
                            {/* <Question> */}
                            <QuestionCardPreview
                                fixedHeight={!flexibleHeight}
                                borderless={true}
                                hoverable={false}
                                className="fixed-height-card"
                                cardTitle={"Question " + (i + 1)}
                                onCardClick={(() => {
                                    if (!disableEdit) {
                                        setEditQuestion(q)
                                        setShowQuestionModal(true)
                                        setShouldBlockNavigation(true)
                                    } else {
                                        if (onQuestionSelect) {
                                            onQuestionSelect(q);
                                        } else {
                                            notification.info({ message: "Cannot edit this question", key: "edit-alert" })
                                        }
                                    }
                                })}
                                showDelete={!disableEdit}
                                showEdit={!disableEdit}
                                onEditClicked={(() => {
                                    if (!disableEdit) {
                                        setEditQuestion(q)
                                        setShowQuestionModal(true)
                                        setShouldBlockNavigation(true)
                                    }
                                })}
                                disableImagePreview={true}
                                onDeleteClicked={() => deleteQuestion(q)}
                                question={q}
                            />

                            {/* </Question> */}
                        </div>
                    </>
                    //     )}

                    // </Composition>
                ))}

            </Space>}
        </div>
    </>
}

export default QuestionGridManager;