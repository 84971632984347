import * as Sentry from "@sentry/react";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import {
  authToken,
  currentUser as currentUserSelector,
} from "@/Models/Selectors";
import { closeSocket } from "./Socket/Socket";
import { fetchCurrentUser, setSignupEmail, signin } from "./User";
import {
  GOOGLE_CLIENT_ID,
  isEmbed,
  isRunningAsZoomApp,
  persistToken,
  ZOOM_APP_OAUTH_STATE_SECRET,
  ZOOM_CLIENT_ID,
} from "./Constants";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Analytics from "@/Services/Analytics";
import { setCurrentPath } from "./Router";

export const onLoginSuccess = function (authToken: string) {
 setAccessToken(authToken);
  let s = store.getState()
    if(s.auth.status === 'authenticating'){
        store.dispatch({
            type: Actions.AUTH.authenticated,
            payload: authToken
           })}   
};

const googleAuthScope =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid";

const onLoginFailure = function () {
  store.dispatch({
    type: Actions.AUTH.signedout,
    payload: null,
  });
};

const onLogout = function () {
  //return false;
  const currentUser = currentUserSelector(store.getState());
  localStorage.removeItem("Btoken");
  localStorage.removeItem("Gtoken");
  localStorage.removeItem("signup_email");
  store.dispatch({
    type: Actions.AUTH.signedout,
    payload: null,
  });
  store.dispatch({
    type: Actions.USERS.DELETE,
    payload: currentUser,
  });
  setCurrentPath("/");

  // store.dispatch()
};

export const getSignupEmail = () => {
  return localStorage.getItem("signup_email");
};

export const isGoogleSignup = (): boolean => {
  const token = localStorage.getItem("Gtoken");
  return token != null && token.length > 0;
};

export const getGoogleToken = () => localStorage.getItem("Gtoken");

export const getAccessToken = () => {
  if (!persistToken) return authToken(store.getState());

  return localStorage.getItem("Btoken");
};

export const setAccessToken = (token: string) => {
  if (persistToken) localStorage.setItem("Btoken", token);
};

export const logout = async function () {
  Analytics.track("Signout");

  if (isGoogleSignup()) {
    gapi.auth2.getAuthInstance().disconnect();
    gapi.auth2.getAuthInstance().signOut();
  }

  onLogout();
  // window.location.pathname = "/";
};

const createRequestParamString = (params: any) => {
  const requestParams = new URLSearchParams();

  for (let param in params) {
    const value = params[param];
    requestParams.set(param, value);
  }

  return requestParams.toString();
};

export const onAuthStart = function () {
  console.log("login button clicked");
  Sentry.captureMessage("Login button clicked");
  store.dispatch({
    type: Actions.AUTH.authenticating,
    payload: null,
  });
  //gapi.auth2.getAuthInstance()
  if (isRunningAsZoomApp) onZoomSignIn();
  else onBrowserSignIn();
  // gapi.auth2.getAuthInstance().signIn();
  // window.initClient();
};

export const getUserGoogleEmail = () => {
  const token = getGoogleToken();
  const uninterceptedAxiosInstance = axios.create();
  const GoogleProfileUrl =
    "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + token;
  return fetch(GoogleProfileUrl);
};

const onZoomSignIn = function () {
  console.log("existing agent", navigator.userAgent);
  const redirectUrl = window.location.origin + "/gauth-login";

  gapi.auth2
    .getAuthInstance()
    .signIn({
      ux_mode: "redirect",
      redirect_uri: redirectUrl,
      scope: googleAuthScope,
      // fetch_basic_profile: true,
      // prompt: 'select_account', // this prevents auto-sign in when theres single account in browser
    })
    .then(() => {
      const authObject = gapi.auth2.getAuthInstance();
      if (authObject.isSignedIn.get()) {
        onSignIn(authObject);
      } else {
        onLogout();
      }
    });
};

const onBrowserSignIn = () => {
  console.log("existing agent", navigator.userAgent);
  const redirectUrl = window.location.origin + "/gauth-login";
  // const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=468450185582-7nukfc72fgjjf91blft52t6vljbf5lj9.apps.googleusercontent.com&response_type=token&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email%20openid&redirect_uri=' + redirectUrl;

  // window.location.href = googleAuthUrl;

  gapi.auth2
    .getAuthInstance()
    .signIn({
      ux_mode: "popup",
      scope: googleAuthScope,
      // fetch_basic_profile: true,
      // prompt: 'select_account', // this prevents auto-sign in when theres single account in browser
    })
    .then(() => {
      const authObject = gapi.auth2.getAuthInstance();
      if (authObject.isSignedIn.get()) {
        onSignIn(authObject);
      } else {
        onLogout();
      }
    });
};

// @ts-expect-error
const onSignIn = function (authObject) {
  // return;
  const googleUser = authObject.currentUser.get();
  console.log("Signed in ", googleUser);
  //@ts-expect-error
  window.googleUser = googleUser;
  const email = googleUser.getBasicProfile().getEmail();
  const token = googleUser.getAuthResponse().id_token;
  localStorage.setItem("Gtoken", token);
  signin(email, token, "gauth")
    .then((data) => {
      if (data.code === 3) {
        // signup
      }
      if (data.code === 0) console.log("gauth login success");
    })
    .catch(() => {
      logout();
    });
  // onLoginSuccess(token);
};

const onSignInWithToken = function (token: string) {
  onLoginSuccess(token);
};

export const getTokenFromUrl = (hashUrl: string) => {
  return hashUrl.split("&")[1].split("=")[1];
};

// Initiate google SDK by default on app load
// Try logging in. If fails, check if this was a Google login.
// if not, do nothing. If yes, then logout as google login failed.
//@ts-ignore
window.initAuth = function () {
  console.log("window initAuth");

  gapi.load("auth2", function () {
    /* Ready. Make a call to gapi.auth2.init or some other API */
    console.log("gapi auth2 loaded");

    const gAuth = gapi.auth2.init({
      client_id: GOOGLE_CLIENT_ID,
      // cookie_policy: 'single_host_origin',
      scope: googleAuthScope,
    });

    gAuth.then(
      (authObject) => {
        console.log("auth object", authObject);
        if (authObject.isSignedIn.get()) {
          onSignIn(authObject);
        } else {
          if (isGoogleSignup()) {
            console.log("logout because authObject is not signed in");
            logout();
          }
        }

        authObject.isSignedIn.listen((isSignedIn) => {
          if (isSignedIn) {
            onSignIn(authObject);
          } else {
            console.log("logout because listening to authObject.isSignedIn");
            logout();
          }
        });
      },
      (reason: { error: string; details: string }) => {
        console.error("Gauth failed to load -");
        console.error(reason.error);
        console.error(reason.details);
        onLoginFailure();
      }
    );
    const token = getAccessToken();
    if (token) {
      onLoginSuccess(token);
    } else {
      onLogout();
    }
  });

  // axios.get('https://accounts.google.com/o/oauth2/v2/auth', {
  //     params: {
  //         client_id: '468450185582-7nukfc72fgjjf91blft52t6vljbf5lj9.apps.googleusercontent.com',
  //         redirect_uri: window.location.href,
  //         response_type: "token",
  //         scope: "email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid",
  //     }
  // })

  // const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=468450185582-7nukfc72fgjjf91blft52t6vljbf5lj9.apps.googleusercontent.com&response_type=token&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email%20openid&redirect_uri=' + window.location.origin;

  // // window.location.href = googleAuthUrl;

  // gapi.load('auth2', function () {
  //     /* Ready. Make a call to gapi.auth2.init or some other API */
  //     console.log('gapi auth2 loaded');

  //     const gAuth = gapi.auth2.init({
  //         client_id: '468450185582-7nukfc72fgjjf91blft52t6vljbf5lj9.apps.googleusercontent.com',
  //     })

  //     gAuth.then((authObject) => {
  //         console.log('auth object', authObject);
  //         if (authObject.isSignedIn.get()) {
  //             // window.location.href = googleAuthUrl;
  //             onSignIn(authObject);
  //         } else {
  //             onLogout();
  //         }
  //         authObject.currentUser.listen((currentUser) => {

  //             console.log("currentUser");
  //             console.log(currentUser);
  //         })
  //         authObject.isSignedIn.listen((isSignedIn) => {
  //             console.log("isSignedIn")
  //             console.log(isSignedIn)

  //             if (isSignedIn) {
  //                 // window.history.pushState({}, "", googleAuthUrl);
  //                 onSignIn(authObject);
  //             } else {
  //                 onLogout();
  //             }
  //         })

  //     }, ((reason: { error: string, details: string }) => {
  //         console.error('Gauth failed to load -');
  //         console.error(reason.error);
  //         console.error(reason.details);
  //         onLoginFailure();
  //     }))
  // });
};
