type Props = {
    body: string;
    className: string
};

const QuestionTitlePreview = ({ body, className }: Props) => {
    if (!body) return <div className={className} />
    return (
        <div className={className}
            style={{

            }}
        >
            {body.split("\n").map((i, key) => {
                return (i === '') ? <br /> : <div key={key}> {i}</div>;
            }
            )}
        </div>
    )
}
export default QuestionTitlePreview;
