import Actions from '@/Models/Actions';

export type AUTH_STATUS = "init" | 'authenticated' | 'authenticating' | 'signed-out' | 'no-current-user' | 'onboarding' | 'active' | 'unreachable';
type authState = {
    status: AUTH_STATUS,
    // signup_complete: boolean
    // signup_step: 0 | 1 | 2 | 3, // signup up | create class | select package | signup complete
    token: string
}

// init, authenticated, no-current-user, signing-up, signed-in, authenticating,
const authInitialState: authState = {
    status: "init",
    token: ''
    // signup_complete: false,
    // signup_step: 0
}

const authReducer = function (state = authInitialState, action: reduxAction): authState {
    switch (action.type) {
        case Actions.AUTH.init:
            return {
                ...state,
                status: 'init',
                token: '',
            }
        case Actions.AUTH.authenticating:
            return {
                ...state,
                status: 'authenticating',
                token: ''
            }
        case Actions.AUTH.authenticated:
            const token = action.payload;
            return {
                ...state,
                token,
                status: 'authenticated'
            }
        case Actions.AUTH.signedout:
            return {
                ...state,
                token: '',
                status: 'signed-out'
            }
        case Actions.AUTH.noCurrentUser:
            return {
                ...state,
                status: 'no-current-user'
            }
        case Actions.AUTH.onboarding:

            return {
                ...state,
                token: action.payload,
                status: 'onboarding'
            }
        case Actions.AUTH.active:
            return {
                ...state,
                status: 'active'
            }
        case Actions.AUTH.unreachable:
            return {
                ...state,
                status: 'unreachable'
            }
        default:
            return state;
    }
}

export type { authState };

export default authReducer;