



import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Composition } from "atomic-layout";
import { Breadcrumb, Button, Divider, Input, Layout, Menu, Modal, notification, PageHeader, Result, Select, Space, Tabs, DatePicker, Card, Statistic, Row, Col, Progress, Table, Tooltip } from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import { classesForNewSession, cumilativeStatsForStudents, currentSession, getSessionById, getUserById, isFetchingClasses, myClasses } from "@/Models/Selectors";
import { fetchAllClasses, fetchClassSessions, startSessionForClass } from "@/Services/Class";
import InviteModal from "@/Components/Class/inviteModal";
import { Link, Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { AppstoreOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, CalendarOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, DownloadOutlined, DownOutlined, FundProjectionScreenOutlined, LaptopOutlined, LikeOutlined, NotificationOutlined, PlusOutlined, QuestionCircleOutlined, SettingOutlined, StarOutlined, TeamOutlined, UngroupOutlined, UserOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Engagement from "@/Icons/engagement.svg"

import "./Stats.css"
import store from "@/Models/store";
import ClassStudentsStats from "./ClassStudentsStats";
import ClassSessionStats from "./ClassSessionStats";
import Loader from "@/Components/Loader";

import correct_icon from '@/Assets/correct.png';
import incorrect_icon from '@/Assets/refresh.png';
import absent_icon from '@/Assets/question_mark.png';
import engagement_icon from '@/Assets/exclamation.png';



import moment from 'moment';
// import { DualAxes, Line, TinyArea } from '@ant-design/charts';
import { LabelList, Bar, ComposedChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as ChartToolTip, ResponsiveContainer, Legend } from 'recharts';

import UserAvatar from "../Common/Image/UserAvatar";
import StatsTiles from "./StatsTiles";
import { DefaultTileCardTextColor } from "@/Services/Constants";
import UserLabel from "../Common/Image/UserLabel";
import { convertJsonToCSV, downloadCSVFile } from "../Common/CSVConverter";
import { start } from "repl";
import Analytics from "@/Services/Analytics";

const { RangePicker } = DatePicker;


const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
    classId: string,
    studentId: string,
}
const { TabPane } = Tabs;


interface StatsComponent extends RouteComponentProps<RouteParams> {
}

const SampleStudentDetailStats: React.FC<StatsComponent> = (props) => {

    const { SubMenu } = Menu;
    const { Header, Content, Sider } = Layout;
    const [sortedInfo, setSortedInfo] = useState<{ "columnKey": string, "order": string }>();

    // const allClasses = useSelector(classesForNewSession);
    const listOfClasses = useSelector(myClasses)();
    const fetchingClasses = isFetchingClasses(store.getState());
    const hasFetchedData = useRef(false);
    const [allClasses, setAllClasses] = useState<Class[]>(listOfClasses);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(90, 'days').toDate())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateDiff, setDateDiff] = useState(moment(endDate).diff(moment(startDate), 'days'))
    const previousStartDate = moment(startDate).subtract(dateDiff, 'days').toDate()
    const [isFetchingData, setIsFetchingData] = useState(false);

    const history = useHistory();


    const [currentClassId, setCurrentClassId] = useState<string>(
        allClasses.length === 1 ? allClasses[0]?.id : ""
    );
    const classIdInURL = props.match.params.classId;
    const studentIdInURL = props.match.params.studentId;
    const currentStudentId = useRef(studentIdInURL);
    const _class: Class = allClasses.filter((c: Class) => c.id == classIdInURL)[0];
    const selectedStudent = useSelector(getUserById)(studentIdInURL)
    const currentSessionObj = useSelector(currentSession);
    const studentsStatsCurrent = useSelector(cumilativeStatsForStudents)(classIdInURL || '', startDate, endDate)
        .filter((stat: studentStats) => stat.email === studentIdInURL);
    const studentsStatsPast = useSelector(cumilativeStatsForStudents)(classIdInURL || '', previousStartDate, startDate);

    const tabValues = [
        'sessions',
        'students',
        'configure'
    ]


    // const studentMenu = _class.students_email?.map((email: string) => {
    //     const student = getUserById(store.getState())(email)
    //     return {
    //         path: 'student/' + student.email,
    //         breadcrumbName: student.full_name,
    //     }
    // });
    function itemRenderer(route: any, params: any, routes: any, paths: any) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span>{route.breadcrumbName}</span>
        ) : (
            <Link to={'/' + paths.join('/')}>{route.breadcrumbName}</Link>
        );
    }
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        Analytics.track('View Sample Student Stats');
    }, [])


    useEffect(() => {
        if (_class) {
            hasFetchedData.current = false;
            setIsFetchingData(true);
            fetchClassSessions(_class).then(() => {
                hasFetchedData.current = true;
                setIsFetchingData(false);
            }).finally(() => {
            });
        }
    }, [_class]);

    useEffect(() => {
        if ((!hasFetchedData.current || currentStudentId.current != studentIdInURL)
            && !isFetchingData
            && _class) {
            fetchClassSessions(_class).then(() => {
                // setSessions(sessions)
                hasFetchedData.current = true;
                currentStudentId.current = studentIdInURL
            });
        }
        onTop()
    }, [studentIdInURL]);


    // if (!classIdInURL && allClasses.length > 0) return <Redirect to={"analyse/class/" + allClasses[0].id} />

    if (!fetchingClasses && classIdInURL !== 'add' && !_class) {
        // fetchAllClasses();
    }

    function onDateChange(dates: any, dateStrings: any) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        // setStartDate(dates[0].toDate());
        // setEndDate(dates[1].toDate());
        // setDateDiff(moment(dates[1]).diff(moment(dates[0]), 'days'))
    }

    const studentBreakdownDataSource = [{ "key": 6226, "email": "jennifer.carroll@vidya.us", "user": null, "name": "Jennifer Carroll", "engagement": 100, "correct": 80, "incorrect": 10, "responses": 100, "dontKnow": 10, "attendance": 100 },
    { "key": 8583, "email": "benjamin.lopez@vidya.us", "user": null, "name": "Benjamin Lopez", "engagement": 55, "correct": 30, "incorrect": 10, "responses": 55, "dontKnow": 15, "attendance": 80 },
    { "key": 7311, "email": "james.lynch@vidya.us", "user": null, "name": "James Lynch", "engagement": 70, "correct": 50, "incorrect": 20, "responses": 70, "dontKnow": 0, "attendance": 80 },
    { "key": 5384, "email": "mark.clark@vidya.us", "user": null, "name": "Mark Clark", "engagement": 64, "correct": 45, "incorrect": 19, "responses": 64, "dontKnow": 0, "attendance": 85 },
    { "key": 5195, "email": "dana.johnson@vidya.us", "user": null, "name": "Dana Johnson", "engagement": 82, "correct": 75, "incorrect": 7, "responses": 82, "dontKnow": 0, "attendance": 100 },
    { "key": 1749, "email": "jillian.arnold@vidya.us", "user": null, "name": "Jillian Arnold", "engagement": 60, "correct": 50, "incorrect": 10, "responses": 60, "dontKnow": 0, "attendance": 80 },
    { "key": 9131, "email": "melissa.harris@vidya.us", "user": null, "name": "Melissa Harris", "engagement": 70, "correct": 40, "incorrect": 20, "responses": 70, "dontKnow": 10, "attendance": 70 },
    { "key": 6683, "email": "kevin.tucker@vidya.us", "user": null, "name": "Kevin Tucker", "engagement": 55, "correct": 30, "incorrect": 10, "responses": 55, "dontKnow": 15, "attendance": 70 },
    { "key": 5200, "email": "todd.lawson@vidya.us", "user": null, "name": "Todd Lawson", "engagement": 25, "correct": 10, "incorrect": 0, "responses": 25, "dontKnow": 15, "attendance": 25 },
    { "key": 9325, "email": "timothy.walker@vidya.us", "user": null, "name": "Timothy Walker", "engagement": 30, "correct": 20, "incorrect": 10, "responses": 30, "dontKnow": 0, "attendance": 30 },
    { "key": 9954, "email": "susan.kelley@vidya.us", "user": null, "name": "Susan Kelley", "engagement": 10, "correct": 10, "incorrect": 0, "responses": 10, "dontKnow": 0, "attendance": 10 },
    { "key": 3505, "email": "amy.moss@vidya.us", "user": null, "name": "Amy Moss", "engagement": 45, "correct": 35, "incorrect": 10, "responses": 45, "dontKnow": 0, "attendance": 50 },
    { "key": 291, "email": "paige.cruz@vidya.us", "user": null, "name": "Paige Cruz", "engagement": 85, "correct": 55, "incorrect": 15, "responses": 85, "dontKnow": 15, "attendance": 90 },
    { "key": 6191, "email": "tracey.phillips@vidya.us", "user": null, "name": "Tracey Phillips", "engagement": 90, "correct": 40, "incorrect": 20, "responses": 90, "dontKnow": 30, "attendance": 100 },
    { "key": 3297, "email": "kenneth.davis@vidya.us", "user": null, "name": "Kenneth Davis", "engagement": 90, "correct": 70, "incorrect": 15, "responses": 90, "dontKnow": 5, "attendance": 90 },
    { "key": 4822, "email": "wayne.miller@vidya.us", "user": null, "name": "Wayne Miller", "engagement": 100, "correct": 75, "incorrect": 5, "responses": 100, "dontKnow": 20, "attendance": 100 },
    { "key": 2416, "email": "anthony.price@vidya.us", "user": null, "name": "Anthony Price", "engagement": 100, "correct": 65, "incorrect": 30, "responses": 100, "dontKnow": 5, "attendance": 100 },
    { "key": 5597, "email": "brian.cantu@vidya.us", "user": null, "name": "Brian Cantu", "engagement": 65, "correct": 50, "incorrect": 10, "responses": 65, "dontKnow": 5, "attendance": 70 },
    { "key": 9548, "email": "christina.robinson@vidya.us", "user": null, "name": "Christina Robinson", "engagement": 80, "correct": 60, "incorrect": 20, "responses": 80, "dontKnow": 0, "attendance": 90 },
    { "key": 3326, "email": "ronnie.myers@vidya.us", "user": null, "name": "Ronnie Myers", "engagement": 85, "correct": 80, "incorrect": 0, "responses": 85, "dontKnow": 5, "attendance": 90 }]


    const studentsStatsDummy = [
        {
            "key": 520,
            "startDate": "January 1, 2023",
            "sessionName": "Algebra 1",
            "engagement": 100,
            "correctness": 80,
            "incorrectness": 10,
            "responses": 100,
            "dontknow": 10
        },
        {
            "key": 680,
            "startDate": "January 2, 2023",
            "sessionName": "Algebra 2",
            "engagement": 55,
            "correctness": 30,
            "incorrectness": 10,
            "responses": 55,
            "dontknow": 15
        },
        {
            "key": 881,
            "startDate": "January 3, 2023",
            "sessionName": "Calculus and analysis 1",
            "engagement": 0,
            "correctness": 0,
            "incorrectness": 0,
            "responses": 0,
            "dontknow": 0
        },
        {
            "key": 548,
            "startDate": "January 4, 2023",
            "sessionName": "Calculus and analysis 2",
            "engagement": 64,
            "correctness": 45,
            "incorrectness": 19,
            "responses": 64,
            "dontknow": 0
        },
        {
            "key": 816,
            "startDate": "January 5, 2023",
            "sessionName": "Calculus and analysis 3",
            "engagement": 82,
            "correctness": 75,
            "incorrectness": 7,
            "responses": 82,
            "dontknow": 0
        },
        {
            "key": 274,
            "startDate": "January 6, 2023",
            "sessionName": "Calculus and analysis 4",
            "engagement": 60,
            "correctness": 50,
            "incorrectness": 10,
            "responses": 60,
            "dontknow": 0
        },
        {
            "key": 439,
            "startDate": "January 7, 2023",
            "sessionName": "Geometry and topology 1",
            "engagement": 70,
            "correctness": 40,
            "incorrectness": 20,
            "responses": 70,
            "dontknow": 10
        },
        {
            "key": 900,
            "startDate": "January 8, 2023",
            "sessionName": "Geometry and topology 2",
            "engagement": 0,
            "correctness": 0,
            "incorrectness": 0,
            "responses": 0,
            "dontknow": 0

        },
        {
            "key": 232,
            "startDate": "January 9, 2023",
            "sessionName": "Combinatorics 1",
            "engagement": 25,
            "correctness": 10,
            "incorrectness": 0,
            "responses": 25,
            "dontknow": 15
        },
        {
            "key": 996,
            "startDate": "January 10, 2023",
            "sessionName": "Combinatorics 2",
            "engagement": 30,
            "correctness": 20,
            "incorrectness": 10,
            "responses": 30,
            "dontknow": 0
        },
        {
            "key": 672,
            "startDate": "January 11, 2023",
            "sessionName": "Combinatorics 3",
            "engagement": 10,
            "correctness": 10,
            "incorrectness": 0,
            "responses": 10,
            "dontknow": 0
        },
        {
            "key": 453,
            "startDate": "January 12, 2023",
            "sessionName": "Logic 1",
            "engagement": 45,
            "correctness": 35,
            "incorrectness": 10,
            "responses": 45,
            "dontknow": 0
        },
        {
            "key": 747,
            "startDate": "January 13, 2023",
            "sessionName": "Logic 2",
            "engagement": 85,
            "correctness": 55,
            "incorrectness": 15,
            "responses": 85,
            "dontknow": 15
        },
        {
            "key": 929,
            "startDate": "January 14, 2023",
            "sessionName": "Number theory 1",
            "engagement": 0,
            "correctness": 0,
            "incorrectness": 0,
            "responses": 0,
            "dontknow": 0
        },
        {
            "key": 647,
            "startDate": "January 15, 2023",
            "sessionName": "Number theory 2",
            "engagement": 0,
            "correctness": 0,
            "incorrectness": 0,
            "responses": 0,
            "dontknow": 0
        },
        {
            "key": 179,
            "startDate": "January 16, 2023",
            "sessionName": "Differential equations 1",
            "engagement": 100,
            "correctness": 75,
            "incorrectness": 5,
            "responses": 100,
            "dontknow": 20
        },
        {
            "key": 317,
            "startDate": "January 17, 2023",
            "sessionName": "Differential equations 2",
            "engagement": 100,
            "correctness": 65,
            "incorrectness": 30,
            "responses": 100,
            "dontknow": 5
        },
        {
            "key": 826,
            "startDate": "January 18, 2023",
            "sessionName": "Differential equations 3",
            "engagement": 65,
            "correctness": 50,
            "incorrectness": 10,
            "responses": 65,
            "dontknow": 5
        },
        {
            "key": 112,
            "startDate": "January 19, 2023",
            "sessionName": "Mathematical physics 1",
            "engagement": 80,
            "correctness": 60,
            "incorrectness": 20,
            "responses": 80,
            "dontknow": 0
        },
        {
            "key": 88,
            "startDate": "January 20, 2023",
            "sessionName": "Mathematical physics 2",
            "engagement": 0,
            "correctness": 0,
            "incorrectness": 0,
            "responses": 0,
            "dontknow": 0
        }
    ]

    const studentDataSource = studentsStatsDummy
        .map((stat) => {
            // const statTime = stat.startDate.getDate() + '-' + (stat.startDate.getMonth() + 1) + '-' + stat.startDate.getFullYear()
            const sessionName = stat.sessionName
            return {
                display_time: stat.startDate,
                date: new Date(stat.startDate),
                key: stat.key,
                engagement: stat.engagement,
                correctness: stat.correctness,
                incorrectness: stat.incorrectness,
                dontknow: stat.dontknow,
                sessionName: stat.sessionName,
                title: sessionName + ' (' + moment(stat.startDate).format('dddd, MMMM Do') + ')'
            }
        })
    const engagement_data = studentDataSource.map((stat) => stat.engagement)
    const correctness_data = studentDataSource.map((stat) => stat.correctness)
    const attendance_data = studentDataSource.map((stat) => stat.engagement === 0 ? 0 : 100)

    let arrTime = []

    const dayMillisec = 24 * 60 * 60 * 1000
    for (let q = studentDataSource[0]?.date; q <= endDate; q = new Date(q.getTime() + dayMillisec)) {
        arrTime.push(q);
    }
    let session_data: number[] = []
    arrTime.map((date: Date) => {
        const sessionExist = studentDataSource.filter((stat) => moment(stat.date).isSame(date, 'days'))
        session_data.push(sessionExist.length > 0 ? 1 : 0)
    })

    const graphConfig = {
        data: studentDataSource,
        xField: 'display_time',
        yField: 'correctness',
        tooltip: {
            title: 'title',
        },
    };

    const allStudentsColumns: Array<any> = [
        {
            "title": <UserOutlined />,
            "dataIndex": "sessionName",
            "key": "sessionName",
            width: 100,
            sortOrder: sortedInfo ? (sortedInfo.columnKey === 'name' || sortedInfo.columnKey === "attendance") && sortedInfo.order : null,
            showSorterTooltip: { title: "Name" }
            // render: (text: any, record: any, index: any) => text ? <Link to={'/analyse/class/' + classIdInURL}><UserLabel email={record.key} /></Link> : record.key

        },
        {
            "title": <CalendarOutlined />,
            "dataIndex": "date",
            "key": "date",
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => Date.parse(a.date) - Date.parse(b.date),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'date' && sortedInfo.order : null,
            showSorterTooltip: { title: "Date" },
            render: (text: any, record: any, index: any) => moment(text).format('MMMM D, YYYY')

        },
        {
            "title": <StarOutlined />,
            "dataIndex": "engagement",
            "key": "engagement",
            sorter: (a: any, b: any) => parseInt(a.engagement) - parseInt(b.engagement),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'engagement' && sortedInfo.order : null,
            showSorterTooltip: { title: "Engagement" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0

        },
        {
            "title": <CheckCircleOutlined />,
            "dataIndex": "correctness",
            "key": "correctness",
            sorter: (a: any, b: any) => parseInt(a.correctness) - parseInt(b.correctness),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'correctness' && sortedInfo.order : null,
            showSorterTooltip: { title: "Correct Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        },
        {
            "title": <CloseCircleOutlined />,
            "dataIndex": "incorrectness",
            "key": "incorrectness",
            sorter: (a: any, b: any) => parseInt(a.incorrectness) - parseInt(b.incorrectness),
            width: 20,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'incorrectness' && sortedInfo.order : null,
            showSorterTooltip: { title: "Incorrect Answers" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        },
        {
            "title": <QuestionCircleOutlined />,
            "dataIndex": "dontKnow",
            "key": "dontKnow",
            sorter: (a: any, b: any) => parseInt(a.dontKnow) - parseInt(b.dontKnow),
            width: 50,
            sortOrder: sortedInfo ? sortedInfo.columnKey === 'dontKnow' && sortedInfo.order : null,
            showSorterTooltip: { title: "Note Sures" },
            render: (text: any, record: any, index: any) => text ? text + '%' : 0
        }
    ];
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo(sorter)
    };

    const routes = [
        {
            path: '/analyse',
            breadcrumbName: 'Analyse',
        },
        {
            path: "/sample-class",
            breadcrumbName: "Sample Class",
        },
        {
            path: "/analyse/sample-class/sample-student/" + selectedStudent.email,
            breadcrumbName: selectedStudent.first_name + " " + selectedStudent.last_name
        }
    ];

    const columnsLayout = {
        xs: 24,
        sm: 12,
        md: 6,
        lg: 6
    }

    const downloadClassStats = () => {
        console.log("download stats for ", selectedStudent.full_name);
        const csvArray: {}[] = [];
        studentsStatsDummy.forEach((stat, index) => {
            var csvObj = {
                '#': index + 1,
                Session_Name: stat.sessionName,
                Date: moment(stat.startDate,).format('LL'),
                Class: "Sample Class",
                Student_Name: selectedStudent.full_name,
                Student_Email: selectedStudent.email,
                Attendance: stat.engagement == 0 ? 'Absent' : 'Present',
                Engagement_Percentage: stat.engagement + "%",
                Correct_Percentage: stat.correctness + "%",
                Incorrect_Percentage: stat.incorrectness + "%",
                DontKnow_Percentage: stat.dontknow + "%"
            }
            csvArray.push(csvObj);
        })
        const csvStats = convertJsonToCSV(csvArray);
        console.log(csvStats);
        downloadCSVFile(csvStats, "Vidya Class Stats - " + selectedStudent.full_name + " (from  " + moment(startDate).format('DD.MM.YY') + ' - ' + moment(endDate).format('DD.MM.YY') + ').csv')
    };




    const currentStats = {
        EngagementPercentage: 80,
        CorrectPercentage: 50,
        IncorrectPercentage: 12,
        AttendancePercentage: 75,
        TotalSessions: 20
    };
    const pastStats = {
        EngagementPercentage: 50,
        CorrectPercentage: 40,
        IncorrectPercentage: 0,
        AttendancePercentage: 90,
        TotalSessions: 22
    };;

    return (
        <>
            <Layout
            // style={{ height: "100vh" }}
            >
                {<>
                    {<>

                        <Layout
                            className="m-auto p-2 sm:p-3 sm:w-full w-full"
                            style={
                                {
                                    // padding: '0 24px 24px',
                                    maxWidth: '1500px',
                                    minHeight: '100vh',
                                    height: 'fit-content'
                                }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    margin: 12,
                                    minHeight: 280,
                                }}
                            >
                                <Layout className="site-layout-background lyt"
                                    style={{
                                        padding: '0 0'
                                    }}>
                                    <Sider
                                        style={{
                                            overflow: 'auto',
                                            left: 0,

                                        }}
                                        width={200}
                                        collapsedWidth={40}
                                        collapsible={true}
                                        breakpoint="lg"
                                        className="site-layout-background"
                                    >
                                        <Menu
                                            theme="light"
                                            mode="inline"
                                            defaultSelectedKeys={[studentIdInURL]}
                                            defaultOpenKeys={['sub1']}
                                            style={{
                                                height: '100%', borderRight: 0, paddingTop: 10,
                                                background: '#fafafa'
                                            }}
                                        >
                                            {studentBreakdownDataSource.map((student) => {
                                                return <Menu.Item
                                                    key={student.email}>
                                                    <Link to={"/analyse/sample-class/sample-student/" + student.email} >
                                                        <UserLabel email={student.email} />
                                                    </Link>
                                                </Menu.Item>
                                            })}
                                        </Menu>
                                    </Sider>
                                    <Content className="vidya-page-layout" style={{ padding: '18px 24px' }}>
                                        {isFetchingData ?
                                            <Loader /> :
                                            <div className="text-left">
                                                <PageHeader
                                                    style={{ padding: 0 }}
                                                    className="site-page-header"
                                                    onBack={() => null}
                                                    title={<span>  <UserAvatar email={studentIdInURL}></UserAvatar> {selectedStudent?.full_name} </span>}
                                                    subTitle={<span>  {selectedStudent.email}</span>}
                                                    backIcon={<Link className="text-black" to={'/analyse/sample-class/'}> <ArrowLeftOutlined /> </Link>}
                                                    breadcrumb={{ routes: routes, itemRender: itemRenderer }}
                                                />
                                                <Composition
                                                    areas={
                                                        `date
                                                            tiles
                                                            graph
                                                            
                                                            studentList
                                                            `}
                                                    // alignItems="start"
                                                    // justifyItems="start"
                                                    gapRow="2em"
                                                    width="100%"
                                                    style={{ display: 'block' }}
                                                >
                                                    {({ Date, Tiles, Graph, StudentBreakdown, SessionList, StudentList }) => (
                                                        <>
                                                            <div className="inline-block mb-4 w-full">
                                                                <Date>
                                                                    <div className='float-right mb-4'>
                                                                        <RangePicker
                                                                            style={{ width: 350 }}
                                                                            ranges={{
                                                                                Yesterday: [moment().subtract(1, "days"), moment()],
                                                                                'Last 7 days': [moment().subtract(7, "days"), moment()],
                                                                                'Last 30 days': [moment().subtract(30, "days"), moment()],
                                                                                'This month': [moment().startOf('month'), moment()],
                                                                                'Last 3 months': [moment().subtract(1, "quarter"), moment()],

                                                                            }}
                                                                            defaultValue={[moment("January 1, 2023"), moment("January 31, 2023")]}
                                                                            onChange={onDateChange}
                                                                            format='MMMM Do, yy'
                                                                        />
                                                                        <Tooltip title="Download Report">
                                                                            <Button
                                                                                className="ml-4"
                                                                                onClick={() => downloadClassStats()} type="text"><DownloadOutlined /></Button>
                                                                        </Tooltip>

                                                                    </div>

                                                                </Date>
                                                                <Divider />
                                                            </div>


                                                            <Tiles>
                                                                <div className="site-statistic-demo-card">
                                                                    <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                                                                        <Col
                                                                            {...columnsLayout}
                                                                            className="gutter-row"
                                                                        // span={16}
                                                                        >
                                                                            <StatsTiles
                                                                                value={currentStats.EngagementPercentage}
                                                                                icon={<StarOutlined />}
                                                                                title="Avg. Engagement"
                                                                                compareNumber={pastStats.EngagementPercentage}
                                                                                type="percent"
                                                                                graphData={engagement_data}
                                                                                tooltip="This compares Total Questions with the Total Responses. Note, absent session would still count as 0 engagement"
                                                                            />
                                                                        </Col>

                                                                        <Col
                                                                            {...columnsLayout}
                                                                            className="gutter-row"
                                                                        >
                                                                            <StatsTiles
                                                                                value={currentStats.CorrectPercentage}
                                                                                icon={<CheckCircleOutlined />}
                                                                                title="Avg. Correctness"
                                                                                compareNumber={pastStats.CorrectPercentage}
                                                                                type="percent"
                                                                                graphData={correctness_data}
                                                                                tooltip="This compares Total Questions with the Total Correct Responses. Note, absent session would still count as 0 correct responsenses"
                                                                            />

                                                                        </Col>
                                                                        <Col
                                                                            {...columnsLayout}
                                                                            className="gutter-row"
                                                                        >
                                                                            <StatsTiles
                                                                                value={currentStats.AttendancePercentage}
                                                                                icon={<UserOutlined />}
                                                                                title="Avg. Attendance"
                                                                                compareNumber={pastStats.AttendancePercentage}
                                                                                type="percent"
                                                                                graphData={attendance_data}
                                                                                tooltip="This is calculated by comparing total sessions attended by the total sessions conducted throughout the selected period"
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            {...columnsLayout}

                                                                            className="gutter-row"
                                                                        >
                                                                            <StatsTiles
                                                                                value={currentStats.TotalSessions}
                                                                                icon={<FundProjectionScreenOutlined />}
                                                                                title="Total Sessions"
                                                                                compareNumber={pastStats.TotalSessions}
                                                                                type="number"
                                                                                graphData={session_data}
                                                                                color={DefaultTileCardTextColor}
                                                                                tooltip="This is the total sessions conducted during the selected period"

                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                            </Tiles>

                                                            <Graph>
                                                                {/* <Line className="mt-12" {...graphConfig} /> */}

                                                                <div style={{ width: '100%', height: 300, marginTop: 50 }}>

                                                                    <ResponsiveContainer width="100%" height="100%">
                                                                        <LineChart
                                                                            width={500}
                                                                            height={300}
                                                                            data={studentDataSource}
                                                                            margin={{
                                                                                top: 5,
                                                                                right: 30,
                                                                                left: 20,
                                                                                bottom: 5,
                                                                            }}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="display_time" />
                                                                            <YAxis />
                                                                            <ChartToolTip
                                                                                label={"title"}

                                                                                content={({ active, payload, label }: any) => {
                                                                                    if (active && payload && payload.length) {
                                                                                        return (
                                                                                            <div
                                                                                                style={{ background: '#f0f0f082', paddingLeft: 10, paddingRight: 10 }}
                                                                                                className="custom-tooltip">
                                                                                                <p className="label bold-heading">{`${payload[0].payload.title}`}</p>
                                                                                                <p className="intro text-green-600">{`Correct : ${payload[0].payload.correctness}%`}</p>
                                                                                                <p className="intro text-blue-600">{`Engagement : ${payload[0].payload.engagement}%`}</p>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    return null;
                                                                                }}
                                                                            // formatter={(value: any, name: any, props: any) => value + "%"}
                                                                            />

                                                                            <Legend verticalAlign="top"
                                                                                formatter={(value, entry, index) => (<span style={{ color: entry?.color }}>{"Correct"}</span>)
                                                                                }
                                                                                values="Correct" />
                                                                            <Line type="monotone" dataKey="correctness" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                                                        </LineChart>

                                                                    </ResponsiveContainer>
                                                                    <span className="bold-heading block" style={{ textAlign: "center" }}>Correctness and Engagement Breakdown</span>

                                                                </div>

                                                            </Graph>

                                                            <StudentList>
                                                                <div className='text-xl mt-12 mb-4'> All Sessions</div>
                                                                <Table
                                                                    className="virtual-table"
                                                                    //   scroll={{ y: 300, x: 100 }}
                                                                    size="small"
                                                                    pagination={{ showSizeChanger: true, pageSize: 100, hideOnSinglePage: true }}
                                                                    dataSource={studentDataSource}
                                                                    columns={allStudentsColumns}
                                                                    onChange={handleChange}
                                                                    rowClassName={(record, index) => {
                                                                        const className = "text-gray-500";
                                                                        // @ts-ignore
                                                                        if (record.engagement === 0) return className
                                                                        return "";
                                                                    }}
                                                                />
                                                            </StudentList>
                                                        </>
                                                    )}

                                                </Composition>
                                            </div>
                                        }
                                    </Content>
                                </Layout>
                            </Content>
                        </Layout>
                    </>
                    }
                </>
                }
            </Layout>
        </>
    );
};


export default SampleStudentDetailStats;
