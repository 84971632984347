import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { Composition } from "atomic-layout";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  Layout,
  notification,
  Select,
  Space,
} from "antd";
import { requestOTP, signUp } from "@/Services/User";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import {
  getGoogleToken,
  getSignupEmail,
  getUserGoogleEmail,
  isGoogleSignup,
  logout,
} from "@/Services/Auth";
import { useSelector } from "react-redux";
import { authStatus } from "@/Models/Selectors";
import store from "@/Models/store";
import { getCurrentPath as currentPathSelector } from "@/Models/Selectors";
import teacher from "@/Assets/teacher.png";
import student from "@/Assets/student.png";
import TeacherSignUpForm from "./TeacherSignUp";
import { Content } from "antd/lib/layout/layout";
import { CheckCircleTwoTone, SwapOutlined } from "@ant-design/icons";
import { createNewClass } from "@/Services/Class";
import { setTimeout } from "timers";

import "./Signup.css";
import _, { upperCase } from "lodash";
import OtpInput from "react-otp-input";
import { isValidEmail } from "./Common/Utils";
import {
  defaultLanguage,
  isRunningAsZoomApp,
  ListOfAllLanguages,
} from "@/Services/Constants";

type singupType = "gauth" | "otp";

type role = "teacher" | "student";

const signUpArea = `
    title
    customForm
`;

export type SignupData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: "TEACHER" | "STUDENT" | "";
  img_url: string;
  signup_type: singupType;
  otp?: string;
};

const defaultState: SignupData = {
  firstName: "",
  lastName: "",
  email: "",
  role: "STUDENT",
  img_url: "",
  signup_type: "otp",
  password: "",
};
interface RouteParams {
  type: "TEACHER" | "STUDENT";
  step: string;
}

interface SignupComponent extends RouteComponentProps<RouteParams> {}

// const ClassStats: React.FC<StatsComponent> = (props) => {

const SignUpForm: React.FC<SignupComponent> = (props) => {
  const currentPath = useSelector(currentPathSelector);
  const signingUpViaJoinLink = (): boolean => {
    const isSigningViaJoin = currentPath?.includes("join-class");
    return isSigningViaJoin;
  };

  const history = useHistory();
  const hasFetchedData = useRef(false);
  const [img_url, setImg_url] = useState("");
  const userTypeInURL = props.match.params.type
    ? props.match.params.type
    : signingUpViaJoinLink()
    ? "STUDENT"
    : "";
  const [step, setStep] = useState(parseInt(props.match.params.step));
  const signupEmail = getSignupEmail();
  const signup_type: singupType = isGoogleSignup() ? "gauth" : "otp";
  const [signUpData, setSignUpData] = useState<SignupData>({
    ...defaultState,
    email: signupEmail || "",
    role: userTypeInURL,
    signup_type,
  });
  const [userType, setUserType] = useState<"TEACHER" | "STUDENT" | "">(
    userTypeInURL
  );
  const [showUserPickerDrawer, setShowUserPickerDrawer] = useState<boolean>(
    !userTypeInURL && !signingUpViaJoinLink()
  );
  const [form] = Form.useForm();
  const status = useSelector(authStatus);
  const [tncAccepted, setTncAccepted] = useState<boolean>(false);

  useEffect(() => {
    setStep(parseInt(props.match.params.step));
  }, [props.match.params.step]);

  const stepUpdated = (step: number) => {
    setStep(step);
  };

  const onSubmit = useCallback(
    (values: any) => {
      const { firstName, lastName, role, img_url, otp, password } = values;
      const { email } = signUpData;

      const username = email;
      Sentry.captureMessage("Sign up clicked");
      const auth_type = isGoogleSignup() ? "gauth" : "custom";
      const token = isGoogleSignup() ? getGoogleToken() : otp;

      if (!isGoogleSignup()) {
      }

      console.log("singup started");
      signUp({
        firstName,
        lastName,
        username,
        email,
        img_url,
        role: "STUDENT",
        auth_type,
        token,
        settings: {},
        password,
      })
        .then(() => {
          console.log("singup complete");
          const url = signingUpViaJoinLink() ? currentPath : "/";
          history.push(url);
          Sentry.captureMessage("Signed up");
        })
        .catch((response) => {
          const { data } = response;
          notification.error({
            message:
              data.message + ". For any help, reach out to support@vidya.us.",
          });
          Sentry.captureMessage(data.message);
        });
    },
    [history]
  );

  const CreateClass = (name: string, subject: string) => {
    createNewClass(
      subject,
      name,
      (data: any) => {
        console.log(data);
        const { Code, Data } = data;
        if (Code != 0) {
          console.log("error while creating class");
          alert("Error while creating class. Try again!");
          return;
        }

        history.push("/signup/TEACHER/1");
      },
      defaultLanguage
    );
  };

  const FinishSignup = () => {
    setTimeout(() => {}, 3000);
    history.push("/signup/TEACHER/1");
  };

  useEffect(() => {
    if (signingUpViaJoinLink()) {
      history.push("/signup/STUDENT/0");
    }
  }, []);

  // useEffect(() => {
  //   setShowUserPickerDrawer(!userTypeInURL);
  // }, [])

  useEffect(() => {
    if (!hasFetchedData.current) {
      if (isGoogleSignup()) {
        //@ts-expect-error
        if (isRunningAsZoomApp || !window.googleUser) {
          getUserGoogleEmail()
            .then((data: any) => {
              return data.json();
            })
            .then((response) => {
              const email = response.email;
              setSignUpData({
                ...signUpData,
                email,
              });
              form.setFieldsValue({ email: email });
              hasFetchedData.current = true;
            })
            .catch((e) => {
              logout();
            });
        } else {
          // googleUser object is unavailable in case of Zoom login
          try {
            //@ts-expect-error
            const basicProfile = window.googleUser.getBasicProfile();
            const firstName = basicProfile.getGivenName();
            const lastName = basicProfile.getFamilyName();
            const img_url = basicProfile.getImageUrl();
            setImg_url(img_url);
            const role = signingUpViaJoinLink()
              ? "STUDENT"
              : userTypeInURL
              ? userTypeInURL
              : "";
            const email = basicProfile.getEmail();
            setSignUpData({
              ...signUpData,
              email,
              firstName,
              lastName,
              img_url,
              role,
            });
            // this sets default value of form. NOTE, ANT Desgin default value or initial value
            // does not set the default values for god knows why.
            form.setFieldsValue({
              firstName: firstName,
              lastName: lastName,
              email: email,
              role: role,
            });
            hasFetchedData.current = true;
          } catch (e) {}
        }
      } else {
        const signup_email = getSignupEmail();
        if (signup_email) {
          setSignUpData({
            ...signUpData,
            email: signup_email,
          });
          form.setFieldsValue({ email: signup_email });
        }
      }
      hasFetchedData.current = true;
    }
  }, [form, signUpData]);

  const evaluateStep = (signUpData: SignupData) => {};

  if (status !== "no-current-user") {
    // return <Redirect to="/" />
  }

  const onFinish = (values: any) => {
    const { firstName, lastName, email, role, password } = values;
    console.log("Signup Success:", values);
    const token = isGoogleSignup() ? getGoogleToken() : signUpData.otp;
    const auth_type = isGoogleSignup() ? "gauth" : "custom";

    // //@ts-expect-error
    // const basicProfile = window.googleUser.getBasicProfile();

    setSignUpData({
      ...signUpData,
      email,
      firstName,
      lastName,
      role,
      img_url,
      password,
    });
    onSubmit({ ...values, img_url });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Signup Error:", errorInfo);
  };

  const onCancel = () => {
    console.log("cancel pressed");
    logout();
    history.push("/login");
  };

  const setUser = (user: "TEACHER" | "STUDENT") => {
    setSignUpData({ ...signUpData, role: user });
    // setUserType(user);
    setShowUserPickerDrawer(false);
    history.push("/signup/" + user + "/0");
  };

  const drawerCloseClicked = () => {
    if (!!signUpData.role) {
      setShowUserPickerDrawer(false);
    } else {
      history.push("/login");
    }
  };

  return (
    <>
      <Layout
        style={{
          overflow: "auto",
          minWidth: "340px",
          overflowX: "scroll",
          minHeight: "max-content",
        }}
      >
        <Drawer
          title=""
          placement={"bottom"}
          width={500}
          height={"100%"}
          className="ml-auto mr-auto"
          closable
          onClose={() => drawerCloseClicked()}
          visible={showUserPickerDrawer}
        >
          <div className="text-2xl text-center mb-12 w-full">
            {" "}
            I am here as a{" "}
          </div>

          <Space
            className="w-full justify-center"
            size={25}
            align="center"
            wrap
            direction="horizontal"
          >
            <Card
              hoverable
              className={
                signUpData.role === "TEACHER" ? "border-bottom-blue" : "teacher"
              }
              style={{ minWidth: 290, maxWidth: 440 }}
              cover={<img alt="example" src={teacher} />}
              onClick={() => setUser("TEACHER")}
            >
              <div className="text-center text-xl">Teacher</div>
              {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
            </Card>
            <Card
              hoverable
              className={
                signUpData.role === "STUDENT" ? "border-bottom-blue" : ""
              }
              style={{ minWidth: 290, maxWidth: 440 }}
              cover={<img alt="example" src={student} />}
              onClick={() => setUser("STUDENT")}
            >
              <div className="text-center text-xl">Student</div>
              {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
            </Card>
          </Space>
        </Drawer>
        <Layout style={{ overflow: "auto" }} className="layout">
          <Content
            className={
              "site-layout-background " +
              (step == 2 ? "onboarding-step-2" : "onboarding-step-1")
            }
            style={{
              padding: "0 20px",
              background: "white",
              // minHeight: step == 2 ? 'calc(100vh + 1300px)' ,
              marginTop: "24px",
              marginLeft: "5%",
              marginRight: "5%",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <Composition
              areas={signUpArea}
              autoRows={150}
              justify="center"
              className="w-full mx-auto min-h-screen"
            >
              {({ Title, CustomForm }) => (
                <>
                  <Title className="h-20 text-3xl">
                    <h1 className="padding-top-1"> User Registration</h1>
                    {step === 0 ? (
                      <div className="text-base mb-24">
                        as a {signUpData.role}
                        <button
                          className="text-blue text-xs ml-2"
                          onClick={() => setShowUserPickerDrawer(true)}
                        >
                          {" "}
                          (change)
                        </button>
                      </div>
                    ) : null}
                  </Title>
                  <CustomForm>
                    {upperCase(signUpData.role) === "TEACHER" ? (
                      <TeacherSignUpForm
                        stepUpdated={stepUpdated}
                        signUpInfo={signUpData}
                        updateInfo={(signupdata: SignupData) =>
                          setSignUpData(signUpData)
                        }
                        step={step}
                        // SignUp = {SignUp}
                        // CreateClass = {CreateClass}
                      />
                    ) : (
                      <Form
                        className="mt-12"
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        initialValues={signUpData}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        style={{
                          marginTop: 50,
                        }}
                      >
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          initialValue={signUpData.firstName}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your First Name!",
                            },
                          ]}
                        >
                          <Input value={signUpData.firstName} />
                        </Form.Item>

                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Last Name!",
                            },
                          ]}
                        >
                          <Input defaultValue={signUpData.lastName} />
                        </Form.Item>

                        <Form.Item
                          label="Registration Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your login email!",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="email"
                            name="email"
                            onChange={(e) =>
                              setSignUpData({
                                ...signUpData,
                                email: e.target.value.trim(),
                              })
                            }
                            // value={signUpData.email}
                            defaultValue={signUpData.email}
                            disabled={signUpData.signup_type != "otp"}
                          />
                        </Form.Item>
                        {signUpData.signup_type === "otp" ? (
                          <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the password!",
                              },
                            ]}
                          >
                            <Form.Item name="password" noStyle>
                              <Input
                                defaultValue={signUpData.email}
                                type="password"
                                onChange={(e) => {
                                  setSignUpData({
                                    ...signUpData,
                                    password: e.target.value.trim(),
                                  });
                                }}
                              />
                            </Form.Item>
                          </Form.Item>
                        ) : null}

                        <Form.Item
                          label="Language"
                          name="language"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your phone number!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ textAlign: "left" }}
                            placeholder="Select Language"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                              )
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={Object.keys(ListOfAllLanguages).map(
                              (name, index) => {
                                const nativeName =
                                  Object.values(ListOfAllLanguages)[index]
                                    .nativeName;
                                const englishName =
                                  Object.values(ListOfAllLanguages)[index].name;
                                const finalName =
                                  nativeName === englishName
                                    ? englishName
                                    : `${englishName} (${nativeName})`;
                                return {
                                  value: Object.keys(ListOfAllLanguages)[index],
                                  label: finalName,
                                };
                              }
                            )}
                          />
                        </Form.Item>
                        {/* <Form.Item
                          label=""
                          name='TnC'
                          style={{ width: 'max-content' }}
                          className="ml-auto mr-auto"
                        // rules={[{ required: true, message: 'Cannot sign up without accepting the terms and conditions' }]}
                        >
                          <Checkbox value={tncAccepted} onChange={(e) => setTncAccepted(e.target.checked)}>
                            I accept the <a href="#">terms and conditions </a>
                          </Checkbox>
                        </Form.Item> */}
                        <div className="mt-8 mb-8">
                          <Checkbox
                            className="pr-8"
                            checked={tncAccepted}
                            onChange={(e) => setTncAccepted(e.target.checked)}
                          >
                            <span className="ml-2">
                              I accept the{" "}
                              <a target={"_blank"} href="https://vdy.app/tnc">
                                terms and conditions{" "}
                              </a>
                            </span>
                          </Checkbox>
                        </div>

                        {/* <Form.Item
                label="I am here as a"
                name="role"
                rules={[{ required: true, message: 'Please select an option!' }]}
              >
                <Select
                  onClick={() => setShowUserPickerDrawer(true)}
                  className="text-left"
                  value={signUpData.role}>
                  <Select.Option value="TEACHER">Teacher</Select.Option>
                  <Select.Option value="STUDENT">Student</Select.Option>
                </Select>
              </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                          <Button
                            htmlType="button"
                            style={{ margin: "0 8px" }}
                            type="default"
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={!tncAccepted}
                            type="primary"
                            htmlType="submit"
                          >
                            Sign Up
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                  </CustomForm>
                </>
              )}
            </Composition>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default SignUpForm;
