import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Composition } from "atomic-layout";
import {
  Button,
  Drawer,
  Modal,
  notification,
  Popconfirm,
  Progress,
  Tabs,
  Tooltip,
} from "antd";
import QuestionBuilder from "@/Components/QuestionBuilder";
import Question from "@/Components/Question";
import {
  askQuestion,
  getNextQuestionLabel,
  endCurrentSession,
  fetchLatestSessionStats,
} from "@/Services/Session";
import QuestionsProgress from "./QuestionsProgress";
import {
  BarChartOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FormOutlined,
  ShareAltOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { questionMode } from "@/Components/QuestionBuilder/QuestionBuilder";
import { Link } from "react-router-dom";
import AppLogo from "@/Components/AppHeader/AppLogo";
import { Typography, Switch } from "antd";
import {
  currentUser,
  getClassById,
  liveStatsForSession,
  summaryStatsForSession,
} from "@/Models/Selectors";
import store from "@/Models/store";
import QuestionCardPreview from "@/Components/Question/QuestionPreview";
import { useSelector } from "react-redux";
import BusinessLogo from "@/Components/AppHeader/BusinessLogo";
import Title from "antd/lib/typography/Title";
import { InviteCode } from "@/Components/Class/inviteModal";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  alternateHostUrl,
  dummyStudentEmail,
  dummyStudentName,
  dumyStudentPassword,
  isRTL,
  isRunningAsZoomApp,
} from "@/Services/Constants";
import Analytics from "@/Services/Analytics";
import { SessionStatsModal } from "./SessionStatsModal";
import { openURLfromZoomApp } from "@/Services/ZoomServices";
import LibraryQuestionSelector from "@/Components/QuestionLibrary/LibraryQuestionSelector";

const { Paragraph, Text } = Typography;

// add invitation in StartSessionArea to add ivvitation bar.

const startedSessionAreas = `
            activeHeader
            questionsArea
`;

const endedSessionAreas = `
            endedHeader
            questionsArea
`;


interface Message {
  id: string;
  content: string;
  timestamp: Date;

}


const { confirm } = Modal;

const getEndConfirmation = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    confirm({
      title: "Are you sure you want to end the session?",
      icon: <ExclamationCircleOutlined />,
      content: "You will not be able to resume the session if ended.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
    });
  });
};

const AdminSessionStarted = ({
  session,
  action,
}: {
  session: SessionState;
  action: string;
}) => {
  const { questions } = session;
  const _class = useSelector(getClassById)(session.classId);
  const endDivRef = useRef<null | HTMLDivElement>(null);
  const loggedInUser: User = useSelector(currentUser);

  const [showQuestionBuilder, setShowQuestionBuilder] = useState(false);
  const [showSessionStats, setShowSessionStats] = useState(false);
  const [draftQuestion, setDraftQuestion] = useState<question>();

  const questionsArea = useRef<HTMLElement>();

  const sessionSummaryStats = useSelector(summaryStatsForSession)(
    session.sessionId
  );
  const sessionEngagement = sessionSummaryStats?.engagement_percent || 0;
  const [prepareDrawerVisible, setPrepareDrawerVisible] = useState(
    session.questions.length == 0 ? true : false
  );
  const [questionSelectorIndex, setQuestionSelectorIndex] = useState(0);
  const [showCode, setShowCode] = useState<boolean>(
    _class.students_email.length === 0
  );


const [messages, setMessages] = useState<Message[]>([]);

const handleSubmitQuestion = async (description:string, values:string, correctValueIndex:string) => {
  try {
    const response = await fetch('https://us-central1-vidya-prod-f2207.cloudfunctions.net/createResources/api/publish_mcq', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        question: description,
        options: values, 
        correctAnswerIndex:correctValueIndex,
        classId:session.classId,
        sessionId:session.sessionId,
        className:session.name
        
      }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log('Question published:', data);
    } else {
      console.error('Failed to publish question:', data.error);
    }
  } catch (error) {
    console.error('Error publishing question:', error);
  }
};



// const pollServer = async () => {
//   try {
//     const response = await fetch('http://localhost:3006/poll');
//     const newMessages = await response.json();
//      setMessages(prevMessages => [...prevMessages, ...newMessages]);
//     pollServer(); // Continue polling after receiving response
//   } catch (error) {
//     console.error('Polling error:', error);
//     setTimeout(pollServer, 5000); // Retry after a delay on error
//   }
// };

  const showPreparedDrawer = () => {
    // setQuestionSelectorIndex(index);
    setPrepareDrawerVisible(true);
  };
  const onPreparedDrawerClose = () => {
    setPrepareDrawerVisible(false);
  };

  const scrollToQuestion = useCallback(
    (index:any) => {
      if (!questionsArea.current) return;
      const totalItems = questions.length + (showQuestionBuilder ? 1 : 0);
      const totalClientHeight = questionsArea.current?.scrollHeight;

      if (!totalClientHeight) return;

      const eachItemHeight = totalClientHeight / totalItems;
      // window.frames.scrollTo({
      //   // questionsArea.current.scrollTo({
      //   top: index * eachItemHeight,
      //   behavior: "smooth",
      // });
      endDivRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    [questions, showQuestionBuilder]
  );

  // useEffect(() => {
  //   pollServer(); 
  // }, []);

  useEffect(() => {
    if (showQuestionBuilder) {
      scrollToQuestion(questions.length);
    }
  }, [showQuestionBuilder, questions.length, scrollToQuestion]);

  useEffect(() => {
    if (questions.length === 0) {
      setShowQuestionBuilder(true);
    }
  }, [questions.length]);

  useEffect(() => {
    if (!questionsArea.current) return;
    scrollToQuestion(2);
    // questionsArea.current.scrollTo({
    //   top: questionsArea.current.scrollHeight,
    //   behavior: "smooth",
    // });
  }, []);

  const isQuestionInProgress = useMemo(
    () => questions.some((q) => q.state === "RECEIVING_RESPONSE"),
    [questions]
  );
  useEffect(() => {
    if (session) fetchLatestSessionStats(session);
  }, [isQuestionInProgress]);

  const onAskNextQuestion = useCallback(() => {
    if (isQuestionInProgress)
      return notification.error({
        message: "Oops!",
        description:
          "Cannot ask a new question while a question is in progress",
      });
    setShowQuestionBuilder(true);
    if (session.draft_questions.length > 0) {
      setPrepareDrawerVisible(true);
    }
    scrollToQuestion(questions.length);
  }, [
    setShowQuestionBuilder,
    questions,
    isQuestionInProgress,
    scrollToQuestion,
  ]);

  const onEndSession = useCallback(() => {
    getEndConfirmation().then(() => {
      endCurrentSession();
    });
  }, []);


  const onAsk = useCallback(
    (question:any, answer:any) => {
      const { description, images, id } = question;
      const { optionsType, value, correctValue, values, correctValueIndex } = answer;
  
      askQuestion({
        id,
        label: getNextQuestionLabel(session),
        description: description,
        images,
        optionsType: optionsType,
        value,
        values,
        correctValue,
        correctValueIndex,
        draft_id: question.draft_id,
        state: "RECEIVING_RESPONSE",
        asked_at: new Date(),
      });
  
      handleSubmitQuestion(description, values, correctValueIndex);
    },
    [session]
  );
  
  const extraSpace = useMemo(
    () => (showQuestionBuilder ? 1 : 0),
    [showQuestionBuilder]
  );

  const headerArea =
    session.state === "ENDED" ? endedSessionAreas : startedSessionAreas;

  const numberOfAttendees =
    sessionSummaryStats?.attendance_percent === 0
      ? _class.students_email.length
      : sessionSummaryStats?.present_students;

  const onPrepareQuestionSelect = (question: question) => {
    setDraftQuestion({ ...question, draft_id: question.id });
    setPrepareDrawerVisible(false);
  };
  const showSelectQuestionButton = true;
  const selectQuestionTitle = draftQuestion ? (
    <>
      <SwapOutlined /> Switch Question{" "}
    </>
  ) : (
    <>
      <FormOutlined /> Select Question{" "}
    </>
  );
  const [selectedDrawerTab, setSelectedDrawerTab] = useState(
    session.draft_questions.length > 0 ? "1" : "2"
  );
  return (
    <div dir={isRTL(_class.settings.language) ? "rtl" : "ltr"}>
      <Drawer
        bodyStyle={{ padding: 0 }}
        title={"Pick a Question"}
        placement="left"
        onClose={onPreparedDrawerClose}
        visible={prepareDrawerVisible}
        width={435}
      >
        <Tabs
          centered
          onChange={(key) => {
            Analytics.track(
              key == "1" ? "Prepared Tab Selected" : "Library Tab Selected"
            );
            setSelectedDrawerTab(key);
          }}
          defaultActiveKey={selectedDrawerTab}
          activeKey={selectedDrawerTab}
        >
          <Tabs.TabPane
            tab={"Prepared Questions (" + session.draft_questions.length + ")"}
            key="1"
          >
            {session.draft_questions.length > 0 ? (
              session.draft_questions.map((q: question, i: number) => {
                const isAsked =
                  session.questions.filter(
                    (que) => que.description === q.description && q.description
                  ).length > 0;
                return (
                  <div className="">
                    <QuestionCardPreview
                      disableImagePreview={true}
                      cardTitle={"Question " + (i + 1)}
                      tags={isAsked ? ["Asked"] : []}
                      className={isAsked ? "opacity-50" : ""}
                      // cardTitle={isAsked ? 'Asked' : 'Unasked'}
                      onCardClick={() => onPrepareQuestionSelect(q)}
                      showDelete={false}
                      showEdit={false}
                      onEditClicked={() => {}}
                      onDeleteClicked={() => {}}
                      question={q}
                    />
                  </div>
                );
              })
            ) : (
              <div className="mt-16 text-center">
                <div className="text-gray-600">
                  You have not prepared any questions for this session
                </div>
                <Button
                  className="mt-2"
                  type="link"
                  onClick={() => setSelectedDrawerTab("2")}
                >
                  Pick from Library
                </Button>
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Library" key="2">
            <LibraryQuestionSelector
              onQuestionSelect={(q: question) => {
                Analytics.track("Library Question During Session", q);
                onPrepareQuestionSelect(q);
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Drawer>

      <div
        className="fixed hidden lg:block"
        style={{
          top: "104px",
          left: "10px",
          maxHeight: "calc(100vh - 304px)",
          overflow: "auto",
        }}
      >
        <QuestionsProgress
          questions={questions}
          currentIndex={questions.length - 1 + (showQuestionBuilder ? 1 : 0)}
          scrollToQuestion={scrollToQuestion}
          addNewQuestionStep={showQuestionBuilder}
        />
      </div>

      <div
        className="md:fixed class-code lg:block"
        style={{
          top: "90px",
          right: "30px",
          maxHeight: "calc(100vh - 304px)",
          overflow: "auto",
          zIndex: 1000,
        }}
      >
        {/* <Popconfirm
          icon={null}
          placement="bottomRight"
          title={<div style={{ marginLeft: "-70px", marginBottom: "15px" }}>
            <InviteCode
              description="Invite code for this class is"
              _class={_class} />
          </div>}
          // title="Are you sure to delete this task?"
          okText="Ok"
          showCancel={false}
        // cancelText="No"
        > */}
        {/* <Button type="default" shape="circle" icon={<ShareAltOutlined />} size={'large'} /> */}

        {session.state == "STARTED" ? (
          <>
            {showCode ? (
              <div
                style={{
                  maxWidth: 235,
                  marginLeft: "auto",
                  marginRight: "auto",
                  // marginTop: "24px"
                }}
                className="mt-4"
              >
                <div
                // style={{ zIndex: 100000000 }}
                >
                  <InviteCode
                    size="small"
                    description="Invite code for students"
                    _class={_class}
                  />
                </div>
                <Tooltip title="See how learners would receive and respond to the LIVE questions">
                  <div className="mt-2">
                    <Button
                      type="primary"
                      onClick={() => {
                        const params = `scrollbars=no,resizable=no,toolbar=no,menubar=no,
      width=480,height=1000,left=-1000,top=-1000`;

                        // const inviteUrl = alternateHostUrl + '/join-class/' + _class.id + '/' + _class.class_invite_code + "?email=" + dummyStudentEmail + "&pwd=" + dumyStudentPassword;
                        const inviteUrl =
                          alternateHostUrl +
                          "/guest-signup/" +
                          dummyStudentName +
                          "/" +
                          _class.class_invite_code;
                        if (isRunningAsZoomApp) {
                          Analytics.track("Emulate Student From Zoom", {
                            ...session,
                          });
                          openURLfromZoomApp(inviteUrl);
                        } else {
                          Analytics.track("Emulate Student From Web", {
                            ...session,
                          });
                          const win = window.open(
                            inviteUrl,
                            "dummystudent",
                            params
                          );
                        }
                        setShowCode(false);
                      }}
                    >
                      <EyeOutlined /> View as Student
                    </Button>{" "}
                  </div>
                </Tooltip>
                <Button
                  className=""
                  type="link"
                  onClick={() => setShowCode(false)}
                >
                  {" "}
                  hide{" "}
                </Button>
              </div>
            ) : (
              <Button
                className="mt-4"
                type="link"
                onClick={() => setShowCode(true)}
              >
                {" "}
                Add Participants{" "}
              </Button>
            )}{" "}
          </>
        ) : null}

        {/* </Popconfirm> */}

        {/* <Button>
          <ShareAltOutlined />
        </Button> */}
      </div>

      <Composition
        areas={headerArea}
        alignItems="center"
        justifyItems="center"
        width="100%"
      >
        {({ ActiveHeader, EndedHeader, Invitation, QuestionsArea }) => (
          <>
            <ActiveHeader width="100%">
              <Composition
                templateCols="repeat(3, 1fr)"
                alignItems="center"
                areas={` blank SessionName Buttons`}
                areaSm={`barChart SessionName endButton`}
                areaMd={`barChart SessionName endButton`}
                style={{
                  margin: "0 0 1em 0",
                  backgroundColor: "#2d3748",
                  color: "#FFF",
                  position: "fixed",
                  top: "0px",
                  zIndex: 1000,
                  minHeight: "68px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  width: "100%",
                }}
              >
                {({ SessionName, Blank, Buttons, barChart, EndButton }) => (
                  <>
                    <Blank>
                      <span>
                        <Link to="/">
                          {loggedInUser &&
                          loggedInUser.primary_institute &&
                          loggedInUser.primary_institute?.feature_flags
                            ?.whitelabel ? (
                            <>
                              <div style={{ marginTop: "-24px" }}>
                                <BusinessLogo
                                  imgUrl={
                                    loggedInUser.primary_institute.img_url
                                  }
                                />
                              </div>
                              {/* <span
                                className="float-left font-bold text-gray-200"
                                style={{ marginLeft: "60px", width: "140px", color: "#fff", fontSize: "1em" }}
                              // ellipsis={{ expandable: false, rows: 1 }}

                              >
                                {loggedInUser.primary_institute.name}</span> */}
                            </>
                          ) : (
                            <AppLogo mode="dark" redirectHome />
                          )}
                        </Link>
                      </span>
                    </Blank>
                    <SessionName>
                      <div className="text-2xl lg:w-full md:w-1/2 sessionTitle">
                        <Text style={{ color: "#fff" }} ellipsis>
                          {session.name}
                        </Text>
                      </div>
                    </SessionName>
                    <Buttons>
                      <Composition
                        // templateColsSm="1fr 100px"
                        // templateRowsSmOnly="100px 1fr"
                        justifyItems="center"
                        justifyItemsSm="flex-end"
                        alignItems="center"
                        templateCols="auto"
                        templateColsSm="1fr 100px"
                        areas={`
                          barChart endButton 
                        `}
                        areasSm={`barChart endButton`}
                        areasMd={`barChart endButton`}
                        gap="5px"
                        marginRight="20px"
                      >
                        {({ EndButton, BarChart }) => (
                          <>
                            <BarChart>
                              <Composition
                                justify="flex-end"
                                align="center"
                                className="cursor-pointer"
                              >
                                {/* <Modal
                                  visible={showSessionStats}
                                  onCancel={() => setShowSessionStats(false)}
                                  title={"Session Stats"}
                                  closable
                                  footer={null}
                                  className={"z10"}
                                  style={{ zIndex: 100000000 }}
                                > */}
                                <SessionStatsModal
                                  open={showSessionStats}
                                  onClose={() => setShowSessionStats(false)}
                                  session={session}
                                />
                                {/* </Modal> */}
                                {/* <Button type="link"
                                  onClick={() => {
                                    const params = `scrollbars=no,resizable=no,toolbar=no,menubar=no,
                      width=480,height=1000,left=-1000,top=-1000`;
                                    const inviteUrl = alternateHostUrl + '/join-class/' + _class.id + '/' + _class.class_invite_code + "?email=" + dummyStudentEmail + "&pwd=" + dumyStudentPassword;
                                    const win = window.open(inviteUrl, "_blank", params);
                                  }}
                                >Emulate Student</Button> */}
                                <span
                                  onClick={() => {
                                    fetchLatestSessionStats(session);
                                    if (!showSessionStats)
                                      setShowSessionStats(true);
                                  }}
                                >
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      sessionEngagement + "% Class Engagement"
                                    }
                                  >
                                    <Progress
                                      type="circle"
                                      strokeColor="#3498db"
                                      percent={sessionEngagement}
                                      format={(percent) => (
                                        <span className="text-white">
                                          {percent}%
                                        </span>
                                      )}
                                      className="progrs"
                                      width={33}
                                    />
                                  </Tooltip>
                                </span>
                                {/* <BarChartOutlined
                                  style={{
                                    fontSize: "2em",
                                  }}
                                  onClick={() => {
                                    fetchLatestSessionStats(session);
                                    if (!showSessionStats) setShowSessionStats(true);
                                  }}
                                /> */}
                              </Composition>
                            </BarChart>
                            <EndButton>
                              <Button
                                danger
                                onClick={onEndSession}
                                className="ml-auto"
                              >
                                End
                              </Button>
                            </EndButton>
                          </>
                        )}
                      </Composition>
                    </Buttons>
                  </>
                )}
              </Composition>
            </ActiveHeader>

            <EndedHeader width="100%">
              <Composition
                templateCols="repeat(3, 1fr)"
                alignItems="center"
                areas={` blank SessionName Buttons`}
                areaSm={`barChart SessionName endButton`}
                areaMd={`barChart SessionName endButton`}
                style={{
                  margin: "0 0 1em 0",
                  color: "#FFF",
                  top: "0px",
                  zIndex: 1000,
                  minHeight: "64px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  width: "100%",
                }}
              >
                {({ SessionName, Blank, Buttons, barChart, EndButton }) => (
                  <>
                    <Blank>
                      <span>
                        {/* <Link to='/'>
                          <AppLogo mode="dark" redirectHome />
                        </Link> */}
                      </span>
                    </Blank>
                    <SessionName>
                      <div className="text-2xl lg:w-full md:w-1/2 sessionTitle">
                        <Text
                          // style={{ color: "#000" }}
                          ellipsis
                        >
                          {session.name}
                        </Text>
                      </div>
                      <div>
                        <Text className="text-xs text-gray-100" ellipsis>
                          {session.start_time.toDateString()}
                        </Text>
                      </div>
                    </SessionName>
                    <Buttons>
                      <Composition
                        // templateColsSm="1fr 100px"
                        // templateRowsSmOnly="100px 1fr"
                        justifyItems="center"
                        justifyItemsSm="flex-end"
                        alignItems="center"
                        templateCols="auto"
                        templateColsSm="1fr "
                        areas={`
                          barChart 
                        `}
                        areasSm={`barChart`}
                        areasMd={`barChart`}
                        gap="5px"
                        marginRight="20px"
                      >
                        {({ BarChart }) => (
                          <>
                            <BarChart>
                              <Composition
                                justify="flex-end"
                                align="center"
                                className="cursor-pointer ml-auto"
                              >
                                <SessionStatsModal
                                  open={showSessionStats}
                                  onClose={() => setShowSessionStats(false)}
                                  session={session}
                                />
                                <span
                                  onClick={() => {
                                    fetchLatestSessionStats(session);
                                    if (!showSessionStats)
                                      setShowSessionStats(true);
                                  }}
                                >
                                  <Tooltip title="Class Engagement">
                                    <Progress
                                      type="circle"
                                      strokeColor="#3498db"
                                      percent={sessionEngagement}
                                      format={(percent) => (
                                        <span className="text-black">
                                          {percent}%
                                        </span>
                                      )}
                                      className="progrs ml-auto"
                                      width={40}
                                    />
                                  </Tooltip>
                                </span>
                                {/* <BarChartOutlined
                                  style={{
                                    fontSize: "2em",
                                  }}
                                  onClick={() => {
                                    fetchLatestSessionStats(session);
                                    if (!showSessionStats) setShowSessionStats(true);
                                  }}
                                /> */}
                              </Composition>
                            </BarChart>
                          </>
                        )}
                      </Composition>
                    </Buttons>
                  </>
                )}
              </Composition>
            </EndedHeader>

            <Invitation>
              <div
                style={{
                  height: "40px",
                  background: "black",
                  color: "white",
                  padding: "10px",
                  margin: "24 auto",
                  // position: "fixed",
                  // top: "68px",
                  left: 0,
                  right: 0,
                  width: "100vw",
                  zIndex: 1000,
                  // position: "fixed"
                }}
              >
                The invitation code is
                <span>
                  <span
                    style={{
                      borderBottom: "white",
                      borderWidth: "thin",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottomStyle: "dashed",
                      letterSpacing: "3px",
                      marginLeft: "10px",
                      marginRight: "5px",
                      // zIndex: 100000000000000
                    }}
                  >
                    {_class.class_invite_code}{" "}
                  </span>
                  <CopyToClipboard
                    text={_class.class_invite_code}
                    onCopy={() => {
                      notification.success({
                        message: "Invite code copied",
                      });
                    }}
                  >
                    <span>
                      <CopyOutlined className="cursor-pointer" />
                    </span>
                  </CopyToClipboard>
                </span>
              </div>
            </Invitation>
            <QuestionsArea
              style={{
                height: "calc(100vh - 10)",
              }}
              className="lg:pl-64 pl-3 pr-3 lg:pr-64 w-full"
              ref={questionsArea}
            >
              <Composition
                templateRows={`repeat(${
                  questions.length + extraSpace
                }, minmax(700px, auto))`}
                templateCols={`repeat(1, minmax(80vw, auto))`}
                templateColsMd={`repeat(1, minmax(500px, auto))`}
                width={"100%"}
                justifyContent="center"
              >
                {questions.map((q, i) => (
                  <Question
                    session={session}
                    question={q}
                    role="TEACHER"
                    onAskNextQuestion={onAskNextQuestion}
                    showNewQuestionButton={
                      !showQuestionBuilder &&
                      !isQuestionInProgress &&
                      i === questions.length - 1
                    }
                  />
                ))}
                {showQuestionBuilder && session.state === "STARTED" ? (
                  <QuestionBuilder
                    isRTL={isRTL(_class.settings?.language)}
                    onSelectQuestion={showPreparedDrawer}
                    selectQuestionButtonTitle={
                      showSelectQuestionButton ? selectQuestionTitle : null
                    }
                    editQuestion={draftQuestion}
                    askButtonLabel={
                      !numberOfAttendees
                        ? `Ask`
                        : numberOfAttendees === 1
                        ? `Ask ${numberOfAttendees} student`
                        : `Ask ${numberOfAttendees} students`
                    }
                    mode={questionMode.LIVE}
                    numberOfStudents={numberOfAttendees}
                    onAsk={onAsk}
                    Label={`Question ${questions.length + 1}`}
                  />
                ) : null}
                {session.state !== "STARTED" &&
                (!questions || questions.length === 0) ? (
                  <span className="text-xl"> No questions asked </span>
                ) : null}
              </Composition>
            </QuestionsArea>
          </>
        )}
      </Composition>

      <div ref={endDivRef} />
    </div>
  );
};

export default AdminSessionStarted;
