import React, { useEffect, useState, HTMLAttributes } from "react";
import { Tooltip, Comment, Avatar } from "antd";
import { getUserById } from "@/Models/Selectors";
import store from "@/Models/store";
import { UserOutlined } from "@ant-design/icons";
import { getFirebaseUser } from "@/Services/User";
import { useSelector } from "react-redux";

type Props = {
  email: string;
  user?: User | undefined;
  size?: number | "large" | "small" | "default";
  tooltip?: string;
};

const UserAvatar = ({ user, size, email, tooltip, ...props }: Props) => {
  const usr: User = useSelector(getUserById)(email);
  const [thisUser, setThisUser] = useState(user || usr);
  const img_url = thisUser?.img_url;
  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

  /* useEffect(() => {
    if (!thisUser.email) {
      console.log("calling from here");
      getFirebaseUser(email);
    }
  }, []); */

  useEffect(() => {
    if (usr && usr.email != thisUser.email) {
      setThisUser(usr);
    }
  }, [usr]);

  const color = ColorList[Math.floor(email.length % ColorList.length)];
  return thisUser.email ? (
    img_url ? (
      <Tooltip title={tooltip}>
        <Avatar
          {...props}
          size={size || "default"}
          src={img_url}
          alt={thisUser.full_name}
        />{" "}
      </Tooltip>
    ) : (
      <Tooltip title={tooltip}>
        <Avatar
          {...props}
          style={{ backgroundColor: color, verticalAlign: "middle" }}
          size={size || "default"}
          gap={2}
        >
          {thisUser?.first_name?.charAt(0)}
          {thisUser?.last_name?.charAt(0)}
        </Avatar>
      </Tooltip>
    )
  ) : (
    <Avatar icon={<UserOutlined />} />
  );
};

export default UserAvatar;
