import { GoogleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, FormInstance, Input, message, PageHeader, Select, Switch, Tag } from "antd"
import { Composition } from "atomic-layout";
import { useEffect, useState } from "react";
import BusinessLogo from "../AppHeader/BusinessLogo";
import UserAvatar from "../Common/Image/UserAvatar";



const TeacherSetting = ({
    userData,
    onFinish,
    onFinishFailed,
    form
}: {
    userData: User,
    onFinish: (values: any, success: () => void, failed: (error: string) => void) => void,
    onFinishFailed: (errorInfo: any) => void,
    form: FormInstance<any>
}) => {
    const [saving, setSaving] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(userData?.img_url);

    useEffect(() => {
        form.setFieldsValue({
            ...userData,
            city: userData?.settings?.city,
            country: userData?.settings?.country,
            phone: userData?.settings?.phone,
            organization_name: userData?.settings?.organization_name
        })
        // setSubject(_class.name);
        // setBatch(_class.description);
    })

    const formFilled = (values: any) => {
        setSaving(true);
        message.loading('Updating', 0);
        const { firstName, lastName, email, role, img_url, city, phone, country, organization_name } = values;
        if (userData.settings) {
            const _user: User = {
                ...userData,
                first_name: firstName,
                last_name: lastName,
                email,
                full_name: firstName + " " + lastName,
                img_url,
                role,
                settings: {
                    ...userData.settings,
                    city,
                    country,
                    phone,
                    organization_name
                }

            };

            onFinish(_user, () => {
                message.destroy();
                message.success('Profile Updated');
                setSaving(false);
            }, (error) => {
                message.destroy();
                message.error('Update failed. Please try again');
                setSaving(false);
                // setUser
            });
        }
    }

    // useEffect(() => {
    //     if (saving) {
    //         message.loading('Updating', 0);
    //     } else {}
    // }, [saving])

    return <div>


        <Composition
            justify="start"
            className="lg:w-6/12 md:w-full"
        >

            <Form
                name="basic"

                labelCol={{ sm: { span: 8 }, md: { span: 8 } }}
                wrapperCol={{ sm: { span: 16 }, md: { span: 16 }, lg: { span: 12 } }}
                initialValues={userData.primary_institute}
                onFinish={formFilled}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Form.Item
                    label="Name"
                    name="name"

                    initialValue={userData.primary_institute?.name}
                    rules={[{ required: true, message: 'Please enter your First Name!' }]}
                >
                    <Input
                        disabled
                        value={userData?.first_name} />
                </Form.Item>


                {/* {userData?.settings?.city ? */}
                <Form.Item
                    label="Location"
                    name="location"
                    rules={[{ required: false, message: 'Please enter your city!' }]}
                >
                    <Input
                        disabled
                        defaultValue={userData?.settings?.city} />
                </Form.Item>
                {/* : null} */}

                {/* {userData?.settings?.country ? */}

                {/* {userData?.primary_institute?.subscription. === "TEACHER" ? */}
                <Form.Item
                    label="Subscription"
                    name="subscription"
                    className="text-left"
                // rules={[{ required: true, message: 'Please select an option!' }]}
                >
                    <Tag className="" color={userData?.primary_institute?.subscription.status ? "green" : "orange"}>{userData?.primary_institute?.subscription.status ? "Active" : "Inactive"} </Tag>
                    {/* {userData?.settings?.in_trial ? <Tag className="" color="geekblue">FREE TRIAL</Tag> : null} */}
                </Form.Item>

                {/* <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button type="primary"
                        htmlType="submit"
                        loading={saving}
                    >
                        Update
                    </Button>

                </Form.Item> */}

            </Form>
        </Composition>
    </div>
}

export default TeacherSetting;