import { AUDIO_RECORDING_MAX_LIMIT, isRunningAsZoomApp } from '@/Services/Constants';
import { AudioOutlined, CloseOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import "./AudioRecorder.css";
import { notification } from 'antd';
import { openURLfromZoomApp } from '@/Services/ZoomServices';
import Analytics from '@/Services/Analytics';


const AudioRecorder = ({ onRecordingComplete, enabled, mode, title, recordingData }: {
    onRecordingComplete: (blobData: Blob) => void;
    enabled: boolean;
    title: string;
    mode: 'light' | 'dark';
    recordingData?: any
}) => {

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [recordedBlob, setRecordingBlob] = useState();
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [displayTime, setDisplayTime] = useState("");

    function fancyTimeFormat(duration: number) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }
    useEffect(() => {
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            if (isRecording)
                setTimeElapsed(timeElapsed + 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeElapsed, isRecording]);


    const toggleRecording = () => {

        if (isRunningAsZoomApp) {
            Analytics.track(`Audio Question pressed in Zoom`);
            notification.info({
                message: "Voice questions are only supported only in the web version of the app",
                btn: "Open Web-App",
                closeIcon: <CloseOutlined />,
                onClick: () => {
                    openURLfromZoomApp(window.location.toString())
                    // //@ts-ignore
                    // window.zoomSdk.openUrl({ url: window.location.toString() });

                    console.log('web app opening')
                },
            })
            return;
        }

        if (!isRecording) {
            Analytics.track(`Audio Recording ON`);
            setIsRecording(true);
            // updateTimer(0)
        } else {
            Analytics.track(`Audio Recording STOP`, { timeElapsed });
            setIsRecording(false);
        }
    }
    const updateTimer = (time: number) => {
        setInterval(() => {
            if (timeElapsed == AUDIO_RECORDING_MAX_LIMIT) {
                onStop(recordedBlob);
            }
            if (isRecording) {
                setTimeElapsed(timeElapsed + 1);
                updateTimer(timeElapsed + 1)
            }
        }, 1000);
    }

    const stopRecordings = () => {
        setIsRecording(false);
    }

    const onData = (recordedBlob: any) => {
        console.log('chunk of real-time data is: ', recordedBlob);
    }

    const onStop = (recordedBlob: any) => {
        console.log('recordedBlob is: ', recordedBlob);
        setRecordingBlob(recordedBlob);
        onRecordingComplete(recordedBlob.blob);
    }

    return <div className='inline-flex mt-4'>
        <button onClick={toggleRecording}
            type="button"
            style={{
                border: "none",
                outline: "none"
            }}
            className='mr-4 ml-4 float-left'>
            {isRecording ?
                <><div className="box">

                    <div className="circle_ripple"> </div>
                    <div className="circle_ripple-2"></div>
                </div>
                    <AudioOutlined
                        className={isRecording ? 'recorder-mic active-mic' : 'recorder-mic'}
                        type="twoTone"
                    />
                </>
                : <AudioOutlined
                    className={isRecording ? 'recorder-mic active-mic' : 'recorder-mic'}
                    type="twoTone"
                />}

            {/* </div></div> */}
        </button>
        <div className={mode == "light" ? "mt-2 mr-2 text-gray-800" : 'mt-2 mr-2 text-gray-400'}>{!isRecording ? title :
            <div className='mt-2 mr-2'>{fancyTimeFormat(timeElapsed)} </div>
        }</div>
        <div
            style={{ display: !isRecording ? 'none' : '' }}>
            <ReactMic
                visualSetting={"sinewave"}
                record={isRecording}
                className="sound-wave max-w-sm"
                onStop={onStop}
                onData={onData}
                strokeColor={mode == "light" ? "#111827" : "#fff"}
                backgroundColor={mode == "light" ? "#fff" : "#111827"}
            /> </div>
        {/* <button onClick={stopRecordings} type="button">Stop</button> */}
    </div>
}

export default AudioRecorder;