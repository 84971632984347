import { isRunningAsZoomApp } from "./Constants";



const configureZoom = () => {

    const zoomConfig = {
        version: "0.16.12",
        popoutSize: { width: 480, height: 1000 },
        // size: { width: 480, height: 1000 },
        capabilities: [
            //APIs
            "authorize",
            "startOrJoinMeeting",
            "shareApp",
            "listCameras",
            "setCamera",
            "setVideoMirrorEffect",
            "getMeetingParticipants",
            "cloudRecording",
            "allowParticipantToRecord",
            "getRunningContext",
            "getMeetingContext",
            "getSupportedJsApis",
            "showNotification",
            "openUrl",
            "setVirtualBackground",
            "canSupportVirtualBackground",
            "listCameras",
            "setCamera",
            "sendAppInvitation",
            "sendAppInvitationToAllParticipants",
            "getUserContext",
            "getRecordingContext",
            "getMeetingContext",
            "getMeetingJoinUrl",
            "getMeetingUUID",
            "expandApp",
            "connect",
            "postMessage",
            "allowParticipantToStartLocalRecording",
            //Events
            "onAuthorized",
            "onShareApp",
            "onSendAppInvitation",
            "onCloudRecording",
            "onActiveSpeakerChange",
            "onAppPopout",
            "onCohostChange",
            "onParticipantChange",
            "onReaction",
            "onConnect",
            "onExpandApp",
            "onMessage",
            "onMeeting",
        ],
    }
    //@ts-expect-error
    window.zoomSdk.config(zoomConfig);
    console.log("Zoom Config initiated")
    console.log(zoomConfig)


}

//@ts-ignore
export const initZoomSetup = function () {
    console.log('window.initZoomSetup(); called')
    configureZoom();
}

export const initiateZoomCollaboration = () => {
    //@ts-expect-error
    window.zoomSdk.getRunningContext()
        .then(function (meetingStatus: any) {
            if (meetingStatus !== "inMainClient") {
                startZoomCollaboration();
            }
            // console.log("zoom collboration initiated ", response)
        })
        .catch(function (error: any) {
            console.log('failed to initiate zoom collaboration ', error)
        });
}

export const startZoomCollaboration = () => {

    //@ts-ignore
    zoomSdk.callZoomApi("startCollaborate",
        {
            "shareScreen": false
        }
    ).then(function (response: any) {
        console.log("zoom collboration initiated ", response)
    })
        .catch(function (error: any) {
            console.log('failed to initiate zoom collaboration ', error)
        })

}

export const showZoomNotification = (title: string, message: string) => {

    //@ts-ignore
    window.zoomSdk.showNotification({
        type: "info",
        title,
        message
    })
        .then(function (response: any) {
            console.log("notification Trigerred ", response)
        })
        .catch(function (error: any) {
            console.log('notification failed ', error)

        })
}

export const openURLfromZoomApp = (url: string) => {
    if (isRunningAsZoomApp) {
        //@ts-ignore
        window.zoomSdk.openUrl({ url });
    } else {
        // add code for opening in browser window
    }

}

