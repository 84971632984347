import React, { useEffect, useState } from "react";
import { Composition } from "atomic-layout";
import background from "@/Assets/get-started.png";
import Layout from "antd/lib/layout/layout";
import logoLight from "@/Assets/logo-light.png";
import { Button, Input } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { authStatus } from "@/Models/Selectors";
import Loader from "./Loader";

interface RouteParams {
  email: string;
  password: string;
  name: string;
  classcode: string;
}

interface PasswordResetComponent extends RouteComponentProps<RouteParams> {}

const LoginAction = ({
  presetEmail,
  presetPassword,
  presetName,
  presetClasscode,
}: {
  presetEmail?: string;
  presetPassword?: string;
  presetName?: string;
  presetClasscode?: string;
}) => {
  const history = useHistory();
  // const presetEmail = props.match.params.email;
  // const presetPassword = props.match.params.password;
  const [email, setEmail] = useState(presetEmail || "");
  const [password, setPassword] = useState("");
  const [loginState, setLoginState] = useState<
    "enter_email" | "sending_email" | "enter_otp" | "sending_otp" | "sign_up"
  >("enter_email");
  const [error, setError] = useState<string | null>(null);
  const [userExists, setUserExists] = useState(false);
  let firebaseUserExists = false;

  const resetPassword = () => {};

  return (
    <>
      {loginState === "enter_email" ||
      loginState === "sending_email" ||
      loginState === "sign_up" ? (
        <div>
          <div style={{ width: "20em" }} className="ml-auto mr-auto mt-12 mb-4">
            <div className="text-left text-sm text-gray-600">New Password</div>
            <Input
              size="large"
              type="text"
              autoComplete="email"
              name="password"
              allowClear
              className="border-red"
              value={email}
              placeholder="Enter new password"
              onChange={(e) => {
                setError(null);
              }}
              onError={(e) => console.log(e)}
              onPressEnter={resetPassword}
              // prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </div>
          <div style={{ width: "20em" }} className="ml-auto mr-auto mt-12 mb-4">
            <div className="text-left text-sm text-gray-600">
              Reset Password
            </div>
            <Input
              size="large"
              type="text"
              autoComplete="email"
              name="re-password"
              allowClear
              className="border-red"
              value={email}
              placeholder="Enter reset password"
              onChange={(e) => {
                setError(null);
              }}
              onError={(e) => console.log(e)}
              onPressEnter={resetPassword}
              // prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </div>
          {userExists && (
            <div
              style={{ width: "20em" }}
              className="ml-auto mr-auto mt-12 mb-4"
            >
              <div className="text-left text-sm text-gray-600">Password</div>
              <Input
                size="large"
                type="text"
                autoComplete="password"
                name="password"
                allowClear
                className="border-red"
                value={password}
                placeholder="Enter Password"
                onChange={(e) => {
                  setError(null);
                  setPassword(e.target.value.trim());
                }}
                onError={(e) => console.log(e)}
              />
            </div>
          )}
          <span className="text-red-600">{error}</span>
          <>
            <div style={{ width: "13em" }} className="ml-auto mr-auto mt-12">
              <Button
                size="large"
                className=""
                style={{ width: "100%" }}
                type="primary"
                onClick={resetPassword}
              >
                Reset Password
              </Button>
            </div>
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const PasswordReset: React.FC<PasswordResetComponent> = function (props) {
  const status = useSelector(authStatus);

  const presetEmail = props.match.params.email || "";
  const presetPassword = props.match.params.password || "";
  const setName = props.match.params.name || "";
  const setClasscode = props.match.params.classcode || "";

  const signUpClicked = () => {
    console.log("Signup Clicked");
  };

  return (
    <Composition
      templateCols="1fr"
      templateRows="1fr"
      templateColsMd="2fr 1.5fr"
      templateRowsMd="200px 1fr"
      height="calc(100vh - 123px)"
      areas={`
      mobileAction
      `}
      areasMd={`banner action`}
    >
      {({ Banner, Action, MobileAction }) => (
        <>
          {/* <Composition col="1/1" colMd="1/3">
        <h1 className="mx-auto p-8 text-6xl">Welcome to Vidya</h1>
      </Composition> */}
          <Banner>
            <div className="lg:w-1/2 md:w-full ml-auto mr-auto">
              <img src={background} alt="login page background" />
            </div>

            <div className="font-light mt-8 text-center text-black">
              <img className="lg:w-1/4 md:w-1/2" src={logoLight} />
            </div>

            <div className="font-light mt-8 text-center text-black text-3xl">
              Making Classrooms Engaging
            </div>
          </Banner>
          <Action>
            <Layout
              style={{
                minHeight: "calc(100vh - 123px)",
                background: "#e7e7e7",
              }}
            >
              {/* <Composition
                templateRows="100px 100px 1fr"
                justifyItems="center"
                className="md:mt-16 md:p-6"
              > */}
              <div className="h-full lg:mt-12 md:mt-12 md:pt-16 mt-12">
                <div className="font-light text-center text-3xl">
                  Password Reset
                </div>
                <LoginAction
                  presetEmail={presetEmail}
                  presetPassword={presetPassword}
                  presetName={setName}
                  presetClasscode={setClasscode}
                />
                <div className=" mt-8 space-x-10 text-white">
                  <span>
                    <a
                      className=""
                      target={"_blank"}
                      href="https://vdy.app/privacy"
                    >
                      Privacy Policy{" "}
                    </a>{" "}
                  </span>
                  {/* <span>|  </span> */}
                  <span>
                    <a
                      className=""
                      target={"_blank"}
                      href="https://vdy.app/tnc"
                    >
                      Terms & Conditions{" "}
                    </a>
                  </span>
                </div>
                <div className="mt-2">
                  {status === "authenticating" ? <Loader /> : null}
                </div>
              </div>

              {/* </Composition> */}
            </Layout>
          </Action>
          <MobileAction>
            <Layout
              style={{
                minHeight: "calc(100vh + 24px)",
                zIndex: 10000,
                marginTop: "-64px",
                position: "absolute",
                width: "100%",
                // background: "#2d3748",
                // background: "#1a202c"
                background: "#e7e7e7",
              }}
            >
              {/* <Composition
                templateRows="100px 100px 1fr"
                justifyItems="center"
                className="md:mt-16 md:p-6"
              > */}
              <div className="h-full lg:mt-10 lg:pt-0 md:mt-0 md:pt-1 mt-10">
                <div className="font-light text-center">
                  <img src={logoLight} className="ml-auto, mr-auto w-2/3" />
                </div>

                <div className="font-light mt-8 ml-4 mr-4 text-center text-2xl">
                  The Best Tool for Classrooms Engagement
                </div>
                <div className="font-light mt-8 text-center text-xl">
                  Reset Password
                </div>
                <LoginAction
                  presetEmail={presetEmail}
                  presetPassword={presetPassword}
                  presetName={setName}
                  presetClasscode={setClasscode}
                />
                <div className="mt-8 space-x-10 text-white">
                  <span>
                    <a className="" href="#">
                      Privacy Policy{" "}
                    </a>{" "}
                  </span>
                  {/* <span>|  </span> */}
                  <span>
                    <a className="" href="#">
                      Terms & Conditions{" "}
                    </a>
                  </span>
                </div>
              </div>

              {/* </Composition> */}
            </Layout>
          </MobileAction>
        </>
      )}
    </Composition>
  );
};

export default PasswordReset;
