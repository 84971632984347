import { createSelector } from "reselect";
import type { applicationState } from "../store";
import store from "../store";

export const currentSessionId = (state: applicationState) =>
  state.sessions.currentSessionId;

export const allSessions = (state: applicationState) =>
  state.sessions.allSessions;

export const allSessionStats = (state: applicationState) =>
  state.statsSummary.allStats;

export const allStudentsStats = (state: applicationState) =>
  state.stats.allStats;

export const allQuestionLibraries = (state: applicationState) =>
  state.questionLibraries.allLibraries;

export const currentSession = createSelector(
  allSessions,
  currentSessionId,
  (allSessions, currentSessionId) => {
    if (!currentSessionId) {
      return undefined;
    }
    return allSessions[currentSessionId];
  }
);
export const getLibraryById = createSelector(
  allQuestionLibraries,
  (allLibraries) =>
    (id: string): QuestionLibrary | undefined =>
      allLibraries[id]
);

export const getAllQuestionLibraries = createSelector(
  allQuestionLibraries,
  (allLibraries) => Object.values(allLibraries)
);

export const getSessionById = createSelector(
  allSessions,
  (allSessions) => (id: string) => allSessions[id]
);

export const getPrepareSessionForClass = createSelector(
  allSessions,
  (allSessions) => (classId: string) => {
    const session = Object.values(allSessions).filter(
      (session) => session.classId === classId && session.state === "DRAFT"
    );
    return session.length > 0 ? session[0] : null;
  }
);

export const currentUser = (state: applicationState) => state.users.currentUser;

export const authToken = (state: applicationState) => state.auth.token;

export const authStatus = (state: applicationState) => state.auth.status;

export const socketStatus = (state: applicationState) =>
  state.sessions.socketStatus;

export const allClasses = (state: applicationState) => state.classes.allClasses;

export const isFetchingClasses = (state: applicationState) =>
  state.classes.fetching;

export const allUsers = (state: applicationState) => state.users.users;

export const myClasses = createSelector(allClasses, (allClasses) => () => {
  return allClasses
    ? Object.values(allClasses)
        .filter(
          (_class) =>
            _class.primary_teacher === currentUser(store.getState()).email
        )
        .sort((_classA, _classB) => {
          return Number(_classA.is_archived) - Number(_classB.is_archived);
        })
    : [];
});

// export const isFetchingClasses = createSelector(allClasses, (allClasses) => () =>
//   allClasses ? Object.values(allClasses).filter(_class => _class.primary_teacher === currentUser(store.getState()).email) : []
// );

export const allClassesArray = createSelector(allClasses, (allClasses) =>
  allClasses ? Object.values(allClasses) : []
);

export const allActiveClassesArray = createSelector(allClasses, (allClasses) =>
  allClasses ? Object.values(allClasses).filter((c) => !c.is_archived) : []
);

export const allUsersArray = createSelector(allUsers, (allUsers) =>
  allUsers ? Object.values(allUsers) : []
);

export const getClassById = createSelector(
  allClasses,
  (allClasses) => (id: string) => allClasses[id]
);

const getClassByIds = (id: string) => {};
export const getUserById = createSelector(
  allUsers,
  (allUsers) => (id: string) => allUsers[id] || {}
);

export const getCurrentRoute = (state: applicationState) =>
  state.appRouter.currentRoute;

export const getCurrentPath = (state: applicationState) =>
  state.appRouter.currentPath;

export const getAllStartedSessions = createSelector(
  allSessions,
  (allSessions) =>
    Object.values(allSessions).filter((session) => {
      return session.state === "STARTED";
    })
);

export const classesWithoutSession = createSelector(
  allClassesArray,
  (classes) => classes.filter((c) => !c.sessionId)
);

export const classesForNewSession = createSelector(allClassesArray, (classes) =>
  classes.filter((c) => c.canStartSession && !c.is_archived)
);

export const sessionsForClass = createSelector(
  allSessions,
  (allSessions) => (id: string) =>
    Object.values(allSessions).filter((c) => c.classId === id)
);

export const startedSessionsForClass = createSelector(
  allSessions,
  (allSessions) => (id: string) =>
    Object.values(allSessions).filter(
      (c) => c.classId === id && c.state === "STARTED"
    )
);

export const pastSessionsForClass = createSelector(
  allSessions,
  (allSessions) => (id: string) =>
    Object.values(allSessions).filter(
      (c) => c.classId === id && c.state !== "DRAFT"
    )
);

export const pastSessionsForClassFromDate = createSelector(
  allSessions,
  (allSessions) => (id: string, start: Date, end: Date) =>
    Object.values(allSessions).filter(
      (s) =>
        s.classId === id &&
        s.state !== "DRAFT" &&
        s.start_time >= start &&
        s.start_time < end
    )
);

export const draftSessionsForClass = createSelector(
  allSessions,
  (allSessions) => (id: string) =>
    Object.values(allSessions).filter(
      (c) => c.classId === id && c.state === "DRAFT"
    )
);

export const summaryStatsForSession = createSelector(
  allSessionStats,
  (allStatsSummary) => (id: string) => allStatsSummary[id]
);

export const allSummaryStats = createSelector(
  allSessionStats,
  (allStatsSummary) => () => allStatsSummary
);

export const liveStatsForSession = createSelector(
  allStudentsStats,
  (allStatsLive) => (id: string) =>
    Object.values(allStatsLive).filter((stat) => stat.sessionId === id)
);

export const cumilativeStatsForClass = createSelector(
  allSessionStats,
  (allSessionStats) => (_classId: string, start: Date, end: Date) =>
    Object.values(allSessionStats).filter(
      (stat) =>
        stat.classId === _classId &&
        stat.startDate >= start &&
        stat.startDate < end
    )
);

export const cumilativeStatsForStudents = createSelector(
  allStudentsStats,
  (allStudentsStats) => (_classId: string, start: Date, end: Date) =>
    Object.values(allStudentsStats).filter(
      (stat: studentStats) =>
        stat.classId === _classId &&
        stat.startDate >= start &&
        stat.startDate < end
    )
);

export const getAllTags = createSelector(
  allQuestionLibraries,
  (allQuestionLibraries): { value: string; count: number }[] => {
    const tagsSet = new Set<string>();
    const allLibraries = Object.values(allQuestionLibraries);
    const tagsCount: { [tag: string]: number } = {};

    allLibraries.map((library: QuestionLibrary) => {
      library.tags?.forEach((rawTag: string) => {
        const tag = rawTag.toLowerCase();
        if (tag != "") {
          if (tagsCount[tag]) {
            tagsCount[tag]++;
          } else {
            tagsCount[tag] = 1;
          }

          tagsSet.add(tag);
        }
      });
    });
    const uniqueTags = Array.from(tagsSet);

    const tagsArray = Object.keys(tagsCount).map((tag) => ({
      value: tag,
      count: tagsCount[tag],
    }));

    return tagsArray;
  }
);

export const getAllSubjects = createSelector(
  allQuestionLibraries,
  (allQuestionLibraries): { value: string; count: number }[] => {
    const allLibraries = Object.values(allQuestionLibraries);

    const subjectSet = new Set<string>();
    const subjectsCount: { [tag: string]: number } = {};

    allLibraries.map((library: QuestionLibrary) => {
      const subject = library.subject.toLowerCase();
      if (subject != "") {
        if (subjectsCount[subject]) {
          subjectsCount[subject]++;
        } else {
          subjectsCount[subject] = 1;
        }
        subjectSet.add(subject);
      }
    });
    const subjectsArray = Object.keys(subjectsCount).map((subject) => ({
      value: subject,
      count: subjectsCount[subject],
    }));
    return subjectsArray;
  }
);

export const classesFetchStatus = (state: applicationState) =>
  state.classes.fetching;
