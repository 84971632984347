import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Result,
  Row,
  Select,
  SelectProps,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import CheckableTag from "antd/lib/tag/CheckableTag";
import {
  addQuestionLibrary,
  createQuestionAndAddToLibrary,
  deleteQuestionLibrary,
  fetchAllLibraries,
  fetchLibraryQuestions,
  fetchQuestionLibrary,
  updateQuestion,
  updateQuestionLibrary,
} from "@/Services/QuestionLibrary";
import { Subjects, sampleQuestions } from "@/Services/Constants";
import { useSelector } from "react-redux";
import {
  currentUser,
  getAllQuestionLibraries,
  getAllTags,
  getLibraryById,
} from "@/Models/Selectors";
import {
  AntDesignOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import UserAvatar from "../Common/Image/UserAvatar";
import moment from "moment";
import { toTitleCase } from "../Common/Utils";
import bannerImage from "@/Assets/prepare.png";
import { useParams } from "react-router-dom";
import QuestionGridManager from "../Question/QuestionGridManager";
import { getQuestionForClient } from "@/Services/Question";
import Analytics from "@/Services/Analytics";
import Search from "antd/lib/input/Search";
import "./QuestionLibrary.css";
import UserLabel from "../Common/Image/UserLabel";
import LibraryShareModal from "./LibraryShareModal";
import BusinessLogo from "../AppHeader/BusinessLogo";
import LaunchModal from "./LaunchLibraryModal";

const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;
const { Paragraph } = Typography;
const messageKey = "messageKey";

const EditQuestionLibrary = ({
  viewOnly,
  onQuestionSelect,
  libraryParam,
  onBackPress,
  changeLibrary,
}: {
  viewOnly: boolean;
  libraryParam?: QuestionLibrary;
  onQuestionSelect?: (question: question) => void;
  onBackPress?: () => void;
  changeLibrary?: (library: QuestionLibrary) => void;
}) => {
  const libraryId = useParams<{ libraryId: string }>().libraryId;
  // const [loadingLibraries, setLoadingLibraries] = useState(false);
  // const [libraries, setLibraries] = useState<QuestionLibrary[]>([]);
  type tabsTypes = "My Sets" | "Shared with Me";
  const tabs: tabsTypes[] = ["My Sets", "Shared with Me"];
  const [selectedTab, setSelectedTab] = useState<string[]>(["My Sets"]);
  const LibraryObj: QuestionLibrary | undefined = useSelector(getLibraryById)(
    libraryParam?.id || libraryId
  );
  const [thisLibrary, setThisLibrary] = useState<QuestionLibrary | undefined>(
    libraryParam || LibraryObj
  );
  const [libraryQuestions, setLibraryQuestions] = useState<question[]>([]);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [subjects, setSubjects] = useState(
    Subjects.map((val) => ({ value: val }))
  );
  const [libraryName, setLibraryName] = useState(thisLibrary?.name || "");
  const [options, setOptions] = useState<string[]>([]);
  const key = "notifyMessage";
  const tagList: { value: string; count: number }[] = useSelector(getAllTags);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const _user: User = useSelector(currentUser);
  const tagInputVisible = useState(false);
  const [tagDropdownOpen, setTagDropdownOpen] = useState(false);
  const selectRef = useRef<any>(null);
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);

  const showShareModalClicked = () => {
    Analytics.track("Library Shared from Edit Library", thisLibrary);
    setShowShareModal(true);
  };

  const launchLibraryClicked = () => {
    Analytics.track("Library Launch clicked from Edit Library", thisLibrary);
    setShowLaunchModal(true);
  };

  useEffect(() => {
    if (!LibraryObj) {
      fetchQuestionLibrary(libraryId).catch(() => {
        Analytics.track("Library Questions Loaded", thisLibrary);
        message.error({ content: "Something went wrong" });
        history.push("/library");
      });
    }
    if (LibraryObj && LibraryObj.questions) {
      Analytics.track("Library Questions Loaded", LibraryObj);
      fetchLibraryQuestions(LibraryObj, LibraryObj.questions)
        .then((questions: question[]) => setLibraryQuestions(questions))
        .catch((error) =>
          message.error({
            content: "Something went wrong",
            key: "msg",
            duration: 2,
          })
        );
    }
    if (thisLibrary && !libraryName) setLibraryName(thisLibrary?.name);

    if (thisLibrary && _user) {
      const hasEditAccess = checkEditAccess(thisLibrary, _user);
      setHasEditAccess(hasEditAccess);
    }
    if (LibraryObj && thisLibrary && libraryId != thisLibrary.id) {
      setThisLibrary(LibraryObj);
      const hasEditAccess = checkEditAccess(LibraryObj, _user);
      setHasEditAccess(hasEditAccess);
      setLibraryName(LibraryObj.name);

      // const hasEditAccess = checkEditAccess(thisLibrary, _user);
      // setHasEditAccess(hasEditAccess);
    }
  }, [LibraryObj, libraryId]);

  // useEffect(() => {
  //     if (libraryId != selectedLibrary?.id) {

  //     }
  // }, [libraryId])

  useEffect(() => {
    setThisLibrary(LibraryObj);
  }, [LibraryObj]);

  // useEffect(() => {
  //     console.log("question libraries state updated")
  //     console.log(questionLibraries);
  // }, [questionLibraries])

  const checkEditAccess = (library: QuestionLibrary, user: User) => {
    let access = false;
    if (library.created_by == user.email) access = true;
    if (library.shared_with_user.includes(user.email)) access = true;
    if (user.institutes && user.institutes?.length > 0) {
      if (library.shared_with_institute.includes(user.institutes[0].id)) {
        access = true;
      }
    }

    let analytics_text = null;
    if (library.is_public) {
      analytics_text = "Public Library Loaded";
    } else if (library.created_by == user.email) {
      analytics_text = "Own Library Loaded";
    } else if (access) {
      analytics_text = "Shared Library Loaded";
    } else {
      analytics_text = "Library Loaded Without Access";
    }
    Analytics.track(analytics_text, library);
    return access;
  };

  const deleteLibrary = (id: string) => {
    Analytics.track("Delete Library Clicked", thisLibrary);

    message.loading({ content: "Deleting...", duration: 3, key: messageKey });
    deleteQuestionLibrary(id).then(() => {
      history.push("/library");
      message.success({ content: "Deleted", duration: 2, key: messageKey });
    });
  };

  const confirmDeleteLibrary = () => {
    if (LibraryObj) {
      if (LibraryObj.created_by == _user.email) {
        const modal = Modal.warning({
          title: "Are you sure you want to Delete",
          content: `This cannot be undone. Everyone who has access to this library will loose it.`,
          okButtonProps: { danger: true },
          okText: "Delete",
          cancelText: "Cancel",
          closable: true,
          okCancel: true,
          cancelButtonProps: { type: "default" },
          onCancel: () => console.log("canceled"),
          onOk: () => deleteLibrary(LibraryObj?.id),
        });
      } else {
        message.warn({
          content: "You don't have permissions to delete",
          duration: 3,
          key: messageKey,
        });
      }
    }
  };

  const handleChange = (tab: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTab, tab]
      : selectedTab.filter((t) => t !== tab);
    console.log("Changed filter to: ", nextSelectedTags);
    if (nextSelectedTags.length > 0) setSelectedTab(nextSelectedTags);
  };

  const createQuestionLibrary = () => {
    const library: QuestionLibrary = {
      created_by: "ribhu@vidya.us",
      subject: "Physics",
      created_on: new Date(),
      id: "32232redsdsf33sed3",
      is_public: false,
      name: "First question library",
      shared_with_institute: [],
      shared_with_user: [],
      updated_by: "ribhu@vidya.us",
      updated_on: new Date(),
      brief: "This is a brief",
      image:
        "https://a278bc1e43.imgdist.com/public/users/Integrators/BeeProAgency/973067_957694/logo-light1.png",
      questions: [],
      tags: ["easy", "class 12"],
    };
    addQuestionLibrary(library);
  };

  const saveQuestion = () => {
    const q = sampleQuestions[0];
    // createQuestionAndAddToLibrary(q, "HfxrHLCZKBeQTMELx43B")
    // addQuestionLibrary(library);
  };

  const handleQuestionListUpdate = (
    updatedQuestions: question[]
  ): Promise<void> => {
    return Promise.resolve();
  };

  const handleQuestionAdd = (question: question): Promise<void> => {
    Analytics.track("Add Question in Library", {
      question,
      library: thisLibrary,
    });
    if (thisLibrary) {
      return createQuestionAndAddToLibrary(question, thisLibrary.id).then(
        (question) => {
          const updatedQuestions = [...libraryQuestions, question];
          setLibraryQuestions(updatedQuestions);
        }
      );
    } else return Promise.reject();
  };

  const handleQuestionUpdate = (question: question): Promise<void> => {
    Analytics.track("Update Question in Library", {
      question,
      library: thisLibrary,
    });
    if (thisLibrary) {
      return updateQuestion(question).then((updatedQuestion) => {
        const updatedQuestions = libraryQuestions.map((q) => {
          if (q.id === question.id) {
            // Perform the necessary updates to the question
            return question;
          }
          return q; // Return unchanged question for other elements
        });
        setLibraryQuestions(updatedQuestions);
      });
    } else return Promise.reject();
  };

  const handleQuestionDelete = (question: question): Promise<void> => {
    if (thisLibrary) {
      const questionsArray = thisLibrary.questions?.filter(
        (q) => q !== question.id
      );
      const updatedLibrary: QuestionLibrary = {
        ...thisLibrary,
        questions: questionsArray,
      };

      return updateQuestionLibrary(updatedLibrary).then(() => {
        const updatedQuestions = libraryQuestions.filter(
          (q) => q.id != question.id
        );
        setLibraryQuestions(updatedQuestions);
        setThisLibrary(updatedLibrary);
      });
    } else return Promise.reject();
  };

  const updatelibraryName = useCallback(
    (v:any) => {
      Analytics.track("Update Library Name", thisLibrary);
      setLibraryName(v);
    },
    [libraryName]
  );
  const updateLibraryNameOnServer = (name: string) => {
    if (thisLibrary) {
      message.loading({ content: "Saving...", key, duration: 2 });
      return updateQuestionLibrary({ ...thisLibrary, name }).then(() => {
        console.log("library name updated on server - ", name);
        setLibraryName(name);
        // setThisLibrary({ ...thisLibrary, name });
        message.success({ content: "Saved!", key, duration: 2 });
      });
    } else return Promise.reject();
  };

  const duplicateLibrary = () => {
    Analytics.track("Duplicate Library Clicked", thisLibrary);
    if (thisLibrary) {
      message.loading({ content: "Duplicating...", key });
      let newLibrary: QuestionLibrary = {
        ...thisLibrary,
        name: "Copy of " + thisLibrary.name,
        created_by: _user.email,
        created_on: new Date(),
        updated_by: _user.email,
        updated_on: new Date(),
        is_public: false,
      };

      addQuestionLibrary(newLibrary)
        .then((library) => {
          console.log(library);
          message.success({ content: "Complete!", key, duration: 2 });

          if (changeLibrary) changeLibrary(library);
          else {
            history.push(`/library/edit/${library.id}`);
          }
        })
        .catch((e) => {
          message.error("Something went wrong. Please try again later");
        });
    }
  };

  const updateTags = (tags: string[]) => {
    Analytics.track("Update Library Tags", tags);
    if (thisLibrary) {
      message.loading({ content: "Saved!", key, duration: 2 });
      return updateQuestionLibrary({ ...thisLibrary, tags }).then(() => {
        console.log("library tags updated on server - ", tags);
        message.success({ content: "Saved!", key, duration: 2 });
      });
    } else return Promise.reject();
  };
  const updateSubject = (subject: string) => {
    Analytics.track("Update Library Subject", { subject });
    if (thisLibrary) {
      message.loading({ content: "Saved!", key, duration: 2 });
      return updateQuestionLibrary({ ...thisLibrary, subject }).then(() => {
        console.log("library subject updated on server - ", subject);
        message.success({ content: "Saved!", key, duration: 2 });
      });
    } else return Promise.reject();
  };

  const onSearch = (searchText: string) => {
    const newSubjects = Subjects.filter((sub) => {
      return sub.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
    setSubjects(newSubjects.map((val) => ({ value: val })));
  };

  const handleSearch = (value: string) => {
    // Perform filtering logic here based on the user's input value
    // You can use this filtered data to update the autocomplete options
    // For simplicity, this example uses a case-insensitive filter
    const filteredSubjects = Subjects.filter((subject) =>
      subject.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredSubjects);
  };

  const handleSelect = (value: string) => {
    // Perform the logic to update the subject on the backend here
    console.log("Selected subject:", value);
    setIsSaving(true);
    updateLibraryNameOnServer(value).finally(() => {
      setIsSaving(false);
    });
    // Your backend update logic goes here
  };

  return (
    <>
      {thisLibrary && !hasEditAccess && !thisLibrary?.is_public ? (
        <Result
          status="403"
          title="Where do we go now?"
          icon={<img style={{ maxWidth: "300px" }} src={bannerImage} />}
          subTitle={
            <div>
              {"You don't have access."} <br />{" "}
              {"Please ask the author to share access with " +
                _user.email +
                (_user.institutes && _user.institutes?.length > 0
                  ? " or grant access to whole " +
                    _user.institutes[0].name +
                    " org if you are part of the same organization. "
                  : "")}
            </div>
          }
          extra={
            <div>
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="ant-btn ant-btn-primary ml-auto"
                onClick={() => history.push("/library")}
              >
                Go Back
              </button>
              {/* {EmptyPageAdditionalAction ? EmptyPageAdditionalAction : null} */}
              {/* <div className="mt-4"> or </div> */}
              {/* <Button type="link" onClick={() => addSampleQuestions()}>Load Sample Questions</Button> */}
            </div>
          }
        ></Result>
      ) : (
        <>
          {thisLibrary ? (
            <LibraryShareModal
              questionLibrary={thisLibrary}
              visible={showShareModal}
              onShareComplete={(library: QuestionLibrary) => {
                setShowShareModal(false);
              }}
            />
          ) : null}

          <LaunchModal
            name={libraryName}
            questions={libraryQuestions}
            visible={showLaunchModal}
            onComplete={() => setShowLaunchModal(false)}
          />
          {!thisLibrary ? (
            <Loader />
          ) : (
            <>
              {
                <>
                  <Layout
                    className="site-layout-background vidya-page-layout lyt"
                    style={{
                      padding: "0 0",
                    }}
                  >
                    <Content style={{ padding: "18px 18px", minHeight: 280 }}>
                      {
                        <div className="text-left">
                          <PageHeader
                            style={{ padding: 0 }}
                            className="site-page-header"
                            onBack={() => {
                              if (onBackPress) onBackPress();
                              else {
                                history.length > 1
                                  ? history.goBack()
                                  : history.push("/library");
                              }
                            }}
                            title={
                              !viewOnly ? (
                                <Text
                                  disabled={!hasEditAccess}
                                  editable={{
                                    onChange: (e) => {
                                      if (e.trim().length > 0) {
                                        if (hasEditAccess) {
                                          setLibraryName(e);
                                          updateLibraryNameOnServer(e);
                                        } else {
                                          message.warning({
                                            content: "No edit access",
                                          });
                                        }
                                      }
                                    },
                                  }}
                                >
                                  {" "}
                                  {libraryName}{" "}
                                </Text>
                              ) : (
                                <Text>{libraryName}</Text>
                              )
                            }
                            extra={
                              !viewOnly ? (
                                <Space direction="horizontal" align="center">
                                  {/* <span>Shared with: </span> */}
                                  <Avatar.Group
                                    maxCount={2}
                                    maxPopoverTrigger="click"
                                    size="large"
                                    maxStyle={{
                                      color: "#f56a00",
                                      backgroundColor: "#fde3cf",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {thisLibrary.shared_with_institute.map(
                                      (institute) => {
                                        return _user?.institutes &&
                                          _user?.institutes[0]?.id ==
                                            institute ? (
                                          <span
                                            onClick={() =>
                                              showShareModalClicked()
                                            }
                                            className="ff"
                                          >
                                            {" "}
                                            <Avatar
                                              src={
                                                _user?.institutes
                                                  ? _user?.institutes[0]
                                                      ?.img_url
                                                  : ""
                                              }
                                            />
                                          </span>
                                        ) : null;
                                      }
                                    )}
                                    {
                                      <UserAvatar
                                        email={thisLibrary.created_by}
                                        tooltip={thisLibrary.created_by}
                                      />
                                    }
                                    {thisLibrary.shared_with_user.map(
                                      (email) => (
                                        <span
                                          onClick={() =>
                                            showShareModalClicked()
                                          }
                                          className="ff"
                                        >
                                          {" "}
                                          <UserAvatar
                                            email={email}
                                            tooltip={email}
                                          />
                                        </span>
                                      )
                                    )}
                                  </Avatar.Group>
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      hasEditAccess
                                        ? "Share Library"
                                        : "You don't have edit access"
                                    }
                                  >
                                    <Button
                                      disabled={!hasEditAccess}
                                      onClick={() => showShareModalClicked()}
                                      style={{
                                        margin: "-5px 0 0 -16px",
                                      }}
                                      type="dashed"
                                      shape="circle"
                                      icon={<UserAddOutlined />}
                                      size="large"
                                    />
                                  </Tooltip>
                                  <span></span>
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        <Menu.Item
                                          onClick={() => duplicateLibrary()}
                                          style={{
                                            // color: "red",
                                            padding: "7px 12px;",
                                          }}
                                        >
                                          <Tooltip title="Copy entire library">
                                            <CopyOutlined /> Duplicate
                                          </Tooltip>
                                        </Menu.Item>
                                        <Menu.Item
                                          onClick={() => confirmDeleteLibrary()}
                                          style={{
                                            // color: "red",
                                            padding: "7px 12px;",
                                          }}
                                        >
                                          <Tooltip title="Dont have access to delete">
                                            <DeleteOutlined /> Delete
                                          </Tooltip>
                                        </Menu.Item>
                                      </Menu>
                                    }
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        <MoreOutlined
                                          className="3r"
                                          style={{ fontSize: 24 }}
                                          size={30}
                                        />
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </Space>
                              ) : null
                            }
                          />
                          <div className="page-header">
                            <div className="text-sm text-gray-600">
                              Collections of questions to conduct live quiz
                            </div>
                            {/* <Divider /> */}
                            {/* Seatch bar and Add New button */}
                            <Row
                              justify="space-between"
                              className="flex flex-wrap mt-8"
                            >
                              <Col xs={24} sm={12} lg={16} className="mb-4">
                                <div className="">
                                  <Space direction="horizontal" wrap>
                                    Subject:{" "}
                                    {!viewOnly ? (
                                      <AutoComplete
                                        disabled={!hasEditAccess}
                                        options={subjects}
                                        defaultActiveFirstOption
                                        style={{ minWidth: 200 }}
                                        defaultValue={thisLibrary.subject}
                                        // onSelect={onSelect}
                                        filterOption={(inputValue, option) =>
                                          option!.value
                                            .toUpperCase()
                                            .indexOf(
                                              inputValue.toUpperCase()
                                            ) !== -1
                                        }
                                        // defaultValue={"Physics"}
                                        onChange={onSearch}
                                        onSelect={updateSubject}
                                        onSearch={(e) => {
                                          updateSubject(e);
                                        }}
                                        placeholder="Subject Name"
                                      />
                                    ) : (
                                      <Text
                                        strong={thisLibrary.subject.length > 0}
                                      >
                                        {thisLibrary.subject || "No Subject"}
                                      </Text>
                                    )}
                                    <span className="ml-4">
                                      Tags:{" "}
                                      {
                                        <Select
                                          disabled={!hasEditAccess}
                                          mode="tags"
                                          ref={selectRef}
                                          style={{
                                            minWidth: "150px",
                                            border: "none",
                                            borderBottom: 1,
                                          }}
                                          bordered={false}
                                          notFoundContent={
                                            <span className="text-italic">
                                              Start typing...
                                            </span>
                                          }
                                          placeholder={
                                            !viewOnly ? "+ New Tag" : "no tags"
                                          }
                                          onDropdownVisibleChange={(
                                            open: boolean
                                          ) => {
                                            if (
                                              open &&
                                              selectRef.current &&
                                              selectRef.current
                                            ) {
                                              selectRef.current.focus();
                                            }
                                            setTagDropdownOpen(open);
                                          }}
                                          tagRender={(props: SelectProps) => {
                                            return (
                                              <>
                                                <Tag> {props.value}</Tag>
                                                {!tagDropdownOpen &&
                                                !viewOnly &&
                                                (thisLibrary.tags ===
                                                  undefined ||
                                                  (thisLibrary.tags.length >
                                                    0 &&
                                                    thisLibrary.tags[
                                                      thisLibrary.tags.length -
                                                        1
                                                    ] == props.value)) ? (
                                                  <Tag
                                                    style={{
                                                      background: "#fff",
                                                      borderStyle: "dashed",
                                                    }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    New Tag{" "}
                                                  </Tag>
                                                ) : null}
                                              </>
                                            );
                                          }}
                                          defaultValue={thisLibrary?.tags}
                                          onChange={(e) => updateTags(e)}
                                          optionLabelProp="tags"
                                        >
                                          {tagList?.map((tag) => (
                                            <Option
                                              value={tag.value}
                                              label={"tags"}
                                            >
                                              <div className="demo-option-label-item">
                                                {`${tag.value}`}
                                              </div>
                                            </Option>
                                          ))}
                                        </Select>
                                      }
                                    </span>
                                  </Space>
                                </div>
                              </Col>
                              {!viewOnly ? (
                                <Col xs={24} sm={12} lg={6} className="mb-4">
                                  <div className="sm:text-right text-center">
                                    {isSaving ? (
                                      <span className="text-gray-500">
                                        {" "}
                                        <SyncOutlined spin />{" "}
                                        <span className="text-gray-500">
                                          saving...{" "}
                                        </span>{" "}
                                      </span>
                                    ) : (
                                      <span className="text-gray-500">
                                        {" "}
                                        <CheckCircleOutlined />{" "}
                                        <span className="text-gray-500">
                                          saved{" "}
                                          {thisLibrary.questions ? (
                                            <>
                                              |{" "}
                                              <span>
                                                {thisLibrary.questions.length}{" "}
                                                {thisLibrary.questions
                                                  ?.length == 1
                                                  ? "Question"
                                                  : "Questions"}
                                              </span>
                                            </>
                                          ) : null}{" "}
                                        </span>{" "}
                                      </span>
                                    )}
                                    <Button
                                      className="ml-2"
                                      // loading={isStarting}
                                      type={"primary"}
                                      icon={<PlayCircleOutlined />}
                                      onClick={() => {
                                        launchLibraryClicked();
                                        // if (session.state == "STARTED") {
                                        //     history.push(`/session/${session.sessionId}`)
                                        // } else {
                                        //     onStart()
                                        // }
                                      }}
                                    >
                                      Use
                                    </Button>
                                  </div>
                                </Col>
                              ) : null}
                            </Row>
                          </div>

                          <div className="w-full">
                            <div className="mt-8">
                              <div
                                style={{
                                  // height: "calc(100vh - 104px)",
                                  display: "block",
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                }}
                                className="w-full"
                              >
                                <div className="w-full">
                                  {thisLibrary.questions &&
                                  thisLibrary.questions?.length > 0 &&
                                  libraryQuestions.length == 0 ? (
                                    <Loader />
                                  ) : viewOnly &&
                                    libraryQuestions.length == 0 ? (
                                    <div>
                                      {" "}
                                      <div className="text-center w-full">
                                        {" "}
                                        There are no questions in this library{" "}
                                      </div>{" "}
                                    </div>
                                  ) : (
                                    <QuestionGridManager
                                      questionList={libraryQuestions || []}
                                      onQuestionListUpdate={
                                        handleQuestionListUpdate
                                      }
                                      onQuestionAdd={handleQuestionAdd}
                                      onQuestionDelete={handleQuestionDelete}
                                      onQuestionEdit={handleQuestionUpdate}
                                      onQuestionSelect={(q: question) => {
                                        if (onQuestionSelect)
                                          onQuestionSelect(q);
                                      }}
                                      flexibleHeight={viewOnly}
                                      disableEdit={!hasEditAccess || viewOnly}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </Content>
                  </Layout>
                </>
              }
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditQuestionLibrary;
