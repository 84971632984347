const Actions = {
  AUTH: {
    init: "on-app-init",
    authenticating: "authenticating",
    authenticated: "authenticated",
    signedout: "signedout",
    noCurrentUser: "no-current-user",
    onboarding: "onboarding",
    active: "ACTIVE",
    unreachable: "unreachable",
  },

  SESSIONS: {
    ADD: "ADD_SESSION",
    ADD_BULK: "ADD_SESSION_BULK",
    DELETE: "DELETE_SESSION",
    UPDATE: "UPDATE_SESSION",
    CURRENT_SESSION: {
      SET: "SET_CURRENT_SESSION",
      UPDATE_NAME: "SET_CURRENT_SESSION_NAME",
      UPDATE_DESCRIPTION: "SET_CURRENT_SESSION_DESCRIPTION",
      START_SESSION: "START_CURRENT_SESSION",
      END_SESSION: "END_CURRENT_SESSION",
      ADD_QUESTION: "ADD_QUESTION",
      UPDATE_QUESTION: "UPDATE_CURRENT_SESSION_QUESTION",
    },
    SOCKET: {
      CONNECTED: "SOCKET_CONNECTED",
      DISCONNECTED: "SOCKET_DISCONNECTED",
      START_CONNECTION: "SOCKET_START_CONNECTION",
    },
    UPDATE_QUESTION: "UPDATE_ANY_QUESTION",
    ADD_QUESTION: "ADD_ANY_QUESTION",
  },

  CLASSES: {
    ADD: "ADD_CLASS",
    ADD_BULK: "ADD_CLASS_BULK",
    DELETE: "DELETE_CLASS",
    UPDATE: "UPDATE_CLASS",
    SET_CURRENT_CLASS: "SET_CURRENT_CLASS",
    SET_FETCHING: "SET_FETCHING",
  },

  STATS: {
    ADD: "ADD_SESSION_STAT",
    DELETE: "DELETE_SESSION_STAT",
    UPDATE: "UPDATE_SESSION_STAT",
    SUMMARY: {
      ADD: "ADD_SESSION_SUMMARY_STAT",
      DELETE: "DELETE_SESSION_SUMMARY_STAT",
      UPDATE: "UPDATE_SESSION_SUMMARY_STAT",
    },
  },

  USERS: {
    ADD: "ADD_USER",
    ADD_BULK_USERS: "ADD_USERS",
    DELETE: "DELETE_USER",
    UPDATE: "UPDATE_USER",
    CURRENT_USER: {
      SET: "SET_CURRENT_USER",
    },
    SET_FETCHING: "SET_FETCHING",
  },
  ROUTE: {
    UPDATE: "UPDATE_ROUTE",
    UPDATE_PATH: "UPDATE_PATH",
  },
  QuestionLibrary: {
    ADD: "ADD_QUESTION_LIBRARY",
    BULK_ADD: "BULK_ADD_QUESTION_LIBRARY",
    DELETE: "DELETE_QUESTION_LIBRARY",
    UPDATE: "UPDATE_QUESTION_LIBRARY",
    ADD_QUESTION: "ADD_QUESTION",
  },
};

export default Actions;
