import { Composition } from "atomic-layout";
import React, { useCallback, useEffect, useState } from "react";
import classImg from "@/Assets/newClass.png";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  Space,
  notification,
} from "antd";
import { createNewClass, detectLanguage } from "@/Services/Class";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentUser, myClasses } from "@/Models/Selectors";
import {
  defaultLanguage,
  isRTL,
  ListOfAllLanguages,
  Subjects,
} from "@/Services/Constants";

const NewClass = ({ showBanner }: { showBanner: boolean }) => {
  const history = useHistory();
  const myClassList = useSelector(myClasses)();
  const [subjects, setSubjects] = useState(
    Subjects.map((val) => ({ value: val }))
  );
  const loggedInUser: User = useSelector(currentUser);
  const defaultSubject =
    myClassList && myClassList.length > 0 ? myClassList[0].name : "";
  const [subject, setSubject] = useState(defaultSubject);
  const [description, setDescription] = useState("");
  const [isRightToLeft, setIsRightToLeft] = useState<boolean>(false);
  const [detectedLang, setDetectedLang] = useState<string>();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ language: detectedLang });
  }, [detectedLang]);
  const onSearch = (searchText: string) => {
    const newSubjects = Subjects.filter((sub) => {
      return sub.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
    setSubjects(newSubjects.map((val) => ({ value: val })));
  };

  const saveComplete = (classId: string) => {
    const subtab = "students";
    history.push("/class/" + classId + "/" + subtab);
  };

  const onSave = (values: any) => {
    const instituteId =
      loggedInUser.institutes && loggedInUser.institutes.length > 0
        ? loggedInUser.institutes[0].id
        : "";
    setIsSaving(true);
    createNewClass(
      values.subject,
      values.description,
      (Data: any) => {
        console.log(Data);

        if (!Data) {
          console.log("error while creating class", Data);
          return;
        }
        saveComplete(Data.id);
      },
      instituteId
    )
      .then(() => {
        setIsSaving(false);
      })
      .catch((e) => {
        setIsSaving(false);
        notification.error({
          message: "Something went wrong. Please refresh and try again!",
        });
      });
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {/* // <Composition
    //     templateRows='auto'
    //     gap='20px'
    //     justifyItems='center'
    //     padding='20px'

    // > */}
      {showBanner ? (
        <>
          <img
            style={{
              minWidth: "250px",
              maxWidth: "50%",
            }}
            src={classImg}
            alt="new class"
          />
          <h1 className="text-4xl">Create new Class</h1>
        </>
      ) : null}
      {/* <div style={{ display: 'inherit' }}> */}

      <Form
        form={form}
        name="basic"
        labelCol={{ offset: 8, span: 8 }}
        wrapperCol={{ offset: 8, span: 8 }}
        initialValues={{ subject: subject, language: detectedLang }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        // autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="I am going to teach"
          name="subject"
          initialValue={subject}
          rules={[{ required: true, message: "You gotta teach something!" }]}
        >
          <AutoComplete
            options={subjects}
            defaultActiveFirstOption
            style={{ minWidth: 200 }}
            defaultValue={subject}
            // onSelect={onSelect}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            // defaultValue={"Physics"}
            onChange={onSearch}
            onSelect={(subject: string) => {
              detectLanguage(subject).then((lang: string) => {
                const isleft = isRTL(lang);
                if (isleft) {
                  setIsRightToLeft(isleft);
                  form.setFieldsValue({ language: lang });
                  setDetectedLang(lang);
                }
              });
              setSubject(subject);
            }}
            onSearch={(e) => {
              setSubject(e);
            }}
            placeholder="Subject Name"
          />

          {/* <Input
                    onChange={(e) => detectLanguage(e.target.value)
                        .then((lang: string) => {
                            const isleft = isRTL(lang);
                            if (isleft) {
                                setIsRightToLeft(isleft);
                                form.setFieldsValue({ language: lang })
                                setDetectedLang(lang);
                            }
                        })}
                    value={subject}
                    placeholder="(example - History of Magic)"
                /> */}
        </Form.Item>

        <Form.Item
          label="to"
          name="description"
          rules={[{ required: true, message: "You gotta teach to someone!" }]}
        >
          <Input placeholder={"Gryffindor"} />
        </Form.Item>
        {detectedLang ? (
          <>
            <Form.Item
              label="Language (optional)"
              name="language"
              dependencies={[detectedLang]}
              shouldUpdate
              rules={[
                { required: false, message: "Please enter your language!" },
              ]}
            >
              <Select
                showSearch
                style={{ textAlign: "left" }}
                placeholder="Select Language"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                defaultValue={detectedLang}
                onChange={(value: string) => {
                  setDetectedLang(value);
                  setIsRightToLeft(isRTL(value));
                }}
                options={Object.keys(ListOfAllLanguages).map((name, index) => {
                  const nativeName =
                    Object.values(ListOfAllLanguages)[index].nativeName;
                  const englishName =
                    Object.values(ListOfAllLanguages)[index].name;
                  const finalName =
                    nativeName === englishName
                      ? englishName
                      : `${englishName} (${nativeName})`;
                  return {
                    value: Object.keys(ListOfAllLanguages)[index],
                    label: finalName,
                  };
                })}
              />
              {detectedLang && isRightToLeft ? (
                <div className="text-xs text-gray-900 text-right">
                  All text would be right to left
                </div>
              ) : null}
            </Form.Item>
          </>
        ) : null}

        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button loading={isSaving} type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  // </Composition>
};

export default NewClass;
