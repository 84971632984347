import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  currentSession,
  currentUser as userSelector,
  allUsersArray as allUsersSelector,
  classesFetchStatus,
  getCurrentPath as currentPathSelector,
  authStatus,
  getUserById,
  getPrepareSessionForClass,
  allActiveClassesArray,
  allSessions,
} from "@/Models/Selectors";
import { isSessionStarted, getSession } from "@/Services/Session";
import type { applicationState } from "@/Models/store";
import { Button, Divider, Menu, Space, Table } from "antd";
import { Composition } from "atomic-layout";
import bannerImage from "@/Assets/student-types.png";
import "./Dashboard.css";
import { setCurrentPath } from "@/Services/Router";
import Modal from "antd/lib/modal/Modal";
import InviteModal from "../Class/inviteModal";
import Loader from "../Loader";
import store from "@/Models/store";
import {
  Link,
  match,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  EditOutlined,
  PlayCircleOutlined,
  PlusCircleFilled,
  SettingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import UserLabel from "../Common/Image/UserLabel";
import UserAvatar from "../Common/Image/UserAvatar";
import {
  createEmptyPrepareSessionForClass,
  fetchAllClasses,
  startPreparedSessionForClass,
} from "@/Services/Class";
import * as Constants from "@/Services/Constants";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import TeacherDashboard from "./TeacherDashboard";
import {
  onStartSessionEvent,
  startEndSessionEventHandler,
  unsubscribeHandler,
} from "@/Services/Firebase/Subscriber";
import { DocumentData } from "firebase/firestore";
import Actions from "@/Models/Actions";

const areas = `
    headerImage
    welcomeTitle
    preparedClasses
    allClasses
    addNewClass
`;

type Props = {
  allClasses: Class[];
  currentSession: SessionState | undefined;
  currentUser: User | undefined;
  currentRoute: string;
  match: match;
};

const Dashboard = function ({
  allClasses,
  currentSession,
  currentUser,
  currentRoute,
  match,
}: Props) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteModalClass, setInviteModalClass] = useState<Class | undefined>();
  const [redirctTo, setRedirctTo] = useState(""); // your state value to manipulate
  const status = useSelector(authStatus);
  const history = useHistory();
  const currentPath = useSelector(currentPathSelector);
  const { search } = useLocation();
  const sessions = useSelector(allSessions);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const areClassesBeingFetched = useSelector(classesFetchStatus);

  const getClassSession = (class_id: string) => {};

  useEffect(() => {
    document.getElementById("Smallchat")?.classList.remove("hidden");
    if (currentPath && currentPath !== "/loading" && currentPath !== "/") {
      const redirectPath = query ? currentPath + "?" + search : currentPath;
      setRedirctTo(redirectPath);
      console.log("current path is ", currentPath);
      setCurrentPath(null);
    }
  }, []);

  function getSessionById(sessionId: string) {
    return getSession(sessionId);
  }

  useEffect(() => {
    let subscribers: any[] = [];
    const renderTimeout = setTimeout(() => {
      if (currentUser?.role === "STUDENT") {
        console.log(Object.values(allSessions), "allSessions");
        allClasses.forEach((current_class) => {
          startEndSessionEventHandler(current_class.id, "STARTED").then(
            (sub) => {
              subscribers.push(sub);
            }
          );

          startEndSessionEventHandler(current_class.id, "ENDED").then((sub) => {
            subscribers.push(sub);
          });
        });
      }
    }, 1500);

    return () => {
      clearTimeout(renderTimeout);
      unsubscribeHandler(subscribers);
    };
  }, [areClassesBeingFetched]);

  useEffect(() => {
    if (
      !currentUser?.settings ||
      !currentUser?.settings.type ||
      currentUser.settings.type === "unknown"
    ) {
      let url;
      // if (status === "no-current-user") {
      //   url = currentUser?.role === "STUDENT" ?
      // }
      if (!areClassesBeingFetched && currentUser?.role === "TEACHER") {
        url =
          status === "no-current-user"
            ? "/signup/"
            : allClasses.length === 0
            ? "/signup/TEACHER/1"
            : "/signup/TEACHER/2";
        setRedirctTo(url);
        console.log(url);
      }
    }
  }, [areClassesBeingFetched, currentPath]);

  const onClassInvite = (_class: Class) => {
    setShowInviteModal(true);
    setInviteModalClass(_class);
  };

  const onInviteModalClose = useCallback(() => {
    setShowInviteModal(false);
    setInviteModalClass(undefined);
  }, []);

  if (
    redirctTo &&
    redirctTo.length > 0 &&
    status !== "no-current-user" &&
    !["/signup", "/login", "/", "guest-signup", "", "password-reset"].includes(
      redirctTo
    )
  ) {
    console.log("current path is ", redirctTo);
    return <Redirect to={redirctTo} />;
  }

  if (currentRoute === "new-class") {
    return <Redirect to="/add-class" />;
  }

  if (status === "init") {
    return <Loader />;
  }

  if (currentRoute === "join-class") {
    return <Redirect to="/join-class" />;
  }

  if (currentRoute === "new-session") {
    return <Redirect to="/start-session" />;
  }

  if (status === "no-current-user") {
    return <Redirect to="/signup" />;
  }

  if (currentSession && currentSession.state !== "ENDED") {
    // return <Redirect to={'/session/' + currentSession.sessionId} />;
  }
  const { email: ownerEmail, first_name, last_name } = currentUser || {};

  const prepareClassClicked = (_class: Class) => {
    // check if new user session
    const isNewSession =
      allClasses.length == 1 &&
      (!_class.sessions || _class.sessions.length == 0);
    const isVidyaUser = currentUser
      ? ["vidya.us", "vidyatest.us"].includes(currentUser?.email.split("@")[1])
      : false;
    const url =
      isNewSession || isVidyaUser
        ? "prepare-class/" + _class.id + "?sample=true"
        : "prepare-class/" + _class.id;
    createEmptyPrepareSessionForClass(_class, _class.name).then(() => {
      history.push(url);
    });
    // console.log(url);
  };

  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Mike',
  //     age: 32,
  //     address: '10 Downing Street',
  //   },
  //   {
  //     key: '2',
  //     name: 'John',
  //     age: 42,
  //     address: '10 Downing Street',
  //   },
  // ];

  const preparedSessionsdataSource: any[] = [];

  allClasses.forEach((_class: Class) => {
    const preparedSession = getPrepareSessionForClass(store.getState())(
      _class.id
    );
    if (preparedSession) {
      const canJoinSession = _class.sessionId
        ? isSessionStarted(getSession(_class.sessionId))
        : false;
      const row = {
        key: preparedSession.sessionId,
        session: preparedSession.name,
        class: _class.description + " (" + _class.name + ")",
        questions: preparedSession.draft_questions.length,
        updated: canJoinSession
          ? preparedSession.start_time.toDateString()
          : preparedSession.updated_at.toDateString(),
        actions: (
          <span>
            <Space direction="horizontal" wrap>
              {canJoinSession ? (
                <Link to={"/session/" + preparedSession.sessionId}>Join</Link>
              ) : (
                <Button
                  type="primary"
                  onClick={() =>
                    onStart(
                      _class,
                      getPrepareSessionForClass(store.getState())(_class.id)
                    )
                  }
                >
                  Start
                </Button>
              )}

              {/* </Button> */}
              <DropdownButton
                className="float-right"
                type={"dashed"}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={(e) => onClassInvite(_class)}
                      key={_class.id + "12343r"}
                    >
                      <ShareAltOutlined /> Invite
                    </Menu.Item>
                    {Constants.enable_setting_class_configure ? (
                      <Menu.Item key={_class.id + "ewdfv4"}>
                        <Link to={"/class/" + _class.id + "/configure"}>
                          <SettingOutlined /> Configure
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
              >
                <span>
                  <Link to={"prepare-class/" + _class.id}>
                    <EditOutlined />
                  </Link>
                </span>
              </DropdownButton>
            </Space>
          </span>
        ),
      };
      preparedSessionsdataSource.push(row);
    }
  });

  const columns = [
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Q's",
      dataIndex: "questions",
      key: "questions",
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "Updated",
      responsive: ["lg"] as Breakpoint[],
      // defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => Date.parse(a.updated) - Date.parse(b.updated),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const onStart = (_class: Class, session: SessionState | null) => {
     if (_class && session) {
      startPreparedSessionForClass(_class, session.name, session).then(
        (data) => {
          // setIsStarting(false);
          history.push("/session/" + data.id);
        }
      );
    }
  };

  return (
    <>
      <Composition
        key={uuidv4()}
        areas={areas}
        justify="center"
        gap="20px"
        paddingTop="1rem"
        paddingBottom="50px"
      >
        {({
          HeaderImage,
          WelcomeTitle,
          PreparedClasses,
          Buttons,
          AllClasses,
          AddNewClass,
        }) => (
          <>
            {currentUser && currentUser?.role == "TEACHER" ? (
              <TeacherDashboard />
            ) : (
              <>
                <HeaderImage key={uuidv4()} justify="center">
                  {currentUser?.role == "STUDENT" ? (
                    <img
                      className="md:w-7/12 mx-auto"
                      src={bannerImage}
                      alt="banner"
                    />
                  ) : null}
                </HeaderImage>
                <WelcomeTitle key={uuidv4()}>
                  <h1 className="text-3xl md:text-4xl ">
                    Welcome, {first_name} {last_name}
                  </h1>
                </WelcomeTitle>
                {!areClassesBeingFetched ? (
                  <>
                    {preparedSessionsdataSource.length > 0 ? (
                      <PreparedClasses>
                        <div
                          style={{
                            maxWidth: 800,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <span className="float-left text-lg text-bold ml-4">
                            Drafted Sessions
                          </span>

                          <Table
                            pagination={false}
                            className="classesList text-left ml-4 mr-4"
                            // width="70vw"
                            // maxWidth="calc(100vw - 30px)"

                            dataSource={preparedSessionsdataSource}
                            columns={columns}
                          />
                        </div>
                      </PreparedClasses>
                    ) : null}

                    <AllClasses key={uuidv4()} justify="center" padding="15px">
                      {allClasses.length > 0 ? (
                        <>
                          {allClasses.filter(
                            (_class) =>
                              !_class.hasPreparedSession ||
                              !_class.canStartSession
                          ).length > 0 ? (
                            <Composition
                              key={uuidv4()}
                              // templateCols="2fr minmax(100px, 1fr) 1fr 130px"
                              templateCols="2fr minmax(50px, 1fr) 130px"
                              templateColsMd="2fr minmax(200px, 1fr) 130px"
                              className="classesList text-left"
                              // width="70vw"
                              maxWidth="calc(100vw - 30px)"
                              style={{
                                overflowX: "clip",
                              }}
                            >
                              <h2 className="pl-2 bold-heading">My classes</h2>

                              <span />
                              {/* <span /> */}

                              {currentUser?.role &&
                              currentUser?.role === "STUDENT" ? (
                                <span className="inset-y-0 right-0">
                                  {/* <Link
                              to="/class/add"
                              className="ant-btn ant-btn-default ant-btn-circle float-right"
                              type="default"
                            >
                              <PlusOutlined />
                            </Link> */}
                                </span>
                              ) : (
                                <span></span>
                              )}
                              {[
                                "Name",
                                currentUser?.email ===
                                Constants.dummyStudentEmail
                                  ? ""
                                  : "Host",
                                "",
                              ].map((header) => (
                                <span key={uuidv4()} className="header">
                                  {header}
                                </span>
                              ))}
                              {/* Action buttons */}
                              {/* <span key={uuidv4()}></span> */}
                              {allClasses
                                .filter(
                                  (_class) =>
                                    !_class.hasPreparedSession ||
                                    !_class.canStartSession
                                )
                                .sort((a, b) => (a.name > b.name ? -1 : 1))
                                .reverse()
                                .map((c, index) => {
                                  const canJoinSession = c.sessionId
                                    ? isSessionStarted(getSession(c.sessionId))
                                    : false;
                                  // const canStartSession =
                                  const teacherMenu: any = (
                                    canStart: boolean,
                                    canJoin: boolean,
                                    isPrepared: boolean
                                  ) => {
                                    if (canStart) {
                                      console.log("can start");
                                    }
                                    return (
                                      <Menu>
                                        {!isPrepared ? (
                                          <Menu.Item
                                            disabled={canJoin}
                                            key={c.id + "efssd"}
                                          >
                                            {/* <Link to={"/class/" + c.id + '/prepare/session'}> */}
                                            <Link to={"/new-session/" + c.id}>
                                              {canJoin ? (
                                                <>
                                                  <EditOutlined /> Edit
                                                  Preparation{" "}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <PlayCircleOutlined /> Start
                                                </>
                                              )}
                                            </Link>
                                            {/* </Link> */}
                                          </Menu.Item>
                                        ) : (
                                          <Menu.Item disabled={canJoin} key="2">
                                            <Link
                                              to={
                                                "/prepare-class/" +
                                                c.id +
                                                "/session/" +
                                                c.sessionId
                                              }
                                            >
                                              <EditOutlined /> Edit Preparation
                                            </Link>
                                          </Menu.Item>
                                        )}

                                        <Menu.Item
                                          onClick={(e) => onClassInvite(c)}
                                          key="3"
                                        >
                                          <ShareAltOutlined /> Invite
                                        </Menu.Item>
                                        {Constants.enable_setting_class_configure ? (
                                          <Menu.Item key="4">
                                            <Link
                                              to={
                                                "/class/" + c.id + "/configure"
                                              }
                                            >
                                              {" "}
                                              <SettingOutlined /> Configure{" "}
                                            </Link>
                                          </Menu.Item>
                                        ) : null}
                                      </Menu>
                                    );
                                  };

                                  const user = getUserById(store.getState())(
                                    c.primary_teacher
                                  );
                                  const img_url = user?.img_url;
                                  const ColorList = [
                                    "#f56a00",
                                    "#7265e6",
                                    "#ffbf00",
                                    "#00a2ae",
                                  ];
                                  const color =
                                    ColorList[
                                      Math.floor(
                                        Math.random() * ColorList.length
                                      )
                                    ];

                                  return (
                                    <>
                                      <span key={uuidv4()}>
                                        {c.description} - {c.name}
                                        <div className="text-xs text-gray-500">
                                          {c.students_count}{" "}
                                          {c.students_count == 1
                                            ? "Student"
                                            : "Students"}
                                        </div>
                                      </span>
                                      {/* <span key={uuidv4()}>
                                  {c.students_count}
                                </span> */}

                                      <span key={uuidv4()} className="truncate">
                                        {currentUser?.email ===
                                        Constants.dummyStudentEmail ? null : (
                                          <UserLabel
                                            email={c.primary_teacher}
                                            label={
                                              <UserAvatar email="Hello@test.com" />
                                            }
                                          />
                                        )}
                                      </span>
                                      <span
                                        key={uuidv4()}
                                        className="actionButtons"
                                      >
                                        <Composition templateCols="1fr 1fr">
                                          {canJoinSession &&
                                          !c.canStartSession ? (
                                            // STUDENT
                                            <Button
                                              type="primary"
                                              size="middle"
                                              className="mr-4 float-right"
                                            >
                                              <Link
                                                to={"/session/" + c.sessionId}
                                              >
                                                Join
                                              </Link>
                                            </Button>
                                          ) : null}

                                          {c.canStartSession ? (
                                            // TEACHER
                                            !canJoinSession ? (
                                              <DropdownButton
                                                className="float-right"
                                                type={
                                                  c.hasPreparedSession
                                                    ? "primary"
                                                    : "ghost"
                                                }
                                                overlay={teacherMenu(
                                                  c.canStartSession,
                                                  canJoinSession,
                                                  c.hasPreparedSession
                                                )}
                                              >
                                                {c.hasPreparedSession &&
                                                getPrepareSessionForClass(
                                                  store.getState()
                                                )(c.id) ? (
                                                  <Button
                                                    onClick={() =>
                                                      onStart(
                                                        c,
                                                        getPrepareSessionForClass(
                                                          store.getState()
                                                        )(c.id)
                                                      )
                                                    }
                                                  >
                                                    Start
                                                  </Button>
                                                ) : (
                                                  <span
                                                    onClick={() =>
                                                      prepareClassClicked(c)
                                                    }
                                                  >
                                                    Prepare
                                                  </span>
                                                )}
                                              </DropdownButton>
                                            ) : (
                                              <DropdownButton
                                                className="float-right"
                                                type="primary"
                                                overlay={teacherMenu(
                                                  c.canStartSession,
                                                  canJoinSession,
                                                  false
                                                )}
                                              >
                                                <Link
                                                  style={{ padding: "0 13px" }}
                                                  to={"/session/" + c.sessionId}
                                                >
                                                  {"Join"}
                                                </Link>
                                              </DropdownButton>
                                            )
                                          ) : null}
                                        </Composition>
                                      </span>
                                    </>
                                  );
                                })}
                            </Composition>
                          ) : null}
                        </>
                      ) : currentUser?.role && currentUser.role == "TEACHER" ? (
                        <>
                          <span>
                            {" "}
                            Create your first interactive vidya session{" "}
                          </span>
                          <div className="inset-y-0 right-0">
                            <Link
                              to="/add-class"
                              className="ant-btn ant-btn-primary mt-6"
                              type="default"
                              // icon={<PlusOutlined />}
                            >
                              {<PlusCircleFilled className="pr-2" />} Create
                              Class
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <span>
                            You are not part of any class yet. Ask the teacher
                            to share the invite link
                          </span>
                          <div className="inset-y-0 right-0">
                            <Link
                              to="/join-class"
                              className="ant-btn ant-btn-default mt-6"
                              type="default"
                              // icon={<PlusOutlined />}
                            >
                              Join Class
                            </Link>
                          </div>
                        </>
                      )}
                    </AllClasses>
                    <AddNewClass>
                      {currentUser &&
                      currentUser.role &&
                      currentUser.role == "STUDENT" &&
                      allClasses.length > 0 ? (
                        <div className="inset-y-0 right-0">
                          <Divider />
                          To join a new batch,
                          <Link
                            to="/join-class"
                            className="ant-btn ant-btn-default mt-6 ml-2"
                            type="default"
                            // icon={<PlusOutlined />}
                          >
                            Enter Class Code
                          </Link>
                        </div>
                      ) : null}
                    </AddNewClass>
                  </>
                ) : (
                  <Loader />
                )}
              </>
            )}
          </>
        )}
      </Composition>

      <Modal
        visible={showInviteModal}
        onOk={onInviteModalClose}
        onCancel={onInviteModalClose}
        width={"550px"}
        closable={true}
        footer={null}
        style={{
          padding: "10px",
        }}
        title={`Invite Students - ${inviteModalClass?.name}`}
      >
        {showInviteModal && inviteModalClass ? (
          <InviteModal _class={inviteModalClass} />
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToProps = function (state: applicationState) {
  return {
    currentSession: currentSession(state),
    currentUser: userSelector(state),
    allClasses: allActiveClassesArray(state),
    allUsers: allUsersSelector(state),
    currentRoute: state.appRouter.currentRoute,
  };
};

export default connect(mapStateToProps)(Dashboard);
