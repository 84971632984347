import mixpanel from "mixpanel-browser";
import { isRunningAsZoomApp } from "./Constants";


const Analytics = {

    track: (eventName: string, body?: object) => {
        if (body && Object.keys(body).length > 0) {
            mixpanel.track(eventName, body);
        } else {
            mixpanel.track(eventName);
        }
    },

    setPeople: (body: any) => {
        let finalBody = {
            ...body,
            $email: body.email,
            $first_name: body.first_name,
            $last_name: body.last_name,
            $phone: body.phone,
            $avatar: body.img_url,
            $name: body.first_name + " " + body.last_name,
            $distinct_id: body.email,
            $region: body.phone // because hubspot only syncs region and not phone in free tier from mixpanel.
        };
        mixpanel.people.set(finalBody);

        // add Microsoft Clarity params
        //@ts-expect-error
        clarity("set", "email", body.email);
        //@ts-expect-error
        clarity("set", "first_name", body.first_name);
        //@ts-expect-error
        clarity("set", "last_name", body.last_name);
        //@ts-expect-error
        clarity("set", "role", body.role);
        //@ts-expect-error
        clarity("set", "isZoomApp", isRunningAsZoomApp ? 'true' : 'false');
        try {
            //@ts-expect-error
            clarity("set", "country", body.country);
            //@ts-expect-error
            clarity("set", "userAgent", body.userAgent);
        } catch (e) {
            console.log(e);
        }




    },

    identify: (id: string) => {
        mixpanel.identify(id);
    },
    initializePendo(body: any) {
        if (body && body.first_name && body.last_name && body.email && body.role) {
            // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
            // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
            // Call this function in your authentication promise handler or callback when your visitor and account id values are available
            // Please use Strings, Numbers, or Bools for value types.

            // @ts-expect-error
            pendo.initialize({
                visitor: {
                    id: body.email,   // Required if user is logged in, default creates anonymous ID
                    email: body.email, // Recommended if using Pendo Feedback, or NPS Email
                    full_name: body.first_name + " " + body.last_name,// Recommended if using Pendo Feedback
                    role: body.role.toUpperCase()// Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: body.email, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                    name: body.first_name + " " + body.last_name, // Optional
                    is_paying: body.settings && body.settings.cost ? body.settings.cost > 0 : false, // Recommended if using Pendo Feedback
                    monthly_value: body.settings && body.settings.cost ? body.settings.cost : "",// Recommended if using Pendo Feedback
                    planLevel: body.settings && body.settings.type ? body.settings.type : "",// Optional
                    planPrice: body.settings && body.settings.cost ? body.settings.cost : "",// Optional
                    creationDate: body.settings && body.settings.trial_begin ? body.settings.trial_begin : "" // Optional
                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                }
            });

        }

    }

}

export default Analytics;






