import React, { useEffect, useState } from "react";
import { Image as AntImage } from "antd";
import { getDownloadLink } from "@/Services/Images";
import Loader from "@/Components/Loader";
import ReactAudioPlayer from "react-audio-player";

type Props = {
  imageId: string;
  disablePreview?: boolean;
};

const Image = ({ imageId, disablePreview }: Props) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [attachmentType, setAttachmentType] = useState<"image" | "audio">(
    "image"
  );
  useEffect(() => {
    if (!imageId) {
      setImageUrl("");
      return;
    }
    /* getDownloadLink(imageId).then((url) => {
      setImageUrl(url);
      fetch(url, {
        method: "GET",
        headers: {}
      }).then(response => {
        response.arrayBuffer().then((buffer) => {
          if (buffer) {
            var blob = new Blob([buffer])
            // blob = blob.slice(0, blob.size, objType)
            isImgUrl(url).then(() => {
              console.log("File is an image")
            }).catch(() => {
              console.log("File is an audio")
            })
            // setImageUrl(url);
            // uploadImage({ file: blob });
            // setQuestionImageUploading(true);
            // setUploadProgress(5);
          }
        })
      }).catch((e) => {
        // onUploadError('RETRY_IMAGE');
      })
    }); */
  }, [imageId]);

  function isImgUrl(url: string) {
    return new Promise((resolve) => {
      const img = React.createElement(
        "img",
        {
          src: url,
          onLoad: () => resolve(true),
          onerror: () => resolve(false),
          // any other image attributes you need go here
        },
        null
      );
    });
  }

  if (!imageUrl) {
    return (
      <div
        style={{
          width: "300px",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <>
      {attachmentType == "image" ? (
        <AntImage
          preview={!disablePreview}
          onError={() => setAttachmentType("audio")}
          placeholder={<Loader />}
          src={imageUrl}
          height={300}
          style={{ maxWidth: 300 }}
          className="object-scale-down"
        />
      ) : (
        <span
        // className="ml-10"
        >
          <ReactAudioPlayer src={imageUrl} controls />
        </span>
      )}
    </>
  );
};

export default Image;
