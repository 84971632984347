import moment from "moment";

export const isValidEmail = (email: string) => {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
}

export const queryParamToObject = (search: string): { [key: string]: string } => {
    let pairs = search.substring(1).split("&"),
        obj: { [key: string]: string } = {},
        pair,
        i;

    for (i in pairs) {
        if (pairs[i] === "") continue;

        pair = pairs[i].split("=");
        obj[pair[0]] = pair[1];
    }

    return obj;
}

export const toTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
}

export const timeAgoDate = (date: Date) => {
    // const date = new Date(d);
    const now = moment();
    const momentDate = moment(date);

    if (momentDate.isSame(now, 'day')) {
        return momentDate.fromNow();
    } else if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
        return 'Yesterday';
    } else {
        return momentDate.format('DD MMM');
    }
};

export function convertUndefinedToEmptyString(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
        const result: any = Array.isArray(obj) ? [] : {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (typeof value === 'undefined') {
                    result[key] = '';
                } else {
                    result[key] = convertUndefinedToEmptyString(value);
                }
            }
        }

        return result;
    }

    return obj;
}
