import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  ConfigProvider,
  Modal,
  Progress,
  Space,
  Statistic,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { Composition } from "atomic-layout";
import "./sessionStatsModal.css";
import Engagement from "@/Icons/engagement.svg";
import {
  InfoCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  StarOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import UserLabel from "@/Components/Common/Image/UserLabel";
import {
  currentUser,
  getUserById,
  liveStatsForSession,
  summaryStatsForSession,
} from "@/Models/Selectors";
import store from "@/Models/store";
import { stat } from "fs";
import { SorterResult } from "antd/lib/table/interface";
import { fetchLatestSessionStats } from "@/Services/Session";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { useSelector } from "react-redux";
import Analytics from "@/Services/Analytics";

type Props = {
  open: boolean;
  onClose: () => void;
  session: SessionState;
};

type sortedInfo = {
  columnKey: string;
  order: string;
};

const tabs = {
  attendance: {
    title: "Attendance",
  },
  dontKnow: {
    title: `Not Sure`,
    strokeColor: "#F2C94C",
  },
  noResponse: {
    title: "No Response",
    strokeColor: "#BDBDBD",
  },
  correct: {
    title: "Correct",
    strokeColor: "#219653",
  },
  incorrect: {
    title: "Incorrect",
    strokeColor: "#F17979",
  },
};

interface HeaderType {
  title: any;
  dataIndex: string;
  key: string;
  defaultSortOrder: any;
  sorter: any;
  width: any;
  sortOrder: any;
  showSorterTooltip: any;
}

const selectedTabClassName = "border-bottom-blue text-blue";

export const SessionStats = ({ open, onClose, session }: Props) => {
  const { questions, stats, joinees } = session;
  const summary_stats = useSelector(summaryStatsForSession)(session.sessionId);
  const live_stats: studentStats[] = useSelector(liveStatsForSession)(
    session.sessionId
  );

  const [sortedInfo, setSortedInfo] =
    useState<{ columnKey: string; order: string }>();
  const [selectedTab, setSelectedTab] = useState<
    "attendance" | "dontKnow" | "noResponse" | "correct" | "incorrect"
  >("attendance");
  const [fetchingUpdates, setFetchingUpdates] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<Date>();

  const [dataType, setDataType] = useState<"numbers" | "percentage">(
    "percentage"
  );

  const teacher = currentUser(store.getState());

  // to live fetch the stats on any changes/responses from students
  useEffect(() => {
    if (!fetchingUpdates) {
      setFetchingUpdates(true);
      setUpdatedAt(new Date());
      fetchLatestSessionStats(session);
    }
  }, [session]);

  const headers: Array<any> = [
    {
      title: <UserOutlined />,
      dataIndex: "name",
      key: "name",
      // defaultSortOrder: 'descend',
      sorter: (a: studentStats, b: studentStats) => {
        if (sortedInfo?.columnKey == "attendance") {
          if (a.user && b.user) return Number(b.absent) - Number(a.absent);
        }
        if (a.user && b.user)
          return b.user?.full_name.localeCompare(a.user?.full_name);
      },
      width: 100,
      sortOrder: sortedInfo
        ? (sortedInfo.columnKey === "name" ||
            sortedInfo.columnKey === "attendance") &&
          sortedInfo.order
        : null,
      showSorterTooltip: { title: "Name" },
    },
    {
      title: <StarOutlined />,
      dataIndex: "responses",
      key: "engagement",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => parseInt(a.responses) - parseInt(b.responses),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "engagement" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Engagement" },
    },
    {
      title: <CheckCircleOutlined />,
      dataIndex: "correct",
      key: "correct",
      // defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => parseInt(a.correct) - parseInt(b.correct),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "correct" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Correct Answers" },
    },
    {
      title: <CloseCircleOutlined />,
      dataIndex: "incorrect",
      key: "incorrect",
      responsive: ["md"] as Breakpoint[],
      // defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => parseInt(a.incorrect) - parseInt(b.incorrect),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "incorrect" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Incorrect Answers" },
    },
    {
      title: <QuestionCircleOutlined />,
      dataIndex: "dontKnow",
      key: "dontKnow",
      responsive: ["md"] as Breakpoint[],
      // defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => parseInt(a.dontKnow) - parseInt(b.dontKnow),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "dontKnow" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Don't Knows" },
    },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  let total_responses: number = 0;
  let total_correct: number = 0;

  live_stats.forEach((stat) => {
    total_responses = total_responses + stat.responses;
    total_correct = total_correct + stat.correct;
  });
  const percentageStats = live_stats.map((stat: studentStats) => ({
    ...stat,
    correct: (Math.floor((stat.correct / questions.length) * 100) || 0) + "%",
    incorrect:
      (Math.floor((stat.incorrect / questions.length) * 100) || 0) + "%",
    dontKnow: (Math.floor((stat.dontKnow / questions.length) * 100) || 0) + "%",
    responses:
      (Math.floor((stat.responses / questions.length) * 100) || 0) + "%",
  }));

  const dataSourceNumbers: {}[] = live_stats.map((stat: studentStats) => ({
    ...stat,
    key: stat.email,
    name: (
      <>
        <span className={stat.absent ? "text-gray-500" : ""}>
          <UserLabel email={stat.email} />{" "}
        </span>
      </>
    ),
    user: getUserById(store.getState())(stat.email),
  }));

  const dataSourcePercentage: {}[] = percentageStats.map((stat) => ({
    ...stat,
    key: stat.email,
    name: (
      <>
        <UserLabel email={stat.email} />
      </>
    ),
    user: getUserById(store.getState())(stat.email),
  }));
  const column = headers;

  const sortData = (sortVal: "engagement" | "correct" | "attendance") => {
    setSortedInfo({
      columnKey: sortVal,
      order: "ascend",
    });
  };

  const selectTab = useCallback((value:any) => {
    setSelectedTab(value);
  }, []);

  // const totalAttendanceText = useMemo(() => {
  //   return `${Math.round(attendance.length * 100 / joinees.length)}% (${attendance.length
  //     }/${joinees.length})`;
  // }, [joinees, attendance]);

  const sortProperty = useMemo(() => {
    switch (selectedTab) {
      case "attendance":
        return "isPresent";
      case "correct":
      case "dontKnow":
      case "incorrect":
      case "noResponse":
      default:
        return "count";
    }
  }, [selectedTab]);

  const dataForSelectedTab = useMemo(() => {
    if (selectedTab === "attendance") {
      const attendanceObj: {
        [key: string]: { type: "attendance"; name: string; isPresent: boolean };
      } = {};
      joinees.forEach((n) => {
        const stat = {
          name: n,
          type: "attendance" as "attendance",
          isPresent: false,
        };
        attendanceObj[n] = stat;
      });
      stats.attendance.forEach((n) => {
        const stat = {
          name: n,
          type: "attendance" as "attendance",
          isPresent: true,
        };
        attendanceObj[n] = stat;
      });
      return Object.values(attendanceObj);
    } else {
      return stats[selectedTab].map((n) => {
        return {
          name: n.name,
          type: "progress" as "progress",
          count: n.count,
          strokeColor: tabs[selectedTab].strokeColor,
        };
      });
    }
  }, [selectedTab, stats, joinees]);

  const engagementDefinition = "Average engagement of present students";
  const correcttDefinition = "Average correct answers of present students";
  const attendenceDefinition =
    "Attendance is calculated by responses. Students with 0 answers are marked absent.";

  const changeDataType = (value: any) => {
    setDataType(value ? "percentage" : "numbers");
  };

  const refreshPressed = () => {
    setFetchingUpdates(true);
    fetchLatestSessionStats(session);
    setUpdatedAt(new Date());
  };

  const totalQuestions = questions.length;
  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <TeamOutlined style={{ fontSize: 20 }} />
      <p>No Students Yet</p>
    </div>
  );

  return (
    <Composition templateRows="auto auto 1fr" gapRow="20px">
      <div>
        <Space direction="horizontal" wrap size="small">
          <Card
            size="small"
            title="Engagement"
            onClick={() => sortData("engagement")}
            hoverable
            extra={
              <Tooltip placement="right" title={engagementDefinition}>
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: 150 }}
          >
            <div>
              <Progress
                type="circle"
                strokeColor="#3498db"
                percent={summary_stats?.engagement_percent}
                width={80}
              />
            </div>
          </Card>

          <Card
            size="small"
            title="Correctness"
            onClick={() => sortData("correct")}
            hoverable
            extra={
              <Tooltip placement="right" title={correcttDefinition}>
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: 150 }}
          >
            <p>
              {" "}
              <Progress
                strokeColor="#27ae60"
                type="circle"
                percent={summary_stats?.correct_percent}
                width={80}
              />
            </p>
          </Card>
          <Card
            size="small"
            title="Attendance"
            onClick={() => sortData("attendance")}
            hoverable
            extra={
              <Tooltip placement="right" title={attendenceDefinition}>
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: 150 }}
          >
            <span className="text-2xl text-gray-700">
              {summary_stats
                ? summary_stats?.total_students - summary_stats?.absent_students
                : 0}
              /
            </span>
            <span className="text-gray-500 text-xl">
              {summary_stats
                ? summary_stats?.total_students
                : session.joinees.length}
            </span>
            <p className="mt-4 pt-1">
              <Progress
                strokeColor="#7f8c8d"
                percent={summary_stats?.attendance_percent}
                size="small"
              />
              <p></p>
            </p>
          </Card>
        </Space>
      </div>
      <div>
        <span>
          {" "}
          Total Questions :{" "}
          <span className="font-bold"> {questions.length} </span>{" "}
        </span>
        <Switch
          className="float-right"
          checked={dataType === "percentage"}
          onChange={changeDataType}
          checkedChildren=" % "
          unCheckedChildren="123"
        />
      </div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          className="virtual-table"
          scroll={{ y: 300, x: 100 }}
          style={{
            tableLayout: "fixed",
            width: "100%",
            display: "table",
          }}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          dataSource={
            dataType === "percentage" ? dataSourcePercentage : dataSourceNumbers
          }
          columns={column}
          onChange={handleChange}
          rowClassName={(record, index) => {
            const className = "text-gray-500";
            // @ts-ignore
            if (record.absent) return className;
            return "";
          }}
        />
      </ConfigProvider>
    </Composition>
    // </Modal>
  );
};

export const SessionStatsModal = ({ open, onClose, session }: Props) => {
  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={"Session Stats"}
      closable
      footer={null}
      className={"z10"}
      style={{ zIndex: 100000000 }}
    >
      <SessionStats open={open} onClose={onClose} session={session} />
    </Modal>
  );
};
