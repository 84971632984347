import axios from "axios";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import { batch } from "react-redux";
import {
  currentUser as currentUserSelector,
  getSessionById,
  getUserById,
} from "@/Models/Selectors";
import { subscribe } from "@/Models/Subscriber";
import { authStatus } from "@/Models/Selectors";
import { goHome } from "./Router";
import { getQuestionForClient, getQuestionForServer } from "./Question";
import {
  getClassSessions,
  processSessionStats,
  setCurrentSession,
  setCurrentSessionObject,
} from "./Session";
import { fetchAllUsers } from "@/Services/User";
import _, { forEach } from "lodash";
import Question from "@/Components/Question";
import Analytics from "@/Services/Analytics";
import { defaultLanguage, DetectLanguageURL } from "./Constants";
import {
  Operator,
  addDocument,
  createOrUpdateData,
  createOrUpdateDataMultiple,
  getCollectionRows,
  getDocumentMultipleCondition,
  getDocuments,
  getFirebaseAuth,
  getSingleDocument,
  updateDocument,
  whereCondition,
} from "./Firebase";
import { randomUUID } from "crypto";
import { getSignupEmail } from "./Auth";

subscribe(authStatus, () => {
  const currentStatus = authStatus(store.getState());
  if (currentStatus === "active") {
    fetchAllClasses();
  }
});

const numberOfHoursToExpire = process.env.NODE_ENV === "development" ? 12 : 12;

export const getSessionObj = (_class: any, _session: any): SessionState => {
  const { description, is_admin } = _class;
  const session = _session;
  const currentUser = currentUserSelector(store.getState());

  let type: "STUDENT" | "ADMIN" =
    _class.primary_teacher === currentUser.email ? "ADMIN" : "STUDENT";

  const quests =
    session.questions?.map((q: any, i: number) => getQuestionForClient(q, i)) ||
    [];
  const draft_quests =
    session.draft_questions?.map((q: any, i: number) => {
      try {
        return getQuestionForClient(q, i);
      } catch (e) {
        return [];
      }
    }) || [];

  let currentQuestion;
  if (type === "STUDENT" && quests.length) {
    currentQuestion = quests[quests.length - 1];
  }
  const sessionStartTimeobj = session.start_time
    ? session.start_time
    : new Date();
  const sessionCreatedTimeobj = session.created_at
    ? session.created_at
    : new Date();
  const sessionUpdatedTimeobj = session.updated_at
    ? session.updated_at
    : new Date();
  const startTimeRaw =
    sessionStartTimeobj.length > 30
      ? sessionStartTimeobj.substring(0, 10)
      : sessionStartTimeobj;

  return {
    sessionId: session.id || session.sessionId,
    classId: _class.id,
    type,
    state: session.status,
    name: session.session_name || _class.name,
    description,
    joinees: _class.students_email || [],
    currentQuestion,
    questions: quests,
    draft_questions: draft_quests,
    start_time: new Date(startTimeRaw),
    created_at: new Date(sessionCreatedTimeobj),
    updated_at: new Date(sessionUpdatedTimeobj),
    stats: {
      attendance: [],
      noResponse: [],
      correct: [],
      incorrect: [],
      dontKnow: [],
    },
    live_stats: [],
  };
};

export const fetchAllClasses = async () => {
  store.dispatch({
    type: Actions.CLASSES.SET_FETCHING,
    payload: true,
  });

  //const { data } = await axios.get("list_classes");
  //const data = { Code: 0, Data: { classes: [] } };
  const response = await getCollectionRows("classes");

  //return [];
  const Code = "";
  const Data = { classes: response };

  //const { Code, Data } = data;

  /* if (Code !== 0) {
    console.error("list_classes failed", data);
    return;
  } */

  const { classes } = Data;
  const sessionsToAdd: SessionState[] = [];
  const sessionsToEnd: SessionState[] = [];

  const temp_classes = classes!.map((temp_class) => {
    return getClassObj(temp_class);
  });

  await fetchAllUsers(temp_classes);
  const sessionStates = await getClassSessions(temp_classes);

  // Add users
  const allClasses = classes!.map((obj: any) => {
    const classSessions: SessionState[] = [];

    let ongoingSessionId;
    if (obj.session) {
      const sessionObj = getSessionObj(obj, obj.session);
      if (sessionObj.state !== "DRAFT" || sessionObj.type === "ADMIN") {
        classSessions.push(sessionObj);
        ongoingSessionId = sessionObj.sessionId;
      }
    }

    sessionsToAdd.push(...classSessions);

    if (sessionStates && sessionStates[obj.id]) {
      obj.session = sessionStates[obj.id];
    }

    return getClassObj(obj);
  });

  // sessionToEnd compilation
  sessionsToAdd.forEach((obj: SessionState) => {
    if (obj.sessionId && obj.start_time && obj.type === "ADMIN") {
      const timeDiff =
        new Date().getTime() - new Date(obj.start_time).getTime();
      const timeDiffInMins = timeDiff / (1000 * 60 * 60);
      const expiryHours = numberOfHoursToExpire; // any session older than 12 hours would get ended automatically upon signin
      console.log("Session expiry time set as hours", expiryHours);
      // @ts-ignore
      if (timeDiffInMins > expiryHours) {
        sessionsToEnd.push(obj);
      }
    }
  });

  sessionsToEnd.forEach((session: SessionState) => {
    // endSession(session)
  });
  console.log("Sessions to end - ", sessionsToEnd);
  // store.dispatch({
  //   type: Actions.CLASSES.SET_FETCHING,
  //   payload: false,
  // });

  // return [];

  batch(() => {
    store.dispatch({
      type: Actions.CLASSES.SET_FETCHING,
      payload: false,
    });
    sessionsToAdd.forEach((obj: SessionState) =>
      store.dispatch({
        type: Actions.SESSIONS.ADD,
        payload: obj,
      })
    );

    /* store.dispatch({
      type: Actions.CLASSES.ADD_BULK,
      payload: {
        Classes: allClasses,
      },
    }); */

    allClasses.forEach((obj: Class) =>
      store.dispatch({
        type: Actions.CLASSES.ADD,
        payload: {
          Class: obj,
        },
      })
    );
  });

  /* fetchAllUsers(allClasses).then(() => {
    // allClasses.forEach((obj: Class) => {
    //   fetchClassSessions(obj);
    // });
  }); */

  return allClasses;
};

const getClassObj = (obj: any): Class => {
  const currentUser = currentUserSelector(store.getState());
  // Add users
  const classSessions: string[] = [];

  let ongoingSessionId;
  if (obj.session) {
    const classSession = getSessionObj(
      obj,
      obj.session
      // obj.session,
      // obj.name,
      // obj.description,
      // obj.id,
      // obj.students || []
    );

    classSessions.push(classSession.sessionId);
    ongoingSessionId = obj.session.sessionId;
  }

  const allUsers = obj?.students?.map((email: string) =>
    getUserById(store.getState())(email)
  );

  const allUserEmails = obj?.students?.map((email: string) => {
    const user = getUserById(store.getState())(email);
    return user.email;
  });

  const _class: Class = {
    id: obj?.id || "no id",
    name: obj.name,
    description: obj.description,
    primary_teacher: obj.primary_teacher ?? obj.primaryteacher,
    students_count: obj?.students?.length || 0,
    students_email: allUserEmails || [],
    state:
      obj?.session?.id && obj?.session?.status !== "DRAFT"
        ? "IN_SESSION"
        : "NO_SESSION",
    //last_session: new Date(),
    sessionId: ongoingSessionId,
    canStartSession: currentUser?.email === obj.primary_teacher,
    sessions: classSessions.length > 0 ? classSessions : [],
    hasPreparedSession:
      classSessions.length > 0 && obj.session.status === "DRAFT",
    students_users: allUsers,
    institute_id: obj.institute_id || undefined,
    class_invite_code: obj.class_invite_code,
    is_archived:
      obj.settings &&
      obj.settings.is_archived &&
      obj.settings.is_archived === "true"
        ? true
        : false,
    settings: obj.settings || { language: defaultLanguage },
    students: obj.students || [],
  };
  return _class;
};

export const getClassJsonObj = (_class: Class) => {
  const obj = {
    primary_teacher: _class.primary_teacher,
    name: _class.name,
    description: _class.description,
    admins: [_class.primary_teacher],
    students: _class.students ?? _class.students_email,
    id: _class.id,
    is_admin: true,
    institute_id: _class.institute_id || "",
    class_invite_code: _class.class_invite_code,
    settings: {
      is_archived: _class.is_archived ? "true" : "false",
      language: _class.settings.language,
    },
  };

  return obj;
};

export const createNewClass = (
  name: string,
  description: string,
  cb: any,
  language: string,
  institute_id?: string
) => {
  const body = {
    name,
    description,
    institute_id: institute_id ? institute_id : "",
    settings: { question_notification: "true", language },
    admins: [getFirebaseAuth().currentUser?.email],
    primaryteacher: getFirebaseAuth().currentUser?.email,
    isadmin: true,
    students: [],
  };
  // return console.log(body);
  return createOrUpdateData("classes", "name", name, body).then((response) => {
    store.dispatch({
      type: Actions.CLASSES.ADD,
      payload: {
        Class: response,
      },
    });
    Analytics.track("Create Class", response);

    fetchAllClasses();
    goHome();
    cb(response);
    return;
  });
  return axios.post("create_class", body).then((response) => {
    const { data } = response;
    const { Code, Data } = data;

    if (Code !== 0) {
      console.error("Error while creating class");
      return;
    }

    store.dispatch({
      type: Actions.CLASSES.ADD,
      payload: {
        Class: Data,
      },
    });
    Analytics.track("Create Class", Data);

    fetchAllClasses();
    goHome();
    cb(data);
  });
};

export const detectLanguage = (text: string) => {
  const headers = { Authorization: "Bearer 05ba6e08bd777bc436bc0716c614ab4a" };
  return fetch(DetectLanguageURL + "?q=" + text, { headers })
    .then((res) => res.json())
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.detections &&
        response.data.detections.length > 0
      )
        return response.data.detections[0].language;
    });
};

export const fetchClassSessions = (_class: Class) => {
  const { id } = _class;

  return getSingleDocument("sessions", "class_id", id).then((response) => {
    if (response) {
      const classSessions: SessionState[] = [];
      const { sessions } = response;

      sessions &&
        sessions.forEach((sessionJson: any) => {
          const sessionObj: SessionState = getSessionObj(_class, sessionJson);
          classSessions.push(sessionObj);
        });

      // batch(() => {
      classSessions.forEach((obj: SessionState) =>
        store.dispatch({
          type: Actions.SESSIONS.ADD,
          payload: obj,
        })
      );

      const updatedClass: Class = {
        ..._class,
        sessions: classSessions.map((session) => session.sessionId),
      };

      store.dispatch({
        type: Actions.CLASSES.UPDATE,
        payload: {
          Class: updatedClass,
        },
      });
      console.log("fetch class sessions complete", updatedClass.id);
      console.log("initiating stats fetch", updatedClass.id);
      fetchClassStats(updatedClass);
      return classSessions;
    } else {
      console.log("session fetch failed");

      store.dispatch({
        type: Actions.CLASSES.UPDATE,
        payload: {
          Class: {
            ..._class,
            sessions: [],
          },
        },
      });

      return [];
    }
  });
};

export const fetchClassStats = (_class: Class) => {
  const { id } = _class;
  return getDocuments("class_stats", {
    field: "classid",
    operator: Operator.Equal,
    value: id,
  }).then((Data) => {
    if (Data === false) {
      return;
    }

    let sessionStats: { [sessionId: string]: {} } = {};
    let sessionData = {};

    Data.forEach((classStat: any) => {
      if (!classStat.user_id) return;
      const userId = classStat.user_id;
      const sessionId = Object.keys(classStat.sessions)[0];
      const stat = classStat.sessions[sessionId].stats;
      sessionData = { ...sessionData, [userId]: stat };
      const thisSession = getSessionById(store.getState())(sessionId);
      if (sessionStats[sessionId])
        sessionStats[sessionId] = {
          ...sessionStats[sessionId],
          [userId]: stat,
        };
      else
        sessionStats = {
          ...sessionStats,
          [sessionId]: { [userId]: stat },
        };
    });
    Object.keys(sessionStats).map((sessionId: string) => {
      const stat = sessionStats[sessionId];
      const thisSession = getSessionById(store.getState())(sessionId);
      if (thisSession) {
        const updatedSessionWithStats = processSessionStats(
          _class,
          { ...thisSession, joinees: _class.students_email },
          { stats: stat }
        );
        batch(() => {
          updatedSessionWithStats.live_stats.forEach((live_stat) => {
            store.dispatch({
              type: Actions.STATS.ADD,
              payload: {
                stats: live_stat,
              },
            });
          });
          store.dispatch({
            type: Actions.STATS.SUMMARY.ADD,
            payload: updatedSessionWithStats.summary_stats,
          });
        });
      }
    });
    console.log("session stats processed for class", _class.id);

    // fill in empty stats for missing sessions
    processEmptyStats(_class, sessionStats);

    return;
  });
};

const processEmptyStats = (
  _class: Class,
  sessionStats: { [sessionId: string]: {} }
) => {
  _class.sessions.forEach((sessionId: string) => {
    const isPresent = sessionStats[sessionId];
    const thisSession = getSessionById(store.getState())(sessionId);
    if (!isPresent) {
      const statsSummary: sessionStats = {
        engagement_percent: 0,
        absent_students: 0,
        present_students: 0,
        correct_percent: 0,
        total_students: 0,
        sessionId: sessionId,
        startDate: thisSession.start_time,
        teacher_email: _class.primary_teacher,
        attendance_percent: 0,
        total_questions: 0,
        classId: _class.id,
      };
      store.dispatch({
        type: Actions.STATS.SUMMARY.ADD,
        payload: statsSummary,
      });
    }
  });
  console.log("empty session stats processed for class", _class.id);
};

export const getInviteCode = (_class: Class) => {
  const { id } = _class;
  return axios.get(`class_invite?class_id=${id}`).then((response) => {
    const { data } = response;
    const { Code, Data } = data;

    if (Code !== 0) {
      throw Error("failed");
    }

    const { invite_code } = Data;

    return invite_code;
  });
};

export const generateRandomInviteCode = () => {
  return Math.floor(1000 + Math.random() * 9000).toString();
};

export const getInviteShortCode = (_class: Class) => {
  const { id } = _class;
  const invite_code = generateRandomInviteCode();

  return updateDocument("classes", id, {
    class_invite_code: invite_code,
  }).then((Data) => {
    const updatedClass: Class = {
      ..._class,
      class_invite_code: invite_code,
    };

    store.dispatch({
      type: Actions.CLASSES.UPDATE,
      payload: {
        Class: updatedClass,
      },
    });

    return invite_code;
  });
};

export const joinClass = (invite_code: string) => {
  return getSingleDocument("classes", "class_invite_code", invite_code).then(
    (Data) => {
      if (Data) {
        let students = Data.students ?? [];
        const email = getSignupEmail();
        students.push(email);

        updateDocument("classes", Data.id, { students }).then(() => {});
        store.dispatch({
          type: Actions.CLASSES.ADD,
          payload: {
            Class: Data,
          },
        });
        return Data;
      } else {
        throw Error("Please check the code and try again.");
      }
    }
  );
  return axios
    .post(`join_class`, {
      invite_code: invite_code.trim(),
    })
    .then((response) => {
      const { data } = response;
      const { Code, Data, Title } = data;

      if (Code !== 0) {
        throw Error("Failed to join class");
      }
      if (Title === "Failure") {
        throw Error("Please check the code and try again.");
      }
      if (!Data) return Promise.resolve();

      store.dispatch({
        type: Actions.CLASSES.ADD,
        payload: {
          Class: Data,
        },
      });
      return Data;

      // fetchAllClasses();
      goHome();
    });
};

export const startSessionForClass = (
  _class: Class,
  sessionName: string,
  sessionId: string
) => {
  const { id } = _class;
  let postBody: any = {
    class_id: id,
    session_name: sessionName || _class.name,
    status: "STARTED",
  };
  if (sessionId) postBody.session_id = sessionId;
  return axios.post("start_session", postBody).then(async (response) => {
    const { data } = response;
    const { Code, Data } = data;

    if (Code !== 0) {
      throw Error("Failed to join class");
    }

    const allClasses = await fetchAllClasses();

    const startedClass = allClasses.find((c: Class) => c.id === id);
    //setCurrentSession(startedClass?.sessionId);
    return Promise.resolve(Data);
  });
};

export const startPreparedSessionForClass = (
  _class: Class,
  sessionName: string,
  session: SessionState
) => {
  const { id } = _class;
  let postBody: any = {
    class_id: id,
    session_name: sessionName || _class.name,
    status: "STARTED",
    state: "STARTED",
  };
  if (session) postBody.id = session.sessionId;
  if (session.draft_questions) {
    postBody.draft_questions = [];
    session.draft_questions.forEach((q: question) => {
      const serverQuestion = getQuestionForServer(q);
      postBody.draft_questions.push(serverQuestion);
    });
  }

  return createOrUpdateData("sessions", "id", session.sessionId, postBody).then(
    (response) => {
      store.dispatch({
        type: Actions.SESSIONS.UPDATE,
        payload: {
          sessionId: session.sessionId,
          updatedSession: {
            ...session,
            sessionId: session.sessionId,
            //start_time: new Date(Data.start_time),
            state: "STARTED",
            draft_questions: session.draft_questions,
          },
        },
      });

      setCurrentSessionObject(session);
      return session;
    }
  );
  return axios.post("start_session", postBody).then(async (response) => {
    const { data } = response;
    const { Code, Data } = data;

    if (Code !== 0) {
      throw Error("Failed to join class");
    }

    const allClasses = await fetchAllClasses();

    // const allClasses = await fetchAllClasses();

    // const startedClass = allClasses.find((c: Class) => c.id === id);

    setCurrentSession(Data.id);
    return Promise.resolve(Data);
  });
};

export const createEmptyPrepareSessionForClass = async (
  _class: Class,
  sessionName: string
) => {
  const { id } = _class;
  const classStorageKey = `add_class_id_${_class.id}`;

  if (localStorage.getItem(classStorageKey)) {
    return;
  } else {
    localStorage.setItem(classStorageKey, _class.id);
  }

  const conditions: whereCondition[] = [
    {
      field: "class_id",
      operator: "==",
      value: id,
    },
    {
      field: "status",
      operator: "!=",
      value: "ENDED",
    },
  ];

  let sessionData: any;

  await getDocumentMultipleCondition("sessions", conditions).then(
    async (data: any) => {
      if (!data.length) {
        /** @todo : check :: CHECK THE SESSION CREATION */
        await createOrUpdateDataMultiple("sessions", conditions, {
          class_id: id,
          session_name: sessionName || _class.name,
          status: "DRAFT",
          draft_questions: [],
        })
          .then((Data) => {
            sessionData = Data;
          })
          .catch((e) => {
            localStorage.removeItem(classStorageKey);
            throw Error("Failed to join class");
          });
      } else {
        sessionData = data[0];
      }
    }
  );

  localStorage.removeItem(classStorageKey);

  if (sessionData) {
    const session = getSessionObj(_class, sessionData);
    _class.sessionId = session.sessionId;

    store.dispatch({
      type: Actions.SESSIONS.ADD,
      payload: session,
    });
    store.dispatch({
      type: Actions.CLASSES.UPDATE,
      payload: {
        Class: { ..._class, hasPreparedSession: true },
      },
    });
    Promise.resolve(session);
  }
};

export const createPrepareSessionForClass = (
  session: SessionState,
  _class: Class
) => {
  const { id } = _class;
  const serverQuestions: any[] = [];
  session.draft_questions.forEach((question: question) => {
    const serverQuestion = getQuestionForServer(question);
    serverQuestions.push(serverQuestion);
  });

  return axios
    .post("start_session", {
      class_id: id,
      session_name: session.name || _class.name,
      status: "DRAFT",
      draft_questions: serverQuestions,
    })
    .then(async (response) => {
      const { data } = response;
      const { Code, Data } = data;

      if (Code !== 0) {
        throw Error("Failed to join class");
      }

      const session = getSessionObj(_class, Data);
      store.dispatch({
        type: Actions.SESSIONS.ADD,
        payload: session,
      });
      store.dispatch({
        type: Actions.CLASSES.UPDATE,
        payload: {
          Class: { ..._class, hasPreparedSession: true },
        },
      });

      // const allClasses = await fetchAllClasses();

      // const startedClass = allClasses.find((c: Class) => c.id === id);
      // setCurrentSession(startedClass?.sessionId);
    });
};

export const updatePrepareSession = (_session: SessionState, _class: Class) => {
  const serverQuestions: any[] = [];
  _session.draft_questions.forEach((question: question) => {
    const serverQuestion = getQuestionForServer(question);
    serverQuestions.push(serverQuestion);
  });

  return updateDocument("sessions", _session.sessionId, {
    class_id: _class.id,
    id: _session.sessionId,
    session_name: _session.name,
    status: "DRAFT",
    draft_questions: serverQuestions,
  }).then((Data) => {
    const session = getSessionObj(_class, Data);
    store.dispatch({
      type: Actions.SESSIONS.UPDATE,
      payload: {
        sessionId: session.sessionId,
        updatedSession: session,
        createOrUpdate: true,
      },
    });
    store.dispatch({
      type: Actions.CLASSES.UPDATE,
      payload: {
        Class: {
          ..._class,
          sessions: [session.sessionId],
          hasPreparedSession: true,
        },
      },
    });
  });
  return axios
    .post("start_session", {
      class_id: _class.id,
      id: _session.sessionId,
      session_name: _session.name,
      status: "DRAFT",
      draft_questions: serverQuestions,
    })
    .then(async (response) => {
      const { data } = response;
      const { Code, Data } = data;

      if (Code !== 0) {
        throw Error("Failed to join class");
      }

      // const allClasses = await fetchAllClasses();

      // const startedClass = allClasses.find((c: Class) => c.id === id);
      // setCurrentSession(startedClass?.sessionId);
    });
};

export const onStartSessionSocketEvent = (message: any) => {
  const { kind } = message;

  if (kind !== "START_SESSION") {
    return;
  }

  // receiveQuestion(payload.data, session_id);
  fetchAllClasses();
};

export const updateClass = (_class: Class) => {
  let obj = getClassJsonObj(_class);

  for (var m in obj) {
    const key = m as keyof typeof obj;

    if (obj[key] == undefined) {
      delete obj[key];
    }
  }
  return updateDocument("classes", obj.id, obj).then((_class) => {
    if (_class) {
      fetchAllClasses();
      // const updatedClass = getClassObj(Data)

      batch(() => {
        store.dispatch({
          type: Actions.CLASSES.UPDATE,
          payload: {
            Class: {
              ..._class,
            },
          },
        });
      });
    }
  });
};

export const addStudentsToClass = async (
  _class: Class,
  students: { first_name: string; last_name: string; email: string }[]
) => {
  const obj = getClassJsonObj(_class);
  const class_id = _class.id;
  const student_emails: any = [];

  students.forEach((student) => {
    const studentObj = {
      ...student,
      auth_type: "custom",
      img_url: "",
      username: student.email,
      role: "STUNDENT",
    };

    student_emails.push(student.email);
    createOrUpdateData("user", "email", student.email, studentObj).then(
      async (createdUser) => {
        await updateDocument("classes", obj.id, {
          students: [...obj.students, ...student_emails],
        });

        fetchAllClasses();

        batch(() => {
          store.dispatch({
            type: Actions.CLASSES.UPDATE,
            payload: {
              Class: {
                ..._class,
              },
            },
          });
        });
      }
    );
  });

  return;

  return axios
    .post("add_students_to_class", {
      class_id,
      students,
    })
    .then((response) => {
      const { data } = response;
      const { Code, Data, success } = data;

      if (Code !== 0) {
        if (success) {
          // process class stats empty
          return;
        } else {
          // console.log("session fetch failed");
        }
      }
      fetchAllClasses();
      // const updatedClass = getClassObj(Data)

      batch(() => {
        store.dispatch({
          type: Actions.CLASSES.UPDATE,
          payload: {
            Class: {
              ..._class,
            },
          },
        });
      });
    });
};
