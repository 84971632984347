import { allClasses, allClassesArray, cumilativeStatsForClass, pastSessionsForClass, pastSessionsForClassFromDate } from "@/Models/Selectors";
import store from "@/Models/store";
import { StarOutlined, UserOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { Composition } from "atomic-layout";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAverageMetric } from "../Stats/ClassSessionStats";
import StatsTiles from "../Stats/StatsTiles";
import { Redirect } from "react-router-dom";


const TeacherDashboard = () => {
    const allClasses = useSelector(allClassesArray);
    const [selectedClass, setSelectedClass] = useState(allClasses[0]);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const _classId = selectedClass && selectedClass.id ? selectedClass.id : "";
    const sessions: SessionState[] = useSelector(pastSessionsForClass)(_classId)
    // .sort((a, b) => a.start_time.getTime() - b.start_time.getTime());

    const [startDate, setStartDate] = useState<Date>(sessions[0]?.start_time || moment(new Date()))
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateDiff, setDateDiff] = useState(moment(endDate).diff(moment(startDate), 'days'))
    const previousStartDate = moment(startDate).subtract(dateDiff, 'days').toDate()
    // const sessions: SessionState[] = pastSessionsForClassFromDate(store.getState())(selectedClass.id, startDate, endDate);

    const desktopAreas = `
    mainArea    stats
    `;
    const mobileAreas = `
    stats
    mainArea
    `

    const desktopMain = `
  classSelector
  classSettings
  gettingStarted
  prepare
`

    const mobileMain = `
  classSelector
  gettingStarted
  classSettings
  prepare
`

    const desktopMainArea = `
    classSelector   
    classSettings
    gettingStarted
    prepare
    `

    const classStatsCurrent = useSelector(cumilativeStatsForClass)(selectedClass?.id, startDate, endDate);
    const classStatsPast = useSelector(cumilativeStatsForClass)(selectedClass?.id, previousStartDate, startDate);


    const pastAverageEngagement = getAverageMetric(classStatsPast, "engagement");
    const currentAverageEngagement = getAverageMetric(classStatsCurrent, "engagement");


    const pastAverageCorrectness = getAverageMetric(classStatsPast, "correctness");
    const currentAverageCorrectness = getAverageMetric(classStatsCurrent, "correctness");


    const engagement_data = classStatsCurrent
        .filter((stat: sessionStats) => stat.engagement_percent > 0)
        .map((stat: sessionStats) => stat.engagement_percent);

    const correctness_data = classStatsCurrent
        .filter((stat: sessionStats) => stat.correct_percent > 0)
        .map((stat: sessionStats) => stat.correct_percent);


    const currentSessionCount = classStatsCurrent.length;
    const pastSessionCount = classStatsPast.length;

    const showLoader = isFetchingData || (sessions.length > 0 && classStatsCurrent.length == 0 && selectedClass.students_email.length != 0)


    return <>
        <Redirect to="/class" />
        <Composition
            templateCols="1fr"
            templateRows="1fr"
            templateColsMd="2fr 1fr"
            areas={mobileAreas}
            areasMd={desktopAreas}
        >
            {({ MainArea, Stats }) => (
                <>
                    <MainArea>
                        <Composition
                            templateCols="1fr"
                            templateRows="auto"
                            areas={mobileMain}
                            areasMd={desktopMain}
                        >
                            {({ ClassSelector, ClassSettings, GettingStarted, Prepare }) => (
                                <>
                                    <ClassSelector>
                                        This is a Class Selector
                                    </ClassSelector>
                                    <GettingStarted>
                                        These are getting started
                                    </GettingStarted>
                                    <ClassSettings>
                                        These are class settings
                                    </ClassSettings>
                                    <Prepare>This is Prepare Section</Prepare>
                                </>
                            )}
                        </Composition>
                        This is Main Area
                    </MainArea>
                    <Stats>
                        <div className="mt-32 text-left">
                            <Card
                                title="Stats"
                                bordered={false}
                                style={{ width: 500 }}
                            >
                                <StatsTiles
                                    value={currentAverageEngagement}
                                    icon={<StarOutlined />}
                                    title="Avg. Engagement"
                                    compareNumber={pastAverageEngagement}
                                    type="percent"
                                    graphData={engagement_data}
                                    loading={showLoader}
                                    tooltip="This is calculated by comparing Total Questions with the Total Responses throughout the selected period"
                                />
                                <StatsTiles
                                    value={currentAverageCorrectness}
                                    icon={<UserOutlined />}
                                    title="Avg. Correctness"
                                    compareNumber={pastAverageCorrectness}
                                    type="percent"
                                    graphData={correctness_data}
                                    loading={showLoader}
                                    tooltip="This is calculated by averaging the correct responses of each session throughout the selected period"
                                />
                                <p>Card content</p>
                                <p>Card content</p>
                                <p>Card content</p>
                            </Card>
                        </div>
                    </Stats>
                </>
            )}
        </Composition>
    </>
}

export default TeacherDashboard;