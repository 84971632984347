
import AppLogo from "@/Assets/Vidya-baloon.png"
import { isRunningAsZoomApp } from "./Constants";
import { showZoomNotification } from "./ZoomServices";



export const requestPermission = () => {
    if (!('Notification' in window)) {
        console.warn('Notification API not supported!');
        return;
    }
    Notification.requestPermission(function (result) {
        if (result) console.log(result);
    });
}

export const showNotification = (title: string, body: string) => {
    if (!('Notification' in window)) {
        console.warn('Notification API not supported!');
        return;
    }
    console.log(AppLogo)
    console.log("document hidden = ", document.hidden)
    if (document.hidden) {
        try {
            const notification = new Notification(title, {
                icon: AppLogo,
                body: body,
            });
            notification.addEventListener('click', (ev: Event) => {
                console.log("notification clicked")
                window.focus();
                notification.close();
            })
        } catch (err) {
            console.warn('Notification API error: ' + err);
        }
    }

    if (isRunningAsZoomApp) {
        showZoomNotification(title, body);
    }
}


