// questionLibraryService.ts

import store from '@/Models/store';
import { addDocument, deleteDocument, updateDocument, getDocument, getRef, queryDocuments, Operator, subscribeUpdatesForCollection } from './Firebase';
import { doc, arrayUnion } from "firebase/firestore";
import Actions from '@/Models/Actions';
import { getQuestionForClient, getQuestionForServer } from './Question';
import { getFirebaseUser } from './User';
import { currentUser } from '@/Models/Selectors';
// import { QuestionLibrary } from './models';

const QUESTION_LIBRARY_COLLECTION = 'question_bank';
const QUESTION_COLLECTION = 'questions';


export const addQuestionLibrary = async (questionLibrary: QuestionLibrary): Promise<any> => {
    try {

        const library = await addDocument(QUESTION_LIBRARY_COLLECTION, questionLibrary);
        const processedLibrary = processQuestionLibraryObject([library])[0];
        store.dispatch({
            type: Actions.QuestionLibrary.ADD,
            payload: library
        })
        return library;
    } catch (error) {
        console.error('Error adding question library:', error);
        throw error;
    }
};

export const createQuestionAndAddToLibrary = async (questionData: question, libraryId: string): Promise<any> => {
    try {
        const serverQuestion = questionData;
        // Add the question to the question collection
        const question = await addDocument(QUESTION_COLLECTION, serverQuestion);
        console.log("Question added with ID:", question.id);

        // Add the question reference to the questionLibrary collection
        const library = await updateDocument(QUESTION_LIBRARY_COLLECTION, libraryId, {
            questions: arrayUnion(question.id)
        });
        store.dispatch({
            type: Actions.QuestionLibrary.UPDATE,
            payload: library
        })
        return Promise.resolve(question);
        console.log("Question created and added to the question bank successfully");
    } catch (error) {
        console.error("Error adding question:", error);
        throw error;
    }
};


export const deleteQuestionLibrary = async (questionLibraryId: string): Promise<void> => {
    try {
        await deleteDocument(QUESTION_LIBRARY_COLLECTION, questionLibraryId);
        store.dispatch({
            type: Actions.QuestionLibrary.DELETE,
            payload: questionLibraryId
        })


    } catch (error) {
        console.error('Error deleting question library:', error);
        throw error;
    }
};

export const updateQuestionLibrary = async (questionLibrary: QuestionLibrary): Promise<void> => {
    try {
        const _user = currentUser(store.getState());
        const library = await updateDocument(QUESTION_LIBRARY_COLLECTION, questionLibrary.id, { ...questionLibrary, updated_by: _user.email, updated_on: new Date() });
        const processedLibrary = processQuestionLibraryObject([library])[0];
        store.dispatch({
            type: Actions.QuestionLibrary.UPDATE,
            payload: processedLibrary
        })
        return Promise.resolve(processedLibrary);
    } catch (error) {
        console.error('Error updating question library:', error);
        throw error;
    }
};

export const updateQuestion = async (questionData: question): Promise<any> => {
    try {
        const question = await updateDocument(QUESTION_COLLECTION, questionData.id, questionData);
        Promise.resolve(questionData);
    } catch (error) {
        console.error('Error updating question library:', error);
        throw error;
    }
};

const processQuestionLibraryObject = (questionLibraries: any) => {
    try {
        const libraries = questionLibraries.map((lib: any) => {
            return {
                ...lib,
                created_on: new Date(lib.created_on.seconds * 1000),
                updated_on: new Date(lib.updated_on.seconds * 1000)
            }
        })
        return libraries;
    } catch (e) {
        console.error("Unable to process question libaries object")
        console.error(e);
        return questionLibraries;
    }
}


export const fetchQuestionLibrary = async (id: string): Promise<QuestionLibrary> => {
    try {
        const questionLibraries = await getDocument(QUESTION_LIBRARY_COLLECTION, id);
        const libraries = processQuestionLibraryObject(questionLibraries);
        store.dispatch({
            type: Actions.QuestionLibrary.ADD,
            payload: libraries
        })

        return libraries as QuestionLibrary;
    } catch (error) {
        console.error('Error getting question libraries:', error);
        throw error;
    }
};

export const fetchLibraryQuestions = async (library: QuestionLibrary, questionRefs: string[]): Promise<question[]> => {
    try {
        // incase questions is empty or undefined
        if (!library.questions) return Promise.resolve([]);

        const questions: question[] = [];
        for (const questionId of questionRefs) {
            const questionSnapshot = await getDocument(QUESTION_COLLECTION, questionId);
            if (questionSnapshot) {
                questions.push(questionSnapshot);
                // Set other question properties accordingly
            };
        }
        // Update the question bank with the fetched questions
        // const updatedQuestionBank: QuestionLibrary = { ...library, questions: questions };
        // store.dispatch({
        //     type: Actions.QuestionLibrary.UPDATE,
        //     payload: updatedQuestionBank
        // })
        return Promise.resolve(questions);
    } catch (error) {
        console.error('Error getting question libraries:', error);
        throw error;
    }
};

export const fetchAllLibraries = async (userEmail: string, instituteId?: string): Promise<QuestionLibrary[]> => {
    try {
        const conditions: { field: QuestionLibraryKeys; operator: Operator; value: any }[] = [
            { field: "created_by", operator: Operator.Equal, value: userEmail },
            { field: "is_public", operator: Operator.Equal, value: true },
            { field: "shared_with_user", operator: Operator.ArrayContainsAny, value: [userEmail] }
        ];
        if (instituteId) {
            conditions.push({ field: "shared_with_institute", operator: Operator.ArrayContainsAny, value: [instituteId] })
        }
        const questionLibraries = await queryDocuments(QUESTION_LIBRARY_COLLECTION, conditions);
        const libraries = processQuestionLibraryObject(questionLibraries);
        store.dispatch({
            type: Actions.QuestionLibrary.BULK_ADD,
            payload: libraries
        })
        questionLibraries.forEach(library => {
            library.shared_with_user.forEach((userEmail: string) => getFirebaseUser(userEmail));
        })
        // only updates QuestionLibrary and not questionBanks. 
        // subscribeUpdatesForCollection(QUESTION_LIBRARY_COLLECTION, conditions);

        return libraries as QuestionLibrary[];
    } catch (error) {
        console.error('Error getting question libraries:', error);
        throw error;
    }
};

