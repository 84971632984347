import { DocumentData } from "firebase/firestore";
import { Operator, subscribeUpdatesForCollectionNew } from "../Firebase";
import { getClassById, getSessionById } from "@/Models/Selectors";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import { v4 as uuid } from "uuid";
import { getSession } from "../Session";

export const start = () => {};

export const handleAskQuestionEvent = async (
  session_id: string,
  callback?: Function
) => {
  return await subscribeUpdatesForCollectionNew(
    "session_questions",
    [
      {
        field: "current_question",
        operator: Operator.Equal,
        value: 1,
      },
      {
        field: "session_id",
        operator: Operator.Equal,
        value: session_id,
      },
    ],
    (data: DocumentData) => {
      console.log("question callback ", data);
      if (callback) {
        callback(data);
      }
    }
  );
};
export const handleEndSessionEvent = async (
  class_id: string,
  callback?: Function
) => {
  return await subscribeUpdatesForCollectionNew(
    "classes",
    [
      {
        field: "id",
        operator: Operator.Equal,
        value: class_id,
      },
      {
        field: "status",
        operator: Operator.Equal,
        value: "ENDED",
      },
    ],
    (data: DocumentData) => {
      if (callback) {
        callback(data);
      }
    }
  );
};

export const handleUpdateQuestionEvent = async (
  class_id: string,
  callback?: Function
) => {
  return await subscribeUpdatesForCollectionNew(
    "session_questions",
    [
      {
        field: "id",
        operator: Operator.Equal,
        value: class_id,
      },
      {
        field: "current_question",
        operator: Operator.Equal,
        value: 1,
      },
    ],
    (data: DocumentData) => {
      if (callback) {
        callback(data);
      }
    }
  );
};

export const handlePostAnswerEvent = async (
  class_id: string,
  callback?: Function
) => {
  return await subscribeUpdatesForCollectionNew(
    "session_questions",
    [
      {
        field: "id",
        operator: Operator.Equal,
        value: class_id,
      },
      {
        field: "current_question",
        operator: Operator.Equal,
        value: 1,
      },
    ],
    (data: DocumentData) => {
      if (callback) {
        callback(data);
      }
    }
  );
};

export const onStartSessionEvent = async (
  class_id: string,
  callback?: Function
) => {
  return await subscribeUpdatesForCollectionNew(
    "sessions",
    [
      {
        field: "classId",
        operator: Operator.Equal,
        value: class_id,
      },
      {
        field: "status",
        operator: Operator.Equal,
        value: "STARTED",
      },
    ],
    (data: DocumentData) => {
      if (callback) {
        callback(data);
      }
    }
  );
};

export const handleAskQuestionResponseEvent = (message: any) => {
  const {
    kind,
    payload: {
      data: { question },
    },
    session_id,
  } = message;

  store.dispatch({
    type: Actions.SESSIONS.ADD_QUESTION,
    payload: {
      session_id,
      question,
    },
  });
};

export const startEndSessionEventHandler = (
  class_id: string,
  status: "STARTED" | "ENDED"
) => {
  return onStartSessionEvent(class_id, (changed_data: DocumentData) => {
    if (changed_data["state"] && changed_data["state"] == status) {
      const sessionObj = getSession(changed_data.sessionId);

      if (sessionObj && sessionObj.state !== changed_data["state"]) {
        console.log("CALLED", changed_data, sessionObj);

        store.dispatch({
          type: Actions.SESSIONS.UPDATE,
          payload: {
            sessionId: changed_data.sessionId,
            changes: {},
            updatedSession: {
              ...changed_data,
              updated_at: new Date(),
              state: status,
              status: status,
              type: "STUDENT",
            },
            createOrUpdate: true,
          },
        });

        //setCurrentClassObject(current_class, currentSession);
      }
    }
  }).then((sub) => {
    console.log(`Subscribed for class_id ${status}`, class_id);
    return sub;
  });
};

export const askQuestionEventHandler = (
  session_id: string,
  class_id: string
) => {
  return handleAskQuestionEvent(session_id, (doc_data: DocumentData) => {
    console.log("question changed", doc_data);

    const question = {
      ...doc_data,
      options: doc_data.question.values,
      correct_answer: doc_data.question.correctValueIndex,
    };
    handleAskQuestionResponseEvent({
      kind: "ASK_QUESTION",
      session_id: doc_data.session_id,
      payload: {
        data: question,
      },
    });
  }).then((sub) => {
    console.log("Subscribed for class_id", class_id);
    return sub;
  });
};

export const unsubscribeHandler = (subscribes: any[]) => {
  subscribes.forEach((subs) => {
    subs.forEach((s: any) => {
      if (typeof s === "function") {
        s();
        console.log("unsubs");
      }
    });
  });
};
