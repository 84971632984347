import { combineReducers } from 'redux';
import authReducer from './auth';
import sessionReducer from './session';
import usersReducer from './user';
import classesReducer from './class';
import appRouterReducer from './route';
import statsReducer from './stats';
import statsSummaryReducer from './statsSummary';
import questionLibraryReducer from './questionLibrary';

export default combineReducers({
    auth: authReducer,
    sessions: sessionReducer,
    users: usersReducer,
    classes: classesReducer,
    appRouter: appRouterReducer,
    stats: statsReducer,
    statsSummary: statsSummaryReducer,
    questionLibraries: questionLibraryReducer
})