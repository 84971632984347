import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

type Props = {
  questions: question[];
  currentIndex: number;
  addNewQuestionStep: boolean;
  scrollToQuestion: (x: number) => void;
};

const getDescriptionForQuestion = (q: question) => {
  const { state, liveStats } = q;
  const { correct = 0, totalCount = 0 } = liveStats || {};

  const correctPercent = Math.round(correct * 100 / totalCount) || 0;

  if (state === "RECEIVING_RESPONSE") return "Awaiting response.";
  if (state === "RECEIVED_RESPONSE") return `${correctPercent}% correct responses.`;

  return "";
};

const QuestionsProgress = ({
  questions,
  currentIndex,
  addNewQuestionStep,
  scrollToQuestion,
}: Props) => {
  return (
    <Steps direction="vertical" current={currentIndex}>
      {questions.map((q, i) => (
        <Step
          className="cursor-pointer"
          title={`Q.${i + 1}`}
          description={<span className={q.state === "RECEIVING_RESPONSE" ? `blinkingText` : ''}>{getDescriptionForQuestion(q)}</span>}
          style={{ color: "#fff" }}
          wrapperStyle={{ color: "#e53fg3" }}
          onClick={() => scrollToQuestion(i)}
        />
      ))}
      {addNewQuestionStep ? (
        <Step
          className="cursor-pointer"
          title={`Ask new question`}
          onClick={() => scrollToQuestion(questions.length)}
        />
      ) : null}
    </Steps>
  );
};

export default QuestionsProgress;
