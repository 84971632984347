import Actions from "@/Models/Actions"
import store from "@/Models/store"

export const goToNewClass = function () {
    store.dispatch({
        type: Actions.ROUTE.UPDATE,
        payload: 'new-class'
    });
}

export const goToJoinClass = function () {
    store.dispatch({
        type: Actions.ROUTE.UPDATE,
        payload: 'join-class'
    })
}

export const goHome = function () {
    store.dispatch({
        type: Actions.ROUTE.UPDATE,
        payload: 'home'
    })
}

export const goToNewSession = function () {
    store.dispatch({
        type: Actions.ROUTE.UPDATE,
        payload: 'new-session'
    })
}

export const setCurrentPath = function (path: any) {
    store.dispatch({
        type: Actions.ROUTE.UPDATE_PATH,
        payload: { currentPath: path }
    })
}