import React from 'react';
import ResponseForm from './ResponseForm';
import ReportView from './ReportView';
import StudentResponseForm from './StudentResponseForm';

/**
 * 
 * @param param0 
 * 
 * Render either report view for teachers,
 * or responseForm for students
 */
const Question = ({
    session,
    question,
    role,
    onAskNextQuestion = () => { },
    showNewQuestionButton
}:
    {
        session: SessionState,
        question: question,
        role: 'TEACHER' | 'STUDENT',
        onAskNextQuestion?: () => void,
        showNewQuestionButton?: boolean
    }) => {

    if (role === 'TEACHER') {
        return <ReportView session={session} question={question} onAskNextQuestion={onAskNextQuestion} showNewQuestionButton={!!showNewQuestionButton} />
    }

    if (role === 'STUDENT') {
        // return <ResponseForm session={session} Question={question} />
        return <StudentResponseForm session={session} Question={question} />
    }
    return null;
}

export default Question;