import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Composition } from "atomic-layout";
import { Input, Checkbox, Tooltip } from "antd";
import Image from "@/Components/Common/Image";
import { getResultFromQuestion, submitAnswer } from "@/Services/Question";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import QuestionTitlePreview from "../Common/QuestionTitlePreview";
import { showNotification } from "@/Services/Notifications";
import "./Question.css";
import StudentOptions, { OptionState } from "./StudentOptions";
import ReactCanvasConfetti from "react-canvas-confetti";
import {
  askQuestionEventHandler,
  unsubscribeHandler,
} from "@/Services/Firebase/Subscriber";
import axios from "axios";
const formArea = `
    labelArea
    descriptionArea
    inputForm
    submitButtons
`;

const textForIndex = ["A", "B", "C", "D"];
export const optionColorIndex = [
  "#FBBC05",
  "#2D9CDB",
  "#6FCF97",
  "#9B51E0",
  "#56CCF2",
];

const StudentResponseForm = ({
  Question,
  session,
}: {
  Question: question;
  session: SessionState;
}) => {
  const {
    label,
    description,
    optionsType,
    values,
    id,
    evaluation,
    correctValueIndex,
    state,
    images,
  } = Question;
  const [response, setResponse] = useState("");
  const [reward, setReward] = useState<boolean>(true);
  const [userid, setuserid] = useState("");
  const [isDontKnow, setIsDontKnow] = useState<boolean>(
    evaluation?.answered_dont_know || false
  );
  const currentId = useRef(id);
  const imageId = images && images.length > 0 ? images[0].url : "";

  const awaitingResult = evaluation && state === "RECEIVING_RESPONSE";
  const showResult = state === "RECEIVED_RESPONSE";
  const answerIndex: any = evaluation?.user_answer.text
    ? values?.indexOf(evaluation?.user_answer.text)
    : undefined;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    answerIndex
  );
  // useEffect(() => {
  //   if (!awaitingResult && !showResult) {
  //     setResponse("");
  //     setIsDontKnow(false);
  //   }
  // }, [id]);
  const getRewardInstance = (instance: any) => {
    // saving the instance to an internal property
    setReward(instance);
  };

  const containsOnlyNumbers = (str: string) => {
    const onlynums = /^[0-9]+(\.[0-9]+)?$/.test(str);
    console.log("contains only numbers : ", onlynums);
    return onlynums;
  };

  function formatNumericAnswer(optionAns: string, studentRes: string): string {
    const sourceNum = parseFloat(optionAns);
    const destNum = parseFloat(studentRes);

    if (!containsOnlyNumbers(optionAns) || !containsOnlyNumbers(studentRes)) {
      return studentRes; // Return the original destination string if either source or dest is not a number
    }
    const decimalPlaces = optionAns.includes(".")
      ? optionAns.split(".")[1].length
      : 0; // Determine the number of decimal places in the source string

    const destStr =
      decimalPlaces > 0
        ? destNum.toFixed(decimalPlaces).replace(/\.?0+$/, "")
        : destNum.toString(); // Format destination string based on source string's decimal places and remove trailing zeros

    const finalStr = optionAns.includes(".")
      ? destStr
      : destStr.replace(/\.0+$/, ""); // If source string does not have decimal places, remove trailing ".0"

    return finalStr;
  }

  const handleSubscribe = async (sessionId:string,classId:string,className:string) => {


    if (!sessionId || !classId || !className) {
      console.log('All fields are required.');
      return;
    }

    try {
      const response = await axios.post('https://us-central1-vidya-prod-f2207.cloudfunctions.net/createResources/api/subscribe_student', {
        sessionId,
        classId,
        className,
        studentId:userid,
      });

      if (response.data.success) {
        console.log(`Successfully subscribed to topic `);
      } else {
        console.log('Failed to subscribe.');
      }
    } catch (error) {
      console.error('Error subscribing to topic:', error);
      console.log('Error subscribing. Please try again.');
    }
  };

  useEffect(() => {

    handleSubscribe(session.sessionId,session.classId,session.name);
    


    const jsonString: string | null = localStorage.getItem("bf_device_data");

    // Check if jsonString is not null before proceeding
    if (jsonString !== null) {
      const bfDeviceData = JSON.parse(jsonString);

      // Now you can safely access properties
      const browserType = bfDeviceData.$browser;
      const userEmail = bfDeviceData.user_email;

      // Example: Log the values to the console
      console.log("Browser Type:", browserType);
      console.log("User Email:", userEmail);

      setuserid(userEmail);
    } else {
      console.log('No data found in localStorage for key "bf_device_data"');
    }
  }, []);


  useEffect(() => {
    if (awaitingResult || showResult) {
      if (evaluation) {
        const {
          user_answer: { text },
          answered_dont_know,
        } = evaluation;
        if (answered_dont_know) {
          setResponse("");
          setIsDontKnow(true);
        } else {
          setResponse(text);
        }
      }
    }
    if (!awaitingResult && !showResult) {
      setResponse("");
      setIsDontKnow(false);
    }

    if (showResult) {
      if (optionsType == "multiple" && selectedIndex === correctValueIndex) {
        setReward(true);
      }

      if (
        optionsType == "text" &&
        !isDontKnow &&
        values &&
        evaluation?.user_answer.text === values[correctValueIndex]
      ) {
        setReward(true);
      }
    }
  }, [awaitingResult, showResult, evaluation, id]);

  useEffect(() => {
    if (currentId.current != id) {
      setSelectedIndex(undefined);
      currentId.current = id;
      window.scrollTo(0, 0);
    }
    setReward(false);
  }, [id]);

  useEffect(() => {
    console.log("FORM");
    let subscribers: any[] = [];
    askQuestionEventHandler(session.sessionId, session.classId).then((sub) => {
      subscribers.push(sub);
    });

    return () => {
      unsubscribeHandler(subscribers);
    };
  }, [session.sessionId]);

  useEffect(() => {
    const jsonString: string | null = localStorage.getItem("bf_device_data");

    // Check if jsonString is not null before proceeding
    if (jsonString !== null) {
      const bfDeviceData = JSON.parse(jsonString);

      // Now you can safely access properties
      const browserType = bfDeviceData.$browser;
      const userEmail = bfDeviceData.user_email;

      // Example: Log the values to the console
      console.log("Browser Type:", browserType);
      console.log("User Email:", userEmail);

      setuserid(userEmail);
    } else {
      console.log('No data found in localStorage for key "bf_device_data"');
    }
  }, []);

  const onResponseChange = function (value: string) {
    setResponse(value);
    setIsDontKnow(false);
  };

  const onResponseSelect = function (index: number) {
    if (awaitingResult || showResult) return;
    // setResponse(value);
    // setIsDontKnow(false);
    setSelectedIndex(index);
    if (values) {
      setResponse(values[index]);
      setIsDontKnow(false);
    }
    window.scrollTo(0, document.body.scrollHeight);
    // setReward(false);
  };

  const onDontKnowSelect = () => {
    if (awaitingResult || showResult) return;
    setIsDontKnow(true);
    setResponse("");
    setSelectedIndex(undefined);
    window.scrollTo(0, document.body.scrollHeight);

    // setReward(true);
  };
  const onDontKnowChange = useCallback(
    function (value:any) {
      setIsDontKnow(value === "dontKnow" && !isDontKnow ? true : false);
      setResponse("");
    },
    [isDontKnow]
  );

  const onsubmit = useCallback(() => {
    const correctAns = values ? values[correctValueIndex] : response;
    const finalResponse = formatNumericAnswer(correctAns, response);
    submitAnswer(finalResponse, id, isDontKnow);
  
    console.log("finalResponse: " + finalResponse);
    console.log("correctAns: " + correctAns);
    console.log("First character of response: " + response.charAt(0));
    console.log("userid: " + userid);
  
    let idx;
    switch (response.charAt(0)) {
      case 'A':
        idx = 0;
        break;
      case 'B':
        idx = 1;
        break;
      case 'C':
        idx = 2;
        break;
      case 'D':
        idx = 3;
        break;
      default:
        idx = '';
        break;
    }
  
    console.log("userIndex:", idx);
    handleSubmitAnswer(userid, correctAns, idx);
  }, [response, values, correctValueIndex, id, isDontKnow, userid]);
  
  console.log('session.name',session.name);
  console.log('session.sessionId',session.sessionId);
  console.log('session.classId',session.classId);

  const handleSubmitAnswer = async (userid:string, correctAns:string, correctValIndex:any) => {
    try {
      const response = await fetch('https://us-central1-vidya-prod-f2207.cloudfunctions.net/createResources/api/submit_answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          studentId: userid,
          answerIndex: correctValIndex,
          className:session.name,
          sessionId: session.sessionId,
          classId: session.classId,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        console.log('Answer submitted successfully:', data);
      } else {
        console.error('Failed to submit answer:', data.error);
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };
  

  let maxCharacterLength = 0;
  values?.map(
    (v) =>
      (maxCharacterLength =
        maxCharacterLength >= v.length - 2 ? maxCharacterLength : v.length - 2)
  );
  const is2x2 = maxCharacterLength == 0;

  const calculataeOptionState = (index: number): OptionState => {
    let state: OptionState = "NORMAL";
    // if (showResult) state = "DONTKNOW";
    // else state = selectedIndex && selectedIndex === index ? "SELECTED" : "NOT-SELECTED";
    if (showResult && isDontKnow) {
      state = correctValueIndex === index ? "CORRECT" : "NORMAL";
    }
    if (showResult && !isDontKnow) {
      state =
        correctValueIndex === index
          ? "CORRECT"
          : selectedIndex === index
          ? "INCORRECT"
          : "NORMAL";
    }

    if (!showResult) {
      state = selectedIndex === index ? "SELECTED" : "NORMAL";
    }

    if (awaitingResult) {
      state = selectedIndex === index ? "SELECTED" : "NOT-SELECTED";
    }
    return state;
  };

  return (
    <>
      <ReactCanvasConfetti
        useWorker={true}
        // set the styles as for a usual react component
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          // background: "#020202",
          zIndex: reward ? 100 : 0,
        }}
        // set the class name as for a usual react component
        className={"yourClassName"}
        onFire={() => console.log("confetti fired")}
        fire={reward}
        // set the callback for getting instance. The callback will be called after initialization ReactCanvasConfetti component
        // refConfetti={this.getInstance}
      />

      <Composition
        areas={formArea}
        className="mt-8"
        alignItems="center"
        justifyItems="center"
        gapRow="10px"
        style={{
          minHeight: "calc(100vh - 150px)",
          zIndex: 50,
        }}
      >
        {({
          LabelArea,
          DescriptionArea,
          InputForm,
          SubmitButtons,
          ProgressArea,
        }) => (
          <>
            <LabelArea>
              <span className={is2x2 ? "text-3xl" : "text-xl"}>{label}</span>
            </LabelArea>
            <DescriptionArea>
              <Composition templateRows={"auto auto"} justifyItems="center">
                <QuestionTitlePreview
                  body={description}
                  className="pb-5 text-2xl font-bold ml-2 mr-2"
                />

                {imageId ? <Image imageId={imageId} /> : null}
              </Composition>
            </DescriptionArea>
            <InputForm>
              <Composition
                justifyItems={is2x2 ? "center" : "self-start"}
                gapRow="5px"
                style={
                  {
                    // marginLeft: "-40px",
                  }
                }
              >
                <>
                  {optionsType === "text" ? (
                    <>
                      <Tooltip
                        visible={
                          response.length < 1 && !awaitingResult && !showResult
                        }
                        trigger={["focus"]}
                        title={"Numeric Response"}
                        placement="top"
                        overlayClassName="numeric-input"
                      >
                        <Input
                          className={"text-answer-input option-box ".concat(
                            showResult && !isDontKnow
                              ? values &&
                                evaluation?.user_answer.text.toLowerCase() ===
                                  values[correctValueIndex].toLowerCase()
                                ? " option-box-correct"
                                : " option-box-incorrect"
                              : ""
                          )}
                          style={
                            {
                              // marginLeft: "40px",
                            }
                          }
                          type={
                            values &&
                            containsOnlyNumbers(values[correctValueIndex])
                              ? "number"
                              : "text"
                          }
                          placeholder="YOUR ANSWER"
                          value={response}
                          onChange={(e) => onResponseChange(e.target.value)}
                          disabled={awaitingResult || showResult}
                        />{" "}
                      </Tooltip>
                      {showResult ? <ResultText ques={Question} /> : null}
                      {!showResult || isDontKnow ? (
                        <>
                          <span style={{ width: 350, marginTop: 35 }}>
                            {" "}
                            OR{" "}
                          </span>
                          <Composition
                            gapRow={"10px"}
                            width={"100%"}
                            marginVertical="80"
                            // templateCols="2fr 2fr"
                            alignItems="center"
                            justifyItems="self-start"
                          >
                            <StudentOptions
                              enabled={!awaitingResult && !showResult}
                              className={
                                awaitingResult && !isDontKnow
                                  ? "option-box-unselected mt-8 ml-auto mr-auto"
                                  : "mt-8 ml-auto mr-auto"
                              }
                              layout={is2x2 ? "extra" : "1x4"}
                              index={values?.length || 4}
                              labelColor={optionColorIndex[4]}
                              // optionLabel={null}
                              body={"I'm not sure"}
                              onSelect={() => onDontKnowSelect()}
                              state={
                                isDontKnow
                                  ? showResult
                                    ? "DONTKNOWRESULT"
                                    : "SELECTED"
                                  : "NOT-SELECTED"
                              }
                            />
                          </Composition>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {optionsType === "multiple" ? (
                    <>
                      <Checkbox.Group className="" value={[response]}>
                        <Composition
                          gapRow={20}
                          gapCol={is2x2 ? 30 : 0}
                          templateCols={is2x2 ? "2fr 2fr" : ""}
                          alignItems="center"
                          justifyItems={is2x2 ? "center" : "self-start"}
                        >
                          {values?.map((v, i) => (
                            <span>
                              <StudentOptions
                                enabled={!awaitingResult && !showResult}
                                className={
                                  awaitingResult && selectedIndex != i
                                    ? "option-box-unselected"
                                    : ""
                                }
                                index={i}
                                labelColor={optionColorIndex[i]}
                                optionLabel={
                                  awaitingResult && i == selectedIndex ? (
                                    <LoadingOutlined className="mr-2" />
                                  ) : showResult && correctValueIndex === i ? (
                                    <CheckOutlined />
                                  ) : (
                                    textForIndex[i]
                                  )
                                }
                                body={v.slice(2)}
                                onSelect={onResponseSelect}
                                layout={is2x2 ? "2x2" : "1x4"}
                                state={calculataeOptionState(i)}
                              />
                              {/* {selectedIndex == i && !awaitingResult && !showResult ?
                                <button
                                  disabled={!response && !isDontKnow}
                                  onClick={() => { onsubmit() }}
                                  className={!response && !isDontKnow ?
                                    "z-100 relative bg-gray-500 font-extrabold mt-2 pointer-events-none px-4 py-2 rounded-lg text-white w-48"
                                    :
                                    "z-100 relative bg-blue-500 font-extrabold hover:bg-blue-800 text-white mt-2 px-4 py-2 rounded-lg w-48"}>

                                  Submit
                                </button> : null} */}
                              {/* trying on-the-spot submit button for students  */}
                              {/* {!awaitingResult && !showResult && selectedIndex && selectedIndex == i ? <button
                                disabled={!response && !isDontKnow}
                                onClick={() => { onsubmit() }}
                                className={"z-100 relative bg-blue-500 font-extrabold hover:bg-blue-800 text-white mb-6 mt-6 px-4 py-2 rounded-lg w-48"}
                              >
                                Submit
                              </button> : null} */}
                            </span>
                            // <Composition
                            //   templateCols="40px 1fr"
                            //   alignItems="center"
                            //   paddingBottom="15px"
                            // >
                            //   {showResult && correctValueIndex === i ? (
                            //     <CheckCircleTwoTone twoToneColor="#52c41a" />
                            //   ) : (
                            //     <div />
                            //   )}

                            //   <Checkbox
                            //     value={v}
                            //     onChange={(e) => onResponseChange(e.target.value)}
                            //     disabled={awaitingResult || showResult}
                            //   >
                            //     <span className="inline-block mr-3">
                            //       ({textForIndex[i]})
                            //     </span>
                            //     {v.slice(2)}
                            //   </Checkbox>
                            // </Composition>
                          ))}
                        </Composition>
                      </Checkbox.Group>
                      <Composition
                        gapRow={30}
                        marginVertical="80"
                        // templateCols="2fr 2fr"
                        alignItems="center"
                        justifyItems="self-start"
                      >
                        <StudentOptions
                          enabled={!awaitingResult && !showResult}
                          className={
                            awaitingResult && !isDontKnow
                              ? "option-box-unselected mt-4"
                              : "mt-4"
                          }
                          layout={is2x2 ? "extra" : "1x4"}
                          index={values?.length || 4}
                          labelColor={optionColorIndex[4]}
                          optionLabel={"E"}
                          body={"I'm not sure"}
                          onSelect={() => onDontKnowSelect()}
                          state={
                            isDontKnow
                              ? showResult
                                ? "DONTKNOWRESULT"
                                : "SELECTED"
                              : "NOT-SELECTED"
                          }
                        />
                      </Composition>
                      {/* {showResult || awaitingResult ? */}
                      <div className="mt-8 w-full">
                        <ResultText ques={Question} />
                      </div>
                      {/* // : null} */}
                    </>
                  ) : null}
                </>

                {/* <Checkbox
                style={{ marginLeft: "40px" }}
                value="dontKnow"
                checked={isDontKnow}
                onChange={(e) => onDontKnowChange(e.target.value)}
                disabled={awaitingResult || showResult}
              >
                Don't Know
              </Checkbox> */}
              </Composition>
            </InputForm>
            <SubmitButtons>
              <div className="mt-4">
                {
                  !showResult ? (
                    !awaitingResult ? (
                      <button
                        disabled={!response && !isDontKnow}
                        onClick={() => {
                          onsubmit();
                        }}
                        className={
                          !response && !isDontKnow
                            ? "z-100 relative bg-gray-500 font-extrabold pointer-events-none px-4 py-2 rounded-lg text-white w-48"
                            : "z-100 relative bg-blue-500 font-extrabold hover:bg-blue-800 text-white px-4 py-2 rounded-lg w-48"
                        }
                      >
                        Submit
                      </button>
                    ) : (
                      // <Button
                      //   type="primary"
                      //   disabled={!response && !isDontKnow}
                      //   onClick={onsubmit}
                      // >
                      //   Submit
                      // </Button>
                      // <button
                      //   disabled={!response && !isDontKnow}
                      //   onClick={() => { onsubmit() }}
                      //   className={
                      //     "bg-gray-500 font-extrabold mb-6 mt-6 pointer-events-none px-4 py-2 rounded-lg text-white w-48"}>
                      //   <LoadingOutlined className="mr-2" />
                      //   Awaiting Result...
                      // </button>
                      <div className="">
                        <div className="text-xl text-blue">
                          Response Submitted!
                        </div>
                        <div className="text-xs blinkingText">
                          (host will soon reveal the answer)
                        </div>
                      </div>
                    )
                  ) : null
                  // <ResultText ques={Question} />
                }
              </div>
            </SubmitButtons>
            {/* <ProgressArea>
                <TimerProgress startTime={Date.now()} endTime={Date.now() + oneMinute}/>
            </ProgressArea> */}
          </>
        )}
      </Composition>
    </>
  );
};

const ResultText = ({ ques }: { ques: question }) => {
  const result = useMemo(() => getResultFromQuestion(ques), [ques]);

  if (!result) {
    return null;
  }

  const { type, questionType, correctAnswer } = result;
  const { correctValueIndex } = ques;
  const displayAnswer =
    questionType === "text" ? correctAnswer : textForIndex[correctValueIndex];

  const answers: {
    [responseType in typeof type]: { message: any; emoji: any; color: any };
  } = {
    CORRECT: {
      message: "You're right!",
      emoji: "👍",
      color: "#219653",
    },
    INCORRECT: {
      message: "Incorrect! Correct answer is " + displayAnswer,
      emoji: "😕",
      color: "#f17979",
    },
    DONT_KNOW: {
      message: "The answer is " + displayAnswer,
      emoji: "🙂",
      color: "#4299e1",
    },
    NOT_RESPONDED: {
      message: "You missed it! The answer is " + displayAnswer,
      emoji: "😶",
      color: "#b8860b",
    },
  };

  showNotification(answers[type].emoji, answers[type].message);

  return (
    <Composition
      templateRows="auto auto"
      style={{ color: answers[type].color, width: "100%" }}
    >
      <div className="text-xl">
        <span role="img" aria-label="thumbsup">
          {answers[type].emoji}
        </span>
      </div>
      <div className="text-xl">{answers[type].message}</div>
    </Composition>
  );
};

export default StudentResponseForm;
