import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { Composition } from "atomic-layout";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  Layout,
  notification,
  Select,
  Space,
  Tooltip,
} from "antd";
import { requestOTP, signUp } from "@/Services/User";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import {
  getGoogleToken,
  getSignupEmail,
  getUserGoogleEmail,
  isGoogleSignup,
  logout,
} from "@/Services/Auth";
import { useSelector } from "react-redux";
import { authStatus } from "@/Models/Selectors";
import store from "@/Models/store";
import { getCurrentPath as currentPathSelector } from "@/Models/Selectors";
import teacher from "@/Assets/teacher.png";
import student from "@/Assets/student.png";
import TeacherSignUpForm from "./TeacherSignUp";
import { Content } from "antd/lib/layout/layout";
import { CheckCircleTwoTone, SwapOutlined } from "@ant-design/icons";
import { createNewClass } from "@/Services/Class";
import { setTimeout } from "timers";

import "./Signup.css";
import _, { upperCase } from "lodash";
import OtpInput from "react-otp-input";
import { isValidEmail } from "./Common/Utils";
import {
  GUESTDOMAIN,
  GUESTOTP,
  isRunningAsZoomApp,
} from "@/Services/Constants";
import { setCurrentPath } from "@/Services/Router";

type singupType = "gauth" | "otp";

type role = "teacher" | "student";

const signUpArea = `
    title
    customForm
`;

export type SignupData = {
  firstName: string;
  lastName: string;
  email: string;
  role: "TEACHER" | "STUDENT" | "";
  img_url: string;
  signup_type: singupType;
  otp?: string;
};

const defaultState: SignupData = {
  firstName: "",
  lastName: "",
  email: "",
  role: "STUDENT",
  img_url: "",
  signup_type: "otp",
  otp: GUESTOTP,
};
interface RouteParams {
  type: "TEACHER" | "STUDENT";
  step: string;
}

interface SignupComponent extends RouteComponentProps<RouteParams> {}

// const ClassStats: React.FC<StatsComponent> = (props) => {

interface RouteParams {
  name: string;
  classcode: string;
}
interface GuestSignUpComponent extends RouteComponentProps<RouteParams> {}

const GuestSignUpForm = ({
  presetName,
  presetClasscode,
}: {
  presetName?: string;
  presetClasscode?: string;
}) => {
  const currentPath = useSelector(currentPathSelector);
  const signingUpViaJoinLink = (): boolean => {
    const isSigningViaJoin = currentPath?.includes("join-class");
    return isSigningViaJoin;
  };

  const history = useHistory();
  const hasFetchedData = useRef(false);
  const [img_url, setImg_url] = useState("");
  const signupEmail = getSignupEmail();
  const signup_type: singupType = isGoogleSignup() ? "gauth" : "otp";
  const [form] = Form.useForm();
  const status = useSelector(authStatus);

  // guest signup states
  const [classCode, setClassCode] = useState<string>(presetClasscode || "");
  const [guestName, setGuestName] = useState<string>(presetName || "");
  const [submitting, setSubmitting] = useState<boolean>(false);

  function createRandomHash(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const generateUserEmail = (name: string) => {
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ").length > 1 ? name.split(" ")[1] : "";
    const guestEmail =
      firstName + lastName + createRandomHash(5) + "@" + GUESTDOMAIN;
    return guestEmail;
  };

  useEffect(() => {
    if (guestName && classCode) {
      // call submit function if presetClassCode and GuestName is present.
      onSubmit();
    }
  }, []);

  const onSubmit = () => {
    /**
     * Check for 4 digit code and user name
     * hit signup API with firstname+lastname+5digithash + @ + Constants.Guestdomain
     * After signup, redirect to join-class page with code in URL.
     *
     */

    // const { firstName, lastName, role, img_url, otp } = values;
    // const { email } = signUpData;

    if (guestName.length == 0) {
      setSubmitting(false);
      return console.log("guest name is empty");
    }
    if (classCode.length == 0) {
      setSubmitting(false);
      return console.log("guest name is empty");
    }
    setSubmitting(true);
    const firstName = guestName.split(" ")[0];
    const lastName =
      guestName.split(" ").length > 1 ? guestName.split(" ")[1] : " ";

    const email = generateUserEmail(guestName).toLowerCase();
    const username = email;
    Sentry.captureMessage("Guest sign up clicked");
    const auth_type = "custom";
    const token = GUESTOTP;
    console.log("singup started");
    requestOTP(email)
      .then((v) => {
        signUp({
          firstName,
          lastName,
          username,
          email,
          img_url,
          role: "STUDENT",
          auth_type,
          token,
          settings: {},
          password: "",
        })
          .then(() => {
            console.log("singup complete");
            const url = "/join-class/invite/" + classCode;
            setCurrentPath(url);
            history.push(url);
            Sentry.captureMessage("Guest Signed up");
          })
          .catch((response) => {
            const { message } = response;
            notification.error({
              message:
                message + ". For any help, reach out to support@vidya.us.",
            });
            Sentry.captureMessage(message);
          })
          .finally(() => setSubmitting(false));
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Composition
        areas={signUpArea}
        autoRows={50}
        justify="center"
        minHeight={"360px"}
        className="w-full mx-auto"
      >
        {({ Title, CustomForm }) => (
          <>
            <Title className="h-20 text-xl">
              <h1 className="padding-top-1"> Join as Guest </h1>
            </Title>
            <CustomForm>
              <div className="mb-8">
                <div
                  style={{ width: "20em" }}
                  className="ml-auto mr-auto mt-12 mb-4"
                >
                  <div className="text-left text-sm text-gray-600">
                    Class Code
                  </div>
                  <Tooltip
                    placement="bottom"
                    title="Ask your host to provide this code"
                  >
                    <Input
                      size="large"
                      type="text"
                      autoComplete="classcode"
                      name="email"
                      maxLength={4}
                      minLength={4}
                      allowClear
                      className="border-red"
                      value={classCode}
                      placeholder="Enter 4-digit class code"
                      onChange={(e) => {
                        // setError(null);
                        // setEmail(e.target.value.trim())
                        // setLoginState("enter_email")
                        setClassCode(e.target.value);
                      }}
                      onError={(e) => console.log(e)}
                      onPressEnter={() => {
                        // loginState == "sign_up" ? signupClicked() : generateOTP()
                      }}
                      // prefix={<MailOutlined className="site-form-item-icon" />}
                    />
                  </Tooltip>
                  {/* <span className="text-sm text-gray-600">Ask your host to provide this code</span> */}
                </div>
                {classCode.length < 4 ? null : (
                  <>
                    <div
                      style={{ width: "20em" }}
                      className="ml-auto mr-auto mt-12 mb-4"
                    >
                      <div className="text-left text-sm text-gray-600">
                        Your Name
                      </div>
                      <Input
                        size="large"
                        type="text"
                        autoComplete="classcode"
                        name="email"
                        allowClear
                        className="border-red"
                        // value={email}
                        placeholder="Host will see your name"
                        onChange={(e) => {
                          // setError(null);
                          // setEmail(e.target.value.trim())
                          // setLoginState("enter_email")
                          setGuestName(e.target.value);
                        }}
                        onError={(e) => console.log(e)}
                        onPressEnter={() => {
                          // loginState == "sign_up" ? signupClicked() : generateOTP()
                        }}
                        // prefix={<MailOutlined className="site-form-item-icon" />}
                      />
                    </div>

                    {/* <Checkbox
                                                className="pr-8"
                                                checked={tncAccepted} onChange={(e) => setTncAccepted(e.target.checked)}>
                                                <span className="ml-2">I accept the <a target={"_blank"} href="https://vdy.app/tnc">terms and conditions </a></span>
                                            </Checkbox> */}

                    <div
                      style={{ width: "13em" }}
                      className="ml-auto mr-auto mt-12"
                    >
                      <Button
                        disabled={classCode.length != 4 || guestName.length < 2}
                        loading={submitting}
                        size="large"
                        className=""
                        type="primary"
                        style={{ width: "100%" }}
                        onClick={() => onSubmit()}
                      >
                        Join
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </CustomForm>
          </>
        )}
      </Composition>
    </>
  );
};

export default GuestSignUpForm;
