import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Composition } from "atomic-layout";
import {
  Breadcrumb,
  Button,
  Divider,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  PageHeader,
  Result,
  Select,
  Space,
  Tabs,
  DatePicker,
  Card,
  Statistic,
  Row,
  Col,
  Progress,
  Table,
  Tooltip,
  InputNumber,
  Dropdown,
} from "antd";
import { setCurrentSession } from "@/Services/Session";
import wallImage from "@/Assets/adminSessionWall.png";
import { useSelector } from "react-redux";
import {
  classesForNewSession,
  cumilativeStatsForClass,
  cumilativeStatsForStudents,
  currentSession,
  getSessionById,
  getUserById,
  isFetchingClasses,
  myClasses,
  pastSessionsForClass,
  pastSessionsForClassFromDate,
} from "@/Models/Selectors";
import {
  fetchAllClasses,
  fetchClassSessions,
  startSessionForClass,
} from "@/Services/Class";
import InviteModal from "@/Components/Class/inviteModal";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  AppstoreOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  DownOutlined,
  FundProjectionScreenOutlined,
  LaptopOutlined,
  LikeOutlined,
  NotificationOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
  UngroupOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Engagement from "@/Icons/engagement.svg";

import "./Stats.css";
import store from "@/Models/store";
import ClassStudentsStats from "./ClassStudentsStats";
import ClassSessionStats from "./ClassSessionStats";
import StudentDetailStats from "./StudentDetailStats";
import Loader from "@/Components/Loader";

import correct_icon from "@/Assets/correct.png";
import incorrect_icon from "@/Assets/refresh.png";
import absent_icon from "@/Assets/question_mark.png";
import engagement_icon from "@/Assets/exclamation.png";

import moment from "moment";
// import { DualAxes, TinyArea } from '@ant-design/charts';
import {
  LabelList,
  Bar,
  ComposedChart,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartToolTip,
  ResponsiveContainer,
  Legend,
} from "recharts";

import StatsTiles from "./StatsTiles";
import { DefaultTileCardTextColor, Subjects } from "@/Services/Constants";
import UserLabel from "../Common/Image/UserLabel";
import { stat } from "fs";
import { convertJsonToCSV, downloadCSVFile } from "../Common/CSVConverter";
import { valueType } from "antd/lib/statistic/utils";
import { uuid4 } from "@sentry/utils";
import Analytics from "@/Services/Analytics";

const { RangePicker } = DatePicker;
const DefaultDateDifference = 7; // 7 days period as default stats date range

const { Option } = Select;

const createdSessionAreas = `
            HeroImage
            Header
            Invitation
            SessionControls
`;
interface RouteParams {
  classId: string;
}
const { TabPane } = Tabs;

interface StatsComponent extends RouteComponentProps<RouteParams> {}

const SampleClassStats: React.FC<StatsComponent> = (props) => {
  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;
  const [sortedInfo, setSortedInfo] =
    useState<{ columnKey: string; order: string }>();
  const dummyStudent: User = {
    email: "richard@vidya.us",
    full_name: "Richard Holfman",
    username: "richard",
    role: "STUDENT",
    first_name: "Richard",
    last_name: "Holfman",
    img_url: "",
  };

  const sampleClass: Class = {
    id: uuid4(),
    canStartSession: true,
    description: "Maths",
    hasPreparedSession: false,
    last_session: new Date("1 Jan, 2022"),
    name: "Sample Class",
    primary_teacher: "john@doe.com",
    sessionId: "",
    sessions: [],
    state: "NO_SESSION",
    students_count: 25,
    students_email: ["richard@vidya.us"],
    students_users: [dummyStudent],
    class_invite_code: "",
    is_archived: false,
    settings: {},
  };

  const listOfClasses = useSelector(myClasses)();
  const fetchingClasses = false;
  const hasFetchedData = useRef(false);
  const allClasses = [...listOfClasses];
  // const [allClasses, setAllClasses] = useState<Class[]>([sampleClass, ...listOfClasses]);
  const [startDate, setStartDate] = useState<Date>(
    moment("January 21, 2022").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment("February 31, 2022").toDate()
  );
  const [dateDiff, setDateDiff] = useState(
    moment(endDate).diff(moment(startDate), "days")
  );
  const previousStartDate = moment(startDate)
    .subtract(dateDiff, "days")
    .toDate();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [percentageFilteredStudents, setPercentageFilteredStudents] =
    useState(50);

  const history = useHistory();

  const classIdInURL = props.match.params.classId;
  const currentClassId = useRef(classIdInURL);
  // const _class: Class = allClasses.filter((c: Class) => c.id == classIdInURL)[0];
  const _class: Class = sampleClass;
  const classStatsCurrent = useSelector(cumilativeStatsForClass)(
    classIdInURL || "",
    startDate,
    endDate
  );
  const classStatsPast = useSelector(cumilativeStatsForClass)(
    classIdInURL || "",
    previousStartDate,
    startDate
  );
  const studentsStatsCurrent = useSelector(cumilativeStatsForStudents)(
    classIdInURL || "",
    startDate,
    endDate
  );
  const sessions: SessionState[] = pastSessionsForClassFromDate(
    store.getState()
  )(classIdInURL, startDate, endDate);

  useEffect(() => {
    Analytics.track("View Sample Class Stats");
  }, []);

  useEffect(() => {
    // if (_class) {
    //     hasFetchedData.current = true;
    //     setIsFetchingData(false);
    //     fetchClassSessions(_class).then(() => {
    //         hasFetchedData.current = true;
    //         setIsFetchingData(false);
    //     }).finally(() => {
    //     });
    // }
    // return () => {
    // }
  }, [_class]);

  useEffect(() => {
    // if ((!hasFetchedData.current || currentClassId.current != classIdInURL) && !isFetchingData && _class) {
    //     fetchClassSessions(_class).then(() => {
    //         // setSessions(sessions)
    //         hasFetchedData.current = true;
    //         currentClassId.current = _class.id
    //     });
    // }
  }, [sessions]);

  const getAverageEngagement = (stats: sessionStats[]) => {
    let totalEngagement = 0;
    stats.map(
      (stat: sessionStats) =>
        (totalEngagement = totalEngagement + (stat.engagement_percent || 0))
    );

    const avgEngagement =
      totalEngagement /
      stats.filter((stat: sessionStats) => stat.engagement_percent != 0).length;

    return parseInt(avgEngagement.toFixed(0)) || 0;
  };

  const getAverageAttendance = (stats: sessionStats[]) => {
    let totalAttendance = 0;
    stats.map(
      (stat: sessionStats) =>
        (totalAttendance = totalAttendance + (stat.attendance_percent || 0))
    );

    const avgAttendance =
      totalAttendance /
      stats.filter((stat: sessionStats) => stat.attendance_percent != 0).length;

    return parseInt(avgAttendance.toFixed(0)) || 0;
  };

  const currentAverageEngagement = 75;
  const pastAverageEngagement = 50;

  const currentAverageAttendance = 80;
  const pastAverageAttendance = 90;

  const currentAverageCorrectness = 65;
  const pastAverageCorrectness = 45;

  const currentSessionCount = 20;
  const pastSessionCount = 18;

  // if (!classIdInURL && allClasses.length > 0) return <Redirect to={"analyse/class/" + allClasses[0].id + '/sessions'} />

  function onDateChange(dates: any, dateStrings: any) {
    console.log("From: ", dates[0], ", to: ", dates[1]);
    console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    // setStartDate(dates[0].toDate());
    // setEndDate(dates[1].toDate());
    // setDateDiff(moment(dates[1]).diff(moment(dates[0]), 'days'))
  }

  // const graphData = classStatsCurrent.map((stat: sessionStats) => {
  //     const statTime = stat.startDate.getDate() + '-' + (stat.startDate.getMonth() + 1) + '-' + stat.startDate.getFullYear()
  //     const sessionName = getSessionById(store.getState())(stat.sessionId).name
  //     return {
  //         time: statTime,
  //         engagement: stat.engagement_percent,
  //         attendance: stat.attendance_percent,
  //         title: sessionName + ' (' + moment(stat.startDate).format('dddd, MMMM Do') + ')'
  //     }
  // })
  const graphData = [
    {
      time: "January 1, 2022",
      correctness: 45,
      engagement: 90,
      title: "Algebra 1",
    },
    {
      time: "January 2, 2022",
      correctness: 55,
      engagement: 90,
      title: "Algebra 2",
    },
    {
      time: "January 3, 2022",
      correctness: 70,
      engagement: 80,
      title: "Calculus and analysis 1",
    },
    {
      time: "January 4, 2022",
      correctness: 64,
      engagement: 75,
      title: "Calculus and analysis 2",
    },
    {
      time: "January 5, 2022",
      correctness: 82,
      engagement: 80,
      title: "Calculus and analysis 3",
    },
    {
      time: "January 6, 2022",
      correctness: 60,
      engagement: 85,
      title: "Calculus and analysis 4",
    },
    {
      time: "January 7, 2022",
      correctness: 70,
      engagement: 84,
      title: "Geometry and topology 1",
    },
    {
      time: "January 8, 2022",
      correctness: 82,
      engagement: 100,
      title: "Geometry and topology 2",
    },
    {
      time: "January 9, 2022",
      correctness: 50,
      engagement: 70,
      title: "Combinatorics 1",
    },
    {
      time: "January 10, 2022",
      correctness: 60,
      engagement: 95,
      title: "Combinatorics 2",
    },
    {
      time: "January 11, 2022",
      correctness: 80,
      engagement: 90,
      title: "Combinatorics 3",
    },
    {
      time: "January 12, 2022",
      correctness: 90,
      engagement: 85,
      title: "Logic 1",
    },
    {
      time: "January 13, 2022",
      correctness: 65,
      engagement: 90,
      title: "Logic 2",
    },
    {
      time: "January 14, 2022",
      correctness: 45,
      engagement: 90,
      title: "Number theory 1",
    },
    {
      time: "January 15, 2022",
      correctness: 60,
      engagement: 100,
      title: "Number theory 2",
    },
    {
      time: "January 16, 2022",
      correctness: 70,
      engagement: 100,
      title: "Differential equations 1",
    },
    {
      time: "January 17, 2022",
      correctness: 75,
      engagement: 95,
      title: "Differential equations 2",
    },
    {
      time: "January 18, 2022",
      correctness: 65,
      engagement: 90,
      title: "Differential equations 3",
    },
    {
      time: "January 19, 2022",
      correctness: 80,
      engagement: 100,
      title: "Mathematical physics 1",
    },
    {
      time: "January 20, 2022",
      correctness: 85,
      engagement: 100,
      title: "Mathematical physics 2",
    },
  ];

  const engagement_data = graphData
    // .filter((stat: sessionStats) => stat.engagement_percent > 0)
    .map((stat) => stat.correctness);

  const attendance_data = graphData
    // .filter((stat: sessionStats) => stat.engagement_percent > 0)
    .map((stat) => stat.engagement);

  let arrTime = [];
  const dayMillisec = 24 * 60 * 60 * 1000;
  for (
    let q = classStatsCurrent.filter(
      (stat: sessionStats) => stat.engagement_percent > 0
    )[0]?.startDate;
    q <= endDate;
    q = new Date(q.getTime() + dayMillisec)
  ) {
    arrTime.push(q);
  }
  let session_data: number[] = [];
  arrTime.map((date: Date) => {
    const sessionExist = graphData.filter((stat) =>
      moment(new Date(stat.time)).isSame(date, "days")
    );
    session_data.push(sessionExist.length > 0 ? 1 : 0);
  });

  // const graphConfig = {
  //     data: [graphData],
  //     xField: 'time',
  //     yField: ['engagement'],
  //     geometryOptions: [
  //         // {
  //         //     geometry: 'column',
  //         // },
  //         {
  //             geometry: 'line',
  //             lineStyle: {
  //                 lineWidth: 2,
  //             },
  //         },
  //     ],
  //     tooltip: {
  //         title: 'title',
  //     }

  // };

  // const studentBreakdownDataSource = _class?.students_email?.map((email: string, index: number) => {

  //     const studentStats = studentsStatsCurrent.filter((stat: studentStats) => stat.email === email)
  //     let totalCorrect = 0;
  //     let totalResponses = 0;
  //     let totalIncorrect = 0;
  //     let totalDontKnows = 0;
  //     let totalPresent = 0;
  //     let totalQuestions = 0;

  //     studentStats.map((stat: studentStats) => {
  //         totalCorrect = totalCorrect + 100 * ((stat.correct || 0) / stat.totalQuestions)
  //         totalResponses = totalResponses + 100 * ((stat.responses || 0) / stat.totalQuestions)
  //         totalIncorrect = totalIncorrect + 100 * ((stat.incorrect || 0) / stat.totalQuestions)
  //         totalDontKnows = totalDontKnows + 100 * ((stat.dontKnow || 0) / stat.totalQuestions)
  //         totalPresent = totalPresent + (stat.absent ? 0 : 1)
  //         totalQuestions = totalQuestions + (stat.totalQuestions || 0)
  //     })

  //     const thisStudent = getUserById(store.getState())(email);

  //     return {
  //         key: classIdInURL + email,
  //         email: email,
  //         user: thisStudent,
  //         name: thisStudent.full_name,
  //         engagement: parseInt((totalResponses / (studentStats.length || 1)).toFixed(0)),
  //         correct: parseInt((totalCorrect / (studentStats.length || 1)).toFixed(0)),
  //         incorrect: parseInt((totalIncorrect / (studentStats.length || 1)).toFixed(0)),
  //         responses: totalResponses,
  //         dontKnow: parseInt((totalDontKnows / (studentStats.length || 1)).toFixed(0)),
  //         attendance: parseInt((100 * totalPresent / (studentStats.length || 1)).toFixed(0))
  //     }
  // })

  const studentBreakdownDataSource = [
    {
      key: 6226,
      email: "jennifer.carroll@vidya.us",
      user: null,
      name: "Jennifer Carroll",
      engagement: 100,
      correct: 80,
      incorrect: 10,
      responses: 100,
      dontKnow: 10,
      attendance: 100,
    },
    {
      key: 8583,
      email: "benjamin.lopez@vidya.us",
      user: null,
      name: "Benjamin Lopez",
      engagement: 55,
      correct: 30,
      incorrect: 10,
      responses: 55,
      dontKnow: 15,
      attendance: 80,
    },
    {
      key: 7311,
      email: "james.lynch@vidya.us",
      user: null,
      name: "James Lynch",
      engagement: 70,
      correct: 50,
      incorrect: 20,
      responses: 70,
      dontKnow: 0,
      attendance: 80,
    },
    {
      key: 5384,
      email: "mark.clark@vidya.us",
      user: null,
      name: "Mark Clark",
      engagement: 64,
      correct: 45,
      incorrect: 19,
      responses: 64,
      dontKnow: 0,
      attendance: 85,
    },
    {
      key: 5195,
      email: "dana.johnson@vidya.us",
      user: null,
      name: "Dana Johnson",
      engagement: 82,
      correct: 75,
      incorrect: 7,
      responses: 82,
      dontKnow: 0,
      attendance: 100,
    },
    {
      key: 1749,
      email: "jillian.arnold@vidya.us",
      user: null,
      name: "Jillian Arnold",
      engagement: 60,
      correct: 50,
      incorrect: 10,
      responses: 60,
      dontKnow: 0,
      attendance: 80,
    },
    {
      key: 9131,
      email: "melissa.harris@vidya.us",
      user: null,
      name: "Melissa Harris",
      engagement: 70,
      correct: 40,
      incorrect: 20,
      responses: 70,
      dontKnow: 10,
      attendance: 70,
    },
    {
      key: 6683,
      email: "kevin.tucker@vidya.us",
      user: null,
      name: "Kevin Tucker",
      engagement: 55,
      correct: 30,
      incorrect: 10,
      responses: 55,
      dontKnow: 15,
      attendance: 70,
    },
    {
      key: 5200,
      email: "todd.lawson@vidya.us",
      user: null,
      name: "Todd Lawson",
      engagement: 25,
      correct: 10,
      incorrect: 0,
      responses: 25,
      dontKnow: 15,
      attendance: 25,
    },
    {
      key: 9325,
      email: "timothy.walker@vidya.us",
      user: null,
      name: "Timothy Walker",
      engagement: 30,
      correct: 20,
      incorrect: 10,
      responses: 30,
      dontKnow: 0,
      attendance: 30,
    },
    {
      key: 9954,
      email: "susan.kelley@vidya.us",
      user: null,
      name: "Susan Kelley",
      engagement: 10,
      correct: 10,
      incorrect: 0,
      responses: 10,
      dontKnow: 0,
      attendance: 10,
    },
    {
      key: 3505,
      email: "amy.moss@vidya.us",
      user: null,
      name: "Amy Moss",
      engagement: 45,
      correct: 35,
      incorrect: 10,
      responses: 45,
      dontKnow: 0,
      attendance: 50,
    },
    {
      key: 291,
      email: "paige.cruz@vidya.us",
      user: null,
      name: "Paige Cruz",
      engagement: 85,
      correct: 55,
      incorrect: 15,
      responses: 85,
      dontKnow: 15,
      attendance: 90,
    },
    {
      key: 6191,
      email: "tracey.phillips@vidya.us",
      user: null,
      name: "Tracey Phillips",
      engagement: 90,
      correct: 40,
      incorrect: 20,
      responses: 90,
      dontKnow: 30,
      attendance: 100,
    },
    {
      key: 3297,
      email: "kenneth.davis@vidya.us",
      user: null,
      name: "Kenneth Davis",
      engagement: 90,
      correct: 70,
      incorrect: 15,
      responses: 90,
      dontKnow: 5,
      attendance: 90,
    },
    {
      key: 4822,
      email: "wayne.miller@vidya.us",
      user: null,
      name: "Wayne Miller",
      engagement: 100,
      correct: 75,
      incorrect: 5,
      responses: 100,
      dontKnow: 20,
      attendance: 100,
    },
    {
      key: 2416,
      email: "anthony.price@vidya.us",
      user: null,
      name: "Anthony Price",
      engagement: 100,
      correct: 65,
      incorrect: 30,
      responses: 100,
      dontKnow: 5,
      attendance: 100,
    },
    {
      key: 5597,
      email: "brian.cantu@vidya.us",
      user: null,
      name: "Brian Cantu",
      engagement: 65,
      correct: 50,
      incorrect: 10,
      responses: 65,
      dontKnow: 5,
      attendance: 70,
    },
    {
      key: 9548,
      email: "christina.robinson@vidya.us",
      user: null,
      name: "Christina Robinson",
      engagement: 80,
      correct: 60,
      incorrect: 20,
      responses: 80,
      dontKnow: 0,
      attendance: 90,
    },
    {
      key: 3326,
      email: "ronnie.myers@vidya.us",
      user: null,
      name: "Ronnie Myers",
      engagement: 85,
      correct: 80,
      incorrect: 0,
      responses: 85,
      dontKnow: 5,
      attendance: 90,
    },
  ];

  const leastEngagedStudents = [...studentBreakdownDataSource]
    .filter(
      (stat) =>
        stat.engagement > 0 && stat.engagement < percentageFilteredStudents
    )
    .sort((a, b) => a.engagement - b.engagement);

  const leastPresentStudents = [...studentBreakdownDataSource]
    .sort((a, b) => a.attendance - b.attendance)
    .filter((stat) => stat.attendance < percentageFilteredStudents);

  const mostCorrectStudents = [...studentBreakdownDataSource]
    .filter(
      (stat) =>
        stat.engagement > 0 && stat.correct >= percentageFilteredStudents
    )
    .sort((a, b) => b.correct - a.correct);

  const leastCorrectStudents = [...studentBreakdownDataSource]
    .filter(
      (stat) =>
        stat.engagement > 0 && stat.correct <= percentageFilteredStudents
    )
    .sort((a, b) => a.correct - b.correct);

  const attentionColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) =>
        text ? (
          <Link to={"/analyse/sample-class/sample-student/" + record.email}>
            <UserLabel email={record.email} />
          </Link>
        ) : (
          record.email
        ),
    },
    {
      title: "Engagement",
      dataIndex: "engagement",
      key: "engagement",
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
  ];

  const attendanceColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) =>
        text ? (
          <Link to={"/analyse/sample-class/sample-student/" + record.email}>
            {" "}
            <UserLabel email={record.email} />
          </Link>
        ) : (
          record.email
        ),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
  ];

  const correctColumn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) =>
        text ? (
          <Link to={"/analyse/sample-class/sample-student/" + record.email}>
            <UserLabel email={record.email} />
          </Link>
        ) : (
          record.email
        ),
    },
    {
      title: "correct",
      dataIndex: "correct",
      key: "correct",
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
  ];

  const allStudentsColumns: Array<any> = [
    {
      title: <UserOutlined />,
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a: studentStats, b: studentStats) => {
        if (sortedInfo?.columnKey == "attendance") {
          if (a.user && b.user) return Number(b.absent) - Number(a.absent);
        }
        if (a.user && b.user)
          return b.user?.full_name.localeCompare(a.user?.full_name);
      },
      width: 100,
      sortOrder: sortedInfo
        ? (sortedInfo.columnKey === "name" ||
            sortedInfo.columnKey === "attendance") &&
          sortedInfo.order
        : null,
      showSorterTooltip: { title: "Name" },
      render: (text: any, record: any, index: any) =>
        text ? (
          <Link to={"/analyse/sample-class/sample-student/" + record.email}>
            <UserLabel email={record.email} />
          </Link>
        ) : (
          record.email
        ),
    },
    {
      title: <StarOutlined />,
      dataIndex: "engagement",
      key: "engagement",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        parseInt(a.engagement) - parseInt(b.engagement),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "engagement" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Engagement" },
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
    {
      title: <CheckCircleOutlined />,
      dataIndex: "correct",
      key: "correct",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => parseInt(a.correct) - parseInt(b.correct),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "correct" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Correct Answers" },
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
    {
      title: <CloseCircleOutlined />,
      dataIndex: "incorrect",
      key: "incorrect",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => parseInt(a.incorrect) - parseInt(b.incorrect),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "incorrect" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Incorrect Answers" },
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
    {
      title: <QuestionCircleOutlined />,
      dataIndex: "dontKnow",
      key: "dontKnow",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => parseInt(a.dontKnow) - parseInt(b.dontKnow),
      width: 50,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "dontKnow" && sortedInfo.order
        : null,
      showSorterTooltip: { title: "Not Sures" },
      render: (text: any, record: any, index: any) => (text ? text + "%" : 0),
    },
  ];
  const handleChange = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  // if (fetchingClasses) {
  //     return <Loader />
  // }

  const downloadClassStats = () => {
    console.log("download stats for ", _class.name);
  };

  const columnsLayout = {
    xs: 24,
    sm: 12,
    md: 6,
    lg: 6,
  };

  const downloadStudentStats = () => {
    const csvArray: {}[] = [];
    studentBreakdownDataSource.forEach((stat, index) => {
      var csvObj = {
        "#": index + 1,
        Class: _class.name,
        Date_from: moment(startDate).format("LL"),
        Date_until: moment(endDate).format("LL"),
        Name: stat.name,
        email: stat.email,
        total_sessions: graphData.length,
        attendance_percentage: stat.attendance + "%",
        engagement_percentage: stat.engagement + "%",
        correct_percentage: stat.correct + "%",
        incorrect_percentage: stat.incorrect + "%",
        dont_know_percentage: stat.dontKnow + "%",
      };
      csvArray.push(csvObj);
    });
    const csvStats = convertJsonToCSV(csvArray);
    console.log(csvStats);
    downloadCSVFile(
      csvStats,
      "Vidya Students Stats - " +
        _class.name +
        " - " +
        moment(startDate).format("DD.MM.YY") +
        " - " +
        moment(endDate).format("DD.MM.YY") +
        ".csv"
    );
  };

  const downloadSessionStats = () => {
    const csvArray: {}[] = [];

    graphData.forEach((stat, index) => {
      // const _session = getSessionById(store.getState())(stat.sessionId)
      var csvObj = {
        "#": index + 1,
        Session_Name: stat.title,
        Teacher: "Mr. Schneebly",
        Class: "Maths Class",
        Date: moment(stat.time).format("LL"),
        Total_Students: studentBreakdownDataSource.length,
        Correctness_percentage: stat.correctness + "%",
        Engagement_percentage: stat.engagement + "%",
        Correct_percentage:
          (stat.engagement < 80 ? stat.engagement * 1.2 : stat.engagement) +
          "%",
      };
      csvArray.push(csvObj);
    });
    const csvStats = convertJsonToCSV(csvArray);
    console.log(csvStats);
    downloadCSVFile(
      csvStats,
      "Vidya Session Stats - " +
        _class.name +
        " - " +
        moment(startDate).format("DD.MM.YY") +
        " - " +
        moment(endDate).format("DD.MM.YY") +
        ".csv"
    );
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => downloadStudentStats()}
        icon={<UserOutlined />}
      >
        Students Stats
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => downloadSessionStats()}
        icon={<FundProjectionScreenOutlined />}
      >
        Sessions Stats
      </Menu.Item>
    </Menu>
  );

  const StudentCount: FC<{ title: string }> = (props: { title: string }) => (
    <div>
      <div
        className="float-right mb-2 mt-2"
        style={{
          justifyItems: "end",
        }}
      >
        <span className="text-gray-500 ml-2">{props.title}</span>
        <InputNumber
          defaultValue={100}
          value={percentageFilteredStudents}
          bordered={false}
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value: string | undefined) =>
            value ? parseInt(value.replace("%", "")) : 0
          }
          onChange={(e) => setPercentageFilteredStudents(50)}
          onStep={(
            value: number,
            info: { offset: valueType; type: "up" | "down" }
          ) => {
            let num =
              info.type == "up" ? (value + 4 > 100 ? 100 : value + 4) : value;
            num = info.type == "down" ? (value - 4 < 0 ? 0 : value - 4) : num;
            setPercentageFilteredStudents(num);
          }}
        />
        {/* <span >{percentageFilteredStudents === 1 ? 'student' : 'students'}</span> */}
      </div>
    </div>
  );

  const showLoader = false;

  return (
    <>
      <Layout>
        <>
          <Layout
            className="m-auto p-2 sm:p-3 sm:w-full w-full"
            style={{
              maxWidth: "1500px",
              minHeight: "100vh",
              height: "fit-content",
            }}
          >
            <Content
              className="site-layout-background"
              style={{
                padding: 0,
                margin: 12,
                minHeight: 280,
              }}
            >
              <Layout
                className="site-layout-background lyt"
                style={{
                  padding: "0 0",
                }}
              >
                <Sider
                  style={{
                    overflow: "auto",
                    // height: '100%',
                    // position: 'fixed',
                    left: 0,
                  }}
                  width={200}
                  collapsedWidth={40}
                  collapsible={true}
                  breakpoint="lg"
                  className="site-layout-background"
                >
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["001j"]}
                    defaultOpenKeys={["sub1"]}
                    style={{
                      height: "100%",
                      borderRight: 0,
                      paddingTop: 10,
                      background: "#fafafa",
                    }}
                  >
                    {allClasses.map((c) => {
                      return (
                        <Menu.Item
                          // className="pl-4"
                          // style={{ paddingLeft: 30 }}
                          // itemIcon={<div className='ml-2'>{c.description}</div>}
                          key={c.id}
                        >
                          <Link to={"/analyse/class/" + c.id + "/sessions"}>
                            {c.description}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                    <Divider />
                    <Menu.Item
                      // className="pl-4"
                      // style={{ paddingLeft: 30 }}
                      // itemIcon={<div className='ml-2'>{c.description}</div>}
                      key="001j"
                    >
                      <Link to={"/analyse/sample-class/"}>Sample Report</Link>
                    </Menu.Item>
                  </Menu>
                </Sider>
                <Content
                  className="vidya-page-layout"
                  style={{ padding: "18px 24px" }}
                >
                  {
                    // isFetchingData || (sessions.length > 0 && !classStatsCurrent.length && _class.students_email.length != 0) ?
                    // <Loader /> :
                    // !fetchingClasses
                    //     && !showLoader
                    //     && hasFetchedData
                    //     && _class
                    //     && _class.students_email.length == 0 ?
                    //     <Result
                    //         status="404"
                    //         title="No Students yet"
                    //         subTitle="You have not invited any students in this class yet."
                    //     />
                    //     :

                    <div className="text-left">
                      <PageHeader
                        style={{ padding: 0 }}
                        className="site-page-header"
                        title={_class.name}
                        subTitle={_class.description}
                        onBack={() =>
                          history.length > 1
                            ? history.goBack()
                            : history.push("/")
                        }
                      />
                      <Composition
                        areas={
                          // !showLoader && (sessions.length == 0 || classStatsCurrent.length == 0) ?
                          //     `date
                          //      noData`
                          //     :
                          `date                                                            
                                                                    tiles
                                                                    graph
                                                                    studentBreakdown
                                                                    studentList
                                                            `
                        }
                        // alignItems="start"
                        // justifyItems="start"
                        gapRow="2em"
                        width="100%"
                        style={{ display: "block" }}
                      >
                        {({
                          Date,
                          Tiles,
                          Graph,
                          StudentBreakdown,
                          SessionList,
                          StudentList,
                          NoData,
                        }) => (
                          <>
                            <div className="inline-block mb-8 w-full">
                              <Date>
                                <div className="float-right mb-4">
                                  <RangePicker
                                    style={{ width: 300 }}
                                    ranges={{
                                      Yesterday: [
                                        moment().subtract(1, "days"),
                                        moment(),
                                      ],
                                      "Last 7 days": [
                                        moment().subtract(7, "days"),
                                        moment(),
                                      ],
                                      "Last 30 days": [
                                        moment().subtract(30, "days"),
                                        moment(),
                                      ],
                                      "This month": [
                                        moment().startOf("month"),
                                        moment(),
                                      ],
                                      "Last 3 months": [
                                        moment().subtract(1, "quarter"),
                                        moment(),
                                      ],
                                    }}
                                    defaultValue={[
                                      moment("January 1, 2022"),
                                      moment("January 31, 2022"),
                                    ]}
                                    onChange={onDateChange}
                                    format="MMM Do, yy"
                                    disabled={showLoader}
                                  />
                                  <Tooltip title="Download Report">
                                    <Dropdown overlay={downloadMenu}>
                                      <Button
                                        type="ghost"
                                        className="ml-4"
                                        icon={<DownloadOutlined />}
                                        size="small"
                                      ></Button>
                                    </Dropdown>

                                    {/* <Button
                                                                                        className="ml-4"
                                                                                        onClick={() => downloadClassStats()} type="text"><DownloadOutlined /></Button> */}
                                  </Tooltip>
                                </div>
                              </Date>
                              <Divider />
                            </div>
                            <NoData>
                              <Result
                                status="404"
                                title="No Data to Analyse"
                                subTitle={
                                  "You have not conducted any sessions from " +
                                  moment(startDate).format("dddd, MMMM Do") +
                                  " to " +
                                  moment(endDate).format("dddd, MMMM Do YYYY")
                                }
                              ></Result>
                            </NoData>

                            <Tiles>
                              <div className="site-statistic-demo-card">
                                <Row
                                  gutter={[
                                    16,
                                    { xs: 8, sm: 16, md: 24, lg: 32 },
                                  ]}
                                >
                                  <Col
                                    {...columnsLayout}
                                    className="gutter-row"
                                    // span={16}
                                  >
                                    <StatsTiles
                                      value={currentAverageEngagement}
                                      icon={<StarOutlined />}
                                      title="Avg. Engagement"
                                      compareNumber={pastAverageEngagement}
                                      type="percent"
                                      graphData={engagement_data}
                                      loading={showLoader}
                                      tooltip="This is calculated by comparing Total Questions with the Total Responses throughout the selected period"
                                    />
                                  </Col>
                                  <Col
                                    {...columnsLayout}
                                    className="gutter-row"
                                    // span={8}
                                  >
                                    <StatsTiles
                                      value={currentAverageCorrectness}
                                      icon={<UserOutlined />}
                                      title="Avg. Correctness"
                                      compareNumber={pastAverageCorrectness}
                                      type="percent"
                                      graphData={attendance_data}
                                      loading={showLoader}
                                      tooltip="This is calculated by averaging the correct responses of each session throughout the selected period"
                                    />
                                  </Col>
                                  <Col
                                    {...columnsLayout}
                                    className="gutter-row"
                                    // span={8}
                                  >
                                    <StatsTiles
                                      value={currentAverageAttendance}
                                      icon={<UserOutlined />}
                                      title="Avg. Attendance"
                                      compareNumber={pastAverageAttendance}
                                      type="percent"
                                      graphData={attendance_data}
                                      loading={showLoader}
                                      tooltip="This is calculated by averaging the attendance of each session throughout the selected period"
                                    />
                                  </Col>
                                  <Col
                                    {...columnsLayout}
                                    className="gutter-row"
                                    // span={8}
                                  >
                                    <StatsTiles
                                      value={currentSessionCount}
                                      icon={<FundProjectionScreenOutlined />}
                                      title="Total Sessions"
                                      compareNumber={pastSessionCount}
                                      type="number"
                                      graphData={session_data}
                                      color={DefaultTileCardTextColor}
                                      loading={showLoader}
                                      tooltip="This is the total sessions conducted during the selected period"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Tiles>

                            <Graph>
                              {/* <DualAxes {...graphConfig}
                                                                        // loading={showLoader}
                                                                        /> */}

                              <div
                                style={{
                                  width: "100%",
                                  height: 400,
                                  marginTop: 50,
                                }}
                              >
                                <ResponsiveContainer width="100%" height="100%">
                                  <ComposedChart
                                    width={500}
                                    height={300}
                                    data={graphData}
                                    margin={{
                                      top: 50,
                                      right: 0,
                                      left: 0,
                                      bottom: 5,
                                    }}
                                  >
                                    <XAxis dataKey="time" />
                                    <YAxis />

                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}

                                    <ChartToolTip
                                      label={"title"}
                                      content={({
                                        active,
                                        payload,
                                        label,
                                      }: any) => {
                                        if (
                                          active &&
                                          payload &&
                                          payload.length
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                background: "#f0f0f082",
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                              }}
                                              className="custom-tooltip"
                                            >
                                              <p className="label bold-heading">{`${payload[0].payload.title}`}</p>
                                              <p className="intro text-green-600">{`Correctness : ${payload[0].payload.correctness}%`}</p>
                                              <p className="intro text-blue-600">{`Engagement : ${payload[0].payload.engagement}%`}</p>
                                              <p className="text-xs text-gray-500">{`${payload[0].payload.time}`}</p>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }}
                                      // formatter={(value: any, name: any, props: any) => value + "%"}
                                    />

                                    <Legend verticalAlign="top" />
                                    <Line
                                      type="monotone"
                                      dataKey="correctness"
                                      stroke="#38a169"
                                      activeDot={{ r: 8 }}
                                    />
                                    {/* <Line type="monotone" dataKey="attendance" stroke="#82ca9d" /> */}
                                  </ComposedChart>
                                </ResponsiveContainer>
                                <span
                                  className="bold-heading block"
                                  style={{ textAlign: "center" }}
                                >
                                  Engagement and Correctness Breakdown
                                </span>
                              </div>
                            </Graph>

                            <StudentBreakdown>
                              <div className="text-xl mt-12 mb-4">
                                {" "}
                                Students who are
                              </div>
                              <Tabs
                                defaultActiveKey="1"
                                style={{ display: "contents" }}
                                tabBarExtraContent={
                                  <div style={{ display: "none" }}>
                                    <div
                                      className="float-right"
                                      style={{
                                        justifyItems: "end",
                                      }}
                                    >
                                      <span className="text-gray-500 ml-2">
                                        Show max
                                      </span>

                                      <InputNumber
                                        className="border-bottom-thin"
                                        bordered={false}
                                        style={{ width: 50, textAlign: "left" }}
                                        value={percentageFilteredStudents}
                                        min={1}
                                        onChange={(e) =>
                                          setPercentageFilteredStudents(50)
                                        }
                                      />
                                      <span>
                                        {percentageFilteredStudents === 1
                                          ? "student"
                                          : "students"}
                                      </span>
                                    </div>
                                  </div>
                                }
                              >
                                <TabPane
                                  disabled={showLoader}
                                  tab={
                                    <span>
                                      <img
                                        className="w-10 ml-4 mr-2 align-middle"
                                        src={engagement_icon}
                                      />
                                      Least Engaged
                                    </span>
                                  }
                                  key="1"
                                >
                                  <span className="text-gray-500">
                                    Students who joined the sessions but
                                    responded the least number of questions
                                  </span>
                                  <StudentCount title="Students below" />
                                  <Table
                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                    pagination={{ position: [] }}
                                    dataSource={leastEngagedStudents}
                                    columns={attentionColumn}
                                    loading={showLoader}
                                  />
                                </TabPane>
                                <TabPane
                                  tab={
                                    <span>
                                      <img
                                        className="w-10 ml-4 mr-2 align-middle"
                                        src={absent_icon}
                                      />
                                      Least Present
                                    </span>
                                  }
                                  key="2"
                                >
                                  <span className="text-gray-500">
                                    Students who joined the least number of
                                    sessions. Note, Zero engagement is treated
                                    is absent.
                                  </span>
                                  <StudentCount title="Students below" />
                                  <Table
                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                    pagination={{ position: [] }}
                                    dataSource={leastPresentStudents}
                                    columns={attendanceColumn}
                                  />
                                </TabPane>
                                <TabPane
                                  tab={
                                    <span>
                                      <img
                                        className="w-10 ml-4 mr-2 align-middle"
                                        src={correct_icon}
                                      />
                                      Most Correct
                                    </span>
                                  }
                                  key="3"
                                >
                                  <span className="text-gray-500">
                                    Students who gave maximum correct responses.
                                  </span>
                                  <StudentCount title="Students above" />
                                  <Table
                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                    pagination={{ position: [] }}
                                    dataSource={mostCorrectStudents}
                                    columns={correctColumn}
                                  />
                                </TabPane>
                                <TabPane
                                  tab={
                                    <span>
                                      <img
                                        className="w-10 ml-4 mr-2 align-middle"
                                        src={incorrect_icon}
                                      />
                                      Least Correct
                                    </span>
                                  }
                                  key="4"
                                >
                                  <span className="text-gray-500">
                                    Students with the least correct responses.
                                    These would include both "incorrect" and
                                    "don't knows".
                                  </span>
                                  <StudentCount title="Students below" />
                                  <Table
                                    className="lg:w-1/2 md:w-full sd sm:w-full mt-6"
                                    pagination={{ position: [] }}
                                    dataSource={leastCorrectStudents}
                                    columns={correctColumn}
                                  />
                                </TabPane>
                              </Tabs>
                            </StudentBreakdown>

                            <SessionList>this is session list</SessionList>

                            <StudentList>
                              <div className="text-xl mt-12 mb-4">
                                {" "}
                                All Students
                              </div>
                              <Table
                                className="virtual-table"
                                //   scroll={{ y: 300, x: 100 }}
                                size="small"
                                pagination={{
                                  hideOnSinglePage: true,
                                  pageSize: 100,
                                  showSizeChanger: false,
                                }}
                                dataSource={studentBreakdownDataSource}
                                columns={allStudentsColumns}
                                onChange={handleChange}
                                loading={showLoader}
                                rowClassName={(record, index) => {
                                  const className = "text-gray-500";
                                  // @ts-ignore
                                  if (record.attendance === 0) return className;
                                  return "";
                                }}
                              />
                            </StudentList>
                          </>
                        )}
                      </Composition>
                    </div>
                  }
                </Content>
              </Layout>
            </Content>
          </Layout>
        </>
      </Layout>
    </>
  );
};

export default SampleClassStats;
