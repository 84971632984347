import { v4 as uuidv4 } from "uuid";
import store from "@/Models/store";
import Actions from "@/Models/Actions";
import { subscribe } from "@/Models/Subscriber";
import {
  authToken,
  currentSession as currentSessionSelector,
  authStatus,
  getAllStartedSessions,
} from "@/Models/Selectors";
import { getQuestionForServer } from "@/Services/Question";
import {
  joinSessionOnServer,
  handleAskQuestionSocketEvent,
  handleEndSessionSocketEvent,
  handleUpdateQuestionSocketEvent,
  handlePostAnswerSocketEvent,
} from "../Session";
import { onStartSessionSocketEvent } from "../Class";
import {
  messageHandler,
  responseHandler,
  registerMessageHandler,
} from "./incomingMessageHandler";
import axios from "axios";
import { logout } from "../Auth";

subscribe(authStatus, () => {
  const status = authStatus(store.getState());

  if (status !== "active") {
    // closeSocket();
  }
  if (status === "authenticated") {
    // createNewSocket();
  }
});

let mainSocket: WebSocket;

// @ts-ignore
window.pauschSocket = mainSocket;

registerMessageHandler(handleAskQuestionSocketEvent);
registerMessageHandler(handleEndSessionSocketEvent);
registerMessageHandler(handleUpdateQuestionSocketEvent);
registerMessageHandler(handlePostAnswerSocketEvent);
registerMessageHandler(onStartSessionSocketEvent);

const handleSocketMessage = function (eventData: string) {
  console.log(eventData);
  const eventStrings = eventData.split("\n");
  eventStrings.forEach((eventString) => {
    try {
      const data = JSON.parse(eventString);
      messageHandler(data);
    } catch (e) {
      // throw e;
      console.error(e);
    }
  });
};

/**
 *
 * @param session_id
 * @param message
 *
 *
 * Types of message to send through web socket
 *
 * 1. Post a question for admins
 * 2. Post a response for students
 *
 *
 * Types of message to be received
 *
 * 1. New question added
 * 2. student joined
 * 3. stats update
 */

type baseMessage = {
  auth_token?: string;
  session_id: string;
  class_id: string;
  msg_uuid: string;
  msg_seq_id: string;
  payload?: any;
  kind?:
    | "ASK_QUESTION"
    | "POST_ANSWER"
    | "UPDATE_QUESTION"
    | "STOP_SESSION"
    | "SESSION_STATS"
    | "FETCH_USER_ANSWERS";
};

const getBaseMessage = function (
  auth_token: string,
  session_id: string,
  class_id: string
): baseMessage {
  return {
    // auth_token,
    session_id,
    msg_uuid: uuidv4(),
    msg_seq_id: "0",
    class_id,
  };
};

const createMessage = function (
  type: string,
  messageData: any
): baseMessage | undefined {
  const auth_token = authToken(store.getState());
  const currentSession = messageData.session
    ? messageData.session
    : currentSessionSelector(store.getState());
  if (!currentSession) {
    return;
  }
  const baseMessage = getBaseMessage(
    auth_token,
    currentSession.sessionId,
    currentSession.classId
  );

  switch (type) {
    case "ASK_QUESTION":
      baseMessage.kind = "ASK_QUESTION";
      // add checks for post question type message
      baseMessage.payload = getQuestionForServer(messageData.question);
      // const { sessionId, classId, question } = messageData;
      // baseMessage.payload =
      break;
    case "POST_ANSWER":
      baseMessage.kind = "POST_ANSWER";
      baseMessage.payload = {
        question_id: messageData.questionId,
        class_id: currentSession.classId,
        answer: !messageData.dontKnow
          ? {
              text: messageData.answer,
            }
          : {},
        dont_know: messageData.dontKnow,
      };
      break;
    case "UPDATE_QUESTION":
      baseMessage.kind = "UPDATE_QUESTION";
      const question = getQuestionForServer(messageData.question);
      baseMessage.payload = {
        ...question,
      };
      break;
    case "STOP_SESSION":
      baseMessage.kind = "STOP_SESSION";
      baseMessage.payload = {
        class_id: currentSession.classId,
      };
      break;
    case "GET_SESSION_STATS":
      baseMessage.kind = "SESSION_STATS";
      baseMessage.payload = {};
      break;
    case "FETCH_USER_ANSWERS":
      baseMessage.kind = "FETCH_USER_ANSWERS";
      baseMessage.payload = {
        id: messageData.question_id,
      };
      break;
    default:
  }

  return baseMessage;
};

export const sendSocketMessage = function (
  action: any,
  responseCallback: ((x: any) => void) | undefined = undefined
): boolean {
  const socket = mainSocket;
  if (!socket) {
    return false;
  }
  const { type, payload } = action;
  const message = createMessage(type, payload);

  if (!message) {
    console.warn(`Message couldn't be created for `, action);
    return false;
  }
  if (responseCallback) {
    responseHandler(message.msg_uuid).then(responseCallback, (error) =>
      console.error(error)
    );
  }
  waitForConnection(
    () => {
      socket.send(JSON.stringify(message));
    },
    2000,
    3
  );
  return true;
};

const waitForConnection = (
  callback: () => any,
  interval: number,
  retries: number
) => {
  const socket = mainSocket;
  if (socket.readyState === 1) {
    callback();
  } else {
    if (retries == -1) {
      console.log(
        "All retries over for awaiting socket connection. Logging out."
      );
      // logout();
      return;
    }
    // var that = this;
    console.log(
      "Awaiting connection as socket ready state is ",
      socket.readyState
    );
    console.log("retry attempt ", retries);
    if (socket.readyState === 3) {
      // createNewSocket();
    }
    // optional: implement backoff for interval here
    setTimeout(function () {
      waitForConnection(callback, interval, retries - 1);
    }, interval);
  }
};

// @ts-expect-error
window.sendsoketmsg = sendSocketMessage;

export const retrySocketConnection = function () {
  const connection = mainSocket;
  if (connection?.CLOSED) {
    // createNewSocket();
  }
};

export const closeSocket = function () {
  const socket = mainSocket;
  if (socket) {
    socket.close();
    return;
  }
  console.warn("There was no socket to close.");
};

// export const createNewSocket = function (
//   isRetry: boolean = false,
//   retryCount: number = 1
// ) {
//   const auth_token = authToken(store.getState());

//   if (!auth_token) {
//     console.log("Skipping socket connection because no auth_token detected");
//     return;
//   }

//   store.dispatch({
//     type: Actions.SESSIONS.SOCKET.START_CONNECTION,
//     payload: {},
//   });
//   let connection;
//   try {
//     connection = new WebSocket(
//       process.env.REACT_APP_SOCKET_END_POINT as string,
//       [uuidv4(), auth_token as string]
//     );

//     mainSocket = connection;

//     // @ts-expect-error
//     window.pauschSocket = mainSocket;
//   } catch (e) {
//     console.error(e);
//     return;
//   }

//   connection.onclose = function (event) {
//     console.log("Connection closed", event, event.code);
//     store.dispatch({
//       type: Actions.SESSIONS.SOCKET.DISCONNECTED,
//       payload: {},
//     });
//     setTimeout(() => {
//       console.log("retrying connection");
//       // verifyAuthToken().then(() => createNewSocket(true, retryCount + 1), () => {
//       //   console.log('retry failed. Token not valid anymore');
//       //   logout();
//       // });
//     }, 1000 * retryCount);
//   };

//   connection.onerror = function (event) {
//     console.log("Connection errored", event);
//     store.dispatch({
//       type: Actions.SESSIONS.SOCKET.DISCONNECTED,
//       payload: {},
//     });
//     // setTimeout(() => createNewSocket(true, retryCount + 1), 1000 * retryCount);
//   };

//   connection.onopen = function () {
//     store.dispatch({
//       type: Actions.SESSIONS.SOCKET.CONNECTED,
//       payload: {},
//     });
//     const allStartedSession = getAllStartedSessions(store.getState());
//     allStartedSession.map((s) => joinSessionOnServer(s.sessionId));
//     retryCount = 1;
//   };

//   connection.onmessage = function (event) {
//     const eventData = event.data;
//     handleSocketMessage(eventData);
//   };
// };


export const createNewSocket = function (
  isRetry: boolean = false,
  retryCount: number = 1
) {
  const auth_token = authToken(store.getState());

  if (!auth_token) {
    console.log("Skipping socket connection because no auth_token detected");
    return;
  }

  store.dispatch({
    type: Actions.SESSIONS.SOCKET.START_CONNECTION,
    payload: {},
  });
  
  let connection;
  try {
    connection = new WebSocket(
      'ws://localhost:3006', // Directly use the endpoint
      [uuidv4(), auth_token]
    );

    mainSocket = connection;

    // @ts-expect-error
    window.pauschSocket = mainSocket;
  } catch (e) {
    console.error(e);
    return;
  }

  connection.onclose = function (event) {
    console.log("Connection closed", event, event.code);
    store.dispatch({
      type: Actions.SESSIONS.SOCKET.DISCONNECTED,
      payload: {},
    });
    setTimeout(() => {
      console.log("Retrying connection");
      createNewSocket(true, retryCount + 1); // Retry connection
    }, 1000 * retryCount);
  };

  connection.onerror = function (event) {
    console.log("Connection errored", event);
    store.dispatch({
      type: Actions.SESSIONS.SOCKET.DISCONNECTED,
      payload: {},
    });
    setTimeout(() => createNewSocket(true, retryCount + 1), 1000 * retryCount); // Retry connection
  };

  connection.onopen = function () {
    store.dispatch({
      type: Actions.SESSIONS.SOCKET.CONNECTED,
      payload: {},
    });
    const allStartedSession = getAllStartedSessions(store.getState());
    allStartedSession.map((s) => joinSessionOnServer(s.sessionId));
    retryCount = 1;
  };

  connection.onmessage = function (event) {
    const eventData = event.data;
    handleSocketMessage(eventData);
  };
};
const verifyAuthToken = () => {
  return axios.post("sign_in");
};

window.addEventListener("online", (e) => createNewSocket());
