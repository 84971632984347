import axios from "axios";
import store from "@/Models/store";
import { authToken } from "@/Models/Selectors";
import { getAccessToken, logout } from "@/Services/Auth";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (!response) {
      // store.dispatch({
      //     type: Actions.AUTH.unreachable,
      //     payload: null
      // })
      return Promise.reject(error);
    }
    if (response.status === 401) {
      const url = response.config.url;
      if (url != "sign_in" && url != "sign_up") logout();
    }
    return Promise.reject(error);
  }
);
axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT;
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
store.subscribe(() => {
  const state = store.getState();
  const token = getAccessToken();
  axios.defaults.headers.common["Authorization"] = `Bearer ` + token;
});
