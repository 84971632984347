import Started from './Started';
import Ended from './Ended';
import Created from './Created';

const AdminSession = ({ session, action }: { session: SessionState, action: string }) => {

    const { state } = session;

    if (state === 'STARTED' || action === "review") {
        return <Started action={action} session={session} />
    }
    if (state === 'CREATED') {
        return <Created session={session} />
    }
    if (state === 'ENDED' && action == "ended") {
        return <Ended session={session} />
    }

    return null;
}

export default AdminSession;