import { GoogleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  PageHeader,
  Select,
  Switch,
  Tag,
} from "antd";
import { Composition } from "atomic-layout";
import moment from "moment";
import { useEffect, useState } from "react";
import UserAvatar from "../Common/Image/UserAvatar";

const BillingSetting = ({
  userData,
  onFinish,
  onFinishFailed,
  form,
}: {
  userData: User;
  onFinish: (
    values: any,
    success: () => void,
    failed: (error: string) => void
  ) => void;
  onFinishFailed: (errorInfo: any) => void;
  form: FormInstance<any>;
}) => {
  const [saving, setSaving] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(userData?.img_url);
  const formFilled = (values: any) => {
    setSaving(true);
    message.loading("Updating", 0);
    const {
      firstName,
      lastName,
      email,
      role,
      img_url,
      city,
      phone,
      country,
      organization_name,
    } = values;
    if (userData.settings) {
      const _user: User = {
        ...userData,
        first_name: firstName,
        last_name: lastName,
        email,
        full_name: firstName + " " + lastName,
        img_url,
        role,
        settings: {
          ...userData.settings,
          city,
          country,
          phone,
          organization_name,
        },
      };

      onFinish(
        _user,
        () => {
          message.destroy();
          message.success("Profile Updated");
          setSaving(false);
        },
        (error) => {
          message.destroy();
          message.error("Update failed. Please try again");
          setSaving(false);
          // setUser
        }
      );
    }
  };

  // useEffect(() => {
  //     if (saving) {
  //         message.loading('Updating', 0);
  //     } else {}
  // }, [saving])

  return (
    <div>
      <Composition justify="start" className="lg:w-6/12 md:w-full">
        {userData.primary_institute ? (
          <span className="text-base mb-8">
            Your subscription is managed by
            <span className="font-bold text-yellow-700">
              {" "}
              {userData.primary_institute.name}.
            </span>{" "}
          </span>
        ) : null}
        <Form
          name="basic"
          labelCol={{ sm: { span: 8 }, md: { span: 8 } }}
          wrapperCol={{ sm: { span: 16 }, md: { span: 16 }, lg: { span: 12 } }}
          initialValues={userData}
          onFinish={formFilled}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label={<b>Tier</b>}
            name="Tier"
            className="text-left"
            // rules={[{ required: true, message: 'Please select an option!' }]}
          >
            {userData.primary_institute ? (
              <Tag className="" color="green">
                {userData?.settings?.type.toUpperCase()}
              </Tag>
            ) : (
              <>
                <Tag className="" color="green">
                  {userData?.settings?.type?.toUpperCase()}{" "}
                </Tag>
                {userData?.settings?.in_trial ? (
                  <Tag className="" color="geekblue">
                    FREE TRIAL
                  </Tag>
                ) : null}
              </>
            )}
          </Form.Item>

          {!userData.primary_institute ? (
            <>
              {userData?.settings?.in_trial ? (
                <Form.Item
                  label={<b>Trial Ends</b>}
                  name="trial"
                  className="text-left"
                  // rules={[{ required: true, message: 'Please select an option!' }]}
                >
                  {/* <Tag className="" color="green">{userData?.settings?.type.toUpperCase()} </Tag> */}
                  <span className="">
                    {" "}
                    {userData?.settings?.in_trial
                      ? moment(userData?.settings.trial_end).format(
                          "MMMM Do YYYY"
                        ) +
                        " (" +
                        moment(userData?.settings.trial_end).from(moment()) +
                        ")"
                      : null}
                  </span>
                </Form.Item>
              ) : null}

              {userData?.settings?.cost ? (
                <Form.Item
                  label={<b>Amount</b>}
                  name="amount"
                  className="text-left"
                  // rules={[{ required: true, message: 'Please select an option!' }]}
                >
                  {/* <Tag className="" color="green">{userData?.settings?.type.toUpperCase()} </Tag> */}
                  <span className="">
                    {userData?.settings?.currency.toUpperCase() +
                      " " +
                      userData?.settings?.cost +
                      "/" +
                      (userData?.settings?.duration == "monthly"
                        ? "month"
                        : "year")}
                  </span>
                </Form.Item>
              ) : null}

              {userData?.settings?.end_date ? (
                <Form.Item
                  label={<b>Renewal on</b>}
                  name="amount"
                  className="text-left"
                  // rules={[{ required: true, message: 'Please select an option!' }]}
                >
                  {/* <Tag className="" color="green">{userData?.settings?.type.toUpperCase()} </Tag> */}
                  <span className="">
                    {userData?.settings?.in_trial
                      ? moment(userData?.settings.start_date).format(
                          "MMMM Do YYYY"
                        ) +
                        " (" +
                        moment(userData?.settings.start_date).from(moment()) +
                        ")"
                      : moment(userData?.settings.end_date).format(
                          "MMMM Do YYYY"
                        ) +
                        " (" +
                        moment(userData?.settings.end_date).from(moment()) +
                        ")"}
                  </span>
                </Form.Item>
              ) : null}
            </>
          ) : null}
          <Form.Item
            wrapperCol={{ lg: { offset: 0, span: 0 }, offset: 0, span: 0 }}
          >
            {/* <Button type="primary"
                        htmlType="submit"
                        loading={saving}
                        disabled
                    >
                        Change Subscription
                    </Button> */}
            <div
              style={{
                marginRight: "34px",
              }}
              className="mt-2 text-blue-600 "
            >
              Please reach out to support@vidya.us to request any subscription
              changes
            </div>
          </Form.Item>
        </Form>
      </Composition>
    </div>
  );
};

export default BillingSetting;
