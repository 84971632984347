import { useEffect, useState } from "react";
import QuestionLibrary from "./QuestionLibrary";
import EditQuestionLibrary from "./EditQuestionLibrary";
import Analytics from "@/Services/Analytics";


const LibraryQuestionSelector = ({ onQuestionSelect }:
    {
        onQuestionSelect: (question: question) => void
    }) => {
    const [selectedLibrary, setSelectedLibrary] = useState<QuestionLibrary>();

    const onQuestionLibrarySelect = (library: QuestionLibrary) => {
        console.log('library selected');
        if(library) {
            Analytics.track("Library Selected During Session", library )
        }
        setSelectedLibrary(library)
    }

    useEffect(() => {
        console.log(selectedLibrary);
    }, [selectedLibrary])

    return (
        <div>{selectedLibrary
            ?
            <EditQuestionLibrary
                viewOnly
                onBackPress={() => setSelectedLibrary(undefined)}
                libraryParam={selectedLibrary}
                onQuestionSelect={onQuestionSelect} />
            :

            <QuestionLibrary viewOnly={true} onLibrarySelect={(library: QuestionLibrary) => onQuestionLibrarySelect(library)} />
        }
        </div>
    )
}

export default LibraryQuestionSelector;