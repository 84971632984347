import { useSelector } from "react-redux";
import { Layout, Menu, Modal } from "antd";
import { Composition } from "atomic-layout";
import { currentUser } from "@/Models/Selectors";
import { Link } from "react-router-dom";
import AppLogo from "@/Components/AppHeader/AppLogo";
import UserAvatar from "../Common/Image/UserAvatar";
import "./AppLogo.css";
import {
  CustomerServiceOutlined,
  EyeOutlined,
  FileTextOutlined,
  LogoutOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { logout } from "@/Services/Auth";
import BusinessLogo from "./BusinessLogo";
import { isGuestAccount } from "@/Services/User";

const appHeaderArea = `
    logo
`;
const { SubMenu } = Menu;
const { Header } = Layout;

const AppHeader = function () {
  const loggedInUser: User = useSelector(currentUser);
  const location = useLocation();

  const menus = ["class", "library", "analyse", "help", "settings"];
  const currentPage = location.pathname.split("/")[1];
  const { confirm } = Modal;

  const selectedIndex = menus.indexOf(currentPage) + 1 || "1";
  // const selectedIndex = location.pathname.startsWith("/class") ? '2' :

  // location.pathname.startsWith("/analyse") ? '3' : '1';
  // result.

  return (
    <Composition
      areas={appHeaderArea}
      justifyItems="center"
      alignItems="center"
      className="px-3 justify-between shadow-md bg-white fixed w-full z-10"
    >
      {({ Logo, AppMenu }) => (
        <>
          <Layout className="layout" style={{ width: "100vw" }}>
            <Header
              style={{
                background: "#FFF",
                padding: "0 10px",
                maxHeight: "64px",
                overflow: "hidden",
              }}
              className="header bg-white appHeader"
            >
              <Link to="/">
                <Logo>
                  {loggedInUser &&
                  loggedInUser.primary_institute &&
                  loggedInUser.primary_institute?.feature_flags.whitelabel ? (
                    <>
                      <div style={{ marginTop: "6px", position: "absolute" }}>
                        <BusinessLogo
                          imgUrl={loggedInUser.primary_institute.img_url}
                        />
                      </div>
                      <span
                        className="float-left font-bold text-gray-900"
                        style={{ marginLeft: "60px" }}
                      >
                        {loggedInUser.primary_institute.name}
                      </span>
                    </>
                  ) : (
                    <AppLogo mode="light" redirectHome />
                  )}
                </Logo>
              </Link>
              {loggedInUser && currentPage !== "signup" ? (
                <Menu
                  overflowedIndicator={<MenuOutlined />}
                  className="lg:float-right md:float-right app-top-menu justify-end"
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={["1"]}
                  selectedKeys={[selectedIndex.toString()]}
                >
                  {/* {Constants.headerMenu.forEach((header, index) =>
                    // if (header.accessRoles === loggedInUser.role || header.accessRoles === "ALL")
                    <Menu.Item key={index + 1}><Link to={header.link}>{header.title}</Link></Menu.Item>
                  )
                  }
                   */}
                  {loggedInUser.role === "TEACHER" ? (
                    <>
                      <Menu.Item key="1">
                        <Link to="/class"> Home</Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link to="/library"> Library</Link>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <Link to="/analyse"> Analyse</Link>
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      <Menu.Item key="1">
                        <Link to="/"> Home </Link>
                      </Menu.Item>
                    </>
                  )}
                  {loggedInUser && loggedInUser.role === "TEACHER" ? (
                    <SubMenu
                      key={10}
                      className="profile-menu text-black-600"
                      title={<QuestionCircleOutlined />}
                    >
                      <Menu.Item key="7" icon={<EyeOutlined />}>
                        {" "}
                        <Link className="text-gray-900" to={"/"}>
                          {" "}
                          Walkthrough
                        </Link>{" "}
                      </Menu.Item>
                      <Menu.Item key="8" icon={<FileTextOutlined />}>
                        <Link to="/settings/help">Guides and FAQs </Link>
                      </Menu.Item>
                      <Menu.Item key="9" icon={<CustomerServiceOutlined />}>
                        <a href="mailto:support@vidya.us"> Support </a>
                      </Menu.Item>
                    </SubMenu>
                  ) : null}
                  {loggedInUser ? (
                    // ? <Menu.Item className="" key="4">
                    <SubMenu
                      key={4}
                      className="profile-menu text-black-600"
                      icon={
                        <UserAvatar email={loggedInUser.email} size="small" />
                      }
                      title={
                        <Link className="text-gray-900" to={"/settings"}>
                          {loggedInUser.full_name}
                        </Link>
                      }
                    >
                      <Menu.Item key="5" icon={<SettingOutlined />}>
                        <Link to={"/settings"}> Setting </Link>
                      </Menu.Item>
                      <Menu.Item
                        key="6"
                        onClick={() => {
                          if (isGuestAccount(loggedInUser.email)) {
                            confirm({
                              title:
                                "You are logged in as Guest. You will not be able to access this session after logging out.",
                              okText: "Logout",
                              okType: "danger",
                              cancelText: "No",
                              onOk() {
                                logout();
                              },
                              onCancel() {},
                            });
                          } else {
                            logout();
                          }
                        }}
                        icon={<LogoutOutlined />}
                      >
                        Logout
                      </Menu.Item>
                    </SubMenu>
                  ) : // </Menu.Item>
                  null}
                </Menu>
              ) : null}
              {/* <SocketStatus /> */}

              {/* {currentUser ? (
                <AppMenu className="cursor-pointer">
                  <Composition templateColsMd="1fr 1fr" templateCols="1fr" gapCol="30px">
                    <div className='hidden md:inline-block'><SocketStatus /></div>
                    <Dropdown overlay={RightMenu} placement="bottomRight">
                      <div>
                        <UserAvatar email={currentUser.email} size="small" />
                        <span className="ml-2 font-bold">{first_name}</span>
                      </div>
                    </Dropdown>
                  </Composition>
                </AppMenu>
              ) : null} */}
            </Header>
          </Layout>
        </>
      )}
    </Composition>
  );
};

export default AppHeader;
