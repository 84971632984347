import React, { useEffect, useState } from "react";
import { Tooltip, Comment, Avatar } from "antd";
import { allClasses, getClassById, getUserById } from "@/Models/Selectors";
import store from "@/Models/store";
import UserAvatar from "./UserAvatar";
import { useSelector } from "react-redux";
import { fetchAllUsers, fetchUserForClass, getFirebaseUser, isGuestAccount } from "@/Services/User"
import { GuestEmailPlaceholder } from "@/Services/Constants";

type Props = {
    email: string,
    user?: User | undefined,
    label?: string | {} | undefined,
    onUserNotFound?: () => void,
    classId?: string,
    suffix?: string
};

const UserLabel = ({ user, label, suffix, email, onUserNotFound, classId }: Props) => {
    const _user = useSelector(getUserById)(email);
    const thisUser = user ? user : _user;
    const userEmail = isGuestAccount(email) ? GuestEmailPlaceholder : email
    const thisLabel = label ? label : (thisUser?.full_name ? thisUser.full_name : userEmail)
    const thisClass = useSelector(getClassById)(classId || "");
    if (!thisUser && onUserNotFound) onUserNotFound();
    if (!thisUser.email && classId) {
        if (thisClass)
            fetchUserForClass(thisClass)
                .catch(e => {
                    console.log("Error in fetching users again for classid " + classId)
                    console.log(e)
                });
        else {
            console.log("class not found to fetch users again for classid " + classId)
        }
    };

    // const classes = useSelector(allClasses);
    // if (thisLabel === email) {
    //     fetchAllUsers(Object.values(classes));
    // This is inefficient way to fetch a missing user.
    // }

    return (
        <Tooltip
            placement="right"
            style={{ background: "#fff" }}
            color="#fff"
            className="profile-tooltip"
            title={thisLabel === email ?
                <span className="text-gray-500" style={{ fontSize: "12px" }}>
                    Unable to get the user name. Please refresh the page
                </span> :
                <Comment
                    className="profile-tooltip-comment"
                    author={<span style={{ fontSize: "14px" }}>{thisUser?.full_name}</span>}
                    avatar={
                        <UserAvatar email={email} size="default" />
                    }
                    content={<span className="text-gray-500" style={{ fontSize: "12px" }}>{userEmail}</span>}
                />} >

            {/* {suffix ? thisLabel + " " + (suffix || null) : thisLabel} { } */}
        </Tooltip>)
};

export default UserLabel;






