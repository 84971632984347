const BusinessLogo = ({ imgUrl }: { imgUrl?: string }) => (
  <>
    {imgUrl ? (
      <div
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          overflow: "hidden",
          borderRadius: "50%",
          border: "#dbdbdb",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <img
          style={{
            display: "inline",
            margin: "0 auto",
            height: "fit-content",
            width: "fit-content",
          }}
          className="h-8 inline-block lg:ml-4 md:ml-4 ml-4 sm:ml-4 cursor-pointer"
          src={imgUrl}
          alt="Business Logo"
        />
      </div>
    ) : null}
  </>
);

export default BusinessLogo;
