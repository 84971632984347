import React from "react";
import LoaderGif from "@/Assets/Ripple-1s-200px.gif";
import { Spin } from "antd";

const Loader = () => {
  return (
    <div className='grid h-full items-center justify-center'
      style={{ height: "100vh" }}
    >
      {/* <img src={LoaderGif} alt='Loader' /> */}
      <Spin size="large" />

    </div>
  );
};

export default Loader;
