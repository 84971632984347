import { allClasses, getSessionById, isFetchingClasses, sessionsForClass } from '@/Models/Selectors';
import { setCurrentSession } from '@/Services/Session';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import Loader from '../Loader';
import ClassStats from './ClassSessionStats';
import StudentDetailStats from './StudentDetailStats';
import {
    currentSession as currentSessionSelector
} from "@/Models/Selectors";
import store from '@/Models/store';
import { fetchClassSessions } from '@/Services/Class';
import { Result } from 'antd';
import SampleClassStats from './SampleClassSessionStats';
import SampleStudentDetailStats from './SampleClassStudentsStats';


interface RouteParams {
    studentId: string,
    classId: string
}

interface AdminSessionComponent extends RouteComponentProps<RouteParams> {
}


const Stats: React.FC<AdminSessionComponent> = (props: AdminSessionComponent) => {
    const studentId = props.match.params.studentId;
    const classId = props.match.params.classId;
    const listOfallClasses = useSelector(allClasses)
    const fetchingClasses = isFetchingClasses(store.getState());
    const isSampleClass = props.match.path.includes('sample-class');
    const isSampleStudent = props.match.path.includes('sample-student');
    let typeOfStats: 'CLASS' | 'STUDENT' = 'CLASS'
    const history = useHistory();

    useEffect(() => {
    })



    // if (!currentSession) {
    if (fetchingClasses) {
        // return <Loader />
    }

    if (Object.keys(listOfallClasses).length === 0 && !fetchingClasses && !isSampleClass) {
        history.push('/analyse/sample-class')
        // return <Result
        //     status="404"
        //     title="No Classes"
        //     subTitle="You have not created any class yet."
        // // extra={<Button type="primary">Create Class</Button>}
        // />
    }


    if (Object.keys(listOfallClasses).length > 0 && !fetchingClasses) {
        if (classId && studentId) {
            typeOfStats = 'STUDENT';
            // migrate logic of fetching stats to individual child components

            // const _thisClass = listOfallClasses[classId];
            // if (_thisClass) fetchClassSessions(_thisClass);
            // return <Loader />
        }
    }

    return <div>
        {isSampleClass ?
            !isSampleStudent ?
                <SampleClassStats {...props} /> :
                <SampleStudentDetailStats {...props} />
            :
            <>
                {typeOfStats === 'CLASS' ? <ClassStats {...props} /> : null}
                {typeOfStats === 'STUDENT' ? <StudentDetailStats  {...props} /> : null}
            </>
        }
    </div>
}


export default Stats;